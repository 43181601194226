import { Education } from '../../types';

export default (
  state: {
    education: Education | null;
    educationPermissions: {
      view: boolean;
      edit: boolean;
    };
  },
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case 'SET_EDUCATION':
      return {
        ...state,
        education: action.payload.education,
      };
    case 'SET_EDUCATION_PERMISSIONS':
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        educationPermissions: action.payload._permissions,
      };

    default:
      throw new Error();
  }
};
