import React from 'react';

import { Router, Route } from '../../routing';
import NewsAddView from './news/NewsAddView';
import NewsDetailView from './news/NewsDetailView';
import NewsEditView from './news/NewsEditView';
import NewsManagement from './news/NewsManagement';
import InfoOverview from './InfoOverview';
import FaqManagement from './faq/FaqManagement';
import FaqForm from './faq/FaqForm';
import { RoleInterface } from '../users/Roles';

/**
 * Information
 */
export const ROUTE_INFO = '/informatie';

const infoOverview = new Route(
  'Informatie',
  ROUTE_INFO,
  <InfoOverview />,
).requireLogin();

Router.addRoute(infoOverview);

/**
 * News
 */
export const ROUTE_NEWS = `${ROUTE_INFO}/nieuws`;
export const ROUTE_NEWS_PAGE = `${ROUTE_NEWS}/pagina`;
export const ROUTE_NEWS_MANAGEMENT = '/nieuwsbeheer';

const newsManagement = new Route(
  'Nieuwsbeheer',
  ROUTE_NEWS_MANAGEMENT,
  <NewsManagement />,
).requireLogin();

const newsPager = new Route(
  'Nieuws',
  `${ROUTE_NEWS_PAGE}/:page`,
  <InfoOverview />,
).requireLogin();

const newsAddView = new Route('Nieuws toevoegen', '/toevoegen', <NewsAddView />)
  .role(['ROLE_ADMIN'])
  .permission('MANAGE_NEWS');
newsManagement.addChild(newsAddView);

const newsEditView = new Route(
  'Nieuws bewerken',
  '/bewerken/:id',
  <NewsEditView />,
)
  .requireLogin()
  .role(['ROLE_ADMIN'])
  .permission('MANAGE_NEWS');
newsManagement.addChild(newsEditView);

const newsDetailView = new Route(
  'Nieuwsbericht',
  '/nieuws/:id',
  <NewsDetailView />,
).requireLogin();
infoOverview.addChild(newsDetailView);

Router.addRoute(newsPager);
Router.addRoute(newsManagement);

/**
 * FAQ
 */
const FAQ_MANAGEMENT = '/faq-beheer';
const FAQ_CREATE = '/aanmaken';

export const FAQ_WRITE_ROLES: (keyof RoleInterface)[] = ['ROLE_ADMIN'];

export const ROUTE_FAQ_MANAGEMENT = `${ROUTE_INFO}${FAQ_MANAGEMENT}`;
export const ROUTE_FAQ_CREATE = `${ROUTE_FAQ_MANAGEMENT}${FAQ_CREATE}`;

const faqManagement = new Route('FAQ beheer', FAQ_MANAGEMENT, <FaqManagement />)
  .requireLogin()
  .role(FAQ_WRITE_ROLES);

const faqEdit = new Route('Bewerken', '/:id', <FaqForm />)
  .requireLogin()
  .role(FAQ_WRITE_ROLES);

const faqCreate = new Route('Aanmaken', FAQ_CREATE, <FaqForm />)
  .requireLogin()
  .role(FAQ_WRITE_ROLES);

infoOverview.addChild(faqManagement);
faqManagement.addChild(faqCreate);
faqManagement.addChild(faqEdit);
