import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Assignment, File } from '../../../types';
import ProductViewDialog from './ProductViewDialog';
import FileChip from '../../../components/file/FileChip';
import AssignmentChip from '../assignment/AssignmentChip';

interface ProductChipsProps {
  assignments: Assignment[];
  files: File[];
  onDeleteAssignment?: (assignment: Assignment, index: number) => void;
  onDeleteFile?: (file: File, index: number) => void;
  onFileChange?: (File: File) => void;
}

const ProductChips = (props: ProductChipsProps) => {
  const { assignments, files, onDeleteAssignment, onDeleteFile, onFileChange } =
    props;
  const [selectedProduct, setSelectedProduct] = useState<Assignment | null>(
    null,
  );

  const handleProductViewDialogClose = () => {
    setSelectedProduct(null);
  };

  return (
    <Grid container spacing={2}>
      {selectedProduct && (
        <ProductViewDialog
          product={selectedProduct}
          onClose={handleProductViewDialogClose}
          open={Boolean(selectedProduct)}
        />
      )}
      {assignments.map((assignment, index) => (
        <Grid item key={assignment.id}>
          <AssignmentChip
            assignment={assignment}
            onClick={() => setSelectedProduct(assignment)}
            onDelete={
              onDeleteAssignment
                ? () => onDeleteAssignment(assignment, index)
                : undefined
            }
          />
        </Grid>
      ))}
      {files.map((file, index) => (
        <Grid item key={file.id}>
          <FileChip
            file={file}
            onDelete={
              onDeleteFile ? () => onDeleteFile(file, index) : undefined
            }
            onChange={onFileChange}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductChips;
