import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import DataTable from '../../../components/DataTable';
import FaqRepository from './FaqRepository';
import TextMedia from '../../../components/pageheader/TextMedia';
import { ReactComponent as FaqIllustration } from '../../../assets/images/faq_illustration.svg';
import {
  FAQ_WRITE_ROLES,
  ROUTE_FAQ_CREATE,
  ROUTE_FAQ_MANAGEMENT,
} from '../index';
import { Faq } from './types';
import AppContext from '../../../AppContext';
import FaqCategoryManagerDialog from './FaqCategoryManagerDialog';
import FaqCategoryRepository from './FaqCategoryRepository';
import Loader from '../../../components/Loader';
import SearchContext from '../../../components/search/SearchContext';
import SearchInput from '../../../components/search/SearchInput';

const FaqManagement = () => {
  const history = useHistory();
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasCategories, setHasCategories] = useState<boolean>(false);
  const [categoryManagerOpen, setCategoryManagerOpen] =
    useState<boolean>(false);
  const [repository, setRepository] = useState<FaqRepository>(
    new FaqRepository(),
  );
  const [query, setQuery] = useState<string>('');

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns = [
    {
      name: 'Titel',
      field: 'title',
      sortable: false,
    },
    {
      name: 'Categorie',
      field: 'category',
      sortable: true,
      render: (faq: Faq) => <span>{faq.category.title}</span>,
    },
  ];

  const handleCreateFaqClick = () => history.push(ROUTE_FAQ_CREATE);

  const handleToggleVisibility = (id: string) => {
    repository
      .toggleVisibility(id)
      .then((response) => {
        notifications.enqueueSnackbar(
          `Item ${response.data.visible ? 'zichtbaar gemaakt' : 'verborgen'}!`,
          {
            variant: 'success',
          },
        );
        setRepository(new FaqRepository()); // Reload data table.
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij veranderen van zichtbaarheid!',
          {
            variant: 'error',
          },
        );
      });
  };

  /**
   * Handling of the category manager dialog.
   */
  const handleCategoryManagerOpen = () => setCategoryManagerOpen(true);
  const handleCategoryManagerClose = () => setCategoryManagerOpen(false);

  const itemActions = (faq: Faq, className: string) => (
    <div>
      <Tooltip title={faq.visible ? 'Verbergen' : 'Tonen'}>
        <IconButton
          size="small"
          className={className}
          onClick={() => handleToggleVisibility(faq.id)}
        >
          <FontAwesomeIcon icon={['fal', faq.visible ? 'eye' : 'eye-slash']} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Bewerken">
        <IconButton
          size="small"
          className={className}
          onClick={() => history.push(`${ROUTE_FAQ_MANAGEMENT}/${faq.id}`)}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </IconButton>
      </Tooltip>
    </div>
  );

  /**
   * On load effect.
   */
  useEffect(() => {
    new FaqCategoryRepository()
      .findBy({})
      .then((response) => {
        if (response.data.totalItems > 0) {
          setHasCategories(true);
        }
      })
      .finally(() => setLoaded(true));
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <SearchContext.Provider value={searchContextValue}>
      <FaqCategoryManagerDialog
        open={categoryManagerOpen}
        onClose={handleCategoryManagerClose}
      />

      <TextMedia
        name="FAQ beheer"
        illustration={<FaqIllustration />}
        description="Beheer hier eenvoudig alle veelgestelde vragen."
        size="medium"
      />
      <Container>
        <Box width="100%" display="flex" justifyContent="space-between" mb={3}>
          <Grid container spacing={2}>
            {roleViewManager.hasRole(FAQ_WRITE_ROLES) && (
              <Grid item>
                <Tooltip
                  title={
                    hasCategories
                      ? 'Nieuwe FAQ aanmaken'
                      : 'Er dient minimaal 1 categorie te bestaan'
                  }
                >
                  <span>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleCreateFaqClick}
                      disabled={!hasCategories}
                      startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                    >
                      Nieuwe FAQ
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
            {roleViewManager.hasRole(FAQ_WRITE_ROLES) && (
              <Grid item>
                <Button
                  startIcon={<FontAwesomeIcon icon={['fal', 'layer-group']} />}
                  onClick={handleCategoryManagerOpen}
                >
                  Categori&euml;en
                </Button>
              </Grid>
            )}
          </Grid>
          <SearchInput />
        </Box>
        <DataTable
          id="faq-management"
          repository={repository}
          columns={columns}
          actions={itemActions}
          defaultFilters={{
            order: [
              {
                field: 'category',
                order: 'asc',
              },
            ],
          }}
          deletable={roleViewManager.hasRole(FAQ_WRITE_ROLES)}
          deleteItemMessage={(item: Faq) => {
            return `Weet je zeker dat je "${item.title}" wilt verwijderen?`;
          }}
        />
      </Container>
    </SearchContext.Provider>
  );
};

export default FaqManagement;
