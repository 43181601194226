import React from 'react';
import { FormLabel, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SafeHtml from '../../../components/SafeHtml';
import { FieldProps } from './FieldProps';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Section = (props: FieldProps) => {
  const { label, description } = props;
  const classes = useStyles();

  return (
    <div>
      <FormLabel className={classes.label}>{label}</FormLabel>
      {description && <SafeHtml html={description} />}
    </div>
  );
};

export default Section;
