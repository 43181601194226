import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Education, EducationModule } from '../../../types';
import { colors } from '../../../config/theme';
import { hoursToSummary } from '../common';
import PieChart from '../../../components/data-visualization/PieChart';
import WidgetColorLabel from './WidgetColorLabel';
import EducationRepository from '../EducationRepository';
import { round } from '../../../utils/common';

interface OptionalEducationWidgetProps {
  education: Education;
}

const useStyles = makeStyles((theme: Theme) => ({
  percentage: {
    fontSize: 16,
    fontWeight: 700,
  },
  indicator: {
    width: 16,
    height: 16,
    borderRadius: 6,
    marginRight: theme.spacing(1),
  },
  indicatorFinished: {
    background: colors.coldPurple,
  },
  indicatorTodo: {
    background: colors.botticelli,
  },
}));

const OptionalEducationWidget = (props: OptionalEducationWidgetProps) => {
  const { education } = props;
  const classes = useStyles();

  const [modules, setModules] = useState<EducationModule[] | undefined>(
    undefined,
  );

  const pointsSummary = hoursToSummary(
    education.electiveCourseHours || 0,
    modules || [],
  );

  useEffect(() => {
    new EducationRepository()
      .getModules(education.id, ['IKO', 'EKO'])
      .then((response) => setModules(response.data as EducationModule[]));
  }, [education.id]);

  useEffect(() => {
    const widget = document.querySelector('#optional-education-widget');
    if (widget) {
      widget.childNodes.forEach((n) => n.remove());
    }

    new PieChart('#optional-education-widget', 100, 100)
      .text(false)
      .colors([colors.tertiary.light, colors.paleCopper, colors.mandysPink])
      .innerRadius(100 / 2 - 8)
      .plot(
        [
          { label: 'Achieved', amount: pointsSummary.achieved },
          { label: 'Not yet achieved', amount: pointsSummary.notYetAchieved },
          { label: 'Todo', amount: pointsSummary.todo },
        ],
        'label',
        'amount',
      );
  }, [
    pointsSummary.achieved,
    pointsSummary.notYetAchieved,
    pointsSummary.todo,
  ]);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      <Box mb={2} width="100%" textAlign="center">
        <div className={classes.percentage}>{pointsSummary.total}</div>
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        <div id="optional-education-widget" style={{ position: 'relative' }} />
      </Box>
      <Box mt={2} mb={2}>
        <WidgetColorLabel
          label={`${round(pointsSummary.achieved, 2)} punten afgerond`}
          color={colors.tertiary.light}
        />
        <WidgetColorLabel
          label={`${round(
            pointsSummary.notYetAchieved,
            2,
          )} punten nog niet afgerond`}
          color={colors.paleCopper}
        />
        <WidgetColorLabel
          label={`${round(pointsSummary.todo, 2)} punten nog in te schrijven`}
          color={colors.mandysPink}
        />
      </Box>
    </Box>
  );
};

export default OptionalEducationWidget;
