import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import { EditorProps } from './EditorProps';
import FileUpload from '../../../components/file/FileUpload';

const FileUploadEditor = (props: EditorProps) => {
  const { field, setPreview, onChange } = props;

  const [state, setState] = useState<{
    documents: boolean;
    audio: boolean;
    video: boolean;
    images: boolean;
  }>({
    documents:
      field.metadata && field.metadata.documents !== undefined
        ? field.metadata.documents
        : true,
    audio:
      field.metadata && field.metadata.audio !== undefined
        ? field.metadata.audio
        : false,
    video:
      field.metadata && field.metadata.video !== undefined
        ? field.metadata.video
        : false,
    images:
      field.metadata && field.metadata.images !== undefined
        ? field.metadata.images
        : true,
  });

  const { documents, audio, video, images } = state;

  useEffect(() => {
    field.metadata = {
      documents,
      audio,
      video,
      images,
    };
    onChange(field);

    setPreview(
      <div>
        <FileUpload
          onChange={() => {}}
          disabled
          documents={documents}
          audio={audio}
          video={video}
          images={images}
        />
      </div>,
    );
  }, [field, state]);

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setState({ ...state, [event.target.name]: checked });
  };

  const imageControl = (
    <Checkbox
      name="images"
      checked={images}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  const documentControl = (
    <Checkbox
      name="documents"
      checked={documents}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  const videoControl = (
    <Checkbox
      name="video"
      checked={video}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  const audioControl = (
    <Checkbox
      name="audio"
      checked={audio}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControlLabel control={imageControl} label="Afbeeldingen" />
      </Grid>
      <Grid item>
        <FormControlLabel control={documentControl} label="Documenten" />
      </Grid>
      <Grid item>
        <FormControlLabel control={videoControl} label="Videos" />
      </Grid>
      <Grid item>
        <FormControlLabel control={audioControl} label="Audio" />
      </Grid>
    </Grid>
  );
};

export default FileUploadEditor;
