import React from 'react';
import { FormEntryField } from '../types';
import Signature from '../fields/Signature';

const SignatureEntryDisplay = (props: FormEntryField) => {
  const { field } = props;

  return (
    <Signature
      id={field.id}
      label={field.label}
      description={field.description}
      metadata={field.metadata}
      options={field.options || []}
      entryField={props}
      disabled
    />
  );
};

export default SignatureEntryDisplay;
