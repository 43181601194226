import { AxiosResponse } from 'axios';

import { Education, Note } from '../../types';
import ApiClient from '../../api/ApiClient';

class NotesRepository {
  findByEducation(
    education: Education,
    educationUser: boolean = false,
  ): Promise<AxiosResponse<Note[]>> {
    return ApiClient.get(`/api/education/${education.id}/notes`, {
      educationUser,
    });
  }

  createForEducation(
    education: Education,
    message: string,
  ): Promise<AxiosResponse<Note>> {
    return ApiClient.post(`/api/education/${education.id}/notes`, {
      message,
    });
  }

  delete(education: Education, note: Note) {
    return ApiClient.delete(`/api/education/${education.id}/notes/${note.id}`);
  }
}

export default NotesRepository;
