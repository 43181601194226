import React, { useEffect, useState } from 'react';

import { FileUploadFormEntryField, FormEntryField } from '../types';
import { File as FileInterface, File } from '../../../types';
import FileChips from '../../../components/file/FileChips';

const FileUploadEntryDisplay = (props: FormEntryField) => {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (file: FileInterface) => {
    const index = files.findIndex((f) => f.id === file.id);
    files[index] = file;
    setFiles([...files]);
  };

  useEffect(() => {
    setFiles((props as FileUploadFormEntryField).files || []);
  }, [props]);

  return (
    <div>
      <FileChips files={files} onFileChange={handleFileChange} />
    </div>
  );
};

export default FileUploadEntryDisplay;
