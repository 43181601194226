import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ApiKeyRepository from './ApiKeyRepository';
import DialogCloseButton from '../../components/DialogCloseButton';

interface CreateApiKeyDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const CreateApiKeyDialog = (props: CreateApiKeyDialogProps) => {
  const { open, onClose, onSubmit } = props;
  const classes = useStyles();
  const [state, setState] = useState<{
    name: string;
    roles: string[];
    dirty: boolean;
  }>({
    name: '',
    roles: [],
    dirty: false,
  });

  const { name, roles, dirty } = state;

  const supportedRoles = Object.entries({
    ROLE_SYNCHRONIZE_AFAS: 'Synchroniseren',
    ROLE_CREATE_NOTIFICATIONS: 'Notificaties aanmaken',
    ROLE_REGISTER_USERS: 'Gebruikers registreren',
  });

  const handleSubmit = () => {
    if (name.length === 0) {
      return;
    }

    new ApiKeyRepository().create(name, roles).then(() => {
      if (onSubmit) {
        onSubmit();
      }
      onClose();
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      dirty: true,
    });
  };

  const handleRoleChange = (
    event: React.ChangeEvent<{ checked: boolean; name: string }>,
  ) => {
    const { name } = event.target;
    let newRoles = [...roles];

    if (event.target.checked) {
      newRoles.push(name);
    } else {
      newRoles = newRoles.filter((role) => role !== name);
    }

    setState({ ...state, roles: newRoles });
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        Nieuwe API key aanmaken
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Naam"
          type="text"
          value={name}
          onChange={handleChange}
          error={dirty && name.length === 0}
          fullWidth
          className={classes.field}
        />
        <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">Rechten</FormLabel>
          <FormGroup>
            {supportedRoles.map(([role, label]) => {
              const control = (
                <Checkbox
                  checked={roles.includes(role)}
                  onChange={handleRoleChange}
                  name={role}
                />
              );

              return <FormControlLabel control={control} label={label} />;
            })}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          disabled={name.length === 0}
        >
          Aanmaken
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateApiKeyDialog;
