import React from 'react';
import { Container, Box } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';

import TextMedia from '../../components/pageheader/TextMedia';
import { ReactComponent as FormsIllustration } from '../../assets/images/forms_illustration.svg';
import DataTable from '../../components/DataTable';
import SyncIssuesRepository from './SyncIssuesRepository';
import { SyncIssue } from './types';
import { ROUTE_SYNC_ISSUES } from './index';
import StatusIndicator from '../../components/StatusIndicator';

const SyncIssuesOverview = () => {
  const repository = new SyncIssuesRepository();

  const columns = [
    {
      name: 'AFAS ID',
      field: 'afasId',
      sortable: false,
      render: (issue: SyncIssue) => (
        <Link to={`${ROUTE_SYNC_ISSUES}/${issue.id}`}>
          <StatusIndicator
            indicator={issue.state === 'resolved' ? 'green' : 'red'}
          />
          <Box ml={1}>{issue.afasId}</Box>
        </Link>
      ),
    },
    {
      name: 'Foutmelding',
      field: 'message',
      sortable: false,
      render: (issue: SyncIssue) => issue.message.substring(0, 50),
    },
    {
      name: 'Eerst gezien op',
      field: 'created',
      render: (issue: SyncIssue) =>
        moment(issue.created).format('YYYY-MM-DD HH:mm'),
    },
    {
      name: 'Laatst gezien op',
      field: 'updated',
      render: (issue: SyncIssue) =>
        moment(issue.updated).format('YYYY-MM-DD HH:mm'),
    },
  ];

  return (
    <>
      <TextMedia
        name="Synchronisatie issues"
        illustration={<FormsIllustration />}
        description="Fouten die ontstaan bij het synchroniseren van gebruikers<br>komen hier terecht ter beoordeling."
        descriptionSpacing={0}
        size="medium"
      />
      <Container>
        <DataTable id="sync-issues" repository={repository} columns={columns} />
      </Container>
    </>
  );
};

export default SyncIssuesOverview;
