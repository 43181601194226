import React from 'react';
import { Grid } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { FormEntryField } from '../types';

const DateTimeEntryDisplay = (props: FormEntryField) => {
  const { value } = props;
  const { metadata } = props.field;
  const { time, range } = metadata;

  let startDate;
  let startTime;
  let endDate;
  let endTime;

  try {
    const parsedValue = JSON.parse(value);

    startDate = parsedValue.startDate;
    startTime = parsedValue.startTime;
    endDate = parsedValue.endDate;
    endTime = parsedValue.endTime;
  } catch (e) {
    // Could not parse JSON (e.g. value is empty), assume empty.
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              margin="normal"
              label="Datum"
              format="DD-MM-YYYY"
              orientation="landscape"
              autoOk
              views={['year', 'month', 'date']}
              onChange={() => {}}
              value={startDate}
              KeyboardButtonProps={{
                'aria-label': 'Verander datum',
              }}
              disabled
            />
          </Grid>
          {time && (
            <Grid item>
              <KeyboardTimePicker
                margin="normal"
                label="Tijd"
                value={startTime}
                onChange={() => {}}
                KeyboardButtonProps={{
                  'aria-label': 'Verander tijd',
                }}
                disabled
              />
            </Grid>
          )}
          {range && (
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                format="DD-MM-YYYY"
                margin="normal"
                label="Datum"
                onChange={() => {}}
                value={endDate}
                KeyboardButtonProps={{
                  'aria-label': 'Verander datum',
                }}
                disabled
              />
            </Grid>
          )}
          {range && time && (
            <Grid item>
              <KeyboardTimePicker
                name="endTime"
                margin="normal"
                label="Tijd"
                value={endTime}
                onChange={() => {}}
                KeyboardButtonProps={{
                  'aria-label': 'Verander tijd',
                }}
                disabled
              />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateTimeEntryDisplay;
