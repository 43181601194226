import { status } from '../../types';

const statusLabels: { [key in status]: string } = {
  definitely_placed: 'Definitief geplaatst',
  finished: 'Afgerond',
  finished_less_points: 'Afgerond met minder punten',
  excemption: 'Vrijstelling',
};

export default statusLabels;
