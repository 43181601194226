import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Radio,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { colors } from '../../../config/theme';
import {
  CompleteUser,
  RegisterEvent,
  RegisterEventSession,
  ScheduleItem,
} from '../../../types';
import { EventWorkshopSelection } from './EventRegisterWizard';
import EventSessionList from './EventSessionList';
import EventSessionListWorkshops from './EventSessionListWorkshops';
import UserRepository from '../../users/UserRepository';

interface EventProps {
  event: RegisterEvent;
  selectable?: boolean;
  readOnly?: boolean;
  selected?: boolean;
  expanded?: boolean;
  onSelect?: (event: RegisterEvent) => void;
  onWorkshopsUpdate?: (
    workshopSelection: EventWorkshopSelection,
    eventId: number,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${colors.alto}`,
    borderBottom: 0,
    background: colors.primary.ultraLightShade,
  },
  details: {
    padding: 0,
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
  },
}));

const Event = (props: EventProps) => {
  const classes = useStyles();
  const selectable = props.selectable === undefined ? true : props.selectable;
  const readOnly = props.readOnly === undefined ? false : props.readOnly;

  const { selected, expanded, event, onSelect, onWorkshopsUpdate } = props;

  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );

  const notifications = useSnackbar();

  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);

  const handleWorkshopsUpdate = (workshopSelection: EventWorkshopSelection) => {
    if (onWorkshopsUpdate === undefined) {
      return;
    }

    onWorkshopsUpdate(workshopSelection, event.eventId);
  };

  const sessions = useMemo(() => {
    return event.sessions.sort((a, b) => {
      if (a.date !== b.date) {
        return new Date(a.date) > new Date(b.date) ? 1 : -1;
      }

      if (a.startTime !== b.startTime) {
        return new Date(a.startTime) > new Date(b.startTime) ? 1 : -1;
      }

      if (a.endTime !== b.endTime) {
        return new Date(a.endTime) > new Date(b.endTime) ? 1 : -1;
      }

      if (a.workshop !== b.workshop) {
        return a.workshop && !b.workshop ? 1 : -1;
      }

      return a.title.localeCompare(b.title);
    });
  }, [event.sessions]);

  const sessionDates = useMemo(() => {
    return (
      sessions
        .map((session: RegisterEventSession) => session.date)
        // workaround to filter unique items
        .filter((date, i, array) => array.indexOf(date) === i)
        .map((date: string) => new Date(date))
    );
  }, [sessions]);

  const loadScheduleItems = useCallback(() => {
    if (!account.id) {
      return;
    }

    new UserRepository()
      .getScheduleAtDates(account.id, sessionDates)
      .then((response) => {
        setScheduleItems(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van de agenda.',
          {
            variant: 'error',
          },
        );
      });
  }, [account.id, sessionDates, notifications]);

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked && onSelect) {
      onSelect(event);
    }
  };

  useEffect(() => {
    loadScheduleItems();
  }, [loadScheduleItems]);

  return (
    <Accordion className={classes.root} defaultExpanded={expanded ?? false}>
      <AccordionSummary
        className={classes.header}
        expandIcon={
          <FontAwesomeIcon icon={['fal', 'chevron-down']} size="sm" />
        }
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box>
            <h2 className={classes.title}>{event.description}</h2>
            {selectable && (
              <div>
                {`Startdatum: ${moment(event.startDate).format('DD-MM-YYYY')}`}
              </div>
            )}
          </Box>
          <Box display="flex">
            {selectable && (
              <>
                {scheduleItems.length >= 1 && (
                  <Tooltip title="Let op! Dit evenement overlapt mogelijk met je agenda.">
                    <Box>
                      <FontAwesomeIcon
                        icon={['fal', 'exclamation-circle']}
                        style={{
                          color: 'red',
                          width: 24,
                          height: 24,
                          padding: '0.5em',
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                <Radio
                  checked={selected}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleSelect}
                  name="event_checked"
                />
              </>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {event.workshops ? (
          <EventSessionListWorkshops
            sessions={sessions}
            scheduleItems={scheduleItems}
            onWorkshopsUpdate={handleWorkshopsUpdate}
            mode={event.enrolled ? 'update' : 'register'}
            readOnly={readOnly}
          />
        ) : (
          <EventSessionList sessions={sessions} scheduleItems={scheduleItems} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Event;
