import { AxiosResponse, CancelTokenSource } from 'axios';

import { Faq, FaqDTO } from './types';
import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';

class FaqRepository implements Repository<Faq> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
    perPage?: number,
  ): Promise<CollectionResponse<Faq>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });

    return ApiClient.get(
      '/api/faqs',
      params,
      {},
      { cancelToken: cancelToken?.token },
    );
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/faqs/${id}`);
  }

  find(id: string): Promise<AxiosResponse<Faq>> {
    return ApiClient.get(`/api/faqs/${id}`);
  }

  create(faq: FaqDTO): Promise<AxiosResponse<Faq>> {
    return ApiClient.post('/api/faqs', faq);
  }

  update(id: string, faq: FaqDTO): Promise<AxiosResponse<Faq>> {
    return ApiClient.post(`/api/faqs/${id}`, faq);
  }

  move(id: string, direction: 'up' | 'down'): Promise<AxiosResponse> {
    return ApiClient.post(`/api/faqs/${id}/move`, { direction });
  }

  toggleVisibility(id: string): Promise<AxiosResponse<Faq>> {
    return ApiClient.post(`/api/faqs/${id}/toggle-visibility`);
  }
}

export default FaqRepository;
