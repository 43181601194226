import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

import TextMediaHeader from '../../components/pageheader/TextMedia';
import { ReactComponent as ApiKeyIllustration } from '../../assets/images/apikey_illustration.svg';
import DataTable from '../../components/DataTable';
import ApiKeyRepository from './ApiKeyRepository';
import CreateApiKeyDialog from './CreateApiKeyDialog';
import StatusIndicator from '../../components/StatusIndicator';
import { ApiKey } from '../../types';
import HiddenText from '../../components/HiddenText';

const useStyles = makeStyles((theme: Theme) => ({
  nameColumn: {
    width: '100%',
  },
  noResults: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  noResultsText: {
    width: '100%',
    textAlign: 'center',
  },
  noResultsButton: {
    marginTop: theme.spacing(2),
  },
}));

const ApiKeyOverview = () => {
  const classes = useStyles();
  const [repository, setRepository] = useState<ApiKeyRepository>(
    new ApiKeyRepository(),
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const columns = [
    {
      name: 'Naam',
      field: 'name',
      render: (apiKey: ApiKey) => (
        <div className={classes.nameColumn}>
          <StatusIndicator indicator={apiKey.enabled ? 'green' : 'red'} />
          <Box ml={2}>{apiKey.name}</Box>
        </div>
      ),
    },
    {
      name: 'Key',
      field: 'token',
      render: (apiKey: ApiKey) => <HiddenText>{apiKey.token}</HiddenText>,
    },
  ];

  const refreshData = () => setRepository(new ApiKeyRepository());

  const handleToggleApiKeyStatus = (apiKey: ApiKey) => {
    repository
      .update({
        ...apiKey,
        enabled: !apiKey.enabled,
      })
      .then(refreshData);
  };

  const itemActions = (apiKey: ApiKey, className: string) => (
    <div>
      <Tooltip title={apiKey.enabled ? 'Uitschakelen' : 'Inschakelen'}>
        <IconButton
          size="small"
          className={className}
          onClick={() => handleToggleApiKeyStatus(apiKey)}
        >
          <FontAwesomeIcon icon={['fal', apiKey.enabled ? 'ban' : 'check']} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const noResults = (
    <div className={classes.noResults}>
      <Typography variant="body1" className={classes.noResultsText}>
        Er bestaan momenteel nog geen API keys.
      </Typography>
      <div className={classes.noResultsButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
          startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        >
          API key aanmaken
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <CreateApiKeyDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={refreshData}
      />

      <TextMediaHeader
        name="API Keys"
        illustration={<ApiKeyIllustration />}
        description="Met behulp van API keys kunnen externe applicaties<br>informatie uitwisselen met de Digitale Leeromgeving."
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDialogOpen}
          startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        >
          Nieuwe API key aanmaken
        </Button>
      </TextMediaHeader>

      <Container>
        <DataTable
          id="api-key-overview"
          repository={repository}
          columns={columns}
          actions={itemActions}
          noResults={noResults}
          deleteItemMessage={(apiKey: ApiKey) => {
            return `Weet je zeker dat je de API key "${apiKey.name}" wilt verwijderen?`;
          }}
          deletable
        />
      </Container>
    </div>
  );
};

export default ApiKeyOverview;
