import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { User } from '../../types';
import TwoFactorConfiguration from './TwoFactorConfiguration';

interface TwoFactorWallProps {
  account: User;
}

const TwoFactorWall = (props: TwoFactorWallProps) => {
  const { account } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      py={3}
    >
      <Box maxWidth={700}>
        <Box mb={2}>
          <Typography variant="h1">
            Inloggen met meervoudige verificatie
          </Typography>
        </Box>

        <Typography variant="body1">
          Om gegevens in MijnNSPOH beter te beschermen is meervoudige
          verificatie nodig om toegang te krijgen tot deze omgeving. Dat
          betekent dat als je als gebruiker toegang wilt tot de leeromgeving je
          naast je gebruikersnaam en wachtwoord extra je identiteit moet
          verifiëren. Je kiest in dit scherm welke vorm van extra identificatie
          je wenselijk vindt en dient vanaf de volgende keer dat je inlogt deze
          extra authenticatie te gebruiken.
        </Typography>

        <Box mt={2} mb={2}>
          <Typography variant="body1">
            Meervoudige verificatie door:
            <ul>
              <li>Gebruik maken van authenticator app. Sterke voorkeur</li>
              <li>
                De verificatiecode op het email adres overeenkomstig je
                gebruikersnaam.
              </li>
            </ul>
          </Typography>
        </Box>

        <Box mt={2} mb={5}>
          <Typography variant="body1">
            Mocht je op een later moment van verificatiemethode willen
            veranderen dan vind je de instelling hiervoor onder je profiel.
          </Typography>
        </Box>

        <TwoFactorConfiguration
          user={account}
          context="login"
          defaultMethod="authenticator"
        />
      </Box>
    </Box>
  );
};

export default TwoFactorWall;
