import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Paper, TextField, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssignmentDialogMessage, {
  DialogMessage,
} from './AssignmentDialogMessage';
import { Assignment } from '../../../types';

interface AssignmentDialogProps {
  assignment?: Assignment;
  messages?: DialogMessage[];
  onNewMessage?: (message: string) => void;
  onDelete?: (message: DialogMessage) => void;
  onEdit?: (message: DialogMessage, newContent: string) => void;
  onChange?: (newContent: string) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    padding: theme.spacing(3),
  },
  scrollContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
}));

const AssignmentDialog = (props: AssignmentDialogProps) => {
  const {
    assignment,
    messages,
    onNewMessage,
    onDelete,
    onEdit,
    onChange,
    className,
  } = props;
  const classes = useStyles();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [newMessage, setNewMessage] = useState<string>('');

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  const scrollToBottom = () => {
    if (!scrollContainer.current) {
      return;
    }

    scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
  };

  const handleSubmit = () => {
    if (onNewMessage) {
      onNewMessage(newMessage);
    }

    setNewMessage('');
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (newMessage.length > 0) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [assignment]);

  if (!onNewMessage && assignment && assignment.messages.length === 0) {
    return <div />;
  }

  return (
    <Paper className={`${classes.root} ${className || ''}`}>
      <div className={classes.scrollContainer} ref={scrollContainer}>
        {[...(assignment ? assignment.messages : []), ...(messages || [])]
          .sort((a, b) => (new Date(a.created) > new Date(b.created) ? 1 : -1))
          .map((message) => (
            <AssignmentDialogMessage
              message={message}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
      </div>
      {onNewMessage && (
        <Box mt={2}>
          <Box mb={2}>
            <TextField
              multiline
              minRows={3}
              variant="outlined"
              value={newMessage}
              onKeyPress={handleKeyPress}
              onChange={handleMessageChange}
              fullWidth
              style={{ minWidth: 300 }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={<FontAwesomeIcon icon={['fal', 'comments']} />}
          >
            Chatbericht toevoegen
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default AssignmentDialog;
