import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import DataTable from '../../components/DataTable';
import UserRepository from './UserRepository';
import { CompleteUser, User } from '../../types';
import ApiClient from '../../api/ApiClient';
import Roles, { RoleInterface } from './Roles';
import StatusIndicator from '../../components/StatusIndicator';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';
import TextMedia from '../../components/pageheader/TextMedia';
import { ReactComponent as UsersIllustration } from '../../assets/images/users_illustration.svg';
import UserImitateButton from './components/UserImitateButton';
import SyncDialog from './SyncDialog';
import UserMergeDialog from './UserMergeDialog';
import AppContext from '../../AppContext';
import UserEducationButton from './components/UserEducationButton';

const useStyles = makeStyles((theme: Theme) => ({
  actionMargin: {
    marginRight: theme.spacing(2),
  },
  userRole: {
    marginRight: theme.spacing(1),
  },
  userRoleIcon: {
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const UsersOverview = () => {
  const classes = useStyles();
  const notifications = useSnackbar();

  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showMergeDialog, setShowMergeDialog] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [syncingUsers, setSyncingUsers] = useState<string[]>([]);
  const [repository, setRepository] = useState<UserRepository>(
    new UserRepository(),
  );
  const { roleViewManager } = useContext(AppContext);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const { account } = useSelector(
    (selector: { user: { account: CompleteUser } }) => ({
      account: selector.user.account,
    }),
  );

  /**
   * User merging callbacks.
   */
  const handleMergeDialogOpen = () => setShowMergeDialog(true);
  const handleMergeDialogClose = () => setShowMergeDialog(false);
  const handleMergeSuccess = () => {
    handleMergeDialogClose();
    notifications.enqueueSnackbar(
      'De gebruikers zijn succesvol samengevoegd.',
      { variant: 'success' },
    );
  };
  const handleMergeFail = () => {
    notifications.enqueueSnackbar('Fout bij samenvoegen gebruikers.', {
      variant: 'error',
    });
  };

  /**
   * Synchronization callbacks.
   */
  const handleOpenSyncDialog = () => setShowSyncDialog(true);
  const handleCloseSyncDialog = () => setShowSyncDialog(false);
  const handleSyncStart = () => setIsSyncing(true);
  const handleSyncFinished = () => {
    setIsSyncing(false);
    handleCloseSyncDialog();
  };

  /**
   * Sync user with AFAS.
   */
  const syncUser = (afasId: string) => {
    notifications.enqueueSnackbar('Gebruiker wordt gesynchroniseerd...');

    syncingUsers.push(afasId);
    setSyncingUsers([...syncingUsers]);

    ApiClient.syncUser(afasId)
      .then(() => {
        notifications.enqueueSnackbar('Gebruiker is gesynchroniseerd.', {
          variant: 'success',
        });
        setRepository(new UserRepository());
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het synchroniseren van de gebruiker.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        setSyncingUsers([...syncingUsers.filter((id) => id !== afasId)]);
      });
  };

  const columns = [
    {
      name: 'Naam',
      field: 'lastName',
      sortable: true,
      render: (user: User) => {
        const name = (
          <>
            <StatusIndicator indicator={user.active ? 'green' : 'red'} />
            <Box ml={1}>{user.fullNameLastNameFirst}</Box>
          </>
        );

        if (roleViewManager.isAdminView()) {
          return <Link to={`/gebruikers/${user.id}`}>{name}</Link>;
        }

        return name;
      },
    },
    ...Array.from<keyof RoleInterface>([
      'ROLE_PARTICIPANT',
      'ROLE_TEACHER',
      'ROLE_PRACTICAL_TRAINER',
      'ROLE_PROGRAM_ASSISTANT',
      'ROLE_INSTITUTE_EDUCATOR',
    ]).map((role) => ({
      name: Roles[role],
      field: `roles-${role}`,
      sortable: false,
      render: (user: User) => (
        <div>
          {user.roles.includes(role) && (
            <FontAwesomeIcon icon={['fal', 'check']} />
          )}
        </div>
      ),
    })),
    {
      name: 'AFAS ID',
      field: 'afasId',
      sortable: false,
    },
  ];

  const itemActions = (user: User, className: string) => (
    <Box display="flex">
      {(user.permissions.includes('VIEW_TEACHER') ||
        user.permissions.includes('VIEW_EDUCATOR')) && (
        <Tooltip title="Toon docent/opleider">
          <IconButton
            size="small"
            component={Link}
            to={`/gebruikers/opleider/${user.id}`}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'eye']} />
          </IconButton>
        </Tooltip>
      )}
      <UserImitateButton user={user} className={className} />
      <UserEducationButton user={user} className={className} />
      {user.permissions.includes('SYNC_USER') && user.afasId && (
        <Tooltip title="Synchroniseren met AFAS">
          <IconButton
            size="small"
            onClick={() => syncUser(user.afasId as string)}
            className={className}
          >
            <FontAwesomeIcon
              spin={syncingUsers.includes(user.afasId)}
              icon={['fal', 'sync']}
            />
          </IconButton>
        </Tooltip>
      )}
      {roleViewManager.isAdminView() && (
        <Tooltip title="Gebruiker bewerken">
          <IconButton
            size="small"
            component={Link}
            to={`/gebruikers/${user.id}/bewerken`}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <TextMedia
        name="Gebruikers"
        description={`
        Gebruikersbeheer met 1 druk op de knop. Overnemen van gebruikers,<br>
        nieuw wachtwoord aanmaken en een realtime sync met Profit. Je regelt het hier.`}
        descriptionSpacing={0}
        illustration={<UsersIllustration />}
        size="medium"
      />

      <SyncDialog
        open={showSyncDialog}
        onClose={handleCloseSyncDialog}
        onSyncStart={handleSyncStart}
        onSyncFinished={handleSyncFinished}
      />

      <UserMergeDialog
        open={showMergeDialog}
        onClose={handleMergeDialogClose}
        onSuccess={handleMergeSuccess}
        onFail={handleMergeFail}
      />

      <Container>
        <div className={classes.actions}>
          <Grid container spacing={2}>
            {account.roles.includes('ROLE_ADMIN') && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleOpenSyncDialog}
                  startIcon={
                    <FontAwesomeIcon spin={isSyncing} icon={['fal', 'sync']} />
                  }
                  className={classes.actionMargin}
                >
                  Synchroniseren
                </Button>
              </Grid>
            )}
            {account.roles.includes('ROLE_ADMIN') && (
              <Grid item>
                <Button
                  onClick={handleMergeDialogOpen}
                  startIcon={<FontAwesomeIcon icon={['fal', 'code-merge']} />}
                  className={classes.actionMargin}
                >
                  Gebruikers samenvoegen
                </Button>
              </Grid>
            )}
          </Grid>
          <SearchInput id="users-overview" persistQuery />
        </div>
        <Box mt={3}>
          <DataTable
            id="users-overview"
            repository={repository}
            columns={columns}
            actions={itemActions}
            deletable={roleViewManager.isAdminView()}
            deleteItemMessage={(user: User) => {
              return `Weet je zeker dat je ${
                user ? user.fullName : 'deze gebruiker'
              } wilt verwijderen?`;
            }}
          />
        </Box>
      </Container>
    </SearchContext.Provider>
  );
};

export default UsersOverview;
