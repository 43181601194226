import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Assignment, User } from '../../../types';
import UserImitateButton from '../../users/components/UserImitateButton';
import NullUser from '../../users/NullUser';

interface AssignmentReviewBadgeProps {
  assignment: Assignment;
}

const AssignmentReviewBadge = ({ assignment }: AssignmentReviewBadgeProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box p={2}>
          {assignment.deliveryDate && (
            <Typography variant="body1">
              {`Ingeleverd op ${moment(assignment.deliveryDate).format(
                'DD-MM-YYYY HH:mm',
              )}.`}
            </Typography>
          )}
          <Typography variant="body1">
            De opdracht wordt momenteel beoordeeld door:
          </Typography>
          {assignment.evaluators.map((evaluator) => {
            const user: User = NullUser;
            user.id = evaluator.id;
            user.email = evaluator.email;
            user.fullName = evaluator.name;
            user.roles = evaluator.roles;
            user.permissions = evaluator.permissions;

            return (
              <div key={evaluator.id}>
                {evaluator.name}
                {evaluator.permissions.includes('IMITATE_USER') && (
                  <Box ml={1} display="inline-block">
                    <UserImitateButton user={NullUser} />
                  </Box>
                )}
              </div>
            );
          })}
        </Box>
      </Popover>
      <Tooltip title="Bekijk status">
        <IconButton onClick={handleOpen} size="small">
          <FontAwesomeIcon icon={['fal', 'ballot-check']} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AssignmentReviewBadge;
