import React, { useContext } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';
import HtmlEditor from '../../../components/HtmlEditor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const Textarea = (props: FieldProps) => {
  const classes = useStyles();
  const { id, entryField, disabled, metadata } = props;
  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (event: any, editor: any) => {
    setFormData!({
      ...formData,
      [id]: {
        valid: true,
        value: editor.getData(),
      },
    });
  };

  return (
    <div className={classes.root}>
      <HtmlEditor
        onChange={handleChange}
        data={entryField ? entryField.value : ''}
        disabled={disabled}
        placeholder={metadata.placeholder || ''}
      />
    </div>
  );
};

export default Textarea;
