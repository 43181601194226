import { Education } from '../types';

export type EducationState = Education | null;

const defaultState: EducationState = null;

const education = (
  state = defaultState,
  action: { type: string; payload?: unknown },
) => {
  switch (action.type) {
    case 'SET_EDUCATION':
      return action.payload;
    default:
      return state;
  }
};

export default education;
