import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DialogCloseButtonProps {
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    width: 40,
    height: 40,
  },
}));

const DialogCloseButton = (props: DialogCloseButtonProps) => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <IconButton className={classes.closeIcon} onClick={onClose}>
      <FontAwesomeIcon icon={['fal', 'times']} />
    </IconButton>
  );
};

export default DialogCloseButton;
