import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import UserRepository from '../users/UserRepository';
import { CompleteUser, ScheduleItem } from '../../types';
import PageContainer from '../../components/PageContainer';
import Calendar from './Calendar';
import Schedule from './Schedule';
import AppContext from '../../AppContext';
import Loader from '../../components/Loader';
import AgendaContext, { AgendaState } from './AgendaContext';
import ApiClient from '../../api/ApiClient';

const repository = new UserRepository();

interface AgendaOverviewProps {
  userId?: string;
  breadcrumbs?: boolean;
  forceView?: 'participant' | 'educator';
}

const AgendaOverview = (props: AgendaOverviewProps) => {
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const id = props.userId || account.id;
  const isOwnAgenda = account.id === id;
  const { forceView } = props;

  const notifications = useSnackbar();
  const [state, setState] = useState<AgendaState>({
    ready: false,
    events: [],
    includePastItems: false,
  });

  const contextValue = useMemo(
    () => ({
      agendaState: state,
      setAgendaState: setState,
    }),
    [state, setState],
  );

  const { ready, events, includePastItems } = state;
  const [manualSyncPopover, setManualSyncPopover] =
    useState<HTMLButtonElement | null>(null);
  const [manualSyncUrl, setManualSyncUrl] = useState<string | null>(null);

  const { roleViewManager } = useContext(AppContext);
  const { localStore } = useContext(AppContext);

  /**
   * Parses a date and time string to a moment date object.
   * @param date
   * @param time
   */
  const parseDate = (date: string, time: string) =>
    moment(date)
      .hour(moment(time).hour())
      .minute(moment(time).minute())
      .toDate();

  /**
   * Fetch the events from the repository.
   */
  const fetchEvents = useCallback(async () => {
    let response: AxiosResponse<ScheduleItem[]> | null = null;

    if (
      (isOwnAgenda && roleViewManager.isEducatorView()) ||
      forceView === 'educator'
    ) {
      response = await repository.getEducatorSchedule(
        id || '0',
        includePastItems,
      );
    } else if (
      (isOwnAgenda && roleViewManager.isParticipantView()) ||
      forceView === 'participant'
    ) {
      response = await repository.getSchedule(id || '0', includePastItems);
    }

    if (!response) {
      return;
    }

    const afasItems = response.data.map((item: ScheduleItem) => ({
      ...item,
      start: parseDate(item.date, item.startTime),
      end: parseDate(item.date, item.endTime),
    }));

    setState({
      ...state,
      events: afasItems.sort((a, b) => (a.start > b.start ? 1 : -1)),
    });
  }, [id, roleViewManager, includePastItems, state, setState]);

  const getAgendaDownloadURL = () => {
    if (roleViewManager.isEducatorView()) {
      return `/api/users/${id}/educator-schedule/icalendar`;
    }

    return `/api/users/${id}/schedule/icalendar`;
  };

  const handleManualSyncPopoverClose = () => setManualSyncPopover(null);
  const handleManualSyncPopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const target = event.currentTarget;

    ApiClient.get(getAgendaDownloadURL(), { manual: true }).then((response) => {
      setManualSyncUrl(response.data.url);
      setManualSyncPopover(target);
    });
  };

  const handleCopyManualSyncURL = () => {
    if (manualSyncUrl) {
      navigator.clipboard.writeText(manualSyncUrl);
      notifications.enqueueSnackbar('Link gekopieerd naar klembord!', {
        variant: 'success',
      });
    }
  };

  /**
   * Handles the download of the iCalendar.
   */
  const handleAgendaDownload = () => {
    window.location.href = `${
      process.env.REACT_APP_API_URL
    }${getAgendaDownloadURL()}`;
  };

  /**
   * Runs fetch of events on load and certain state changes.
   */
  useEffect(() => {
    if (!ready) {
      return;
    }

    fetchEvents();
  }, [id, includePastItems, ready]);

  useEffect(() => {
    const newState = { ...state, ready: true };

    localStore
      .getItem<boolean>('agenda_include_past_items')
      .then((value) => {
        if (value !== null) {
          newState.includePastItems = value;
        }
      })
      .finally(() => {
        setState(newState);
      });
  }, []);

  if (!ready) {
    return <Loader />;
  }

  return (
    <AgendaContext.Provider value={contextValue}>
      <PageContainer breadcrumbs={props.breadcrumbs}>
        <Box
          mb={3}
          alignItems="center"
          justifyContent="flex-end"
          display="flex"
        >
          {isOwnAgenda && events.length > 0 && (
            <Box mr={1}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleAgendaDownload}
                startIcon={<FontAwesomeIcon icon={['fal', 'sync']} />}
              >
                Agenda synchroniseren
              </Button>
            </Box>
          )}
          {isOwnAgenda && events.length > 0 && (
            <>
              <Popover
                open={Boolean(manualSyncPopover)}
                onClose={handleManualSyncPopoverClose}
                anchorEl={manualSyncPopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                elevation={1}
              >
                <Box p={2}>
                  <Box style={{ maxWidth: 400 }}>
                    <strong>Handmatige link</strong>
                    <Typography variant="body1">
                      Indien de Agenda synchroniseren knop niet werkt, kun je de
                      onderstaande link handmatig toevoegen aan je kalender.
                    </Typography>
                    <Box mt={1} display="flex">
                      <Box mr={1} width="100%">
                        <TextField
                          value={manualSyncUrl}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          variant="outlined"
                        />
                      </Box>
                      <Tooltip title="Kopiëren">
                        <IconButton onClick={handleCopyManualSyncURL}>
                          <FontAwesomeIcon icon={['fal', 'clipboard']} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Popover>
              <Tooltip title="Handmatig agenda synchroniseren">
                <IconButton onClick={handleManualSyncPopoverOpen}>
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
        <Box mb={8}>
          <Calendar events={events} />
        </Box>
        <Schedule events={events} />
      </PageContainer>
    </AgendaContext.Provider>
  );
};

export default AgendaOverview;
