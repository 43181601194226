import React from 'react';
import { useParams } from 'react-router-dom';

import MoodleLoader from '../../components/MoodleLoader';

const NotificationMoodleLoader = () => {
  const { notificationId } = useParams<{ notificationId: string }>();

  return (
    <MoodleLoader endpoint={`/api/moodle/notification/${notificationId}`} />
  );
};

export default NotificationMoodleLoader;
