import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Container,
  debounce,
  IconButton,
  Input,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import EducatorEventRepository from './EducatorEventRepository';
import { ApiFilterCriteria, EducatorEvent } from '../../types';
import TextMedia from '../../components/pageheader/TextMedia';
import { ReactComponent as EventsIllustration } from '../../assets/images/events_illustration.svg';
import AppContext from '../../AppContext';
import InfiniteScroll from '../../components/InfiniteScroll';
import Loader from '../../components/Loader';
import FilterColumn, {
  FilterColumnOption,
} from '../../components/FilterColumn';
import { UserState } from '../../reducers/user';
import EducatorEventSync from './EducatorEventSync';
import EducatorUserContext from '../users/EducatorUserContext';

interface EventOverviewProps {
  educatorId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.dark,
  },
  iconInactive: {
    color: theme.palette.primary.dark,
    opacity: 0.3,
    '&.MuiIconButton-root.Mui-disabled': {
      color: theme.palette.primary.dark,
    },
  },
  table: {
    borderCollapse: 'initial',
    borderSpacing: `0 ${theme.spacing(1)}px`,
    paddingTop: 0,
  },
  tr: {
    boxShadow: theme.shadows[1],
  },
  td: {
    position: 'relative',
    padding: theme.spacing(1),
    background: '#FFF',
  },
}));

const EventOverview = (props: EventOverviewProps) => {
  const classes = useStyles();
  const { educatorId } = props;
  const { educator: educatorUser } = useContext(EducatorUserContext);
  const [onlyOwnEvents, setOnlyOwnEvents] = useState<boolean>(true);
  const [repository, setRepository] = useState<EducatorEventRepository>(
    new EducatorEventRepository(onlyOwnEvents, educatorId),
  );
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  const [loading, setLoading] = useState<boolean>(false);
  const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
  const [state, setState] = useState<{
    events: EducatorEvent[];
    orderBy: {
      field: string;
      order: 'asc' | 'desc';
    } | null;
    query?: string;
    date?: MaterialUiPickersDate;
    status?: FilterColumnOption[];
    permissions?: FilterColumnOption[];
    page: number;
    skipEducators: number;
    skipTeachers: number;
    scrollLock: boolean;
  }>({
    events: [],
    orderBy: null,
    page: 1,
    skipEducators: 0,
    skipTeachers: 0,
    scrollLock: false,
  });
  const { roleViewManager } = useContext(AppContext);
  const { localStore } = useContext(AppContext);

  const {
    events,
    date,
    status,
    permissions,
    query,
    orderBy,
    page,
    skipEducators,
    skipTeachers,
    scrollLock,
  } = state;

  const handleSync = () => {
    setRepository(new EducatorEventRepository(onlyOwnEvents, educatorId));
  };

  const loadNextPage = () => {
    // Cancel all existing tokens.
    cancelTokens.forEach((c) => {
      c.cancel();
    });

    const token = axios.CancelToken.source();
    setCancelTokens([...cancelTokens, token]);
    setLoading(true);

    const criteria: ApiFilterCriteria = {
      filters: {
        skipEducators,
        skipTeachers,
      },
    };

    if (orderBy) {
      criteria.order = [orderBy];
    }

    if (query) {
      criteria.query = query;
    }

    if (date && criteria.filters) {
      criteria.filters.date = date.format();
    }

    if (status && criteria.filters) {
      criteria.filters.status = status;
    }

    if (permissions && criteria.filters) {
      criteria.filters.permissions = permissions;
    }

    setLoading(true);
    setState({ ...state, scrollLock: true });

    repository
      .findBy(criteria, page, token)
      .then((response) => {
        const newState = { ...state };
        newState.events = [...events, ...response.data.items];

        if ('skipTeachers' in response.data) {
          newState.skipTeachers = response.data.skipTeachers;
          newState.skipEducators = response.data.skipEducators;
          newState.scrollLock = response.data.items.length === 0;
        } else {
          newState.page += 1;
          newState.scrollLock = response.data.items.length < 10;
        }

        setState(newState);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Sorting
   */
  const handleSort = (field: string) => () => {
    let order: 'asc' | 'desc' = 'asc';

    if (orderBy !== null) {
      order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    setState({
      ...state,
      events: [],
      page: 1,
      skipTeachers: 0,
      skipEducators: 0,
      orderBy: { field, order },
      scrollLock: true,
    });
  };

  const doQueryChange = debounce((query: string) => {
    setState({
      ...state,
      query,
      events: [],
      skipTeachers: 0,
      skipEducators: 0,
      page: 1,
      scrollLock: true,
    });
  }, 300);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    doQueryChange(value);
  };

  const handleFilterByDate = (date: MaterialUiPickersDate) => {
    setState({
      ...state,
      date,
      events: [],
      skipTeachers: 0,
      skipEducators: 0,
      page: 1,
      scrollLock: true,
    });
  };

  const handleFilterByStatus = (status: FilterColumnOption[]) => {
    setState({
      ...state,
      status,
      events: [],
      skipTeachers: 0,
      skipEducators: 0,
      page: 1,
      scrollLock: true,
    });
  };

  const handleFilterByPermissions = (permissions: FilterColumnOption[]) => {
    setState({
      ...state,
      permissions,
      events: [],
      skipTeachers: 0,
      skipEducators: 0,
      page: 1,
      scrollLock: true,
    });
  };

  const statusFilterOptions: FilterColumnOption[] = [
    { label: 'Voorlopig', value: 'Voorlopig' },
    { label: 'Definitief', value: 'Definitief' },
    { label: 'Afgerond', value: 'Afgerond' },
  ];

  const permissionsFilterOptions: FilterColumnOption[] = [
    { label: 'Leesrechten', value: 'read' },
    { label: 'Schrijfrechten', value: 'write' },
  ];

  useEffect(() => {
    loadNextPage();
  }, [orderBy, query, date, status, permissions, repository]);

  useEffect(() => {
    setState({
      ...state,
      scrollLock: true,
      skipEducators: 0,
      skipTeachers: 0,
      page: 1,
      events: [],
    });
    setRepository(new EducatorEventRepository(onlyOwnEvents, educatorId));
  }, [onlyOwnEvents]);

  useEffect(() => {
    localStore
      .getItem<boolean>('show_only_own_events')
      .then((value) => {
        if (value !== null) {
          setOnlyOwnEvents(value);
        }
      })
      .catch(() => {});
  }, []);

  const adornment = (
    <InputAdornment position="start">
      <FontAwesomeIcon icon={['fal', 'search']} />
    </InputAdornment>
  );

  return (
    <div>
      <TextMedia
        name="Modulen"
        illustration={<EventsIllustration />}
        // eslint-disable-next-line max-len
        description="Hier tref je het leermateriaal aan van onderwijs waar je als les- en/of waarderend docent aan gekoppeld bent danwel waar je beheerrechten voor hebt. Klik op het boekje en je kunt aan de slag!"
        descriptionSpacing={0}
        size="medium"
      />

      <Container>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Box>
            {roleViewManager.isAdminOrImitatingAdmin() && (
              <EducatorEventSync onSync={handleSync} />
            )}
          </Box>
          <Input
            type="text"
            onChange={handleQueryChange}
            placeholder="Zoek een module..."
            startAdornment={adornment}
          />
        </Box>
        <InfiniteScroll onTrigger={loadNextPage} lock={scrollLock}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">
                    <Box display="flex" alignItems="center">
                      Rechten
                      <FilterColumn
                        field="writePermissions"
                        name="writePermissions"
                        type="checkbox"
                        onChange={(permissions: FilterColumnOption[]) => {
                          handleFilterByPermissions(permissions);
                        }}
                        onDateChange={() => {}}
                        options={permissionsFilterOptions}
                        criteria={{}}
                        showLabel={false}
                      />
                    </Box>
                  </TableCell>
                  <TableCell width="60%">
                    <TableSortLabel
                      active={Boolean(orderBy && orderBy.field === 'name')}
                      direction={(orderBy && orderBy.order) || undefined}
                      onClick={handleSort('name')}
                    >
                      Naam
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="15%">
                    <Box display="flex" alignItems="center">
                      <TableSortLabel
                        active={Boolean(orderBy && orderBy.field === 'status')}
                        direction={(orderBy && orderBy.order) || undefined}
                        onClick={handleSort('status')}
                      >
                        Status
                      </TableSortLabel>
                      <FilterColumn
                        field="status"
                        name="status"
                        type="checkbox"
                        onChange={(status: FilterColumnOption[]) => {
                          handleFilterByStatus(status);
                        }}
                        onDateChange={() => {}}
                        options={statusFilterOptions}
                        criteria={{}}
                        showLabel={false}
                      />
                    </Box>
                  </TableCell>
                  <TableCell width="15%">
                    <Box display="flex" alignItems="center">
                      <TableSortLabel
                        active={Boolean(
                          orderBy && orderBy.field === 'startDate',
                        )}
                        direction={(orderBy && orderBy.order) || undefined}
                        onClick={handleSort('startDate')}
                      >
                        Startdatum
                      </TableSortLabel>
                      <FilterColumn
                        field="startDate"
                        name="startDate"
                        type="datepicker"
                        onChange={() => {}}
                        onDateChange={(date: MaterialUiPickersDate) => {
                          handleFilterByDate(date);
                        }}
                        criteria={{}}
                        showLabel={false}
                      />
                    </Box>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => {
                  const moodleUrl = `/modulen/user/${
                    educatorUser?.id || account?.id
                  }/evenement/${event.id}/leermateriaal`;

                  return (
                    <TableRow key={event.afasId} className={classes.tr}>
                      <TableCell>
                        {event.writePermissions && (
                          <FontAwesomeIcon
                            icon={['fal', 'pencil']}
                            title="Schrijfrechten"
                          />
                        )}
                        {!event.writePermissions && (
                          <FontAwesomeIcon
                            icon={['fal', 'glasses']}
                            title="Leesrechten"
                          />
                        )}
                      </TableCell>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>{event.status}</TableCell>
                      <TableCell>
                        {moment(event.startDate).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-end">
                          <Tooltip
                            title={
                              event.moodleCourseId
                                ? 'Naar leermateriaal'
                                : 'Moodle ID ontbreekt in AFAS Profit'
                            }
                          >
                            <span>
                              <IconButton
                                size="small"
                                className={`${classes.icon} ${
                                  event.moodleCourseId
                                    ? ''
                                    : classes.iconInactive
                                }`}
                                component={Link}
                                to={moodleUrl || '#'}
                                disabled={!moodleUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={['fal', 'books']} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {loading && (
              <Box width="100%" display="flex" justifyContent="center">
                <Loader inline />
              </Box>
            )}
          </>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

export default EventOverview;
