import React, { useContext, useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Education, RequestModule } from '../../../types';
import MyRequestsOverview from './MyRequestsOverview';
import MyRequestsForm from './MyRequestsForm';
import AppContext from '../../../AppContext';
import ModuleRepository from '../module/ModuleRepository';
import Loader from '../../../components/Loader';

interface MyRequestsProps {
  education: Education;
}

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
}));

const MyRequests = (props: MyRequestsProps) => {
  const { education } = props;
  const classes = useStyles();
  const { appState, setAppState } = useContext(AppContext);
  const [animationDirection, setAnimationDirection] = useState<
    'previous' | 'next'
  >('next');
  const [educationLoaded, setEducationLoaded] = useState<boolean>(true);
  const [wizardLoaded, setWizardLoaded] = useState<boolean>(false);
  const [formModule, setFormModule] = useState<{
    form: any;
    module: RequestModule | null;
  }>({
    form: null,
    module: null,
  });

  const moduleRepository = new ModuleRepository();

  // Prevent animation from starting on initial load.
  useEffect(() => {
    setTimeout(() => {
      setWizardLoaded(true);
    }, 0);
  }, []);

  const handleOpenForm = (module: RequestModule, next: () => void) => {
    setAnimationDirection('next');

    moduleRepository
      .getForm(module)
      .then((response) => {
        setFormModule({
          form: response.data,
          module,
        });

        next();
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  };

  const handlePrevious = (previous: () => void) => {
    setAnimationDirection('previous');

    setTimeout(() => {
      previous();
    }, 0);
  };

  const handleSubmit = (previous: () => void) => {
    setEducationLoaded(false);
    handlePrevious(previous);
  };

  useEffect(() => {
    setEducationLoaded(true);
  }, [education]);

  if (!educationLoaded) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <Wizard
        render={({ step }) => (
          <div
            className={`${
              animationDirection === 'previous' ? 'wizard-reverse' : ''
            } ${wizardLoaded ? 'wizard-loaded' : ''}`}
          >
            <TransitionGroup>
              <CSSTransition
                key={step.id}
                classNames="wizard"
                timeout={{ enter: 500, exit: 500 }}
              >
                <div>
                  <Steps key={step.id} step={step}>
                    <Step
                      id="overview"
                      render={({ next }) => (
                        <MyRequestsOverview
                          education={education}
                          onOpenForm={(module: RequestModule) => {
                            handleOpenForm(module, next);
                          }}
                        />
                      )}
                    />
                    <Step
                      id="form"
                      render={({ previous }) => (
                        <>
                          <IconButton
                            onClick={() => handlePrevious(previous)}
                            size="small"
                          >
                            <FontAwesomeIcon icon="arrow-left" />
                          </IconButton>
                          {formModule.form && formModule.module && (
                            <MyRequestsForm
                              module={formModule.module}
                              form={formModule.form}
                              onSubmit={() => handleSubmit(previous)}
                            />
                          )}
                        </>
                      )}
                    />
                  </Steps>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      />
    </div>
  );
};

export default MyRequests;
