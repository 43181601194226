import { createContext, Dispatch, SetStateAction } from 'react';
import { ScheduleItem } from '../../types';

export interface AgendaState {
  ready: boolean;
  events: ScheduleItem[];
  includePastItems: boolean;
}

const AgendaContext = createContext<{
  agendaState: AgendaState;
  setAgendaState: Dispatch<SetStateAction<AgendaState>>;
}>({
  agendaState: {
    ready: false,
    events: [],
    includePastItems: false,
  },
  setAgendaState: () => {},
});

export default AgendaContext;
