import moment from 'moment';
import { Module, PresenceItem } from '../../types';

interface pointsSummary {
  total: number;
  achieved: number;
  notYetAchieved: number;
  todo: number;
  percentageAchieved: number;
  percentageNotYetAchieved: number;
}

// eslint-disable-next-line import/prefer-default-export
export const hoursToSummary = (
  hours: number,
  modules: Module[],
): pointsSummary => {
  const pointsAchieved = modules
    .filter((m) => m.finished)
    .reduce((amount, module) => amount + (module.points || 0), 0);
  const pointsNotYetAchieved = modules
    .filter((m) => !m.finished)
    .reduce((amount, module) => amount + (module.points || 0), 0);
  const pointsTodo = Math.max(hours - pointsAchieved - pointsNotYetAchieved, 0);
  const percentageAchieved = 100 * (Math.min(hours, pointsAchieved) / hours);
  const percentageNotYetAchieved =
    100 * (Math.min(hours, pointsNotYetAchieved) / hours);

  return {
    total: hours,
    achieved: pointsAchieved,
    notYetAchieved: pointsNotYetAchieved,
    todo: pointsTodo,
    percentageAchieved,
    percentageNotYetAchieved,
  };
};

export const getPresenceItemMessage = (presenceItem: PresenceItem): string => {
  let message = `Je hebt onderwijs gemist of gaat onderwijs missen, '${presenceItem.sessionName}' `;

  if (presenceItem.sessionStartTime && presenceItem.sessionEndTime) {
    const catchingUpStartTime = new Date(presenceItem.sessionStartTime);
    const catchingUpEndTime = new Date(presenceItem.sessionEndTime);

    const dateText = moment(catchingUpStartTime).format('DD-MM-YYYY');
    const startTimeText = moment(catchingUpStartTime).format('HH:mm');
    const endTimeText = moment(catchingUpEndTime).format('HH:mm');

    message += `op ${dateText} van ${startTimeText} tot ${endTimeText} `;
  }

  if (
    presenceItem.catchingUpPresenceItemId &&
    presenceItem.catchingUpSessionName &&
    presenceItem.catchingUpSessionStartTime &&
    presenceItem.catchingUpSessionEndTime
  ) {
    const catchingUpStartTime = new Date(
      presenceItem.catchingUpSessionStartTime,
    );
    const catchingUpEndTime = new Date(presenceItem.catchingUpSessionEndTime);

    const catchingUpDateText = moment(catchingUpStartTime).format('DD-MM-YYYY');
    const catchingUpStartTimeText = moment(catchingUpStartTime).format('HH:mm');
    const catchingUpEndTimeText = moment(catchingUpEndTime).format('HH:mm');

    message += `en haalt dit in bij '${presenceItem.catchingUpSessionName}' `;
    message += `op ${catchingUpDateText} van ${catchingUpStartTimeText} tot ${catchingUpEndTimeText} `;
  }

  if (
    presenceItem.catchingUpAssignmentId &&
    presenceItem.catchingUpAssignmentName
  ) {
    message += `en haalt dit in met opdracht '${presenceItem.catchingUpAssignmentName}' `;
  }

  return message;
};
