import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';

import { EditorProps } from './EditorProps';
import LabelList, { Label } from '../LabelList';
import useFormFieldStyles from '../FormFieldStyles';

const ChoiceListEditor = (props: EditorProps) => {
  const classes = useFormFieldStyles();
  const { field, onChange, setPreview } = props;
  const [state, setState] = useState<{
    labels: Label[];
    multiple: boolean;
  }>({
    labels: field.options || [],
    multiple: field.metadata.multiple || false,
  });

  const { labels, multiple } = state;

  useEffect(() => {
    field.options = labels;
    field.metadata = { ...field.metadata, multiple };

    onChange(field);

    setPreview(
      <div>
        {labels.length > 0 && (
          <div className={classes.preview}>
            {labels.map((label) => (
              <div key={label.id}>
                <FormControlLabel
                  key={label.id}
                  control={
                    multiple ? <Checkbox disabled /> : <Radio disabled />
                  }
                  label={label.label}
                />
              </div>
            ))}
          </div>
        )}
      </div>,
    );
  }, [field, labels, state]);

  const handleChange = (labels: Label[]) => {
    setState({ ...state, labels });
  };

  const handleMultipleChange = (
    event: React.ChangeEvent<{ checked: boolean }>,
  ) => {
    setState({ ...state, multiple: event.target.checked });
  };

  const answerControl = (
    <Checkbox
      checked={multiple}
      onChange={handleMultipleChange}
      name="multiple"
    />
  );

  return (
    <div>
      <FormControlLabel
        control={answerControl}
        label="Meerdere antwoorden mogelijk"
      />

      <Typography className={classes.title} component="h3">
        Keuzes
      </Typography>

      <LabelList onChange={handleChange} labels={labels} />
    </div>
  );
};

export default ChoiceListEditor;
