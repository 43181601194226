import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import NewsRepository from './NewsRepository';
import Loader from '../../../components/Loader';
import { ROUTE_NEWS, ROUTE_NEWS_PAGE } from '../index';
import Pagination from './Paginator';
import NewsItem from './NewsItem';
import { NewsItem as NewsItemInterface } from '../../../types';

const NewsOverview = () => {
  const history = useHistory();
  const { page } = useParams<{ page: string }>();
  const notifications = useSnackbar();

  const PAGER = 10;
  const [items, setItems] = useState<NewsItemInterface[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(
    page ? parseInt(page, 10) : 1,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const news = new NewsRepository();

  const fetchNews = async () => {
    setLoading(true);

    await news
      .findBy({}, currentPage, undefined, PAGER)
      .then(({ data }) => {
        if (data.items) {
          setItems(data.items);
        }

        setTotal(Math.ceil(data.totalItems / PAGER));
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van het nieuws',
          {
            variant: 'error',
          },
        );
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, [currentPage]);

  /**
   * Pagination handler.
   */
  const paginationOnChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    history.push(`${ROUTE_NEWS_PAGE}/${page}`);
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  /**
   * News item click handler.
   */
  const onClick = (id: string) => {
    if (!id) return;
    history.push(`${ROUTE_NEWS}/${id}`);
  };

  /**
   * Render news items.
   */
  const loadNewsItem = items.map(
    ({ id, title, content, image, published, publishedStart, read }) => (
      <div key={`title-${id}`}>
        <NewsItem
          id={id}
          published={published}
          publishedStart={publishedStart}
          title={title}
          content={content}
          image={image}
          onClick={onClick}
          read={read}
        />
      </div>
    ),
  );

  /**
   * Return news items.
   */
  return (
    <>
      <Container>
        {items.length > 0 && loading ? (
          <Loader inline />
        ) : (
          <>
            <Box mt={3}>{loadNewsItem}</Box>
            <Box display="flex" justifyContent="center">
              <Pagination
                onChange={paginationOnChange}
                page={currentPage}
                count={total}
              />
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
export default NewsOverview;
