import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, Link, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from './config/theme';
import { ReactComponent as NspohLogo } from './assets/images/main_logo_nspoh.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    overflow: 'hidden',
    marginTop: theme.spacing(6),
    background: theme.palette.primary.main,
  },
  container: {
    position: 'relative',
    padding: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100%',
      transform: 'translateX(-100%)',
      background: colors.primary.ultraLight,
    },
  },
  firstBlock: {
    color: theme.palette.primary.main,
    background: colors.primary.ultraLight,
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    order: 0,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  contrastBlock: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    '& a': {
      color: theme.palette.primary.contrastText,
    },
    '& ul': {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      listStyleType: 'none',
      paddingLeft: 0,
      '& li': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  logo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 70,
  },
  blockTitle: {
    fontWeight: 700,
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: theme.spacing(1),
  },
  nfirLink: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
  },
  socialLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: colors.tertiary.main,
    width: 32,
    height: 32,
    transition: theme.transitions.create('background'),
    '&:hover': {
      background: colors.tertiary.light,
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={4} sm={12} xs={12} className={classes.firstBlock}>
            <div className={classes.blockTitle}>Mede mogelijk gemaakt door</div>
            <a
              href="https://www.nspoh.nl"
              title="NSPOH website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <NspohLogo className={classes.logo} />
            </a>
            <div>
              <div>Succesvolle pentest november 2022</div>
              <div>
                uitgevoerd door
                <a
                  href="https://www.nfir.nl"
                  title="NFIR BV"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.nfirLink}
                >
                  NFIR BV
                </a>
              </div>
            </div>
          </Grid>
          <Grid item md={5} sm={7} xs={12} className={classes.contrastBlock}>
            <div className={classes.blockTitle}>Overige informatie</div>
            <Grid container>
              <Grid item md={6}>
                <ul>
                  <li>
                    <a
                      href="https://www.nspoh.nl/privacy-verklaring/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy en disclaimer
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nspoh.nl/cookie-verklaring/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie voorwaarden
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nspoh.nl/accreditatie/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Accreditatie en erkenning
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nspoh.nl/onze-kijk-op-leren-opleiden/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visie op leren en opleiden
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item md={6}>
                <ul>
                  <li>
                    <a
                      href="https://www.nspoh.nl/voorwaarden-prijzen-herroepingsrecht/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Algemene voorwaarden
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nspoh.nl/onderwijs-examenregeling/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Examenreglement
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.stageetalage.nl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stage etalage
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nspoh.nl/bij-en-nascholing/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kalender bij- en nascholing
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} sm={5} xs={12} className={classes.contrastBlock}>
            <div className={classes.blockTitle}>Contact</div>
            <Box mt={2}>
              <Link
                href="https://www.nspoh.nl/melden-incident"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Technische) vraag stellen
              </Link>
            </Box>
            <Box mt={2}>
              <Grid container spacing={1}>
                <Grid item>
                  <a
                    href="https://www.facebook.com/NetherlandsSchoolOfPublicAndOccupationalHealth"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://twitter.com/nspoh"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.instagram.com/nspoh_nederland"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.youtube.com/user/nspoh"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://nl.linkedin.com/company/nspoh-netherlands-school-of-public-&-occupational-health-"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
