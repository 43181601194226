import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import FormRepository from './FormRepository';
import DialogCloseButton from '../../components/DialogCloseButton';
import { Form as FormInterface } from '../yard-forms/types';
import Form from '../yard-forms/Form';

interface FormPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  formId: string;
}

const FormPreviewDialog = (props: FormPreviewDialogProps) => {
  const { open, onClose, formId } = props;
  const [form, setForm] = useState<FormInterface | null>(null);

  useEffect(() => {
    setForm(null); // Reset form data
    new FormRepository().find(formId).then((response) => {
      setForm(response.data);
    });
  }, [formId]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Opdracht voorbeeld
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box p={4}>
          {form && (
            <Form
              form={form}
              description
              onSubmit={() => {}}
              externalUser={false}
              preview
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FormPreviewDialog;
