import React from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Theme,
  Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import settings from '../config/settings';
import { colors } from '../config/theme';

interface SubAppBarProps {
  children: React.ReactElement | React.ReactElement[];
  title?: string;
  previousRoute?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: settings.header.height,
    background: 'none',
  },
  container: {
    paddingLeft: 0,
    background: 'rgba(247,249, 251, 0.85)',
    backdropFilter: 'blur(6px)',
    boxShadow: '0 0 8px 3px rgb(0, 0, 0, 0.04)',
    '& .MuiButtonBase-root': {
      marginRight: theme.spacing(2),
      '&.active:not(:hover)': {
        background: colors.primary.ultraLight500,
      },
    },
  },
  title: {
    position: 'relative',
    marginRight: theme.spacing(6),
    fontFamily: theme.typography.h1.fontFamily,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -23,
      right: -theme.spacing(3),
      height: 64,
      width: 2,
      opacity: 0.85,
      background: colors.primary.ultraLight500,
    },
  },
}));

const SubAppBar = (props: SubAppBarProps) => {
  const classes = useStyles();
  const { title, children, previousRoute } = props;

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Container className={classes.container}>
        <Toolbar>
          {previousRoute && (
            <Box mr={3}>
              <IconButton component={Link} to={previousRoute} size="small">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={30}
                  height={30}
                >
                  <FontAwesomeIcon icon="arrow-left" size="sm" />
                </Box>
              </IconButton>
            </Box>
          )}
          {title && <Box className={classes.title}>{title}</Box>}
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default SubAppBar;
