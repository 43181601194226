import { AxiosResponse } from 'axios';

import { Assignment, Education, Module } from '../../types';
import ApiClient from '../../api/ApiClient';
import { ModuleType } from './module/ModuleTypes';

interface EducationResponse {
  education: Education;
  _permissions: {
    view: boolean;
    edit: boolean;
    imitatingAdmin?: boolean;
  };
}

export type ModuleCategory = 'IO' | 'PRK' | 'BEG' | 'EPA' | 'REQ';

export interface DownloadSummaryItem {
  identifier: ModuleCategory;
  category: string;
  numberOfAssignments: number;
  numberOfFiles: number;
}

class EducationRepository {
  find(id: string): Promise<AxiosResponse<EducationResponse>> {
    return ApiClient.get(`/api/education/${id}`);
  }

  getModules(
    id: string,
    types: ModuleType[] = [],
  ): Promise<AxiosResponse<Module[]>> {
    return ApiClient.get(`/api/education/${id}/modules`, {
      types: types.join(','),
    });
  }

  getInstituteEducationModules(id: string): Promise<AxiosResponse<Module[]>> {
    return ApiClient.get(`/api/education/${id}/institute-education-modules`);
  }

  getPracticalModules(id: string): Promise<AxiosResponse<Module[]>> {
    return ApiClient.get(`/api/education/${id}/practical-modules`);
  }

  getPortfolioModules(id: string): Promise<AxiosResponse<Module[]>> {
    return ApiClient.get(`/api/education/${id}/portfolio-modules`);
  }

  getDownloadSummary(
    id: string,
  ): Promise<AxiosResponse<DownloadSummaryItem[]>> {
    return ApiClient.get(`/api/education/${id}/download-summary`);
  }

  getDownloadSummaryDetails(
    id: string,
    identifier: string,
  ): Promise<AxiosResponse<Assignment[]>> {
    return ApiClient.get(`/api/education/${id}/download-summary-details`, {
      identifier,
    });
  }
}

export default EducationRepository;
