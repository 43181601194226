import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ScheduleItem } from '../../../types';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 14,
  },
  locationDescription: {
    fontSize: 13,
  },
}));

const AgendaEvent = ({ event }: { event: ScheduleItem }) => {
  const classes = useStyles();

  return (
    <div>
      <strong className={classes.title}>{event.event}</strong>
      {event.locationDescription && (
        <div className={classes.locationDescription}>
          <Box display="inline-block" mr={1} mt={1}>
            <Typography color="secondary">
              <FontAwesomeIcon icon="map-marker-alt" />
            </Typography>
          </Box>
          {event.locationDescription}
        </div>
      )}
    </div>
  );
};

export default AgendaEvent;
