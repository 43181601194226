import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { EditorProps } from './EditorProps';

const DateTimeEditor = (props: EditorProps) => {
  const { field, setPreview } = props;
  const [state, setState] = useState<{
    [key: string]: any;
  }>(
    field.metadata || {
      range: false,
      time: false,
    },
  );
  const { range, time } = state;

  useEffect(() => {
    field.metadata = {
      range,
      time,
    };

    setPreview(
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={2}>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                label="Datum"
                onChange={() => {}}
                value={moment()}
                KeyboardButtonProps={{
                  'aria-label': 'Verander datum',
                }}
                disabled
              />
            </Grid>
            {time && (
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  label="Tijd"
                  value={moment()}
                  onChange={() => {}}
                  disabled
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            )}
            {range && (
              <Grid item>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  label="Datum"
                  onChange={() => {}}
                  value={moment()}
                  KeyboardButtonProps={{
                    'aria-label': 'Verander datum',
                  }}
                  disabled
                />
              </Grid>
            )}
            {range && time && (
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  label="Tijd"
                  value={moment()}
                  onChange={() => {}}
                  disabled
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            )}
          </Grid>
        </MuiPickersUtilsProvider>
      </div>,
    );
  }, [field, state]);

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setState({ ...state, [event.target.name]: checked });
  };

  const rangeControl = (
    <Checkbox
      name="range"
      checked={range}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  const timeControl = (
    <Checkbox
      name="time"
      checked={time}
      onChange={handleCheckChange}
      color="primary"
    />
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControlLabel control={rangeControl} label="Bereik" />
      </Grid>
      <Grid item>
        <FormControlLabel control={timeControl} label="Tijd" />
      </Grid>
    </Grid>
  );
};

export default DateTimeEditor;
