import React, { ChangeEvent, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from '../../../components/TabPanel';
import ProfessionalActivities from './ProfessionalActivities';
import URLHashParameters from '../../../utils/URLHashParameters';
import Products from './Products';
import { Education } from '../../../types';
import Guidance from './Guidance';
import MyRequests from './MyRequests';

interface PortfolioProps {
  education: Education;
}

const Portfolio = (props: PortfolioProps) => {
  const { education } = props;
  const [tab, setTab] = useState<string | null>(
    URLHashParameters.get('portfolio', 'voortgang-begeleiding'),
  );

  const changeTab = (event: ChangeEvent<{}>, value: string) => {
    URLHashParameters.set('portfolio', value);
    setTab(value);
  };

  return (
    <div>
      <Tabs value={tab} onChange={changeTab} aria-label="portfolio tabs">
        <Tab
          label="Voortgang en begeleiding"
          id="voortgang-begeleiding-tab"
          aria-controls="voortgang-begeleiding-panel"
          value="voortgang-begeleiding"
        />
        <Tab
          label="Producten"
          id="producten-tab"
          aria-controls="producten-panel"
          value="producten"
        />
        {education.epaModules && (
          <Tab
            label="KBA's/EPA's"
            id="kba-epa-tab"
            aria-controls="kba-epa-panel"
            value="kba-epa"
          />
        )}
        <Tab
          label="Aanvragen"
          id="aanvragen-tab"
          aria-controls="aanvragen-panel"
          value="aanvragen"
        />
      </Tabs>

      <TabPanel
        id="voortgang-begeleiding-panel"
        value={tab}
        index="voortgang-begeleiding"
      >
        <Guidance education={education} />
      </TabPanel>

      <TabPanel id="producten-panel" value={tab} index="producten">
        <Products education={education} selectable={false} />
      </TabPanel>

      <TabPanel id="kba-epa-panel" value={tab} index="kba-epa">
        <ProfessionalActivities education={education} />
      </TabPanel>

      <TabPanel id="aanvragen-panel" value={tab} index="aanvragen">
        <MyRequests education={education} />
      </TabPanel>
    </div>
  );
};

export default Portfolio;
