import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface WidgetColorLabelProps {
  label: string;
  color: string;
  opacity?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    width: 16,
    height: 16,
    borderRadius: 6,
    marginRight: theme.spacing(1),
  },
}));

const WidgetColorLabel = (props: WidgetColorLabelProps) => {
  const { label, color, opacity } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <div
        className={classes.indicator}
        style={{
          background: color,
          opacity: opacity || 1,
        }}
      />
      {label}
    </Box>
  );
};

export default WidgetColorLabel;
