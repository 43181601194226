import React from 'react';
import { Router, Route } from '../../routing';
import AgendaOverview from './AgendaOverview';
import ScheduleItemMoodleLoader from './ScheduleItemMoodleLoader';

Router.addRoute(
  new Route('Agenda', '/agenda', <AgendaOverview />).requireLogin(),
);

Router.addRoute(
  new Route(
    'Leermateriaal',
    '/agenda/user/:userId/evenement/:eventId/leermateriaal',
    <ScheduleItemMoodleLoader />,
  ).requireLogin(),
);
