import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import _uniqueId from 'lodash/uniqueId';
import { makeStyles } from '@material-ui/styles';

import Likert, { LikertSection } from '../Likert';
import { EditorProps } from './EditorProps';
import LabelList, { Label } from '../LabelList';
import useFormFieldStyles from '../FormFieldStyles';

const useStyles = makeStyles((theme: Theme) => ({
  likert: {
    marginTop: theme.spacing(2),
  },
}));

const LikertEditor = (props: EditorProps) => {
  const classes = useStyles();
  const fieldClasses = useFormFieldStyles();
  const { field, onChange, setPreview } = props;
  const [state, setState] = useState<{
    multiple: boolean;
    columns: string[];
    rows: string[];
    sections: LikertSection[];
  }>({
    multiple: field.metadata.multiple || false,
    columns: field.metadata.columns || [],
    rows: field.metadata.rows || [],
    sections: field.metadata.sections || [],
  });

  const { multiple, columns, rows, sections } = state;

  const findOptionByIndex = (i: number, j: number) => {
    if (!field.options) {
      return null;
    }

    return (
      field.options.find((option) => option.label === `field_${i}_${j}`) || null
    );
  };

  useEffect(() => {
    const options = [];

    for (let i = 0; i < rows.length; ++i) {
      for (let j = 0; j < columns.length; ++j) {
        const option = findOptionByIndex(i, j);
        options.push({
          id: option ? option.id : '',
          label: `field_${i}_${j}`,
          order: i,
        });
      }
    }

    field.options = options;
    field.metadata = { rows, columns, sections, multiple };

    onChange(field);

    setPreview(
      <div>
        {rows.length > 0 && columns.length > 0 ? (
          <Likert
            rows={rows}
            columns={columns}
            sections={sections}
            className={classes.likert}
            multiple={multiple}
            disabled
          />
        ) : (
          <div />
        )}
      </div>,
    );
  }, [state, field]);

  const handleMultipleChange = (
    event: React.ChangeEvent<{ name?: string; checked: boolean }>,
  ) => {
    setState({ ...state, multiple: event.target.checked });
  };

  const handleChangeColumns = (labels: Label[]) => {
    setState({ ...state, columns: labels.map((label) => label.label) });
  };

  const handleChangeRows = (labels: Label[]) => {
    setState({ ...state, rows: labels.map((label) => label.label) });
  };

  const handleChangeSections = (labels: Label[]) => {
    setState({
      ...state,
      sections: labels.map(({ label, option }) => ({
        description: label,
        row: option || 0,
      })),
    });
  };

  const answerControl = (
    <Checkbox
      checked={multiple}
      onChange={handleMultipleChange}
      name="multiple"
    />
  );

  return (
    <div>
      <FormControlLabel
        control={answerControl}
        label="Meerdere antwoorden mogelijk"
      />
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography className={fieldClasses.title} component="h3">
            Rijen
          </Typography>
          <LabelList
            onChange={handleChangeRows}
            labels={rows.map((row, i) => ({
              id: _uniqueId(),
              label: row,
              order: i,
            }))}
          />
        </Grid>

        <Grid item md={6}>
          <Typography className={fieldClasses.title} component="h3">
            Kolommen
          </Typography>
          <LabelList
            onChange={handleChangeColumns}
            labels={columns.map((column, i) => ({
              id: _uniqueId(),
              label: column,
              order: i,
            }))}
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography className={fieldClasses.title} component="h3">
          Secties
        </Typography>
        <LabelList
          labels={sections.map((section, i) => ({
            id: _uniqueId(),
            label: section.description,
            option: section.row,
            order: i,
          }))}
          onChange={handleChangeSections}
          options={rows.map((row, index) => ({ key: index, value: row }))}
          optionsPlaceholder="Selecteer een regel..."
        />
      </Box>
    </div>
  );
};

export default LikertEditor;
