import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';

interface CopyIdProps {
  id: string;
  icon?: 'fingerprint' | 'clipboard';
  iconOnly?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: grey[600],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  id: {
    marginRight: theme.spacing(1),
  },
}));

const CopyId = ({
  id,
  icon = 'clipboard',
  iconOnly = false,
  className = '',
}: CopyIdProps) => {
  const classes = useStyles();
  const notifications = useSnackbar();

  const handleCopyId = () => {
    navigator.clipboard.writeText(id);
    notifications.enqueueSnackbar(`"${id}" gekopieerd naar klembord!`, {
      variant: 'success',
    });
  };

  return (
    <div className={`${classes.root} ${className}`}>
      {!iconOnly && <span className={classes.id}>{id}</span>}
      <IconButton onClick={handleCopyId} size="small">
        <FontAwesomeIcon icon={['fal', icon]} />
      </IconButton>
    </div>
  );
};

export default CopyId;
