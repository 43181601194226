import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import { Assignment } from '../../../types';
import AssignmentStates from './AssignmentStates';

interface AssignmentStatusColumnProps {
  assignment: Assignment;
  label?: string | React.ReactElement;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    width: 140,
  },
  statusIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 2,
  },
  statusLabel: {
    marginLeft: theme.spacing(1),
  },
  statusOpen: {
    background: red[300],
  },
  statusRejected: {
    background: orange[300],
  },
  statusReview: {
    background: orange[300],
  },
  statusFinished: {
    background: green[300],
  },
}));

const AssignmentStatusColumn = (props: AssignmentStatusColumnProps) => {
  const { assignment, label, className } = props;
  const classes = useStyles();

  let statusClass: string;

  switch (assignment.state) {
    case 'open':
      statusClass = classes.statusOpen;
      break;
    case 'rejected':
      statusClass = classes.statusRejected;
      break;
    case 'in_review':
      statusClass = classes.statusReview;
      break;
    case 'finished':
      statusClass = classes.statusFinished;
      break;
    default:
      statusClass = '';
      break;
  }

  return (
    <div className={`${classes.status} ${className}`}>
      <div className={`${classes.statusIndicator} ${statusClass}`} />
      <div className={classes.statusLabel}>
        {label || AssignmentStates[assignment.state]}
      </div>
    </div>
  );
};

export default AssignmentStatusColumn;
