import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import NotificationPreferencesRepository from './NotificationPreferencesRepository';
import {
  CompleteUser,
  NotificationPreferences as NotificationPreferencesInterface,
} from '../../../types';
import notificationTypes from '../../notifications/notificationTypes';
import AppContext from '../../../AppContext';
import Loader from '../../../components/Loader';

const NotificationPreferences = () => {
  const notifications = useSnackbar();
  const [preferences, setPreferences] =
    useState<NotificationPreferencesInterface | null>(null);
  const [changeSet, setChangeSet] =
    useState<NotificationPreferencesInterface | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { appState, setAppState } = useContext(AppContext);
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser | null;
      };
    }) => selector.user.account,
  );

  useEffect(() => {
    new NotificationPreferencesRepository()
      .getPreferences()
      .then((response) => {
        setPreferences(response.data);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [appState, setAppState]);

  useEffect(() => {
    if (!changeSet) {
      return;
    }

    new NotificationPreferencesRepository()
      .updatePreferences(changeSet)
      .then(() => {
        notifications.enqueueSnackbar('Instellingen opgeslagen!', {
          variant: 'success',
        });
        setPreferences(changeSet);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij opslaan van instellingen!', {
          variant: 'error',
        });
      })
      .finally(() => setChangeSet(null));
  }, [notifications, changeSet]);

  const handleTypesChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    let types = preferences!.optOutTypes;

    if (event.target.checked) {
      types = types.filter((t) => t !== event.target.name);
    } else {
      types.push(event.target.name);
    }

    setChangeSet({ ...preferences!, optOutTypes: types });
  };

  const handleMailingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeSet({ ...preferences!, optOutMailings: !event.target.checked });
  };

  const allowedTypes = Object.entries(notificationTypes).filter(
    ([, options]) =>
      account &&
      account.roles?.filter((role) => options.roles.includes(role)).length > 0,
  );

  if (!loaded) {
    return <Loader />;
  }

  const mailControl = (
    <Switch
      checked={!preferences?.optOutMailings}
      onChange={handleMailingsChange}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );

  return (
    <FormGroup>
      {preferences && allowedTypes.length > 0 && (
        <>
          <Box mb={2}>
            <Typography variant="body1">
              Je ontvangt van een aantal activiteiten standaard een notificatie
              in MijnNSPOH. Als je van een activiteit een notificatie niet wilt
              ontvangen, kun je ze onderstaand uitzetten:
            </Typography>
          </Box>
          {allowedTypes.map(([type, options]) => {
            const control = (
              <Switch
                checked={!preferences.optOutTypes.includes(type)}
                onChange={handleTypesChanges}
                name={type}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            );

            return <FormControlLabel control={control} label={options.label} />;
          })}
        </>
      )}
      <Box mt={3} mb={2}>
        <Typography variant="body1">
          Naast dat je deze MijnNSPOH ontvangt kun je er ook voor kiezen om 1x
          per 24 uur een verzamelmail te ontvangen van alle niet gelezen
          notificaties die in dat etmaal zijn afgegeven en een wekelijkse email
          met de ongelezen nieuwsberichten. Wil je dat? Dan kun je dat
          onderstaand aanzetten.
        </Typography>
      </Box>
      <FormControlLabel control={mailControl} label="Mail ontvangen" />
    </FormGroup>
  );
};

export default NotificationPreferences;
