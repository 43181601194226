import React, { useState } from 'react';
import { Box, IconButton, Popover } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EventEducator, ScheduleItem } from '../../types';
import ApiClient from '../../api/ApiClient';
import Loader from '../../components/Loader';

interface TeachersPopoverProps {
  scheduleItem: ScheduleItem;
}

const TeachersPopover = (props: TeachersPopoverProps) => {
  const { scheduleItem } = props;
  const { sessionId, eventId } = scheduleItem;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [teachers, setTeachers] = useState<EventEducator[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchEducators = async () => {
    if (loaded) {
      return;
    }

    const response = await ApiClient.get(
      `/api/events/${eventId}/sessions/${sessionId}/educators`,
    );

    setTeachers(response.data);
    setLoaded(true);
  };

  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    fetchEducators();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <Box p={2}>
          <Box>
            <strong>Docenten</strong>
          </Box>
          {!loaded && <Loader inline />}
          {loaded && teachers.length === 0 && 'Nog geen docent bekend.'}
          {teachers.map((t) => t.name).join(', ')}
        </Box>
      </Popover>
      <IconButton onClick={handleOpen}>
        <FontAwesomeIcon size="sm" icon={['fal', 'user']} />
      </IconButton>
    </>
  );
};

export default TeachersPopover;
