import { AxiosResponse, CancelTokenSource } from 'axios';

import { ApiFilterCriteria, CollectionResponse, Repository } from '../../types';
import { FormCategory } from '../yard-forms/types';
import ApiClient from '../../api/ApiClient';
import { parseCriteria } from '../../utils/common';

class FormCategoryRepository implements Repository<FormCategory> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
    perPage?: number,
  ): Promise<CollectionResponse<FormCategory>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });
    return ApiClient.get('/api/forms/categories', params);
  }

  create(name: string): Promise<AxiosResponse<FormCategory>> {
    return ApiClient.post('/api/forms/categories', { name });
  }

  update(
    category: FormCategory,
    name: string,
  ): Promise<AxiosResponse<FormCategory>> {
    return ApiClient.post(`/api/forms/categories/${category.id}`, { name });
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/forms/categories/${id}`);
  }
}

export default FormCategoryRepository;
