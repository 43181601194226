import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Input, InputAdornment, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import {
  Assignment,
  Education,
  EducationModule,
  File,
  Module,
  PracticalModule,
} from '../../../types';
import ProductPanel from './ProductPanel';
import Loader from '../../../components/Loader';
import StyledAccordion from '../../../components/StyledAccordion';
import MaterialUploader from './MaterialUploader';
import EducationMaterialViewer from './EducationMaterialViewer';
import ProductContext from './ProductContext';
import ProductChips from './ProductChips';
import ModuleTypes, { ModuleType } from '../module/ModuleTypes';
import EducationContext from '../EducationContext';
import EducationRepository from '../EducationRepository';

interface ProductsProps {
  education: Education;
  selectable?: boolean;
  onSelectChange?: (assignments: Assignment[], files: File[]) => void;
  summaryClassName?: string;
  selectedAssignments?: Assignment[];
  selectedFiles?: File[];
}

export interface ProductContextState {
  selectedFiles: File[];
  selectedAssignments: Assignment[];
}

interface CategorizedAssignments {
  [key: string]: Assignment[];
}

const useStyles = makeStyles((theme: Theme) => ({
  nameColumn: {
    width: '100%',
  },
  selectedGrid: {
    marginBottom: theme.spacing(2),
  },
  ownMaterials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  educationMaterials: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  educationMaterial: {
    width: '100%',
  },
  summary: {
    '&[aria-expanded="true"]': {
      '& $icon': {
        color: '#FFF',
      },
    },
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

const Products = (props: ProductsProps) => {
  const { education, selectable, onSelectChange, summaryClassName } = props;
  const classes = useStyles();

  const [dirty, setDirty] = useState<boolean>(false);
  const initialContext = useRef<ProductContextState>({
    selectedFiles: props.selectedFiles || [],
    selectedAssignments: props.selectedAssignments || [],
  });
  const [productContext, setProductContext] = useState<ProductContextState>({
    selectedFiles: props.selectedFiles || [],
    selectedAssignments: props.selectedAssignments || [],
  });

  const [modules, setModules] = useState<
    (Module | EducationModule | PracticalModule)[] | undefined
  >(undefined);

  const { selectedAssignments, selectedFiles } = productContext;
  const productContextValue = useMemo(
    () => ({ productContext, setProductContext }),
    [productContext, setProductContext],
  );
  const { educationPermissions } = useContext(EducationContext);

  const [assignments, setAssignments] = useState<CategorizedAssignments>({});
  const [query, setQuery] = useState<string>('');
  const [materialUploaderOpen, setMaterialUploaderOpen] =
    useState<boolean>(false);
  const [ownMaterialsOpen, setOwnMaterialsOpen] = useState<boolean>(false);

  const handleAddMaterial = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    setMaterialUploaderOpen(true);
  };

  const handleMaterialUploaderClose = () => {
    setMaterialUploaderOpen(false);
  };

  const handleUpload = () => {
    setMaterialUploaderOpen(false);
    setOwnMaterialsOpen(true);
  };

  const handleOwnMaterialsChange = (
    event: React.ChangeEvent<{}>,
    expanded: boolean,
  ) => {
    setOwnMaterialsOpen(expanded);
  };

  // Extract finished assignments from modules, grouped by module type
  const getGroupedAssignments = useCallback(() => {
    const assignments: CategorizedAssignments = {};

    if (!modules) {
      return assignments;
    }

    modules.forEach((module) => {
      let { type }: { type: string } = module;

      // Group IKO and EKO in one category.
      if (module.type === 'IKO' || module.type === 'EKO') {
        type = 'KO';
      }

      if (assignments[type] === undefined) {
        assignments[type] = [];
      }

      if ('assignmentContainers' in module) {
        module.assignmentContainers.forEach((container) => {
          container.assignments.forEach((assignment) => {
            if (assignment.finished) {
              assignments[type].push({
                ...assignment,
                module,
              } as Assignment);
            }
          });
        });
      } else {
        (module.assignments || []).forEach((assignment) => {
          if (assignment.finished) {
            assignments[type].push({
              ...assignment,
              module,
            } as Assignment);
          }
        });
      }
    });

    return assignments;
  }, [modules]);

  const removeSelectedAssignment = (index: number) => {
    productContext.selectedAssignments.splice(index, 1);
    setProductContext({
      ...productContext,
      selectedAssignments: [...productContext.selectedAssignments],
    });
  };

  const removeSelectedFile = (index: number) => {
    productContext.selectedFiles.splice(index, 1);
    setProductContext({
      ...productContext,
      selectedFiles: [...productContext.selectedFiles],
    });
  };

  // Search
  const doSearch = _.debounce((query: string) => {
    const assignments = getGroupedAssignments();

    Object.entries(assignments).forEach((entry) => {
      assignments[entry[0]] = entry[1].filter((assignment) =>
        assignment.name.includes(query),
      );
    });

    setAssignments(assignments);
    setQuery(query);
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    doSearch(query);
  };

  useEffect(() => {
    setAssignments(getGroupedAssignments());
  }, [getGroupedAssignments]);

  // Trigger selected assignments / files change callback.
  useEffect(() => {
    if (!dirty || !onSelectChange) {
      return;
    }

    onSelectChange(selectedAssignments, selectedFiles);
  }, [onSelectChange, dirty, selectedAssignments, selectedFiles]);

  useEffect(() => {
    new EducationRepository()
      .getPortfolioModules(education.id)
      .then((response) => setModules(response.data));
  }, [education.id]);

  useEffect(() => {
    if (
      !dirty &&
      JSON.stringify(initialContext) !== JSON.stringify(productContext)
    ) {
      setDirty(true);
    }
  }, [dirty, productContext]);

  const onDeleteAssignment = (assignment: Assignment, index: number) =>
    removeSelectedAssignment(index);

  const searchAdornment = (
    <InputAdornment position="start">
      <FontAwesomeIcon icon={['fal', 'search']} />
    </InputAdornment>
  );

  const materialsSummary = (
    <div className={classes.ownMaterials}>
      <div className={classes.nameColumn}>Eigen materialen</div>
      {educationPermissions.edit && (
        <IconButton
          onClick={handleAddMaterial}
          size="small"
          className={classes.icon}
        >
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </IconButton>
      )}
    </div>
  );

  if (!modules) {
    return <Loader />;
  }

  return (
    <ProductContext.Provider value={productContextValue}>
      <MaterialUploader
        education={education}
        open={materialUploaderOpen}
        onClose={handleMaterialUploaderClose}
        onUpload={handleUpload}
      />

      <ProductChips
        assignments={selectedAssignments}
        files={selectedFiles}
        onDeleteAssignment={onDeleteAssignment}
        onDeleteFile={(file: File, index: number) => removeSelectedFile(index)}
      />

      <div className={classes.searchContainer}>
        <Input
          type="text"
          onChange={handleSearch}
          data-testid="search-box"
          startAdornment={searchAdornment}
          placeholder="Zoeken..."
        />
      </div>

      {[...Object.entries(ModuleTypes), ['KO', 'Keuzeonderwijs']]
        .filter((type) => assignments[type[0]] !== undefined)
        .filter(([type]) => !['AANVRAAG', 'EPA'].includes(type))
        .map((type) => (
          <ProductPanel
            key={type[0]}
            type={type[0] as ModuleType}
            name={type[1]}
            assignments={assignments[type[0]]}
            selectable={selectable}
            summaryClassName={summaryClassName}
          />
        ))}

      <StyledAccordion
        id="own-materials-panel"
        summary={materialsSummary}
        expanded={ownMaterialsOpen}
        onChange={handleOwnMaterialsChange}
        summaryClassName={`${summaryClassName || ''} ${classes.summary}`}
        expandable={education && education.materials.length > 0}
        chevron
        noPadding
      >
        <div className={classes.educationMaterials}>
          {education && (
            <EducationMaterialViewer
              education={education}
              query={query}
              selectable={selectable}
            />
          )}
        </div>
      </StyledAccordion>
    </ProductContext.Provider>
  );
};

export default Products;
