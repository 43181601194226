import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import { Education, Note } from '../../types';
import NotesRepository from './NotesRepository';
import SafeHtml from '../../components/SafeHtml';
import HtmlEditor from '../../components/HtmlEditor';
import EducationContext from '../education/EducationContext';

interface NotesDialogProps {
  education: Education;
  open: boolean;
  onClose: () => void;
  educationUser?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  notes: {
    marginTop: theme.spacing(2),
    width: 500,
  },
  note: {
    position: 'relative',
    padding: theme.spacing(2),
    background: grey[100],
    maxWidth: 500,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '&:hover $delete': {
      opacity: 1,
    },
  },
  date: {
    fontStyle: 'italic',
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  delete: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
}));

const NotesDialog = (props: NotesDialogProps) => {
  const { education, open, onClose } = props;
  const educationUser =
    props.educationUser !== undefined ? props.educationUser : true;
  const repository = new NotesRepository();
  const notifications = useSnackbar();
  const classes = useStyles();
  const [notes, setNotes] = useState<Note[]>([]);
  const [showNewNote, setShowNewNote] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>('');
  const { educationPermissions } = useContext(EducationContext);

  const fetchNotes = () => {
    repository
      .findByEducation(education, educationUser)
      .then((response) => {
        setNotes(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij ophalen van notities.', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    fetchNotes();
  }, [education]);

  const handleMessageChange = (event: any, editor: any) => {
    setNewNote(editor.getData());
  };

  const handleCreateNote = () => {
    repository
      .createForEducation(education, newNote)
      .then(() => {
        fetchNotes();
        setShowNewNote(false);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij opslaan van notities.', {
          variant: 'error',
        });
      });
  };

  const handleOpenNewNote = () => setShowNewNote(true);

  const handleDeleteNote = (note: Note) => {
    repository.delete(education, note).then(() => {
      fetchNotes();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="notes-dialog-title"
      maxWidth="md"
    >
      <DialogContent>
        {(!educationUser || educationPermissions.edit) && (
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
            onClick={handleOpenNewNote}
          >
            Maak nieuwe aan
          </Button>
        )}
        <div className={classes.notes}>
          {showNewNote && (
            <div className={classes.note}>
              <div className={classes.date}>
                {moment().format('D MMMM YYYY')}
              </div>
              <HtmlEditor onChange={handleMessageChange} />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCreateNote}
                className={classes.button}
              >
                Aanmaken
              </Button>
            </div>
          )}

          {notes.map((note) => (
            <div className={classes.note}>
              {(!educationUser || educationPermissions.edit) && (
                <div className={classes.delete}>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteNote(note)}
                  >
                    <FontAwesomeIcon icon={['fal', 'trash']} />
                  </IconButton>
                </div>
              )}
              <div className={classes.date}>
                {moment(note.created).format('D MMMM YYYY')}
              </div>
              <SafeHtml html={note.message} />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuleren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesDialog;
