import { Account, TwoFactorMethod } from '../types';

export type UserState = {
  isLoggedIn: boolean;
  isImitating: boolean;
  account: Account | null;
  requestTwoFactorCode: boolean;
  requestTwoFactorConfiguration: boolean;
  twoFactorMethod: TwoFactorMethod | null;
  twoFactorEmail: string | null;
};

const defaultState: UserState = {
  isLoggedIn: false,
  isImitating: false,
  account: null,
  requestTwoFactorCode: false,
  requestTwoFactorConfiguration: false,
  twoFactorMethod: null,
  twoFactorEmail: null,
};

const user = (
  state = defaultState,
  action: { type: string; payload?: unknown },
) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        isLoggedIn: true,
        requestTwoFactorCode: false,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        isLoggedIn: false,
        account: null,
        requestTwoFactorCode: false,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      };
    case 'SET_IMITATING':
      return {
        ...state,
        isImitating: action.payload,
      };
    case 'UPDATE_AVATAR':
      return {
        ...state,
        account: { ...state.account, avatar: action.payload },
      };
    case 'REQUEST_TWO_FACTOR_CODE':
      return {
        ...state,
        requestTwoFactorCode: true,
        twoFactorMethod: (
          action.payload as { email: string; method: TwoFactorMethod }
        ).method,
        twoFactorEmail: (
          action.payload as { email: string; method: TwoFactorMethod }
        ).email,
      };
    case 'REQUEST_TWO_FACTOR_CONFIGURATION':
      return {
        ...state,
        requestTwoFactorConfiguration: true,
      };
    case 'FULFILL_TWO_FACTOR_CONFIGURATION_REQUEST':
      return {
        ...state,
        requestTwoFactorConfiguration: false,
      };
    default:
      return state;
  }
};

export default user;
