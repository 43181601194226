import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormLabel, Theme } from '@material-ui/core';

interface FieldLabelProps {
  children: React.ReactNode;
  required?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const FieldLabel = ({
  children,
  required = false,
  className = '',
}: FieldLabelProps) => {
  const classes = useStyles();

  return (
    <FormLabel className={`${classes.root} ${className}`} required={required}>
      {children}
    </FormLabel>
  );
};

export default FieldLabel;
