import { IconName } from '@fortawesome/fontawesome-svg-core';
import Roles, { RoleInterface } from '../users/Roles';
import { Notification } from '../../types';
import AssignmentRepository from '../education/assignment/repository/AssignmentRepository';
import { ROUTE_SYNC_ISSUES } from '../afas';
import RoleViewManager from '../../RoleViewManager';

/**
 * Obtain the URL of an assignment by ID. Checks if the assignment is open for entries or not.
 * @param assignmentId
 */
const getAssignmentUrl = async (assignmentId: string) => {
  const response = await new AssignmentRepository().find(assignmentId);

  const assignment = response.data;

  if (assignment.editable) {
    return `/onderwijs/opdracht/${assignmentId}`;
  }

  return `/onderwijs/opdracht/${assignmentId}/bekijken`;
};

const allRoles = Object.keys(Roles) as (keyof RoleInterface)[];

const notificationTypes: {
  [key: string]: {
    label: string;
    roles: (keyof RoleInterface)[];
    icon: IconName;
    target?: (
      notification: Notification,
      roleViewManager: RoleViewManager,
    ) => string | Promise<string>;
    newWindow?: boolean;
  };
} = {
  Moodle: {
    label: 'Leermateriaal, forum activiteiten en voorbereidende opdrachten',
    roles: allRoles,
    icon: 'books',
    target: (notification) => {
      const { contexturl } = notification.references;

      if (contexturl !== undefined) {
        return `/notificaties/${notification.id}/leermateriaal`;
      }

      return '';
    },
    newWindow: true,
  },
  AssignmentEvaluated: {
    label: 'Opdracht beoordeeld',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'check-circle',
    target: (notification) =>
      getAssignmentUrl(notification.references.assignmentId),
  },
  AssignmentMessage: {
    label: 'Bericht in opdracht',
    roles: allRoles,
    icon: 'comments',
    target: (notification, roleViewManager) => {
      if (roleViewManager.isEducatorView()) {
        return `/beoordelingen/${notification.references.assignmentId}`;
      }

      return getAssignmentUrl(notification.references.assignmentId);
    },
  },
  ResetEvaluation: {
    label: 'Opdracht heropend',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'undo',
    target: (notification) =>
      getAssignmentUrl(notification.references.assignmentId),
  },
  AssignmentRejected: {
    label: 'Opdracht teruggestuurd',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'pause-circle',
    target: (notification) =>
      getAssignmentUrl(notification.references.assignmentId),
  },
  EvaluatorAssigned: {
    label: 'Te beoordelen opdracht',
    roles: ['ROLE_EDUCATOR', 'ROLE_ADMIN'],
    icon: 'user-graduate',
    target: (notification) =>
      `/beoordelingen/${notification.references.assignmentId}`,
  },
  ExternalSavedAssignmentFormEntry: {
    label: 'Externe gebruiker bijdrage geleverd',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'plus-circle',
    target: (notification) =>
      getAssignmentUrl(notification.references.assignmentId),
  },
  AfasSyncIssue: {
    label: 'AFAS Synchronisatie Issue',
    roles: ['ROLE_ADMIN'],
    icon: 'sync',
    target: (notification) =>
      `${ROUTE_SYNC_ISSUES}/${notification.references.issueId}`,
  },
  WorkshopRegistrationEndsSoon: {
    label: 'Registratie workshop loopt ten einde',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'chalkboard-teacher',
  },
  RequestedDownloadFailed: {
    label: 'Aangevraagde download mislukt',
    roles: ['ROLE_PARTICIPANT'],
    icon: 'file',
  },
};

export default notificationTypes;
