import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Theme, Typography } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import { gradients } from '../config/theme';
import settings from '../config/settings';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    marginTop: `${theme.spacing(6) + settings.header.height}px`,
    marginBottom: 70,
  },
  header: ({
    headingJustifyContent = 'space-between',
  }: PageContainerProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: headingJustifyContent,
    width: '100%',
  }),
  centered: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
  gradient: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    height: 500,
    width: '100%',
    background: gradients.primaryLight,
  },
}));

type PageContainerProps = {
  children: ReactNode;
  actions?: ReactNode;
  centered?: boolean;
  title?: string;
  headingJustifyContent?: string;
  marginBottom?: boolean;
  containerClassName?: string;
  breadcrumbs?: boolean;
};

const PageContainer = (props: PageContainerProps) => {
  const classes = useStyles(props);
  const {
    children,
    actions,
    title,
    centered,
    marginBottom,
    containerClassName,
  } = props;
  const boxMarginBottom = marginBottom ? 3 : 0;

  const breadcrumbs =
    props.breadcrumbs === undefined ? true : props.breadcrumbs;

  return (
    <div>
      <div className={classes.gradient} />
      <Container
        className={`${classes.container} ${centered && classes.centered} ${
          containerClassName || ''
        }`}
      >
        {breadcrumbs && <Breadcrumbs />}
        <Box mb={boxMarginBottom} className={classes.header}>
          {title && <Typography variant="h1">{title}</Typography>}
          {actions}
        </Box>
        {children}
      </Container>
    </div>
  );
};

export default PageContainer;
