const typeColumnWidth = 85;
const assignmentsColumnWidth = 120;
const statusColumnWidth = 85;
const presenceColumnWidth = 100;
const startDateColumnWidth = 115;
const furtherEducationPointsColumnWidth = 120;

const columnConfig = {
  nameColumn: {
    width: '100%',
    maxWidth: `calc(
                    100% 
                    - 20% 
                    - ${typeColumnWidth}px
                    - ${assignmentsColumnWidth}px
                    - ${statusColumnWidth}px 
                    - ${startDateColumnWidth}px 
                )`,
  },
  statusColumn: {
    minWidth: statusColumnWidth,
    width: statusColumnWidth,
  },
  presenceColumn: {
    minWidth: presenceColumnWidth,
  },
  startDateColumn: {
    minWidth: startDateColumnWidth,
  },
  typeColumn: {
    minWidth: typeColumnWidth,
  },
  assignmentsColumn: {
    minWidth: assignmentsColumnWidth,
  },
  furtherEducation: {
    nameColumn: {
      width: '100%',
      maxWidth: `calc(
                    100% 
                    - 20% 
                    - ${typeColumnWidth}px
                    - ${assignmentsColumnWidth}px 
                    - ${furtherEducationPointsColumnWidth}px 
                    - ${statusColumnWidth}px 
                    - ${startDateColumnWidth}px
                )`,
    },
    pointsColumn: {
      minWidth: furtherEducationPointsColumnWidth,
    },
  },
};

export default columnConfig;
