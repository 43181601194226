import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { debounce, TextField } from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import { ApiFilterCriteria, Repository } from '../types';

interface DataAutocompleteProps<T> {
  repository: Repository<T>; // Repository to use for retrieving data.
  keys: string[]; // The keys to search through.
  renderOption: (option: T) => React.ReactElement;
  getLabel: (option: T) => string;
  placeholder?: string;
  noOptionsText?: string;
  initialData?: T[];
  onChange: (data: T[]) => void;
  filters?: {};
  error?: boolean;
}

const DataAutocomplete = <T,>(props: DataAutocompleteProps<T>) => {
  const {
    repository,
    keys,
    renderOption,
    getLabel,
    placeholder,
    noOptionsText,
    initialData,
    onChange,
    filters,
    error,
  } = props;
  const [value, setValue] = useState<T[]>(initialData || []);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<T[]>(initialData || []);

  const getOptions = debounce((query: string) => {
    const criteria: ApiFilterCriteria = { query, filters };

    if (!criteria.filters) {
      criteria.filters = {};
    }

    criteria.filters.perPage = 10000;

    repository.findBy(criteria, 1, undefined).then((response) => {
      if ('data' in response) {
        if ('hydra:member' in response.data) {
          setOptions(response.data['hydra:member'] as T[]);
        } else {
          setOptions(response.data.items as T[]);
        }
      }
    });
  }, 200);

  const filterOptions = (
    options: T[],
    { inputValue }: { inputValue: string },
  ) => matchSorter<T>(options, inputValue, { keys });

  useEffect(() => {
    if (inputValue.length >= 3) {
      getOptions(inputValue);
    }
  }, [inputValue, value]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={placeholder}
          variant="outlined"
          error={error}
          fullWidth
        />
      )}
      getOptionLabel={getLabel}
      options={options}
      autoComplete
      onChange={(event: any, newValue: T[]) => {
        setOptions(newValue ? [...newValue, ...options] : options);
        setValue(newValue);
      }}
      value={value}
      filterOptions={filterOptions}
      noOptionsText={noOptionsText || 'Geen resultaten gevonden'}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderOption={renderOption}
      multiple
    />
  );
};

export default DataAutocomplete;
