import {
  ApiFilterCriteria,
  DataCollection,
  Module,
  Repository,
} from '../../../types';
import { FilterColumnOption } from '../../../components/FilterColumn';

class EducationModuleRepository implements Repository<Module> {
  private modules: Module[];

  constructor(modules: Module[]) {
    this.modules = modules;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<DataCollection<Module>> {
    return new Promise<DataCollection<Module>>((resolve) => {
      const modules = this.modules
        .filter((module) => {
          if (criteria && criteria.query) {
            return module.publishedName
              .toLowerCase()
              .includes(criteria.query.toLowerCase());
          }

          if (criteria && criteria.filters) {
            const { status }: { status?: FilterColumnOption[] } =
              criteria.filters;

            if (
              module.status &&
              status &&
              !status.find((o) => o.value === module.status)
            ) {
              return false;
            }
          }

          return true;
        })
        .sort((a, b) => {
          if (criteria && criteria.order) {
            for (const { field, order } of criteria.order) {
              if (field === 'publishedName') {
                const compare = a.publishedName.localeCompare(b.publishedName);

                return order === 'asc' ? compare : -compare;
              }
            }
          }

          return a.publishedName.localeCompare(b.publishedName);
        });
      const perPage = 10;
      const start = ((page || 1) - 1) * perPage;

      resolve({
        items: modules.slice(start, start + perPage),
        totalItems: modules.length,
        perPage,
      });
    });
  }
}

export default EducationModuleRepository;
