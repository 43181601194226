import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import AssignmentRepository from './repository/AssignmentRepository';
import { Assignment, Education } from '../../../types';
import FormEntry from '../../yard-forms/FormEntry';
import AssignmentEvaluations from '../../evaluation/AssignmentEvaluation';
import AssignmentDialog from './AssignmentDialog';
import EducationRepository from '../EducationRepository';
import EducationContext from '../EducationContext';
import FormDescription from '../../yard-forms/FormDescription';
import AppContext from '../../../AppContext';
import { FormEntry as FormEntryInterface } from '../../yard-forms/types';
import { ReactComponent as FormIllustration } from '../../../assets/images/form_illustration.svg';
import TextMedia from '../../../components/pageheader/TextMedia';
import { DialogMessage } from './AssignmentDialogMessage';
import AssignmentMessageRepository from './repository/AssignmentMessageRepository';

const AssignmentView = () => {
  const { id } = useParams<{ id: string }>();
  const repository = new AssignmentRepository();
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);
  const [assignment, setAssignment] = useState<Assignment | null>(null);
  const [education, setEducation] = useState<Education | null>(null);
  const [entry, setEntry] = useState<FormEntryInterface | null>(null);

  useEffect(() => {
    repository.find(id).then((response) => {
      setAssignment(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (!assignment) {
      return;
    }

    const { educationId } = assignment;

    if (educationId) {
      new EducationRepository().find(educationId).then((response) => {
        setEducation(response.data.education);
      });
    }
  }, [assignment]);

  const educationContextValue = useMemo(
    () => ({
      education,
      educationPermissions: {
        view: false,
        edit: false,
      },
    }),
    [education],
  );

  const handleFormEntryChange = (entry: FormEntryInterface) => {
    setEntry(entry);
  };

  const createNewMessage = async (
    content: string,
  ): Promise<AxiosResponse<DialogMessage>> =>
    new AssignmentMessageRepository().create(assignment!!.id, content);

  const handleNewMessage = (message: string) => {
    if (!assignment) {
      return;
    }

    createNewMessage(message).then((response) => {
      setAssignment({
        ...assignment,
        messages: [...assignment.messages, response.data],
      });
    });
  };

  const handleDeleteMessage = (message: DialogMessage) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    newMessages.splice(
      newMessages.findIndex((m) => m.id === message.id),
      1,
    );

    new AssignmentMessageRepository()
      .delete(assignment.id, message.id)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het verwijderen van het bericht.',
          { variant: 'error' },
        );
      });
  };

  const handleEditMessage = (message: DialogMessage, newContent: string) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    const messageIndex = newMessages.findIndex((m) => m.id === message.id);

    if (!messageIndex) {
      return;
    }

    const oldContent = newMessages[messageIndex].content;
    newMessages[messageIndex].content = newContent;

    new AssignmentMessageRepository()
      .update(assignment.id, message.id, newContent)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het bewerken van het bericht', {
          variant: 'error',
        });

        // Revert message.
        newMessages[messageIndex].content = oldContent;
        setAssignment({ ...assignment, messages: newMessages });
      });
  };

  const makeAssignmentDescription = (assignment: Assignment) => (
    <>
      {assignment.module && (
        <Box mb={1}>
          <strong>Module:</strong>
          <Box ml={1} display="inline-block">
            {assignment.module.publishedName}
          </Box>
        </Box>
      )}
      {roleViewManager.isAdminOrImitatingAdmin() && (
        <Box mb={1}>
          <strong>Formulier ID:</strong>
          <Box ml={1} display="inline-block">
            {entry ? entry.form.id : assignment.form && assignment.form.id}
          </Box>
        </Box>
      )}
      {assignment.user.id !== roleViewManager.getUser().id && (
        <Box mb={1}>
          <strong>Deelnemer:</strong>
          <Box ml={1} display="inline-block">
            {assignment.user.fullNameLastNameFirst}
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <EducationContext.Provider value={educationContextValue}>
      {assignment && (
        <TextMedia
          name={assignment ? assignment.name : ''}
          illustration={<FormIllustration />}
          description={makeAssignmentDescription(assignment)}
        />
      )}
      <Container>
        {assignment && assignment.form && (
          <Box mb={2}>
            <FormDescription form={assignment.form} />
          </Box>
        )}
        {assignment && assignment.form && (
          <FormEntry
            form={assignment.form}
            entries={assignment.entries}
            onFormEntryChange={handleFormEntryChange}
          />
        )}
        {assignment && (
          <AssignmentDialog
            assignment={assignment}
            onNewMessage={handleNewMessage}
            onEdit={handleEditMessage}
            onDelete={handleDeleteMessage}
          />
        )}
        {assignment && assignment.finished && (
          <AssignmentEvaluations assignment={assignment} />
        )}
      </Container>
    </EducationContext.Provider>
  );
};

export default AssignmentView;
