import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import moment from 'moment';

import { RegisterEvent } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  summationList: {
    marginTop: 0,
    paddingLeft: theme.spacing(2),
  },
}));

interface EventSelectionFeedbackProps {
  event: RegisterEvent;
}

const EventSelectionFeedback = (props: EventSelectionFeedbackProps) => {
  const { event } = props;

  const classes = useStyles();

  let deadlineWorkshopsText;
  if (event.workshopsEditableUntil) {
    const deadlineWorkshops = moment(event.workshopsEditableUntil).format(
      'DD-MM-YYYY',
    );

    deadlineWorkshopsText = `Mocht je je deelname aan de workshops willen wijzigen dan kan dat tot ${deadlineWorkshops}. `;
  } else {
    deadlineWorkshopsText =
      'Mocht je je deelname aan de workshops willen wijzigen dan kan dat tot enkele dagen voor de start van het evenement. ';
  }
  deadlineWorkshopsText +=
    'Je klikt op het icoon naast de datum en je komt in het scherm terecht waar je je deelname kunt wijzigen.';

  return (
    <Box mt={2} mb={2}>
      <ul className={classes.summationList}>
        <li>
          De datum van de uitvoering is zichtbaar op de pagina
          instituutsopleiding of de bij- en nascholing.
        </li>
        {event.workshops ? (
          <>
            <li>De workshops zijn zichtbaar in je agenda.</li>
            <li>{deadlineWorkshopsText}</li>
            <li>
              Mocht je je inschrijving in zijn geheel willen annuleren dan word
              je verzocht contact op te nemen met de programma assistent die
              vermeld staat achter de ‘i’ op de regel van het onderwijs.
            </li>
          </>
        ) : (
          <>
            <li>De bijeenkomsten zijn zichtbaar in je agenda.</li>
            <li>
              Mocht je je inschrijving willen wijzigen dan word je verzocht
              contact op te nemen met de programma assistent die vermeld staat
              achter de `i` op de regel van het onderwijs.
            </li>
          </>
        )}
      </ul>
    </Box>
  );
};

export default EventSelectionFeedback;
