import React, { useState } from 'react';
import { TextField, Box, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import EducatorEventRepository from './EducatorEventRepository';

interface EducatorEventSyncProps {
  onSync?: () => void;
}

const EducatorEventSync = (props: EducatorEventSyncProps) => {
  const { onSync } = props;
  const notifications = useSnackbar();
  const [eventId, setEventId] = useState<string>('');
  const [syncing, setSyncing] = useState<boolean>(false);

  const handleEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventId(e.target.value);
  };

  const handleSync = () => {
    if (!eventId) {
      return;
    }

    setSyncing(true);

    new EducatorEventRepository()
      .sync(parseInt(eventId, 10))
      .then(() => {
        notifications.enqueueSnackbar(
          'Het evenement is succesvol gesynchroniseerd.',
          { variant: 'success' },
        );

        if (onSync) {
          onSync();
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het synchroniseren van het evenement.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        setSyncing(false);
      });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <TextField
          type="number"
          label="Evenement ID"
          value={eventId}
          onChange={handleEventChange}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSync}
          startIcon={<FontAwesomeIcon icon={['fal', 'sync']} spin={syncing} />}
        >
          Sync
        </Button>
      </Box>
    </Box>
  );
};

export default EducatorEventSync;
