import {
  ApiFilterCriteria,
  DataCollection,
  Repository,
  RequestAssignment,
  RequestModule,
} from '../../../../types';
import { FilterColumnOption } from '../../../../components/FilterColumn';

class RequestAssignmentRepository implements Repository<RequestAssignment> {
  private modules: RequestModule[];

  constructor(modules: RequestModule[]) {
    this.modules = modules;
  }

  findBy(
    criteria?: ApiFilterCriteria,
  ): Promise<DataCollection<RequestAssignment>> {
    const self = this;
    const filters = (criteria && criteria.filters) || {};

    return new Promise<DataCollection<RequestAssignment>>((resolve) => {
      const modules = self.modules.filter(
        (module) => module.moduleType === 'request',
      ) as RequestModule[];

      const assignments = modules
        .flatMap((module) =>
          module.assignments.flatMap((assignment) => ({
            ...assignment,
            module,
          })),
        )
        .filter((assignment: RequestAssignment) => {
          const {
            status,
            module,
          }: {
            status?: FilterColumnOption[];
            module?: FilterColumnOption[];
          } = filters;

          if (status) {
            let statusFilter = false;

            status
              .map((o) => o.value)
              .forEach((status) => {
                if (status === 'finished' && assignment.finished) {
                  statusFilter = true;
                } else if (status === 'rejected' && assignment.rejected) {
                  statusFilter = true;
                } else if (
                  status === 'requested' &&
                  !assignment.finished &&
                  !assignment.draft
                ) {
                  statusFilter = true;
                } else if (status === 'concept' && assignment.draft) {
                  statusFilter = true;
                } else if (assignment.valuation === status) {
                  statusFilter = true;
                }
              });

            if (!statusFilter) {
              return false;
            }
          }

          if (module) {
            let moduleFilter = false;

            module
              .map((o) => o.value)
              .forEach((module) => {
                if (assignment.module.id === module) {
                  moduleFilter = true;
                }
              });

            if (!moduleFilter) {
              return false;
            }
          }

          return true;
        })
        .sort((a: RequestAssignment, b: RequestAssignment) => {
          if (criteria && criteria.order && criteria.order.length > 0) {
            if (criteria.order[0].field === 'date') {
              if (new Date(a.created) > new Date(b.created)) {
                return criteria.order[0].order === 'desc' ? -1 : 1;
              }
              if (new Date(a.created) < new Date(b.created)) {
                return criteria.order[0].order === 'desc' ? 1 : -1;
              }
            }
          }

          if (a.rejected) return -1;
          if (a.finished) return 1;
          if (new Date(a.created) > new Date(b.created)) return 1;
          if (new Date(a.created) < new Date(b.created)) return -1;
          return 0;
        });

      return resolve({
        items: assignments,
        totalItems: assignments.length,
        perPage: assignments.length,
      });
    });
  }
}

export default RequestAssignmentRepository;
