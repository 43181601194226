import React from 'react';

import { Route, Router } from '../../routing';
import FormOverview from './FormOverview';
import FormEditor from './FormEditor';
import ViewForm from './ViewForm';

const forms = new Route(
  'Formulieren',
  '/formulieren',
  <FormOverview />,
).permission('LIST_FORMS');
Router.addRoute(forms);

const createForm = new Route(
  'Formulier aanmaken',
  '/aanmaken',
  <FormEditor />,
).permission('SAVE_FORM');
forms.addChild(createForm);

const editForm = new Route(
  'Formulier bewerken',
  '/:id',
  <FormEditor />,
).permission('LIST_FORMS');
forms.addChild(editForm);

const viewForm = new Route(
  'Formulier bekijken',
  '/:id/bekijken',
  <ViewForm />,
).requireLogin();
forms.addChild(viewForm);
