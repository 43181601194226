import React, { PropsWithChildren } from 'react';

import { Box, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { colors } from '../config/theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: colors.primary.ultraLight,
  },
}));

const TableContainer = (props: PropsWithChildren<any>) => {
  const classes = useStyles();

  return (
    <Paper>
      <Box className={classes.container}>{props.children}</Box>
    </Paper>
  );
};

export default TableContainer;
