import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Flow as IFlow } from 'flowjs';
// @ts-ignore
import Flow from '@flowjs/flow.js';

import Form, { FormContextFilesState } from '../../yard-forms/Form';
import { FieldState } from '../../yard-forms/fields/FieldProps';
import { FormDataViolation, RequestModule } from '../../../types';
import ModuleRepository from '../module/ModuleRepository';
import { refreshAccount } from '../../../actions';
import { handleFlowFileUploadForAssignmentForm } from '../assignment/helpers';
import FlowProgress from '../../../components/file/FlowProgress';

interface MyRequestsFormProps {
  module: RequestModule;
  form: any;
  onSubmit: () => void;
}

const MyRequestsForm = (props: MyRequestsFormProps) => {
  const { module, form, onSubmit } = props;
  const notifications = useSnackbar();
  const dispatch = useDispatch();
  const [violations, setViolations] = useState<FormDataViolation[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const flow: IFlow = new Flow({
    target: `${process.env.REACT_APP_API_URL}/api/file`,
    withCredentials: true,
  });

  const handleSubmitOrSaveRequest = (
    formData: { [key: string]: FieldState },
    submit: boolean,
    files?: FormContextFilesState,
  ) => {
    setSaving(true);

    const doSubmitOrSaveRequest = () => {
      new ModuleRepository()
        .createRequestAssignment(module, submit, formData)
        .then(() => {
          notifications.enqueueSnackbar(
            'De aanvraag is succesvol aangemaakt!',
            {
              variant: 'success',
            },
          );
          dispatch(refreshAccount());
          onSubmit();
        })
        .catch((err) => {
          if (err.response.data.detail === 'validation_failed') {
            setViolations(err.response.data.violations);
          } else {
            onSubmit();
          }
        })
        .finally(() => setSaving(false));
    };

    if (files) {
      handleFlowFileUploadForAssignmentForm(
        flow,
        formData,
        files,
        doSubmitOrSaveRequest,
      );
    } else {
      doSubmitOrSaveRequest();
    }
  };

  const handleSubmit = (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
  ) => {
    handleSubmitOrSaveRequest(formData, true, files);
  };

  const handleSave = (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
  ) => {
    handleSubmitOrSaveRequest(formData, false, files);
  };

  return (
    <div>
      {form && (
        <Form
          form={form}
          title={false}
          onSubmit={handleSubmit}
          onSave={handleSave}
          submitLabel="Aanvragen"
          submitColor="secondary"
          beforeSubmit={<FlowProgress flow={flow} />}
          violations={violations}
          submitable={!saving}
          description
          autosave={false}
        />
      )}
    </div>
  );
};

export default MyRequestsForm;
