import React, { FormEvent, useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import validator from 'validator';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CompleteUser } from '../../types';
import ApiClient from '../../api/ApiClient';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionHeading: {
    marginBottom: theme.spacing(3),
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  fieldSelect: {
    marginBottom: theme.spacing(3),
    // Work-around for bug that input border is visible behind the label
    // See e.g. https://github.com/mui-org/material-ui/issues/14530
    // TODO: implement this work-around somewhere central rather than per component
    '& label': {
      paddingLeft: '5px',
      marginLeft: '-5px',
      paddingRight: '8px',
      marginRight: '-8px',
      backgroundColor: '#fff',
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

type MyInput = {
  id: string;
  label: string;
  value: any;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  multiline?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  validate?: (
    value: any,
    required: boolean,
    inputs: MyInput[],
  ) => string | null;
  recompute?: (inputs: MyInput[], value: any) => MyInput[];
};

type MyInputProps = {
  id: string;
  onChange: (id: string, value: any) => void;
  inputs: MyInput[];
  // eslint-disable-next-line react/no-unused-prop-types
  options?: { key: string | number; value: any }[];
  [key: string]: any;
};

const genders = [
  { key: 'M', value: 'Man' },
  { key: 'V', value: 'Vrouw' },
  { key: 'O', value: 'Onbekend' },
];

const MyTextField = (props: MyInputProps) => {
  const { id, onChange, inputs } = props;

  const input = inputs.find((input: MyInput) => input.id === id);

  if (!input) {
    return null;
  }

  return (
    <TextField
      key={input.id}
      id={input.id}
      name={input.id}
      label={input.label}
      value={input.value}
      required={input.required}
      error={input.error}
      helperText={input.helperText}
      onChange={(e) => onChange(input.id, e.target.value)}
      variant="outlined"
      fullWidth
      className={input.className}
      multiline={input.multiline}
      rows={input.multiline ? 4 : 1}
    />
  );
};

const MySelect = (props: MyInputProps) => {
  const { id, onChange, inputs, options } = props;

  const input = inputs.find((input: MyInput) => input.id === id);

  if (!input) {
    return null;
  }

  return (
    <FormControl variant="outlined" fullWidth className={input.className}>
      <InputLabel htmlFor={input.id} error={input.error}>
        {input.label}
      </InputLabel>
      <Select
        key={input.id}
        id={input.id}
        name={input.id}
        value={input.value}
        required={input.required}
        error={input.error}
        onChange={(e) => onChange(input.id, e.target.value)}
      >
        {options &&
          options.map((option: { key: string | number; value: string }) => (
            <MenuItem value={option.key}>{option.value}</MenuItem>
          ))}
      </Select>
      <FormHelperText error={input.error}>
        {input.helperText || ''}
      </FormHelperText>
    </FormControl>
  );
};

const MyCheckbox = (props: MyInputProps) => {
  const { id, onChange, inputs } = props;

  const input = inputs.find((input: MyInput) => input.id === id);

  if (!input) {
    return null;
  }

  const control = (
    <Checkbox
      key={input.id}
      id={input.id}
      name={input.id}
      checked={!!input.value}
      required={input.required}
      onChange={(e) => onChange(input.id, e.target.checked)}
      color="primary"
    />
  );

  return (
    <FormControl fullWidth className={input.className}>
      <FormControlLabel control={control} label={input.label} />
    </FormControl>
  );
};

const MyDatePicker = (props: MyInputProps) => {
  const { id, onChange, inputs } = props;

  const input = inputs.find((input: MyInput) => input.id === id);

  if (!input) {
    return null;
  }

  const onDateChange = (date: MaterialUiPickersDate) =>
    onChange(input.id, date ? moment(date).format('YYYY-MM-DD') : null);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        key={input.id}
        id={input.id}
        name={input.id}
        label={input.label}
        value={input.value ? moment(input.value) : null}
        required={input.required}
        error={input.error}
        helperText={input.helperText}
        onChange={onDateChange}
        format="DD-MM-YYYY"
        orientation="landscape"
        autoOk
        views={['year', 'month', 'date']}
        inputVariant="outlined"
        fullWidth
        className={input.className}
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * Prepare user data to be used inside the form
 *
 * @param user
 */
const prepareUserData = (user: any) => {
  const modifiedUser = {} as any;

  for (const property in user) {
    // Convert null into string
    modifiedUser[property] = user[property] === null ? '' : user[property];
  }

  modifiedUser.addressHouseNumber = user.addressHouseNumber
    ? user.addressHouseNumber.toString()
    : '';

  return modifiedUser;
};

const postprocessUserData = (user: any) => ({
  ...user,
  addressHouseNumber:
    typeof user.addressHouseNumber === 'string'
      ? parseInt(user.addressHouseNumber, 10) || null
      : user.addressHouseNumber,
});

type CompleteUserFormProps = {
  user: CompleteUser;
  submit: (e: FormEvent<HTMLFormElement>, user: CompleteUser) => void | null;
};

const CompleteUserForm = (props: CompleteUserFormProps) => {
  const notifications = useSnackbar();
  const { user: initialUser, submit } = props;
  const [user, setUser] = useState(() => prepareUserData(initialUser));

  const [specialisations, setSpecialisations] = useState<
    { key: string; value: string }[]
  >([]);
  const [countries, setCountries] = useState<{ key: string; value: string }[]>(
    [],
  );

  const [isDirty, setDirty] = useState<boolean>(false);
  const [isFormValid, setFormValid] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const classes = useStyles();
  const extended = !!user.afasId; // Extended form, rather than just the main fields

  useEffect(() => {
    ApiClient.getUserFormData().then((res) => {
      setSpecialisations(res.data.specialisations);

      const { countries } = res.data;
      countries.unshift({ key: '', value: '' });
      setCountries(countries);
    });
  }, []);

  const getInput = (inputs: MyInput[], id: string) => {
    const index = inputs.findIndex((input: MyInput) => input.id === id);
    const input = index >= 0 ? inputs[index] : null;

    return { input, index };
  };

  // If any address field is filled, they must all be (except "addressHouseNumberAddition")
  const recomputeAddressFields = (inputs: MyInput[]) => {
    let anyNotEmpty = false;

    [
      'addressStreet',
      'addressHouseNumber',
      'addressHouseNumberAddition',
      'addressPostalCode',
      'addressTown',
      'addressCountryCode',
    ].forEach((id) => {
      const { input } = getInput(inputs, id);
      if (input && !validator.isEmpty(input.value)) {
        anyNotEmpty = true;
      }
    });

    const newInputs = [...inputs];

    [
      'addressStreet',
      'addressHouseNumber',
      'addressPostalCode',
      'addressTown',
      'addressCountryCode',
    ].forEach((id) => {
      const { input, index } = getInput(newInputs, id);
      if (input) {
        newInputs[index] = {
          ...input,
          required: anyNotEmpty,
        };
      }
    });

    return newInputs;
  };

  const initialInputs = useRef([
    {
      id: 'email',
      label: 'E-mailadres (gebruikersnaam)',
      value: user.email,
      required: true,
      className: classes.field,
      validate: (value: any) => {
        if (validator.isEmpty(value)) {
          return 'Dit is een verplicht veld';
        }

        if (!validator.isLength(value, { min: 0, max: 75 })) {
          return 'Maximaal 75 tekens';
        }

        return !validator.isEmail(value)
          ? 'Vul een geldig e-mailadres in'
          : null;
      },
    },
    {
      id: 'nickname',
      label: 'Roepnaam',
      value: user.nickname,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 80 })) {
          return 'Maximaal 80 tekens';
        }

        return null;
      },
    },
    {
      id: 'firstName',
      label: 'Voornaam',
      value: user.firstName,
      required: true,
      className: classes.field,
      validate: (value: any) => {
        if (validator.isEmpty(value)) {
          return 'Dit is een verplicht veld';
        }

        if (!validator.isLength(value, { min: 0, max: 50 })) {
          return 'Maximaal 50 tekens';
        }

        return null;
      },
    },
    {
      id: 'initials',
      label: 'Voorletters',
      value: user.initials,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 15 })) {
          return 'Maximaal 15 tekens';
        }

        return null;
      },
    },
    {
      id: 'insertion',
      label: 'Tussenvoegsel',
      value: user.insertion,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 15 })) {
          return 'Maximaal 15 tekens';
        }

        return null;
      },
    },
    {
      id: 'lastName',
      label: 'Achternaam',
      value: user.lastName,
      required: true,
      className: classes.field,
      validate: (value: any) => {
        if (validator.isEmpty(value)) {
          return 'Dit is een verplicht veld';
        }

        if (!validator.isLength(value, { min: 0, max: 80 })) {
          return 'Maximaal 80 tekens';
        }

        return null;
      },
    },
    {
      id: 'gender',
      label: 'Geslacht',
      value: user.gender,
      className: classes.fieldSelect,
    },
    {
      id: 'dateOfBirth',
      label: 'Geboortedatum',
      value: user.dateOfBirth || null,
      className: classes.field,
    },
    {
      id: 'bigId',
      label: 'Registratienummer (bijv. BIG)',
      value: user.bigId,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 20 })) {
          return 'Maximaal 20 tekens';
        }

        return null;
      },
    },
    {
      id: 'specialisationId',
      label: 'Specialisme',
      value: user.specialisationId || '99',
      className: classes.fieldSelect,
    },
    {
      id: 'phoneMobile',
      label: 'Mobiel',
      value: user.phoneMobile,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 255 })) {
          return 'Maximaal 255 tekens';
        }

        return null;
      },
    },
    {
      id: 'addressStreet',
      label: 'Straat',
      value: user.addressStreet,
      className: classes.field,
      validate: (value: any, required: boolean) => {
        if (required && validator.isEmpty(value)) {
          return 'Gelieve een compleet adres in te vullen';
        }

        if (!validator.isLength(value, { min: 0, max: 60 })) {
          return 'Maximaal 60 tekens';
        }

        return null;
      },
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'addressHouseNumber',
      label: 'Huisnummer',
      value: user.addressHouseNumber,
      className: classes.field,
      validate: (value: any, required: boolean) => {
        if (required && validator.isEmpty(value)) {
          return 'Gelieve een compleet adres in te vullen';
        }

        return !validator.isEmpty(value) && !validator.isNumeric(value)
          ? 'Alleen cijfers'
          : null;
      },
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'addressHouseNumberAddition',
      label: 'Huisnummertoevoeging',
      value: user.addressHouseNumberAddition,
      className: classes.field,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 30 })) {
          return 'Maximaal 30 tekens';
        }

        return null;
      },
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'addressPostalCode',
      label: 'Postcode',
      value: user.addressPostalCode,
      className: classes.field,
      validate: (value: any, required: boolean, inputs: MyInput[]) => {
        if (required && validator.isEmpty(value)) {
          return 'Gelieve een compleet adres in te vullen';
        }

        if (!validator.isLength(value, { min: 0, max: 15 })) {
          return 'Maximaal 15 tekens';
        }

        const { input: inputAddressCountryCode } = getInput(
          inputs,
          'addressCountryCode',
        );

        if (
          !validator.isEmpty(value) &&
          inputAddressCountryCode &&
          inputAddressCountryCode.value === 'NL'
        ) {
          return validator.isPostalCode(value, 'NL')
            ? null
            : 'Vul een geldige postcode in (1234 AB)';
        }

        return null;
      },
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'addressTown',
      label: 'Plaats',
      value: user.addressTown,
      className: classes.field,
      validate: (value: any, required: boolean) => {
        if (required && validator.isEmpty(value)) {
          return 'Gelieve een compleet adres in te vullen';
        }

        if (!validator.isLength(value, { min: 0, max: 50 })) {
          return 'Maximaal 50 tekens';
        }

        return null;
      },
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'addressCountryCode',
      label: 'Land',
      value: user.addressCountryCode,
      className: classes.fieldSelect,
      validate: (value: any, required: boolean) =>
        required && validator.isEmpty(value)
          ? 'Gelieve een compleet adres in te vullen'
          : null,
      recompute: (inputs: MyInput[]) => recomputeAddressFields(inputs),
    },
    {
      id: 'newsletter',
      label: 'Nieuwsbrief',
      value: user.newsletter,
      className: classes.field,
    },
    {
      id: 'dietaryPreferences',
      label: 'Voedselallergieën/voedingsvoorschriften',
      value: user.dietaryPreferences,
      className: classes.field,
      multiline: true,
      validate: (value: any) => {
        if (!validator.isLength(value, { min: 0, max: 255 })) {
          return 'Maximaal 255 tekens';
        }

        return null;
      },
    },
  ]);
  const [inputs, setInputs] = useState<MyInput[]>(initialInputs.current);

  const onChange = (id: string, value: any) => {
    setDirty(true);

    const { input, index } = getInput(inputs, id);

    if (!input) {
      return;
    }

    // Validate this input's new value
    const error = input.validate
      ? input.validate(value, input.required || false, inputs)
      : null;

    // Update input
    const newInputs = [...inputs];

    newInputs[index] = {
      ...input,
      value,
      error: !!error,
      helperText: error || '',
    };

    // Update user
    setUser({ ...user, [id]: value });

    // Compute effects of this input's new value for other inputs
    const recomputedInputs = input.recompute
      ? input.recompute(newInputs, value)
      : newInputs;

    // Re-validate all inputs
    // TODO: also run this on initial load of the form ?
    let formValid = true;
    const revalidatedInputs = recomputedInputs.map((input: MyInput) => {
      const error = input.validate
        ? input.validate(input.value, input.required || false, recomputedInputs)
        : null;

      if (error) {
        formValid = false;
      }

      return {
        ...input,
        error: !!error,
        helperText: error || '',
      };
    });

    setInputs(revalidatedInputs);
    setFormValid(formValid);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitting(true);

    submit(e, postprocessUserData(user));

    setSubmitting(false);
  };

  const onReset = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDirty(false);
    setUser(initialUser);
    setInputs(initialInputs.current);
    notifications.enqueueSnackbar('Het formulier is ge-reset!', {
      variant: 'success',
    });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit} onReset={onReset}>
      <Grid container className={classes.container} spacing={4}>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} className={classes.section}>
            <Typography
              component="h2"
              variant="h6"
              className={classes.sectionHeading}
            >
              Persoonsgegevens
            </Typography>

            <MyTextField id="email" onChange={onChange} inputs={inputs} />
            {extended && (
              <MyTextField id="nickname" onChange={onChange} inputs={inputs} />
            )}
            <MyTextField id="firstName" onChange={onChange} inputs={inputs} />
            {extended && (
              <MyTextField id="initials" onChange={onChange} inputs={inputs} />
            )}
            <MyTextField id="insertion" onChange={onChange} inputs={inputs} />
            <MyTextField id="lastName" onChange={onChange} inputs={inputs} />

            {extended && (
              <>
                <MySelect
                  id="gender"
                  onChange={onChange}
                  inputs={inputs}
                  options={genders}
                />
                <MyDatePicker
                  id="dateOfBirth"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField id="bigId" onChange={onChange} inputs={inputs} />
                {specialisations && (
                  <MySelect
                    id="specialisationId"
                    onChange={onChange}
                    inputs={inputs}
                    options={specialisations}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          {extended && (
            <>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Contactgegevens
                </Typography>

                <MyTextField
                  id="phoneMobile"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="addressStreet"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="addressHouseNumber"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="addressHouseNumberAddition"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="addressPostalCode"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="addressTown"
                  onChange={onChange}
                  inputs={inputs}
                />
                {countries && (
                  <MySelect
                    id="addressCountryCode"
                    onChange={onChange}
                    inputs={inputs}
                    options={countries}
                  />
                )}
              </Grid>

              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Voorkeuren
                </Typography>

                <MyCheckbox
                  id="newsletter"
                  onChange={onChange}
                  inputs={inputs}
                />
                <MyTextField
                  id="dietaryPreferences"
                  onChange={onChange}
                  inputs={inputs}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        className={classes.button}
        startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        disabled={!isDirty || !isFormValid || isSubmitting}
      >
        Opslaan
      </Button>
      <Button
        variant="outlined"
        type="reset"
        color="secondary"
        className={classes.button}
        disabled={!isDirty || isSubmitting}
      >
        Annuleren
      </Button>
    </form>
  );
};

export default CompleteUserForm;
