import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { Form as FormInterface } from '../yard-forms/types';
import FormRepository from './FormRepository';
import Form from '../yard-forms/Form';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiButton-root': {
      display: 'none',
    },
  },
}));

const ViewForm = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState<FormInterface | null>(null);

  useEffect(() => {
    document.body.classList.add('form-pdf-view');

    new FormRepository().find(id).then((response) => {
      setForm(response.data);
    });
  }, [id]);

  if (!form) {
    return null;
  }

  return (
    <Box p={4} className={classes.root}>
      <Form form={form} description onSubmit={() => {}} externalUser={false} />
    </Box>
  );
};

export default ViewForm;
