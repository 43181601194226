import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { File as StoredFile } from '../../types';
import { getImageURL } from '../../utils/common';

interface FileIconProps {
  file: File | StoredFile;
  className?: string;
}

const useStyles = makeStyles(() => ({
  previewImage: {
    width: 32,
    height: 32,
  },
}));

const FileIcon = (props: FileIconProps) => {
  const classes = useStyles();
  const { file, className } = props;

  let type: string;

  if ((file as File).type) {
    type = (file as File).type;
  } else {
    type = (file as StoredFile).mimeType;
  }

  if (
    ['image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml'].includes(type)
  ) {
    let url: string;

    if ((file as File).type) {
      url = URL.createObjectURL(file as File);
    } else {
      url = getImageURL(file as StoredFile, 'icon');
    }

    return (
      <img
        alt={file.name}
        src={url}
        className={`${className} ${classes.previewImage}`}
      />
    );
  }

  let icon: IconName;

  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = 'file-word';
      break;
    case 'application/pdf':
    case 'application/x-pdf':
      icon = 'file-pdf';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = 'file-excel';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      icon = 'file-powerpoint';
      break;
    case 'audio/mpeg':
    case 'audio/vnd.wav':
    case 'audio/x-wav':
      icon = 'file-audio';
      break;
    case 'video/mp4':
    case 'video/quicktime':
    case 'video/x-ms-wmv':
    case 'video/x-msvideo':
      icon = 'file-video';
      break;
    default:
      icon = 'file';
  }

  return (
    <FontAwesomeIcon
      icon={['fal', icon]}
      style={{ width: 32, height: 32 }}
      className={className}
    />
  );
};

export default FileIcon;
