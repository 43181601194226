import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import ApiClient from '../../api/ApiClient';
import { Account } from '../../types';
import { setAccount, setImitating } from '../../actions';
import RoleViewManager, { RoleView } from '../../RoleViewManager';

class UserImitator {
  /**
   * Dispatcher for dispatching Redux actions.
   */
  private readonly dispatch: Dispatch<any>;

  private readonly roleViewManager: RoleViewManager;

  /**
   * Interface for interacting with local storage.
   */
  private readonly localStore: LocalForage;

  private imitating: boolean;

  constructor(
    dispatch: Dispatch,
    roleViewManager: RoleViewManager,
    localStore: LocalForage,
    imitating: boolean,
  ) {
    this.dispatch = dispatch;
    this.roleViewManager = roleViewManager;
    this.localStore = localStore;
    this.imitating = imitating;
  }

  /**
   * Check if user is imitating.
   */
  async isImitating(): Promise<boolean> {
    this.dispatch(setImitating(this.imitating));

    return this.imitating;
  }

  /**
   * Call API to set IMITATE_BEARER cookie.
   * Update the Local Storage such that the imitation state is persisted.
   *
   * @param email
   * @param view
   */
  async imitate(email: string, view?: RoleView) {
    await ApiClient.get('/api/imitate', { _switch_user: email });
    await this.localStore.removeItem('education');

    this.dispatch(setImitating(true));
    await this.refreshAccount(() => {}, view);
  }

  /**
   * Remove the IMITATE_BEARER cookie in the API.
   * Set imitating to false and store state in Local Storage.
   */
  async stopImitating(callback?: () => void) {
    // This will remove the IMITATE_BEARER cookie.
    await ApiClient.get('/api/imitate?_switch_user=_exit');
    await this.localStore.removeItem('view');

    this.imitating = false;

    await this.refreshAccount(() => {
      if (callback) {
        callback();
      }

      this.dispatch(setImitating(false));
    });
  }

  /**
   * Refresh the user account.
   */
  async refreshAccount(callback?: () => void, view?: RoleView) {
    const account: AxiosResponse<Account> = await ApiClient.get(
      '/api/users/account',
    );

    if (view !== undefined) {
      await this.roleViewManager.setCurrentView(view);
    } else {
      this.roleViewManager.setCurrentViewFromRoles(account.data.roles);
    }

    this.dispatch(setAccount(account.data));

    if (callback) {
      callback();
    }
  }
}

export default UserImitator;
