import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { matchSorter } from 'match-sorter';

import { Faq } from './types';
import FaqRepository from './FaqRepository';
import SearchContext from '../../../components/search/SearchContext';
import StyledAccordion from '../../../components/StyledAccordion';
import FaqAccordion from './FaqAccordion';

const useStyles = makeStyles((theme: Theme) => ({
  categoryContainer: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  categoryTitle: {
    fontSize: 20,
  },
}));

interface FaqGroup {
  category: string;
  items: Faq[];
}

const FaqOverview = () => {
  const classes = useStyles();
  const notifications = useSnackbar();
  const { query } = useContext(SearchContext);
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [faqGroupExpanded, setFaqGroupExpanded] = useState<boolean | undefined>(
    undefined,
  );

  const categorizedFaqGroups = useMemo(() => {
    const result: FaqGroup[] = [];

    faqs
      .filter((faq) => faq.visible)
      .forEach((faq) => {
        const category = result.find((c) => c.category === faq.category.title);

        if (!category) {
          result.push({
            category: faq.category.title,
            items: [faq],
          });
        } else {
          category.items.push(faq);
        }
      });

    return result;
  }, [faqs]);

  const filterFaqGroups = (faqGroups: FaqGroup[], query: string) => {
    const filteredFaqGroups: FaqGroup[] = [];

    faqGroups.forEach((faqGroup) => {
      const filteredFaqs = matchSorter<Faq>(faqGroup.items, query, {
        keys: ['title', 'content'],
        threshold: matchSorter.rankings.CONTAINS,
      });

      // only add the group if one or more faqs were matched
      if (filteredFaqs.length >= 1) {
        filteredFaqGroups.push({
          ...faqGroup,
          items: filteredFaqs,
        });
      }
    });

    return filteredFaqGroups;
  };

  useEffect(() => {
    new FaqRepository()
      .findBy({}, 1, undefined, 9999)
      .then((response) => {
        setFaqs(response.data.items);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het ophalen van de FAQ!', {
          variant: 'error',
        });
      });
  }, []);

  const filteredFaqGroups = filterFaqGroups(categorizedFaqGroups, query);

  // automatically expand faq groups if the user starts a search
  useEffect(() => {
    setFaqGroupExpanded(query.length >= 1 ? true : undefined);
  }, [query]);

  return (
    <Container>
      {filteredFaqGroups.map(({ category, items }) => {
        const summary = (
          <Box width="100%" className={classes.categoryTitle}>
            {category}
          </Box>
        );

        return (
          <StyledAccordion
            id={category}
            summary={summary}
            expanded={faqGroupExpanded}
            chevron
            size="sm"
            noShadow
          >
            <Box className={classes.categoryContainer}>
              {items.map((faq) => (
                <FaqAccordion key={faq.id} faq={faq} />
              ))}
            </Box>
          </StyledAccordion>
        );
      })}
    </Container>
  );
};

export default FaqOverview;
