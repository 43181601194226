import React from 'react';

import { Route, Router } from '../../routing';
import NotificationMoodleLoader from './NotificationMoodleLoader';

const moodleNotification = new Route(
  'Leermateriaal',
  '/notificaties/:notificationId/leermateriaal',
  <NotificationMoodleLoader />,
).requireLogin();
Router.addRoute(moodleNotification);
