import { createContext, Dispatch, SetStateAction } from 'react';
import { FormEntry } from '../../yard-forms/types';

const FormEntryContext = createContext<{
  formEntry: FormEntry | undefined;
  setFormEntry: Dispatch<SetStateAction<FormEntry | undefined>> | null;
}>({
  formEntry: undefined,
  setFormEntry: null,
});

export default FormEntryContext;
