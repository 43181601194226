import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';
import PageContainer from '../../components/PageContainer';
import FormBuilder, { FormBuilderState } from '../yard-forms/FormBuilder';
import FormRepository, { FormSaveRequest } from './FormRepository';
import { Form } from '../yard-forms/types';
import { createFlow } from '../../utils/common';
import FlowProgress from '../../components/file/FlowProgress';
import { File as FileInterface } from '../../types';

const FormEditor = () => {
  const { id } = useParams<{ id?: string }>();
  const [form, setForm] = useState<Form | undefined>();
  const repository = new FormRepository();
  const history = useHistory();
  const notifications = useSnackbar();

  const flow = createFlow();

  useEffect(() => {
    if (!id) {
      return;
    }

    repository.find(id).then((response) => {
      setForm(response.data);
    });
  }, [id]);

  const doSave = (form: FormSaveRequest) => {
    let savePromise: Promise<AxiosResponse>;

    if (id) {
      savePromise = repository.update(id, form);
    } else {
      savePromise = repository.create(form);
    }

    savePromise
      .then(() => {
        history.push('/formulieren');
        notifications.enqueueSnackbar(
          'Het formulier is succesvol opgeslagen!',
          {
            variant: 'success',
          },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het opslaan van het formulier!',
          { variant: 'error' },
        );
      });
  };

  const handleSave = (form: FormBuilderState, files: File[]) => {
    const formData = {
      ...form,
      attachments: form.attachments.map((a) => a.id),
    };

    if (files.length) {
      files.forEach((f) => flow.addFile(f));

      flow.on('fileSuccess', (flowFile, message) => {
        const response: FileInterface = JSON.parse(message);
        formData.attachments = [...formData.attachments, response.id];
      });
      flow.on('complete', () => doSave(formData));

      flow.upload();
      return;
    }

    doSave(formData);
  };

  return (
    <PageContainer>
      {id && form && (
        <FormBuilder
          form={form}
          onSave={handleSave}
          attachmentProgress={<FlowProgress flow={flow} />}
        />
      )}
      {!id && (
        <FormBuilder
          onSave={handleSave}
          attachmentProgress={<FlowProgress flow={flow} />}
        />
      )}
    </PageContainer>
  );
};

export default FormEditor;
