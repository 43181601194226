import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import { grey } from '@material-ui/core/colors';

export const colors = {
  primary: {
    ultraLight: '#ecf1f4',
    ultraLightShade: '#dce5eb',
    ultraLight300: '#f7f9fb',
    ultraLight500: '#ecf1f4',
  },
  secondary: {
    ultraLight500: '#F4EEEC',
  },
  tertiary: {
    light: '#d45537',
    main: '#d34320',
    dark: '#9a2f15',
    ultraLight: '#e3c6ba',
  },
  ghost: '#c9cdd9',
  alto: '#d8d6d6',
  monteCarlo: '#83c8b9',
  mandysPink: '#f0baa4',
  paleCopper: '#e2886e',
  foam: '#edf9fe',
  jaggedIce: '#c0e3e9',
  coldPurple: '#a3b0de',
  botticelli: '#d9ddeb',
  eastBay: '#3e5f7e',
  santasGray: '#9ca0b9',
};

export const gradients = {
  primaryLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,241,245,1) 100%)',
  primaryUltraLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,248,250,1) 100%)',
};

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1e4163',
      light: '#274f75',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
    },
    secondary: {
      main: '#e86e45',
      dark: '#632F1E',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    info: {
      main: colors.eastBay,
    },
  },

  shadows: [
    'none',
    '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
    '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
    ...Array(22).fill('none'),
  ] as Shadows,

  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeightBold: 600,
    h1: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: 35,
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
    },
    h3: {
      fontFamily: 'Raleway, sans-serif',
    },
    h4: {
      fontFamily: 'Raleway, sans-serif',
    },
    h5: {
      fontFamily: 'Raleway, sans-serif',
    },
    h6: {
      fontFamily: 'Raleway, sans-serif',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        backgroundColor: colors.primary.ultraLight,
        '&:hover': {
          backgroundColor: colors.primary.ultraLightShade,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        marginRight: 8,
        background: grey[100],
        transition: 'all 0.3s ease',
        '&[aria-selected="true"]': {
          background: '#FFF',
          boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
        },
        textTransform: 'none',
        fontWeight: 600,
      },
    },
  },
});

export default theme;
