import React, { PropsWithChildren, useEffect } from 'react';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import background from './assets/images/login_background.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    background: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0.5,
      background: theme.palette.primary.main,
    },
  },
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 160,
    paddingBottom: theme.spacing(4),
    color: '#FFF',
  },
  content: {
    maxWidth: 600,
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    fontFamily: theme.typography.body1.fontFamily,
    marginBottom: theme.spacing(4),
  },
  description: {
    fontSize: 17,
  },
}));

const Home = (props: PropsWithChildren<any>) => {
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add('footer-no-margin');

    return () => document.body.classList.remove('footer-no-margin');
  }, []);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={12} md={6} className={classes.content}>
            <Box height="100%" display="flex" alignItems="center">
              <Typography variant="h1" component="h1" className={classes.title}>
                Welkom bij de
                <br />
                leeromgeving MijnNspoh
              </Typography>
            </Box>
          </Grid>

          <Grid item>{props.children}</Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
