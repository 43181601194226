import React from 'react';

import { Box, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SafeHtml from '../../../components/SafeHtml';
import StyledAccordion from '../../../components/StyledAccordion';
import FileList from '../../../components/file/FileList';
import { Faq } from './types';

const useStyles = makeStyles(() => ({
  summaryLight: {
    '&[aria-expanded="true"]': {
      background: '#9aabba',
    },
  },
}));

interface FaqAccordionProps {
  faq: Faq;
}

const FaqAccordion = (props: FaqAccordionProps) => {
  const { faq } = props;

  const classes = useStyles();

  return (
    <Box mt={2} mb={2}>
      <StyledAccordion
        id={faq.id}
        summary={<Box width="100%">{faq.title}</Box>}
        summaryClassName={classes.summaryLight}
        chevron
        size="sm"
      >
        <Box>
          <SafeHtml html={faq.content} />
          {faq.attachments.length > 0 && (
            <Box mt={3} width="100%">
              <FormLabel>Bijlagen</FormLabel>
              <FileList files={faq.attachments} />
            </Box>
          )}
        </Box>
      </StyledAccordion>
    </Box>
  );
};

export default FaqAccordion;
