import React, { useEffect } from 'react';

import { EditorProps } from './EditorProps';
import Section from '../fields/Section';

const SectionEditor = (props: EditorProps) => {
  const { field, setPreview } = props;

  useEffect(() => {
    setPreview(
      <div>
        <Section
          id={field.id}
          label={field.label}
          description={field.description}
          metadata={field.metadata}
          options={field.options || []}
        />
      </div>,
    );
  }, [field]);

  return <div />;
};

export default SectionEditor;
