import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';

const NumberField = (props: FieldProps) => {
  const { id, entryField, disabled } = props;

  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid =
      event.target.value.length > 0 &&
      !Number.isNaN(parseFloat(event.target.value));
    setFormData!({
      ...formData,
      [id]: {
        valid: isValid,
        value: isValid ? parseFloat(event.target.value) : null,
      },
    });
  };

  return (
    <div>
      <TextField
        type="number"
        required={false}
        onChange={handleChange}
        defaultValue={entryField ? entryField.value : undefined}
        disabled={disabled}
      />
    </div>
  );
};

export default NumberField;
