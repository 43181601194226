import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';

import fields from '../fields';
import { FormFieldInterface } from '../FormField';
import FieldLabel from './FieldLabel';
import FormFieldRoles from './FormFieldRoles';
import SafeHtml from '../../../components/SafeHtml';
import { FormDataViolation } from '../../../types';

interface FieldStartProps {
  formField: FormFieldInterface;
  className?: string;
  violation?: FormDataViolation;
}

const useStyles = makeStyles((theme: Theme) => ({
  violationMessage: {
    color: red[300],
  },
  fieldDescription: {
    overflowX: 'auto',
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > ul': {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const FieldStart = (props: FieldStartProps) => {
  const { formField, className, violation } = props;
  const classes = useStyles();
  const field = fields.find((f) => f.type === formField.type);

  if (!field) {
    return <div />;
  }

  return (
    <>
      {(field.hasLabel !== undefined ? field.hasLabel : true) && (
        <FieldLabel required={formField.required} className={className}>
          {violation && (
            <Box mr={2} display="inline-block">
              <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
            </Box>
          )}
          {formField.label}
        </FieldLabel>
      )}
      {violation && (
        <div className={classes.violationMessage}>{violation.title}</div>
      )}
      <FormFieldRoles field={formField} />
      {(field.hasDescription !== undefined ? field.hasDescription : true) &&
        formField.description.length > 0 && (
          <SafeHtml
            html={formField.description}
            className={classes.fieldDescription}
          />
        )}
    </>
  );
};

export default FieldStart;
