import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme, Tooltip } from '@material-ui/core';
import FormPreviewDialog from './FormPreviewDialog';

interface FormPreviewIconProps {
  formId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.dark,
    paddingTop: theme.spacing(0),
  },
}));

const FormPreviewIcon = (props: FormPreviewIconProps) => {
  const { formId } = props;
  const classes = useStyles();
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
  const [previewFormId, setPreviewFormId] = useState<string>('');

  const handlePreviewDialogOpen = (formId: string) => {
    setPreviewDialogOpen(true);
    setPreviewFormId(formId);
  };

  const handlePreviewDialogClose = () => {
    setPreviewDialogOpen(false);
    setPreviewFormId('');
  };

  return (
    <div>
      {previewFormId.length > 0 && (
        <FormPreviewDialog
          formId={previewFormId}
          open={previewDialogOpen}
          onClose={handlePreviewDialogClose}
        />
      )}
      <Tooltip title="Opdracht voorbeeld">
        <IconButton
          onClick={() => handlePreviewDialogOpen(formId)}
          size="small"
          className={classes.icon}
        >
          <FontAwesomeIcon icon={['fal', 'info-circle']} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default FormPreviewIcon;
