import React, { useState } from 'react';
import { Box, Button, IconButton, Popover, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { Assignment, MinimalUser } from '../../types';
import DataAutocomplete from '../../components/DataAutocomplete';
import AssignmentRepository from '../education/assignment/repository/AssignmentRepository';
import EvaluationUserRepository from './EvaluationUserRepository';

interface EvaluatorEducatorProps {
  assignment: Assignment;
  className?: string;
  onSave?: () => void;
}

const useStyles = makeStyles(() => ({
  content: {
    minWidth: 300,
  },
}));

const EvaluatorEditor = (props: EvaluatorEducatorProps) => {
  const classes = useStyles();
  const notifications = useSnackbar();
  const { assignment, className, onSave } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [users, setUsers] = useState<MinimalUser[]>(
    assignment.assignedEvaluators?.map((a) => a.evaluator) || [],
  );
  const open = Boolean(anchorEl);
  const [error, setError] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleUserSelectChange = (users: MinimalUser[]) => {
    setUsers(users);
  };

  const handleSave = () => {
    if (users.length === 0) {
      setError(true);
      return;
    }

    new AssignmentRepository()
      .updateEvaluators(assignment, users)
      .then(() => {
        notifications.enqueueSnackbar('De beoordelaars zijn opgeslagen.', {
          variant: 'success',
        });
        handleClose();

        if (onSave) {
          onSave();
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij opslaan van beoordelaars.', {
          variant: 'error',
        });
      });
  };

  const evaluationUserRepository = new EvaluationUserRepository();

  return (
    <div>
      <Tooltip title="Beoordelaars bewerken">
        <IconButton onClick={handleOpen} className={className} size="small">
          <FontAwesomeIcon icon={['fad', 'users']} />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box p={2} className={classes.content}>
          <DataAutocomplete
            initialData={users}
            repository={evaluationUserRepository}
            keys={['email', 'fullName', 'fullNameLastNameFirst']}
            renderOption={(option: MinimalUser) => <div>{option.fullName}</div>}
            getLabel={(option: MinimalUser) => option.fullName || ''}
            onChange={handleUserSelectChange}
            error={error}
          />
          <Box mt={2}>
            <Button
              startIcon={<FontAwesomeIcon icon={['fal', 'save']} />}
              variant="contained"
              color="secondary"
              onClick={handleSave}
            >
              Opslaan
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default EvaluatorEditor;
