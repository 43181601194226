import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import {
  Badge,
  createStyles,
  IconButton,
  Theme,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import NewsRepository from './news/NewsRepository';
import { colors } from '../../config/theme';
import AppContext from '../../AppContext';
import { RoleInterface } from '../users/Roles';
import { ROUTE_INFO } from './index';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  notifications: {
    margin: 0,
    padding: theme.spacing(1),
    maxWidth: 400,
    maxHeight: 500,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 5,
      minWidth: 17,
      height: 17,
      fontSize: 10,
      border: `2px solid ${theme.palette.primary.main}`,
      background: colors.tertiary.main,
      padding: '0 4px',
    },
  }),
)(Badge);

const InfoBell = () => {
  const classes = useStyles();
  const history = useHistory();
  const [totalCount, setTotalCount] = useState<number>(0);
  const { roleViewManager } = useContext(AppContext);
  const privilegedUsers: (keyof RoleInterface)[] = [
    'ROLE_ADMIN',
    'ROLE_PROGRAM_ASSISTANT',
  ];

  /**
   * Determines if the user has unread news.
   */
  const checkUnreadNews = useCallback(() => {
    new NewsRepository()
      .getUnreadNews()
      .then((response) => {
        setTotalCount(response.data.totalItems);
      })
      .catch(() => {});
  }, []);

  /**
   * On load, fetch unread news for count.
   */
  useEffect(() => {
    checkUnreadNews();
    const checkInterval = setInterval(checkUnreadNews, 60000);

    return () => clearInterval(checkInterval);
  }, [checkUnreadNews]);

  return (
    <div className={classes.root}>
      {!roleViewManager.hasRole(privilegedUsers) && (
        <StyledBadge badgeContent={totalCount} color="secondary">
          <IconButton
            size="small"
            className={classes.button}
            onClick={() => history.push(ROUTE_INFO)}
          >
            <FontAwesomeIcon icon="info" />
          </IconButton>
        </StyledBadge>
      )}
      {roleViewManager.hasRole(privilegedUsers) && (
        <IconButton
          size="small"
          className={classes.button}
          onClick={() => history.push(ROUTE_INFO)}
        >
          <FontAwesomeIcon icon="info" />
        </IconButton>
      )}
    </div>
  );
};

export default InfoBell;
