import React from 'react';
import TextMediaHeader from '../../components/pageheader/TextMedia';
import { ReactComponent as EducationIllustration } from '../../assets/images/education_illustration.svg';

const ELearningOverview = () => {
  return (
    <div>
      <TextMediaHeader
        name="Gratis e-learnings"
        illustration={<EducationIllustration />}
      >
        <p>
          Welkom bij Mijn NSPOH! Gebruik de link in het menu om aan de slag te
          gaan met de gratis e-learnings.
        </p>
      </TextMediaHeader>
    </div>
  );
};

export default ELearningOverview;
