import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import debounce from 'lodash/debounce';

import Checkbox from '@material-ui/core/Checkbox';
import NewsRepository from './NewsRepository';
import HtmlEditor from '../../../components/HtmlEditor';
import { NewsItem } from '../../../types';
import ImageSuggest from '../../../components/image-suggest/ImageSuggest';
import Roles, { RoleInterface } from '../../users/Roles';
import FileUpload from '../../../components/file/FileUpload';
import FileList from '../../../components/file/FileList';

type Filter = {
  afasId: number;
  moodleCourseId?: number | null;
  name: string;
  participantAfasId?: number | null;
  practicalTrainerAfasId?: number | null;
  startDate?: string | null;
};

interface Filters {
  events: Filter[];
  educations: Filter[];
  roles: (keyof RoleInterface)[];
}

interface NewsFormProps {
  item?: NewsItem | null;
  filters: Filters | null;
  onChangeFile: (file: File) => void;
  onUploadFiles: (files: File[]) => void;
  onSubmit: (data: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  switch: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    width: 250,
  },
}));

const NewsForm = (props: NewsFormProps) => {
  const { item, filters, onChangeFile, onUploadFiles, onSubmit } = props;
  const classes = useStyles();
  const notifications = useSnackbar();

  const [formatted, setFormatted] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Filter[]>([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = useState('');
  const [eventsQuery, setEventsQuery] = useState('');

  const news = new NewsRepository();

  // @ts-ignore
  const [state, setState] = useState<{
    newsId: string | undefined;
    title: string | '';
    content: string | null;
    roles: [];
    events: Filter[];
    educations: Filter[];
    startDate: Moment | string | null;
    endDate: Moment | string | null;
    imageUrl: string | null;
    published: boolean;
    files: Array<any> | null;
  }>({
    newsId: item?.id,
    title: item?.title || '',
    content: item?.content || null,
    roles: item?.roles || [],
    events: item?.events || [],
    educations: item?.educations || [],
    startDate: item?.publishedStart || null,
    endDate: item?.publishedEnd || null,
    imageUrl: item?.imageUrl || null,
    published: item?.published || false,
    files: item?.files || null,
  });
  const {
    newsId,
    title,
    content,
    roles,
    events,
    educations,
    startDate,
    endDate,
    published,
    imageUrl,
    files,
  } = state;

  /**
   * Handle HtmlEditor change.
   */
  const handleChangeDescription = debounce((event: any, editor: any) => {
    const editorData = editor.getData();
    setState({ ...state, content: editorData });
  }, 300);

  /**
   * Handle published change.
   */
  const handleChangePublished = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setState({ ...state, published: checked });
  };

  /**
   * Handle date change.
   */
  const handleChangeDate = (name: string, date: MaterialUiPickersDate) => {
    setState({ ...state, [name]: date });
  };

  /**
   * Handle text field change.
   */
  const handleTextFieldChange = (
    e: React.ChangeEvent<{ name?: any; value: unknown }>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  /**
   * Handle multiple select change.
   */
  const handleChangeRole = (name: string, value: unknown) => {
    setState({ ...state, [name]: value as string[] });
  };

  const filter = createFilterOptions<Filter>();

  /**
   * Handle multiple select change.
   */
  const handleChangeMultiple = (name: string, value: Filter[]) => {
    const data = value.map((option) => option);

    setState({ ...state, [name]: data });

    const selectAll = data.find((option) => option.afasId === 0);

    if (selectAll && filters) {
      const result: Filter[] = [];
      // Get the search keyword from dummy value input
      const search = selectAll.name.split(':').pop();

      // @ts-ignore
      if (search) {
        filters.educations
          .filter((option) =>
            option.name.toLowerCase().includes(search.toLowerCase().trim()),
          )
          .map((item) => result.push(item));
        setState({ ...state, [name]: result });
      }
    }
  };

  /**
   * Handle manual file upload.
   */
  const handleFileChangeManual = (file: File) => {
    onChangeFile(file);
  };

  /**
   * Handle url file upload.
   */
  const handleFileChangeURL = (imageUrl: string) => {
    setState({ ...state, imageUrl });
  };

  /**
   * Handle dropzone upload
   */
  const handleUploadFiles = (files: File[]) => {
    onUploadFiles(files);
  };

  /**
   * Format the event label (include dates)
   */
  const formatEventLabel = (option: Filter) => {
    if (option && !option.startDate) return option.name;
    return `${option.name} -  (${moment(option.startDate).format(
      'DD-MM-YYYY',
    )})`;
  };

  /**
   * Handle reset educations
   */
  const resetEducations = () => {
    setState({ ...state, educations: [] });
  };

  const filterEvents = useCallback(
    _.debounce((query) => {
      new NewsRepository()
        .filterEvents(query)
        .then(({ data }) => {
          const { events } = data as { events: Filter[] };
          if (!events) return;
          setOptions(
            events.sort((f1: Filter, f2: Filter) => {
              if (f1.name !== f2.name) {
                return f1.name.localeCompare(f2.name);
              }

              // in this context, start dates are never undefined
              if (!f1.startDate || !f2.startDate) {
                return 0;
              }

              // newest first
              return new Date(f1.startDate) < new Date(f2.startDate) ? 1 : -1;
            }),
          );
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Er ging iets mis bij het ophalen van de filters',
            {
              variant: 'error',
            },
          );
        });
    }, 500),
    [notifications],
  );

  const handleEventFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEventsQuery(event.target.value);
  };

  useEffect(() => {
    if (eventsQuery.length >= 3) {
      filterEvents(eventsQuery);
    }
  }, [eventsQuery, filterEvents]);

  /**
   * Handle form submit.
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!content) {
      notifications.enqueueSnackbar('Omschrijving is een verplicht veld', {
        variant: 'info',
      });
    }

    if (!startDate || !endDate) {
      notifications.enqueueSnackbar(
        'Start- en einddatum zijn verplichte velden',
        {
          variant: 'info',
        },
      );
    }

    if (!roles.length && !educations.length && !events.length) {
      notifications.enqueueSnackbar(
        'Je moet minstens een rol of opleiding of evenement aangeven',
        {
          variant: 'info',
        },
      );
    }

    onSubmit({
      title,
      content,
      published,
      publishedStart: startDate,
      publishedEnd: endDate,
      roles,
      imageUrl,
      educations: educations.map((e) => e.afasId),
      events: events.map((e) => e.afasId),
    });
  };

  useEffect(() => {
    const formattedEducations = educations.map((value: any) =>
      filters?.educations.find((education) => education.afasId === value),
    );

    news
      .getEvents(events)
      .then(({ data }) => {
        const { events } = data;
        if (!events) return;
        setState({
          ...state,
          events,
          educations: formattedEducations as Filter[],
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van de evenementen',
          {
            variant: 'error',
          },
        );
      });

    setState({
      ...state,
      educations: formattedEducations as Filter[],
    });

    setFormatted(true);
  }, []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleRemoveFile = (index: number) => {
    if (files) {
      const fileId = files[index].id;
      files.splice(index, 1);
      setState({ ...state, files });
      news
        .removeFile(newsId, fileId)
        .then(() => {
          notifications.enqueueSnackbar('Bestand succesvol verwijderd', {
            variant: 'success',
          });
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Er ging iets mis bij het ophalen van de filters',
            {
              variant: 'error',
            },
          );
        });
    }
  };

  const publishControl = (
    <Switch
      color="secondary"
      checked={published}
      onChange={handleChangePublished}
      name="published"
    />
  );

  // @ts-ignore
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      onSubmit={handleSubmit}
      onKeyPress={(event) => {
        if (event.which === 13 /* Enter */) {
          event.preventDefault();
        }
      }}
      autoComplete="off"
    >
      <Paper elevation={0} style={{ padding: 16 }}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Titel"
              name="title"
              required
              value={title}
              onChange={handleTextFieldChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <HtmlEditor
                data={content || ''}
                onChange={handleChangeDescription}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FileUpload onChange={handleUploadFiles} documents video />
            {files && <FileList files={files} onRemove={handleRemoveFile} />}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <ImageSuggest
                imageUrl={imageUrl}
                onChangeManual={handleFileChangeManual}
                onChangeUrl={handleFileChangeURL}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  format="DD-MM-YYYY"
                  orientation="landscape"
                  autoOk
                  margin="normal"
                  label="Startdatum"
                  value={startDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    return handleChangeDate('startDate', date || null);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Verander startdatum',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  format="DD-MM-YYYY"
                  orientation="landscape"
                  autoOk
                  margin="normal"
                  label="Einddatum"
                  value={endDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    return handleChangeDate('endDate', date || null);
                  }}
                  minDate={startDate}
                  KeyboardButtonProps={{
                    'aria-label': 'Verander einddatum',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.switch}>
            <FormControlLabel control={publishControl} label="Publiceren" />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              {filters && filters.roles && (
                <Autocomplete
                  id="role-select"
                  getOptionLabel={(option) => Roles[option]}
                  multiple
                  disabled={!!(events.length || educations.length)}
                  noOptionsText="Geen opties"
                  onChange={(e, value) => handleChangeRole('roles', value)}
                  options={filters.roles}
                  value={Array.from<keyof RoleInterface>(roles)}
                  renderTags={(value, getTagProps) => {
                    return (
                      <>
                        {value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={Roles[option]}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getTagProps({ index })}
                          />
                        ))}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="standard"
                      label="Rollen"
                      placeholder="Kies rollen"
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              {filters && filters.educations && formatted && (
                <Autocomplete
                  id="education-select"
                  inputValue={inputValue}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    if (!option || !value) return false;
                    return option.afasId === value.afasId;
                  }}
                  multiple
                  disableCloseOnSelect
                  disabled={!!(roles.length || events.length)}
                  noOptionsText="Geen opties"
                  onChange={(e, value) => {
                    return handleChangeMultiple('educations', value);
                  }}
                  options={filters.educations}
                  onBlur={() => setInputValue('')}
                  value={educations}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    // Add 'select all' option to the list
                    if (params.inputValue.length > 2) {
                      filtered.unshift({
                        afasId: 0,
                        name: `Selecteer alles met: ${params.inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderTags={(selected: any, getTagProps) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) =>
                        filters.educations
                          .filter(
                            (education) => education.afasId === value.afasId,
                          )
                          .map((education, index) => (
                            <Chip
                              key={education.afasId}
                              label={education.name}
                              variant="outlined"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...getTagProps({ index })}
                            />
                          )),
                      )}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      onChange={(e) => setInputValue(e.target.value)}
                      variant="standard"
                      label="Opleidingen"
                      placeholder="Kies opleidingen"
                    />
                  )}
                />
              )}
            </FormControl>
            {educations.length != 0 && (
              <Button
                color="secondary"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={['fad', 'eraser']} />}
                type="button"
                onClick={resetEducations}
              >
                Wissen
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                id="events-select"
                getOptionLabel={(option) => formatEventLabel(option)}
                getOptionSelected={(option, value) => {
                  return option.afasId === value.afasId;
                }}
                loading={loading}
                multiple
                disabled={!!(roles.length || educations.length)}
                noOptionsText="Geen opties"
                onChange={(e, value) => handleChangeMultiple('events', value)}
                onClose={() => {
                  setOpen(false);
                }}
                onOpen={() => {
                  setOpen(true);
                }}
                options={options}
                value={events}
                renderTags={(selected: any, getTagProps) => (
                  <div className={classes.chips}>
                    {selected.map((value: any, index: number) => (
                      <Chip
                        key={value.afasId}
                        label={value.name}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getTagProps({ index })}
                      />
                    ))}
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onChange={handleEventFilterChange}
                    variant="standard"
                    label="Evenementen"
                    placeholder="Typ om evenementen te zoeken"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <FormControl margin="normal">
              <Button
                color="secondary"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
                type="submit"
              >
                Opslaan
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default NewsForm;
