import { createContext } from 'react';
import { Education } from '../../types';

const EducationContext = createContext<{
  education: Education | null;
  educationPermissions: {
    view: boolean;
    edit: boolean;
    imitatingAdmin?: boolean;
  };
}>({
  education: null,
  educationPermissions: {
    view: false,
    edit: false,
  },
});

export default EducationContext;
