import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useFormFieldStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  preview: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: grey[100],
  },
}));

export default useFormFieldStyles;
