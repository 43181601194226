import { Module, PracticalModule } from '../../types';

/**
 * Filter modules by type
 *
 * @param modules
 * @param type
 */
export const filterModulesByType = (modules: Module[], type: string) => {
  let moduleType = 'education';

  if (type === 'onderwijs') {
    moduleType = 'education';
  } else if (type === 'praktijk') {
    moduleType = 'practical';
  }

  return modules.filter((module: Module) => module.moduleType === moduleType);
};

/**
 * Sort modules by a given column
 *
 * @param modules
 * @param column
 * @param order
 */
export const sortModulesByColumn = (
  modules: Module[],
  column: 'publishedName' | 'type' | 'status' | 'date',
  order: 'ASC' | 'DESC',
) => {
  return modules.sort((a: Module, b: Module) => {
    if (column === 'date') {
      const aEvent = a.events && a.events.length >= 1 ? a.events[0] : null;
      const bEvent = b.events && b.events.length >= 1 ? b.events[0] : null;

      if (aEvent && !bEvent) {
        return order === 'ASC' ? 1 : -1;
      }

      if (!aEvent && bEvent) {
        return order === 'ASC' ? -1 : 1;
      }

      if (!aEvent || !bEvent) {
        return 0;
      }

      return new Date(aEvent.startDate) > new Date(bEvent.startDate)
        ? order === 'ASC'
          ? -1
          : 1
        : order === 'ASC'
        ? 1
        : -1;
    }

    if (column === 'type') {
      const orderFactor = order === 'DESC' ? -1 : 1;
      if (a.type > b.type) return orderFactor;
      if (a.type < b.type) return -orderFactor;
      return (a.publishedName as string).localeCompare(
        b.publishedName as string,
      );
    }

    if (typeof a[column] === 'string') {
      if (order === 'DESC') {
        return (b[column] as string).localeCompare(a[column] as string);
      }

      return (a[column] as string).localeCompare(b[column] as string);
    }

    if (order === 'DESC') {
      return a[column]! > b[column]! ? -1 : 1;
    }

    return a[column]! > b[column]! ? 1 : -1;
  });
};

/**
 *
 * @param modules
 * @param query
 */
export const filterModulesByName = (modules: Module[], query: string) =>
  modules.filter((module: Module | PracticalModule) => {
    const value = query.toLowerCase();

    return (
      module.publishedName.toLowerCase().includes(value) ||
      module?.assignments?.find((a) => a.name.toLowerCase().includes(value)) ||
      module?.events?.find((e) => e.name.toLowerCase().includes(value)) ||
      ('assignmentContainers' in module &&
        module.assignmentContainers.find((c) =>
          c.name.toLowerCase().includes(value),
        ))
    );
  });
