import { Box, Button, Container, IconButton, Tooltip } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataTable from '../../../components/DataTable';
import {
  ApiFilterCriteria,
  GlobalUserPermission,
  NewsItem,
} from '../../../types';
import TextMedia from '../../../components/pageheader/TextMedia';
import NewsManagementRepository from './NewsManagementRepository';
import AppContext from '../../../AppContext';
import { RoleInterface } from '../../users/Roles';
import { ReactComponent as NewsIllustration } from '../../../assets/images/news_illustration.svg';
import { ROUTE_NEWS_MANAGEMENT } from '../index';
import StatusIndicator from '../../../components/StatusIndicator';

const NewsManagement = () => {
  const [state] = useState<{
    defaultFilters: ApiFilterCriteria;
    repository: NewsManagementRepository;
  }>({
    defaultFilters: {
      filters: {},
      query: '',
    },
    repository: new NewsManagementRepository(),
  });
  const { defaultFilters, repository } = state;
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);
  const notifications = useSnackbar();

  const allowedRoles: (keyof RoleInterface)[] = ['ROLE_ADMIN'];
  const allowedPermissions: GlobalUserPermission[] = ['MANAGE_NEWS'];

  const canManage =
    roleViewManager.hasRole(allowedRoles) ||
    roleViewManager.hasPermission(allowedPermissions);

  const canDelete = roleViewManager.hasRole(allowedRoles);

  const columns: any = [
    {
      name: 'Titel',
      field: 'title',
      sortable: true,
      render: (news: NewsItem) => (
        <span>
          <StatusIndicator indicator={news.published ? 'green' : 'orange'} />
          <Box ml={1}>{news.title}</Box>
        </span>
      ),
    },
    {
      name: 'Auteur',
      field: 'author',
      sortable: false,
      filter: {
        type: 'autocomplete' as const,
        options: async () => {
          const response = await repository.authors();

          return response.data.map((author) => ({
            value: author.id!,
            label: author.fullNameLastNameFirst!,
          }));
        },
      },
      render: (news: NewsItem) => <div>{news.author.fullName}</div>,
    },
    {
      name: 'Startdatum',
      field: 'start',
      sortable: true,
      render: (news: NewsItem) => {
        if (news.publishedStart == null) {
          return '-';
        }

        return <div>{moment(news.publishedStart).format('DD-MM-YYYY')}</div>;
      },
    },
    {
      name: 'Einddatum',
      field: 'end',
      sortable: true,
      render: (news: NewsItem) => {
        if (news.publishedEnd == null) {
          return '-';
        }

        return <div>{moment(news.publishedEnd).format('DD-MM-YYYY')}</div>;
      },
    },
    {
      name: 'Status',
      field: 'status',
      sortable: true,
      filter: {
        type: 'checkbox' as const,
        options: [
          { label: 'Concept', value: 'concept' },
          { label: 'Gepubliceerd', value: 'published' },
        ],
      },
      render: (news: NewsItem) => {
        if (news.published) {
          return 'Gepubliceerd';
        }
        return 'Concept';
      },
    },
  ];

  /**
   * Handle duplicate.
   */
  const onDuplicate = (id: string) => {
    repository
      .duplicate(id)
      .then(({ data }) => {
        history.push(`${ROUTE_NEWS_MANAGEMENT}/bewerken/${data.id}`);
        notifications.enqueueSnackbar('Succesvol gedupliceerd', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis, het item kon niet worden gedupliceerd',
          {
            variant: 'error',
          },
        );
      });
  };

  const itemActions = (news: NewsItem, className: string) => (
    <div>
      {canManage && (
        <Tooltip title="Bewerken">
          <IconButton
            size="small"
            component={Link}
            to={`/nieuwsbeheer/bewerken/${news.id}`}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} />
          </IconButton>
        </Tooltip>
      )}
      {canManage && (
        <Tooltip title="Dupliceren">
          <IconButton
            size="small"
            onClick={() => onDuplicate(news.id as string)}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'copy']} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div>
      <TextMedia
        name="Nieuwsbeheer"
        illustration={<NewsIllustration />}
        description="Beheer hier eenvoudig alle nieuwsberichten."
        size="medium"
      >
        {canManage && (
          <Button
            color="secondary"
            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
            onClick={() => history.push('/nieuwsbeheer/toevoegen')}
            variant="contained"
          >
            Nieuws toevoegen
          </Button>
        )}
      </TextMedia>
      <Container>
        <DataTable
          id="news-management"
          repository={repository}
          columns={columns}
          defaultFilters={defaultFilters}
          actions={itemActions}
          persistFilters
          deletable={canDelete}
          deleteItemMessage={(news: NewsItem) => {
            return `Weet je zeker dat je ${
              news ? news.title : 'dit nieuwsbericht'
            } wilt verwijderen?`;
          }}
        />
      </Container>
    </div>
  );
};

export default NewsManagement;
