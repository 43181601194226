import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) => ({
  sortIcon: {
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform'),
  },
  sortIconAsc: {
    transform: 'rotate(-180deg)',
  },
}));

type TabPanelHeadSortable = {
  label: string;
  order: string;
  orderBy: string;
  sortBy: string;
  handleSortBy: Function;
  className: string;
};

const TabPanelHeadSortable = (props: TabPanelHeadSortable) => {
  const { className, label, handleSortBy, sortBy, order, orderBy } = props;

  const classes = useStyles();

  return (
    <div className={className} onClick={() => handleSortBy(sortBy)}>
      {label}
      <FontAwesomeIcon
        icon={[
          'fad',
          orderBy === sortBy
            ? order === 'ASC'
              ? 'sort-down'
              : 'sort-up'
            : 'sort',
        ]}
        className={classes.sortIcon}
      />
    </div>
  );
};

export default TabPanelHeadSortable;
