import { AxiosResponse, CancelTokenSource } from 'axios';

import { ApiFilterCriteria, CollectionResponse, Repository } from '../../types';
import { SyncIssue } from './types';
import { parseCriteria } from '../../utils/common';
import ApiClient from '../../api/ApiClient';

class SyncIssuesRepository implements Repository<SyncIssue> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
  ): Promise<CollectionResponse<SyncIssue>> {
    const params = parseCriteria(criteria, { page: page || 1 });
    return ApiClient.get(
      '/api/afas/sync/issues',
      params,
      {},
      { cancelToken: cancelToken?.token },
    );
  }

  find(id: string): Promise<AxiosResponse<SyncIssue>> {
    return ApiClient.get(`/api/afas/sync/issues/${id}`);
  }

  resolve(id: string): Promise<AxiosResponse> {
    return ApiClient.post(`/api/afas/sync/issues/${id}/resolve`);
  }
}

export default SyncIssuesRepository;
