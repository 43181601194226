import React from 'react';
import { Button } from '@material-ui/core';

interface EventRegisterButtonProps {
  onClick?: (e: React.MouseEvent) => void;
}

const EventRegisterButton = (props: EventRegisterButtonProps) => {
  const { onClick } = props;

  return (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      onClick={onClick}
    >
      Kies uitvoering
    </Button>
  );
};

export default EventRegisterButton;
