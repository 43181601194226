export interface RoleInterface {
  ROLE_ADMIN: string;
  ROLE_PARTICIPANT: string;
  ROLE_FREE_E_LEARNING_USER: string;
  ROLE_EDUCATOR: string;
  ROLE_PRACTICAL_TRAINER: string;
  ROLE_INSTITUTE_EDUCATOR: string;
  ROLE_PROGRAM_ASSISTANT: string;
  ROLE_TEACHER: string;
  ROLE_EXTERNAL: string;
}

const Roles: RoleInterface = {
  ROLE_ADMIN: 'Admin',
  ROLE_PARTICIPANT: 'Deelnemer',
  ROLE_FREE_E_LEARNING_USER: 'E-learninggebruiker',
  ROLE_EDUCATOR: 'Beoordelaar',
  ROLE_PRACTICAL_TRAINER: 'Praktijkopleider',
  ROLE_INSTITUTE_EDUCATOR: 'NSPOH opleider',
  ROLE_PROGRAM_ASSISTANT: 'Programma assistent',
  ROLE_TEACHER: 'Docent',
  ROLE_EXTERNAL: 'Extern (vanuit verzoek tot bijdrage)',
};

/**
 * All the roles that are considered to be "educators".
 * @private
 */
export const educatorRoles: (keyof RoleInterface)[] = [
  'ROLE_EDUCATOR',
  'ROLE_TEACHER',
  'ROLE_PRACTICAL_TRAINER',
  'ROLE_INSTITUTE_EDUCATOR',
  'ROLE_PROGRAM_ASSISTANT',
];

export default Roles;
