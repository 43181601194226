export interface AssignmentStateInterface {
  open: string;
  rejected: string;
  in_review: string;
  finished: string;
}

const AssignmentStates: AssignmentStateInterface = {
  open: 'Nog niet ingeleverd',
  rejected: 'In afwachting',
  in_review: 'Te beoordelen',
  finished: 'Beoordeeld',
};

/**
 * States that allow the participant to update the assignment, and to hand it in
 */
export const OpenAssignmentStates: (keyof AssignmentStateInterface)[] = [
  'open',
  'rejected',
];

export default AssignmentStates;
