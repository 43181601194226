export type InternshipTypeLabels = {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  11: string;
};

const InternshipTypes: InternshipTypeLabels = {
  1: 'Aanpalend specialisme',
  2: 'M&G stage',
  3: '(Keuze) stage',
  4: 'Opleidingsperiode 1',
  5: 'Opleidingsperiode 2',
  6: 'WO',
  7: 'Arbeid en gezondheid',
  8: 'Arbo/reïntegratie',
  9: 'Arbocuratief/klinisch',
  10: 'Oriënterend',
  11: 'Privaat-publiek',
};

export default InternshipTypes;
