import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import AppContext from '../../AppContext';
import MoodleLoader from '../../components/MoodleLoader';

const EducationMoodleLoader = () => {
  const { educationId, eventId } =
    useParams<{ educationId: string; eventId?: string }>();

  const { roleViewManager } = useContext(AppContext);

  return (
    <MoodleLoader
      endpoint={`/api/education/${educationId}/moodle-redirect`}
      params={{
        eventId,
        requestContext: roleViewManager.getCurrentView(),
      }}
    />
  );
};

export default EducationMoodleLoader;
