import { FormContextState } from './Form';
import { Form, FormEntry } from './types';
import { FormFieldInterface } from './FormField';
import { User } from '../../types';

/**
 * Get field from form by ID.
 * @param form
 * @param id
 */
export const getFieldById = (form: Form, id: string) =>
  form.fields.find((field) => field.id === id);

/**
 * Determines if the user can access the given form field.
 * @param field
 * @param user
 * @param isParticipantView
 */
export const canAccessFormField = (
  field: FormFieldInterface,
  user?: User,
  isParticipantView: boolean = false,
) => {
  if (field.roles === undefined) {
    return false;
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (!user || urlParams.has('token')) {
    return field.roles.includes('ROLE_EXTERNAL');
  }

  if (isParticipantView) {
    return field.roles.includes('ROLE_PARTICIPANT');
  }

  return field.roles.filter((role) => user.roles.includes(role)).length >= 1;
};

/**
 * Filter form data by fields the user has access to.
 */
export const filterFormData = (
  form: Form,
  data: FormContextState,
  user?: User,
  isParticipantView: boolean = false,
) =>
  Object.fromEntries(
    Object.entries(data).filter(([id]) => {
      const field = getFieldById(form, id);

      if (!field) {
        return false;
      }

      return canAccessFormField(field, user, isParticipantView);
    }),
  );

/**
 * Obtain the initial form data from the given form entry.
 *
 * @param entry
 * @param user
 * @param isParticipantView
 */
export const getInitialFormDataForEntry = (
  entry: FormEntry,
  user?: User,
  isParticipantView: boolean = false,
) => {
  const initialData: FormContextState = {};

  entry.fields.forEach((field) => {
    if (!canAccessFormField(field.field, user, isParticipantView)) {
      return;
    }

    initialData[field.field.id] = {
      valid: true,
      value: field.value,
      options: field.options
        .filter((option) => option.selected)
        .map((option) => option.option.id),
    };

    // indicates either a field of type `file-upload` or `product`
    if ('files' in field) {
      if ('assignments' in field) {
        // indicates a field of type `product`
        initialData[field.field.id].value = JSON.stringify({
          assignments: field.assignments.map((assignment) => assignment.id),
          files: field.files.map((file) => file.id),
        });
      } else {
        // indicates a field of type `file-upload`
        initialData[field.field.id].value = field.files
          .map((file) => file.id)
          .join(',');
      }
    }
  });

  return initialData;
};
