import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Link,
  Theme,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { ROUTE_NEWS_MANAGEMENT } from '../index';
import { colors } from '../../../config/theme';
import PageContainer from '../../../components/PageContainer';
import { getFileURL } from '../../../utils/common';
import Loader from '../../../components/Loader';
import NewsRepository from './NewsRepository';
import { NewsItem } from '../../../types';
import AppContext from '../../../AppContext';
import SafeHtml from '../../../components/SafeHtml';
import FileIcon from '../../../components/file/FileIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: colors.primary.ultraLight,
    borderRadius: 4,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: 120,
      paddingLeft: 120,
    },
  },
  containerHasImage: {
    marginTop: -100,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: colors.primary.ultraLight,
    borderRadius: 4,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: 120,
      paddingLeft: 120,
    },
  },
  dropdown: {
    marginRight: theme.spacing(2),
  },
  meta: {
    fontSize: 14,
  },
  visual: {
    height: 300,
    width: '100%',
    zIndex: 1,
    position: 'relative',
    objectFit: 'cover',

    [theme.breakpoints.up('md')]: {
      height: 500,
    },
  },
}));

const NewsDetailView = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);

  const [data, setData] = useState<NewsItem>();
  const [loading, setLoading] = useState<boolean>(false);

  const news = new NewsRepository();

  const fetch = async () => {
    setLoading(true);

    await news
      .find(id)
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van het nieuws',
          {
            variant: 'error',
          },
        );
      });

    setLoading(false);
  };

  const openEditPage = () =>
    history.push(`${ROUTE_NEWS_MANAGEMENT}/bewerken/${id}`);

  /**
   * News actions.
   */
  const newsActions = (
    <div>
      {roleViewManager.hasRole(['ROLE_ADMIN', 'ROLE_PROGRAM_ASSISTANT']) && (
        <>
          <Button
            color="secondary"
            startIcon={<FontAwesomeIcon icon={['fal', 'edit']} />}
            onClick={openEditPage}
            variant="contained"
          >
            Item bewerken
          </Button>
        </>
      )}
    </div>
  );

  useEffect(() => {
    fetch();
  }, []);

  const avatar = (
    <Avatar alt={data?.author?.firstName} src={data?.author?.avatar} />
  );

  const subheader = (
    <Typography className={classes.meta} color="textSecondary" gutterBottom>
      {`Laatst gewijzigd: ${moment(data?.updated).format('D MMMM')}`}
    </Typography>
  );

  if (loading) {
    return <Loader />;
  }

  const formattedPublishedStartDate = data?.publishedStart
    ? moment(data?.publishedStart).format('D MMMM')
    : 'onbekend';
  const formattedPublishedEndDate = data?.publishedEnd
    ? moment(data?.publishedEnd).format('D MMMM')
    : 'onbekend';

  return (
    <Container maxWidth="xl">
      {data?.image && (
        <img className={classes.visual} src={getFileURL(data?.image)} alt="" />
      )}
      <PageContainer
        containerClassName={`${
          data?.image ? classes.containerHasImage : classes.container
        }`}
        actions={newsActions}
        marginBottom
        title={data?.title}
      >
        <Card elevation={0}>
          <CardHeader
            avatar={avatar}
            title={data?.author?.fullName}
            subheader={subheader}
          />
          {data && (
            <CardContent>
              <Typography
                className={classes.meta}
                color="textSecondary"
                gutterBottom
              >
                {`Start: ${formattedPublishedStartDate} - Einde: ${formattedPublishedEndDate}`}
              </Typography>
              <SafeHtml html={data.content} />
            </CardContent>
          )}
        </Card>
        {data?.files.map((file) => (
          <Box mt={2}>
            <Link
              href={`${process.env.REACT_APP_API_URL}/api/news/file/${file.id}`}
              target="_blank"
            >
              <Card elevation={0}>
                <CardContent>
                  <FileIcon file={file} className="small" />
                  {file.name}
                </CardContent>
              </Card>
            </Link>
          </Box>
        ))}
      </PageContainer>
    </Container>
  );
};

export default NewsDetailView;
