import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import { NotificationPreferences } from '../../../types';

class NotificationPreferencesRepository {
  getPreferences(): Promise<AxiosResponse<NotificationPreferences>> {
    return ApiClient.get('/api/users/notification-preferences');
  }

  updatePreferences(
    preferences: NotificationPreferences,
  ): Promise<AxiosResponse> {
    return ApiClient.post('/api/users/notification-preferences', preferences);
  }
}

export default NotificationPreferencesRepository;
