import React, { useContext, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import { Grid } from '@material-ui/core';

import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';

const DateTime = (props: FieldProps) => {
  const { id, metadata, entryField, disabled } = props;

  const initialized = useRef<boolean>(false);

  const now = moment();
  const [state, setState] = useState<{
    startDate: Moment | null;
    startTime: Moment | null;
    endDate: Moment | null;
    endTime: Moment | null;
  }>({
    startDate: null,
    startTime: now,
    endDate: null,
    endTime: now,
  });
  const { setFormData } = useContext(FormContext);

  const { startDate, startTime, endDate, endTime } = state;
  const { range, time } = metadata;

  const handleChange = (name: string, date: MaterialUiPickersDate) => {
    setState({ ...state, [name]: date });
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) =>
    handleChange('startDate', date);
  const handleStartTimeChange = (date: MaterialUiPickersDate) =>
    handleChange('startTime', date);

  const handleEndDateChange = (date: MaterialUiPickersDate) =>
    handleChange('endDate', date);
  const handleEndTimeChange = (date: MaterialUiPickersDate) =>
    handleChange('endTime', date);

  useEffect(() => {
    const now = moment();

    if (!entryField) {
      return;
    }

    try {
      const parsedValue = JSON.parse(entryField.value);

      setState({
        startDate: parsedValue.startDate || null,
        startTime: parsedValue.startTime || now,
        endDate: parsedValue.endDate || null,
        endTime: parsedValue.endTime || now,
      });
    } catch (e) {
      //
    }
  }, [entryField]);

  useEffect(() => {
    if (
      !initialized.current ||
      (!state.startDate && !state.startTime && !state.endDate && !state.endTime)
    ) {
      initialized.current = true;
      return;
    }

    setFormData!((prev) => ({
      ...prev,
      [id]: {
        valid: true,
        value: JSON.stringify(state),
      },
    }));
  }, [id, setFormData, state]);

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              margin="normal"
              label="Datum"
              format="DD-MM-YYYY"
              orientation="landscape"
              autoOk
              views={['year', 'month', 'date']}
              onChange={handleStartDateChange}
              value={startDate}
              KeyboardButtonProps={{
                'aria-label': 'Verander datum',
              }}
              disabled={disabled}
            />
          </Grid>
          {time && (
            <Grid item>
              <KeyboardTimePicker
                margin="normal"
                label="Tijd"
                value={startTime}
                onChange={handleStartTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'Verander tijd',
                }}
                disabled={disabled}
              />
            </Grid>
          )}
          {range && (
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                margin="normal"
                label="Datum"
                format="DD-MM-YYYY"
                orientation="landscape"
                autoOk
                views={['year', 'month', 'date']}
                onChange={handleEndDateChange}
                value={endDate}
                KeyboardButtonProps={{
                  'aria-label': 'Verander datum',
                }}
                disabled={disabled}
              />
            </Grid>
          )}
          {range && time && (
            <Grid item>
              <KeyboardTimePicker
                name="endTime"
                margin="normal"
                label="Tijd"
                value={endTime}
                onChange={handleEndTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'Verander tijd',
                }}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateTime;
