import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Button, TextField, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import validator from 'validator';

import Home from '../../Home';
import { useStyles as loginStyles } from '../../Login';
import ApiClient from '../../api/ApiClient';
import PasswordInput from '../../components/password/PasswordInput';
import PasswordValidator from '../../components/password/PasswordValidator';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.text.primary,
  },
  passwordField: {
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    color: theme.palette.text.primary,
  },
}));

type State = {
  email: string;
  password: string;
  confirmPassword: string;
  errors: {
    email?: boolean | undefined;
    password?: boolean | undefined;
    confirmPassword?: boolean | undefined;
  };
  isValid: boolean;
};

const ForgotPassword = () => {
  const classes = useStyles();
  const formClasses = loginStyles();
  const location = useLocation();
  const history = useHistory();
  const notifications = useSnackbar();
  const token = new URLSearchParams(location.search).get('token');

  const [state, setState] = useState<State>({
    email: '',
    password: '',
    confirmPassword: '',
    errors: {},
    isValid: false,
  });
  const { email, password, confirmPassword, errors, isValid } = state;

  let form;
  if (token) {
    const submitForm = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      errors.password = validator.isEmpty(password);
      errors.confirmPassword = validator.isEmpty(confirmPassword);
      setState({ ...state, errors });

      if (errors.password || errors.confirmPassword) {
        return;
      }

      // Send link
      const data = new FormData();
      data.append('token', token);
      data.append('password', password);

      ApiClient.post('/api/change-password', data, {})
        .then(() => {
          history.push('/');
          notifications.enqueueSnackbar(
            'Je wachtwoord is veranderd. Je kunt nu inloggen.',
            { variant: 'success' },
          );
        })
        .catch((err) => {
          if (err.response.data.error === 'invalid_password') {
            state.errors.password = true;
            state.errors.confirmPassword = true;
            setState({ ...state });
          }
          notifications.enqueueSnackbar('Wachtwoord veranderen mislukt!', {
            variant: 'error',
          });
        });
    };

    const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
      state.errors.password = false;
      setState({ ...state, password: e.target.value });
    };

    const changeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
      state.errors.confirmPassword = e.target.value !== password;
      setState({ ...state, confirmPassword: e.target.value });
    };

    const handleValidation = (isValid: boolean) => {
      setState({ ...state, isValid });
    };

    form = (
      <form onSubmit={submitForm} className={classes.form}>
        <Typography variant="body1" className={classes.description}>
          Voer een nieuw wachtwoord in.
        </Typography>
        <PasswordInput
          id="password"
          onChange={changePassword}
          error={errors.password}
          className={classes.passwordField}
        />
        <PasswordInput
          id="confirm-password"
          onChange={changeConfirmPassword}
          error={errors.confirmPassword}
          className={classes.field}
          label="Bevestig wachtwoord"
        />
        <PasswordValidator password={password} onValidate={handleValidation} />
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          data-testid="submit"
          disabled={!isValid}
        >
          Versturen
        </Button>
      </form>
    );
  } else {
    const handleFailure = () => {
      errors.email = true;
      setState({ ...state, errors });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      errors.email = !validator.isEmail(email);
      setState({ ...state, errors });

      if (!validator.isEmail(email || '')) {
        return;
      }

      // Send link
      const data = new FormData();
      data.append('email', email);

      ApiClient.post('/api/forgot-password', data, {})
        .then(() => {
          history.push('/');
          notifications.enqueueSnackbar(
            'Instructies voor het resetten van je wachtwoord zijn verstuurd!',
            { variant: 'success' },
          );
        })
        .catch(handleFailure);
    };

    const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, email: e.target.value });
    };

    form = (
      <form onSubmit={handleSubmit}>
        <Typography variant="body1" className={classes.description}>
          <p>Voer het e-mailadres van je account in.</p>
          <p>
            Instructies voor het resetten van je wachtwoord worden naar dit
            adres gestuurd.
          </p>
        </Typography>
        <TextField
          type="email"
          data-testid="email"
          value={email || ''}
          onChange={changeEmail}
          error={errors.email}
          className={classes.field}
          label="E-mail"
          fullWidth
        />
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          data-testid="submit"
        >
          Versturen
        </Button>
      </form>
    );
  }

  return (
    <Home>
      <Box className={formClasses.form}>
        <Typography variant="h6" className={formClasses.formTitle}>
          Wachtwoord vergeten
        </Typography>
        {form}
      </Box>
    </Home>
  );
};

export default ForgotPassword;
