import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { uniqueId } from 'lodash';
import FileUploadComponent from '../../../components/file/FileUpload';
import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';
import { FileUploadFormEntryField } from '../types';
import { File as FileInterface } from '../../../types';
import FileChips from '../../../components/file/FileChips';
import FormEntryContext from '../../education/assignment/FormEntryContext';

const FileUpload = (props: FieldProps) => {
  const { id, metadata, disabled } = props;
  const entryField = props.entryField as FileUploadFormEntryField | undefined;
  const { formEntry } = useContext(FormEntryContext);

  const [lock, setLock] = useState<boolean>(false);
  const [entryFiles, setEntryFiles] = useState<FileInterface[]>(
    entryField ? entryField.files : [],
  );
  const [initialEntryFiles, setInitialEntryFiles] = useState<FileInterface[]>(
    entryField ? entryField.files : [],
  );
  const { files, setFiles, formData, setFormData } = useContext(FormContext);
  const [dirty, setDirty] = useState<boolean>(false);
  const [uploadId, setUploadId] = useState<string>(uniqueId());

  const updateData = useCallback(() => {
    if (setFormData) {
      setFormData({
        ...formData,
        [id]: {
          valid: true,
          value: entryFiles.map((file) => file.id).join(','),
        },
      });
    }

    setLock(false);
  }, [entryFiles, lock, formData]);

  useEffect(() => {
    if (!formEntry) {
      return;
    }

    const field = formEntry.fields.find((f) => f.field.id === id);

    if (!field) {
      return;
    }

    setLock(true);
    setEntryFiles((field as FileUploadFormEntryField).files);

    // Generate a new upload id to reset the file upload component.
    setUploadId(uniqueId());

    setTimeout(() => {
      setLock(false);
    }, 0);
  }, [id, formEntry]);

  useEffect(() => {
    if (lock) {
      return;
    }

    setEntryFiles(entryField ? entryField.files : []);
    setInitialEntryFiles(entryField ? entryField.files : []);
  }, [lock, entryField]);

  useEffect(() => {
    if (lock) {
      return;
    }

    let isDirty = dirty;

    if (JSON.stringify(entryFiles) !== JSON.stringify(initialEntryFiles)) {
      isDirty = true;
      setDirty(true);
    }

    if (isDirty) {
      setTimeout(updateData, 0);
    }
  }, [entryFiles]);

  const handleChange = (selectedFiles: File[]) => {
    if (setFormData && setFiles) {
      files[id] = selectedFiles.map((file) => ({
        uploaded:
          files[id] &&
          files[id].find((f) => f.file === file)?.uploaded === true,
        file,
      }));
      setFiles({ ...files });
      updateData();
    }
  };

  const handleDelete = (file: FileInterface) => {
    const newEntryFiles = [...entryFiles];

    const index = newEntryFiles.findIndex((f) => f.id === file.id);
    newEntryFiles.splice(index, 1);
    setEntryFiles(newEntryFiles);
  };

  const handleFileChange = (file: FileInterface) => {
    const index = entryFiles.findIndex((f) => f.id === file.id);
    entryFiles[index] = file;
    setEntryFiles([...entryFiles]);
  };

  return (
    <div>
      {entryFiles.length > 0 && (
        <Box mt={2} mb={2}>
          <FileChips
            files={entryFiles}
            onDelete={handleDelete}
            onFileChange={handleFileChange}
          />
        </Box>
      )}

      <FileUploadComponent
        onChange={handleChange}
        documents={
          metadata.documents !== undefined ? metadata.documents : false
        }
        video={metadata.video !== undefined ? metadata.video : false}
        audio={metadata.audio !== undefined ? metadata.audio : false}
        images={metadata.images !== undefined ? metadata.images : false}
        disabled={disabled}
        uploadId={uploadId}
      />
    </div>
  );
};

export default FileUpload;
