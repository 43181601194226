import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AxiosResponse } from 'axios';

import { CompleteUser, TwoFactorMethod } from '../types';
import { EducationState } from '../reducers/education';
import ApiClient from '../api/ApiClient';

export const loginUser = () => ({
  type: 'LOGIN_USER',
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

export const setAccount = (user: CompleteUser) => ({
  type: 'SET_ACCOUNT',
  payload: user,
});

export const requestTwoFactorCode = (
  email: string,
  method: TwoFactorMethod,
) => ({
  type: 'REQUEST_TWO_FACTOR_CODE',
  payload: { method, email },
});

export const resetTwoFactorCodeRequest = () => ({
  type: 'RESET_TWO_FACTOR_CODE_REQUEST',
});

export const requestTwoFactorConfiguration = () => ({
  type: 'REQUEST_TWO_FACTOR_CONFIGURATION',
});

export const fulfillTwoFactorConfigurationRequest = () => ({
  type: 'FULFILL_TWO_FACTOR_CONFIGURATION_REQUEST',
});

export const refreshAccount =
  (callback?: () => void): ThunkAction<void, any, any, any> =>
  async (dispatch) => {
    const account: AxiosResponse<CompleteUser> = await ApiClient.get(
      '/api/users/account',
    );
    dispatch(setAccount(account.data));

    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
  };

export const setImitating = (status: boolean) => ({
  type: 'SET_IMITATING',
  payload: status,
});

export const updateAvatar = (url: string | null) => ({
  type: 'UPDATE_AVATAR',
  payload: url,
});

export const updateEducation =
  (
    education: EducationState,
    localStore: LocalForage,
  ): ThunkAction<void, EducationState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: 'SET_EDUCATION',
      payload: education,
    });

    if (education) {
      localStore.setItem('education', education.id);
    } else {
      localStore.removeItem('education');
    }
  };
