import React from 'react';
import { Box, Chip, Grid, Theme, Tooltip } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { FormFieldInterface } from '../FormField';
import Roles, { educatorRoles } from '../../users/Roles';
import { colors } from '../../../config/theme';
import ConditionalWrapper from '../../../components/ConditionalWrapper';

interface FormFieldRolesProps {
  field: FormFieldInterface;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-flex',
    width: 'auto',
  },
  gridItem: {
    display: 'inline-block',
    height: 32,
    lineHeight: '32px',
  },
  chip: {
    fontSize: 13,
    color: theme.palette.primary.main,
    background: colors.primary.ultraLight500,
    borderRadius: 20,
  },
  chipSecondary: {
    fontSize: 13,
    color: theme.palette.secondary.dark,
    background: colors.secondary.ultraLight500,
    borderRadius: 20,
  },
}));

const FormFieldRoles = ({ field }: FormFieldRolesProps) => {
  const classes = useStyles();

  if (!field.roles) {
    return null;
  }

  return (
    <Box mt={1} mb={1}>
      <Grid container spacing={1} className={classes.container}>
        {field.roles
          .filter((role) => role !== 'ROLE_ADMIN')
          .sort((a, b) => {
            const educatorRoleA = educatorRoles.includes(a);
            const educatorRoleB = educatorRoles.includes(b);

            if (educatorRoleA && !educatorRoleB) return 1;
            if (!educatorRoleA && educatorRoleB) return -1;
            return b.localeCompare(a);
          })
          .map((role) => (
            <Grid item className={classes.gridItem} key={role}>
              <ConditionalWrapper
                condition={educatorRoles.includes(role)}
                wrapper={(children) => (
                  <Tooltip title="Beoordelaar">{children}</Tooltip>
                )}
              >
                <Chip
                  label={Roles[role]}
                  className={`${classes.chip} ${
                    educatorRoles.includes(role) ? classes.chipSecondary : ''
                  }`}
                />
              </ConditionalWrapper>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default FormFieldRoles;
