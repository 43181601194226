import React from 'react';

import { Route, Router } from '../../routing';
import EvaluationOverview from './EvaluationOverview';
import EvaluationView from './EvaluationView';

const evaluations = new Route(
  'Beoordelingen',
  '/beoordelingen',
  <EvaluationOverview />,
)
  .requireLogin()
  .role([
    'ROLE_TEACHER',
    'ROLE_PRACTICAL_TRAINER',
    'ROLE_PROGRAM_ASSISTANT',
    'ROLE_INSTITUTE_EDUCATOR',
    'ROLE_ADMIN',
  ]);
Router.addRoute(evaluations);

const evaluationView = new Route(
  'Beoordeling',
  '/:id',
  <EvaluationView />,
).requireLogin();
evaluations.addChild(evaluationView);
