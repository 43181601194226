import { AxiosResponse, CancelTokenSource } from 'axios';

import {
  ApiFilterCriteria,
  CollectionResponse,
  NewsItem,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class NewsRepository implements Repository<NewsItem> {
  find(id: string): Promise<AxiosResponse<NewsItem>> {
    return ApiClient.get(`/api/news/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
    perPage?: number,
  ): Promise<CollectionResponse<NewsItem>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });
    return ApiClient.get('/api/news', params);
  }

  getFilters(): Promise<AxiosResponse> {
    return ApiClient.get('/api/news/filters');
  }

  getUnreadNews(): Promise<AxiosResponse> {
    return ApiClient.get('/api/news?count=true');
  }

  filterEvents(searchTerm?: string): Promise<AxiosResponse> {
    return ApiClient.get(`/api/news/filters?query=${searchTerm}`);
  }

  getEvents(ids?: any): Promise<AxiosResponse> {
    return ApiClient.get(`/api/news/filters?events=${ids}`);
  }

  update(id: string, data: any): Promise<AxiosResponse> {
    return ApiClient.post(`/api/news/${id}`, data);
  }

  uploadImage(id: string, image: any): Promise<AxiosResponse> {
    return ApiClient.post(`/api/news/${id}/image`, image);
  }

  uploadFiles(id: string, files: FormData): Promise<AxiosResponse> {
    return ApiClient.post(`/api/news/${id}/upload`, files);
  }

  removeFile(
    newsId: string | undefined,
    fileId: string,
  ): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/news/${newsId}/upload/${fileId}`);
  }

  imageSearch(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<AxiosResponse> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });
    return ApiClient.get(`/api/image-search/images`, params);
  }

  create(data: any): Promise<AxiosResponse> {
    return ApiClient.post('/api/news', data);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/news/${id}`);
  }

  deleteImage(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/news/${id}/image`);
  }
}

export default NewsRepository;
