import React, { useContext, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { IconButton, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FieldProps } from './FieldProps';
import theme from '../../../config/theme';
import FormContext from '../FormContext';

const width = 375;
const height = 180;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width,
    height,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  eraser: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Signature = (props: FieldProps) => {
  const { id, entryField, disabled } = props;
  const classes = useStyles();
  const canvas = useRef<SignatureCanvas | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { formData, setFormData } = useContext(FormContext);

  const handleEnd = () => {
    if (!canvas.current) {
      return;
    }

    setFormData!({
      ...formData,
      [id]: {
        valid: true,
        value: canvas.current?.toDataURL('image/png'),
      },
    });
  };

  const handleClear = () => {
    if (canvas.current) {
      canvas.current.clear();
    }
  };

  useEffect(() => {
    if (canvas.current && disabled) {
      canvas.current.off();
    }
  }, [disabled]);

  useEffect(() => {
    if (!loaded && entryField && canvas.current !== null) {
      canvas.current.fromDataURL(entryField.value, { width, height });
    }

    setLoaded(true);
  }, [canvas]);

  return (
    <div className={classes.root}>
      {!disabled && (
        <Tooltip title="Leeg maken">
          <IconButton
            size="small"
            className={`signature-clear-button ${classes.eraser}`}
            onClick={handleClear}
          >
            <FontAwesomeIcon icon={['fad', 'eraser']} />
          </IconButton>
        </Tooltip>
      )}
      <SignatureCanvas
        penColor={theme.palette.primary.main}
        canvasProps={{ width, height }}
        onEnd={handleEnd}
        ref={canvas}
      />
    </div>
  );
};

export default Signature;
