import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';
import AppContext from '../../../AppContext';
import { Module, RegisterEvent } from '../../../types';
import DialogCloseButton from '../../../components/DialogCloseButton';
import Event from './Event';
import EventRegisterRepository from './EventRegisterRepository';
import { EventWorkshopSelection } from './EventRegisterWizard';
import Loader from '../../../components/Loader';
import ModuleRepository from './ModuleRepository';
import { refreshAccount } from '../../../actions';
import EventSelectionFeedback from './EventSelectionFeedback';

const useStyles = makeStyles(() => ({
  successIcon: {
    color: green[300],
  },
  error: {
    color: red[300],
  },
}));

interface WorkshopsSelectionWizardProps {
  module: Module;
  eventId: number;
  open: boolean;
  onClose: (event?: object, reason?: string) => void;
}

const WorkshopsSelectionWizard = (props: WorkshopsSelectionWizardProps) => {
  const { module, eventId, open, onClose } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { appState, setAppState } = useContext(AppContext);

  const [event, setEvent] = useState<RegisterEvent | null>(null);
  const [workshopSelections, setWorkshopSelections] =
    useState<EventWorkshopSelection>({
      selectedIds: [] as number[],
      unselectedIds: [] as number[],
    });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<'availability' | 'unknown' | null>(null);

  const loadEvent = useCallback(() => {
    new EventRegisterRepository()
      .findEventOfModule(module.id, eventId)
      .then((response) => {
        setEvent(response.data);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [module.id, eventId, appState, setAppState]);

  const handleWorkshopsUpdateEvent = (
    workshopSelection: EventWorkshopSelection,
  ) => {
    setWorkshopSelections(workshopSelection);
  };

  const handleSave = (push: (id?: string) => void) => {
    // merge selected and unselected workshop ids into one object, indicating selection using a boolean value
    const workshopIds = {
      ...Object.fromEntries(
        workshopSelections.selectedIds.map((id) => [id, true]),
      ),
      ...Object.fromEntries(
        workshopSelections.unselectedIds.map((id) => [id, false]),
      ),
    };

    setError(null);
    push('validate');

    new ModuleRepository()
      .updateWorkshopSelection(module.id, eventId, workshopIds)
      .then(() => {
        dispatch(
          refreshAccount(() => {
            push('success');
          }),
        );
      })
      .catch((err) => {
        if (err.response.data.error === 'workshops_unavailable') {
          setError('availability');
        } else {
          setError('unknown');
        }

        push('error');
      });
  };

  // Fetch events on open.
  useEffect(() => {
    if (!open) {
      return;
    }

    loadEvent();
  }, [open, loadEvent]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Box mr={5}>{`Workshops kiezen voor "${module.publishedName}"`}</Box>
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        {(loading || !event) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Loader inline />
          </Box>
        )}
        {!loading && event && (
          <Wizard
            render={({ step }) => (
              <div>
                <TransitionGroup>
                  <CSSTransition
                    key={step.id}
                    classNames="wizard"
                    timeout={{ enter: 500, exit: 500 }}
                  >
                    <div>
                      <Steps key={step.id} step={step}>
                        <Step
                          id="select-event"
                          render={({ push }) => (
                            <>
                              <Event
                                key={event.eventId}
                                event={event}
                                selectable={false}
                                readOnly={false}
                                expanded
                                onSelect={() => {}}
                                onWorkshopsUpdate={handleWorkshopsUpdateEvent}
                              />
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => handleSave(push)}
                                >
                                  Opslaan
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                        <Step
                          id="validate"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" justifyContent="center">
                                <Loader inline />
                              </Box>
                              <Typography variant="body1" align="center">
                                De beschikbaarheid van de gekozen workshops
                                wordt gecontroleerd.
                                <br />
                                Een moment geduld alstublieft.
                              </Typography>
                            </Box>
                          )}
                        />
                        <Step
                          id="success"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Box mr={1}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'check-circle']}
                                    className={classes.successIcon}
                                  />
                                </Box>
                                <div>
                                  De gekozen workshops zijn succesvol
                                  opgeslagen.
                                </div>
                              </Box>
                              <EventSelectionFeedback event={event} />
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  color="default"
                                  variant="outlined"
                                  onClick={onClose}
                                >
                                  Sluiten
                                </Button>
                              </Box>
                            </Box>
                          )}
                        />
                        <Step
                          id="error"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Box mr={1}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'exclamation-circle']}
                                    className={classes.error}
                                  />
                                </Box>
                                <div>
                                  Er is een fout opgetreden bij het opslaan van
                                  workshops:
                                </div>
                              </Box>
                              {error === 'unknown' && (
                                <Box mt={2} mb={2}>
                                  De actie is mislukt. Neem contact op met de
                                  programma assistent van de opleiding.
                                </Box>
                              )}
                              {error === 'availability' && (
                                <Box mt={2} mb={2}>
                                  Er zijn geen plekken beschikbaar. Probeer het
                                  later nog eens of neem contact op met de
                                  programma assistent van de opleiding.
                                </Box>
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  color="default"
                                  variant="outlined"
                                  onClick={onClose}
                                >
                                  Sluiten
                                </Button>
                              </Box>
                            </Box>
                          )}
                        />
                      </Steps>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WorkshopsSelectionWizard;
