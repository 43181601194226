import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Box, IconButton, Link } from '@material-ui/core';
import { Address } from '../types';

const TRAVEL_METHODS: {
  label: string;
  icon: IconName;
  dirflg: 'w' | 'b' | 'd' | 'r';
}[] = [
  {
    label: 'Auto',
    icon: 'car',
    dirflg: 'd',
  },
  {
    label: 'Trein',
    icon: 'train',
    dirflg: 'r',
  },
  {
    label: 'Wandelen',
    icon: 'walking',
    dirflg: 'w',
  },
  {
    label: 'Fiets',
    icon: 'bicycle',
    dirflg: 'b',
  },
];

interface GoogleMapsDirectionsProps {
  address: Address;
}

const GoogleMapsDirections = (props: GoogleMapsDirectionsProps) => {
  const { street, houseNumber, postalCode, city } = props.address;

  const addressUri = encodeURIComponent(
    `${street} ${houseNumber} ${postalCode} ${city}`,
  );
  const url = `https://maps.google.com/?saddr=Current+Location&daddr=${addressUri}`;

  return (
    <Box display="flex">
      {TRAVEL_METHODS.map((method) => (
        <IconButton
          component={Link}
          href={`${url}&dirflg=${method.dirflg}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={['fal', method.icon]} />
        </IconButton>
      ))}
    </Box>
  );
};

export default GoogleMapsDirections;
