import React from 'react';

import { FormEntryField } from '../types';
import LikertComponent from '../Likert';

const LikertEntryDisplay = (props: FormEntryField) => {
  const { options } = props;
  const { metadata } = props.field;
  const { rows, columns, sections, multiple } = metadata;

  return (
    <div>
      <LikertComponent
        rows={rows}
        columns={columns}
        sections={sections}
        initialSelected={options}
        multiple={multiple}
        disabled
      />
    </div>
  );
};

export default LikertEntryDisplay;
