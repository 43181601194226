import { AxiosResponse } from 'axios';
import ApiClient from '../../../../api/ApiClient';
import { DialogMessage } from '../AssignmentDialogMessage';

class AssignmentMessageRepository {
  create(
    assignmentId: string,
    content: string,
  ): Promise<AxiosResponse<DialogMessage>> {
    return ApiClient.post(`/api/assignment/${assignmentId}/messages`, {
      content,
    });
  }

  update(
    assignmentId: string,
    messageId: string,
    content: string,
  ): Promise<AxiosResponse<DialogMessage>> {
    return ApiClient.put(
      `/api/assignment/${assignmentId}/messages/${messageId}`,
      { content },
    );
  }

  delete(
    assignmentId: string,
    messageId: string,
  ): Promise<AxiosResponse<DialogMessage>> {
    return ApiClient.delete(
      `/api/assignment/${assignmentId}/messages/${messageId}`,
    );
  }
}

export default AssignmentMessageRepository;
