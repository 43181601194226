import { createContext, Dispatch, SetStateAction } from 'react';
import localforage from 'localforage';

import { AppState } from './App';
import RoleViewManager from './RoleViewManager';
import NullUser from './modules/users/NullUser';

const AppContext = createContext<{
  appState: AppState | null;
  setAppState: Dispatch<SetStateAction<AppState>> | null;
  roleViewManager: RoleViewManager;
  localStore: LocalForage;
}>({
  appState: null,
  setAppState: null,
  roleViewManager: new RoleViewManager(NullUser, 'participant'),
  localStore: localforage.createInstance({ name: 'mijn-nspoh' }),
});

export default AppContext;
