import { createContext, Dispatch, SetStateAction } from 'react';
import { ProductContextState } from './Products';

const ProductContext = createContext<{
  productContext: ProductContextState;
  setProductContext: Dispatch<SetStateAction<ProductContextState>> | null;
}>({
  productContext: {
    selectedFiles: [],
    selectedAssignments: [],
  },
  setProductContext: null,
});

export default ProductContext;
