import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import { Certificate, User } from '../../../types';

class CertificateRepository {
  findAll(user: User): Promise<AxiosResponse<Certificate[]>> {
    return ApiClient.get(`/api/users/${user.id}/certificates`);
  }

  static getCertificateDownloadUrl(user: User, certificate: Certificate) {
    return `${process.env.REACT_APP_API_URL}/api/users/${user.id}/certificates/${certificate.eventId}`;
  }
}

export default CertificateRepository;
