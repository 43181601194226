import { AxiosResponse } from 'axios';

import { Module } from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { FieldState } from '../../yard-forms/fields/FieldProps';
import { Form } from '../../yard-forms/types';

class ModuleRepository {
  findByModuleId(id: string): Promise<AxiosResponse<Module>> {
    return ApiClient.get(`/api/module/${id}`);
  }

  getForm(module: Module): Promise<AxiosResponse<Form>> {
    return ApiClient.get(`/api/module/${module.id}/form`);
  }

  createAssignment(module: Module): Promise<AxiosResponse<Module>> {
    return ApiClient.post(`/api/module/${module.id}/assignments`);
  }

  createEPAAssignment(
    module: Module,
    level: number,
    submit: boolean,
    formData: { [key: string]: FieldState },
    assignedUsers: string[],
    messages: string[],
  ) {
    return ApiClient.post(`/api/module/${module.id}/assignments`, {
      formData,
      submit,
      level,
      assignedUsers,
      messages,
    });
  }

  createRequestAssignment(
    module: Module,
    submit: boolean,
    formData: { [key: string]: FieldState },
  ) {
    return ApiClient.post(`/api/module/${module.id}/assignments`, {
      formData,
      submit,
    });
  }

  registerForEvent(
    moduleId: string,
    eventId: number,
    workshopIds: { [key: number]: boolean },
  ): Promise<AxiosResponse> {
    return ApiClient.post(`/api/module/${moduleId}/event`, {
      eventId,
      workshopIds,
    });
  }

  updateWorkshopSelection(
    moduleId: string,
    eventId: number,
    workshopIds: { [key: number]: boolean },
  ): Promise<AxiosResponse> {
    return ApiClient.put(`/api/module/${moduleId}/event/${eventId}/workshops`, {
      workshopIds,
    });
  }
}

export default ModuleRepository;
