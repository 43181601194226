import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';

import { EditorProps } from './EditorProps';

const NumberEditor = (props: EditorProps) => {
  const { field, setPreview } = props;

  useEffect(() => {
    setPreview(
      <div>
        <TextField
          type="number"
          required={field.required}
          label={field.label}
          disabled
        />
      </div>,
    );
  }, [field]);

  return <div />;
};

export default NumberEditor;
