import React, { useEffect, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  iconMargin: {
    marginLeft: theme.spacing(2),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface Label {
  id: string;
  label: string;
  order: number;
  option?: number;
}

export interface LabelListOption {
  key: string | number;
  value: string;
}

interface LabelListProps {
  labels?: Label[];
  options?: LabelListOption[];
  optionsPlaceholder?: string;
  onChange: (labels: Label[]) => void;
}

const LabelList = (props: LabelListProps) => {
  const initialState =
    props.labels && props.labels.length > 0
      ? props.labels
      : [{ id: _uniqueId('label-list-item'), label: '', order: 0 }];
  const options = props.options || [];

  const uniqueId = _uniqueId('label-list-');
  const classes = useStyles();
  const [labels, setLabels] = useState<Label[]>(initialState);
  const [focus, setFocus] = useState<number | null>(null);

  useEffect(() => {
    props.onChange(labels.filter((label) => label.label.length > 0));

    if (focus) {
      const field = document.querySelector<HTMLInputElement>(
        `#${uniqueId}-${focus}`,
      );
      if (field) {
        field.focus();
        setFocus(null);
      }
    }
  }, [labels]);

  const handleChangeLabel = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    labels[parseInt(event.target.name!, 10)].label = event.target
      .value as string;
    setLabels([...labels]);
  };

  const handleChangeLabelOption = (
    event: React.ChangeEvent<{ value: unknown }>,
    label: number,
  ) => {
    const option = options.find((option) => option.key === event.target.value);

    if (option) {
      labels[label].option = option.key as number;
    }
    setLabels([...labels]);
  };

  const addLabelAfterIndex = (index: number) => {
    let newLabels = labels.slice(0, index + 1);
    newLabels.push({
      id: _uniqueId('label-list-item'),
      label: '',
      order: index + 1,
    });
    newLabels = newLabels.concat(labels.slice(index + 1, labels.length + 1));

    setLabels(newLabels);
  };

  const deleteLabel = (index: number) => {
    if (labels.length === 1) {
      return;
    }

    labels.splice(index, 1);
    setLabels([...labels]);
  };

  const handleKeyPressEvent = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter' && labels[index].label.length > 0) {
      addLabelAfterIndex(index);
      setFocus(index + 1);
    }

    if (event.key === 'Backspace' && labels[index].label.length === 0) {
      deleteLabel(index);
      setFocus(index - 1);
    }
  };

  return (
    <Grid container>
      {labels.map((label, index) => {
        const onOptionSelect = (e: React.ChangeEvent<{ value: unknown }>) =>
          handleChangeLabelOption(e, index);

        return (
          <Grid item xs={12} className={classes.label} key={label.id}>
            <TextField
              value={label.label}
              id={`${uniqueId}-${index}`}
              name={`${index}`}
              onChange={handleChangeLabel}
              onKeyDown={(event) => handleKeyPressEvent(event, index)}
            />
            {options && options.length > 0 && (
              <Select
                value={label.option || ''}
                onChange={onOptionSelect}
                autoWidth
                displayEmpty
              >
                <MenuItem value="">
                  <em>
                    {props.optionsPlaceholder || 'Selecteer een optie...'}
                  </em>
                </MenuItem>
                {options.map((option) => (
                  <MenuItem value={option.key}>{option.value}</MenuItem>
                ))}
              </Select>
            )}
            <IconButton
              size="small"
              aria-label="add"
              className={classes.iconMargin}
              onClick={() => addLabelAfterIndex(index)}
            >
              <FontAwesomeIcon icon={['fal', 'plus']} size="sm" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              className={classes.iconMargin}
              onClick={() => deleteLabel(index)}
              disabled={index === 0 && labels.length === 1}
            >
              <FontAwesomeIcon icon={['fal', 'minus']} size="sm" />
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LabelList;
