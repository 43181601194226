import { IconName } from '@fortawesome/fontawesome-svg-core';

import { EditorProps } from './editor/EditorProps';
import { FormEntryField } from './types';
import { FieldProps } from './fields/FieldProps';
import ProductEntryDisplay from './entry-display/ProductEntryDisplay';
// Fields
import FileUpload from './fields/FileUpload';
import Likert from './fields/Likert';
import NumberField from './fields/NumberField';
import Product from './fields/Product';
import Text from './fields/Text';
import Textarea from './fields/Textarea';
// Editors
import ChoiceListEditor from './editor/ChoiceListEditor';
import LikertEditor from './editor/LikertEditor';
import NumberEditor from './editor/NumberEditor';
import TextareaEditor from './editor/TextareaEditor';
import TextEditor from './editor/TextEditor';
import LikertEntryDisplay from './entry-display/LikertEntryDisplay';
import Section from './fields/Section';
import SectionEditor from './editor/SectionEditor';
import DateTime from './fields/DateTime';
import DateTimeEditor from './editor/DateTimeEditor';
import DateTimeEntryDisplay from './entry-display/DateTimeEntryDisplay';
import TextareaDisplay from './entry-display/TextareaDisplay';
import FileUploadEditor from './editor/FileUploadEditor';
import FileUploadEntryDisplay from './entry-display/FileUploadEntryDisplay';
import ChoiceList from './fields/ChoiceList';
import ChoiceListEntryDisplay from './entry-display/ChoiceListEntryDisplay';
import Signature from './fields/Signature';
import SignatureEntryDisplay from './entry-display/SignatureEntryDisplay';
import RequestPoints from './fields/RequestPoints';
import Internship from './fields/Internship';
import InternshipEntryDisplay from './entry-display/InternshipEntryDisplay';
import InternshipEditor from './editor/InternshipEditor';

const fields: {
  label: string;
  type: string;
  icon: IconName;
  editor?: (props: EditorProps) => JSX.Element;
  field?: (props: FieldProps) => JSX.Element;
  entryDisplay?: (props: FormEntryField) => JSX.Element;
  hasLabel?: boolean;
  hasDescription?: boolean;
}[] = [
  {
    label: 'Sectie',
    type: 'section',
    icon: 'align-left',
    field: Section,
    editor: SectionEditor,
    hasLabel: false,
    hasDescription: false,
  },
  {
    label: 'Likert',
    type: 'likert',
    icon: 'poll-h',
    editor: LikertEditor,
    field: Likert,
    entryDisplay: LikertEntryDisplay,
  },
  {
    label: 'Bestand(en)',
    type: 'file-upload',
    icon: 'file',
    editor: FileUploadEditor,
    field: FileUpload,
    entryDisplay: FileUploadEntryDisplay,
  },
  {
    label: 'Keuzelijst',
    type: 'choicelist',
    icon: 'ballot-check',
    editor: ChoiceListEditor,
    field: ChoiceList,
    entryDisplay: ChoiceListEntryDisplay,
  },
  {
    label: 'Tekstvak',
    type: 'text',
    icon: 'text',
    editor: TextEditor,
    field: Text,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Getal',
    type: 'number',
    icon: 'abacus',
    editor: NumberEditor,
    field: NumberField,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Teksteditor',
    type: 'textarea',
    icon: 'file-alt',
    editor: TextareaEditor,
    field: Textarea,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Product',
    type: 'product',
    icon: 'clipboard-list-check',
    field: Product,
    entryDisplay: ProductEntryDisplay,
  },
  {
    label: 'Datum / Tijd',
    type: 'date',
    icon: 'calendar-alt',
    field: DateTime,
    editor: DateTimeEditor,
    entryDisplay: DateTimeEntryDisplay,
  },
  {
    label: 'Handtekening',
    type: 'signature',
    icon: 'signature',
    field: Signature,
    entryDisplay: SignatureEntryDisplay,
  },
  {
    label: 'Titel',
    type: 'title',
    icon: 'heading',
    editor: TextEditor,
    field: Text,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Punten (Aanvragen)',
    type: 'request-points',
    icon: 'money-check-edit',
    editor: NumberEditor,
    field: RequestPoints,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Bedrag (Aanvragen)',
    type: 'request-amount',
    icon: 'money-bill-wave',
    editor: NumberEditor,
    field: NumberField,
    entryDisplay: TextareaDisplay,
  },
  {
    label: 'Stagetype',
    type: 'internship-type',
    icon: 'graduation-cap',
    field: Internship,
    editor: InternshipEditor,
    entryDisplay: InternshipEntryDisplay,
  },
];

export default fields;
