import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScheduleItem } from '../../types';

import { colors } from '../../config/theme';
import ScheduleRow from './ScheduleRow';
import AppContext from '../../AppContext';
import ModuleSearchDropdown, {
  EventOption,
} from './components/ModuleSearchDropdown';
import AgendaContext from './AgendaContext';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  table: {
    '& th': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),
      lineHeight: 0,
      border: 'none',
    },
  },
  csvExportButton: {
    color: theme.palette.primary.contrastText,
  },
  toggle: {
    color: theme.palette.primary.contrastText,
  },
}));

type Schedule = {
  events: ScheduleItem[];
};

const Schedule = ({ events = [] }: Schedule) => {
  const classes = useStyles();
  let prevDate: string | Date = '';
  const { roleViewManager, localStore } = useContext(AppContext);
  const { agendaState, setAgendaState } = useContext(AgendaContext);
  const [filteredModules, setFilteredModules] = useState<number[]>([]);
  const { includePastItems } = agendaState;

  const csvExportUrl = useMemo(() => {
    const userId = roleViewManager.getUser().id;
    const scheduleType = roleViewManager.isEducatorView()
      ? 'educator-schedule'
      : 'schedule';

    return `${process.env.REACT_APP_API_URL}/api/users/${userId}/${scheduleType}/csv-export`;
  }, [roleViewManager]);

  const handleModuleOptionsChange = (events: EventOption[]) => {
    setFilteredModules(events.map((e) => e.id));
  };

  /**
   * Handles the switch to include past items.
   */
  const handleIncludePastItemsSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Update state.
      setAgendaState({
        ...agendaState,
        includePastItems: event.target.checked,
      });

      // Store choice in local storage.
      localStore.setItem<boolean>(
        'agenda_include_past_items',
        event.target.checked,
      );
    },
    [localStore, setAgendaState, agendaState],
  );

  const pastItemsControl = (
    <Switch
      checked={includePastItems}
      onChange={handleIncludePastItemsSwitch}
      name="includePastItems"
      color="secondary"
    />
  );

  return (
    <Box>
      <Box
        bgcolor="primary.main"
        p={2}
        pl={3}
        display="flex"
        justifyContent="space-between"
      >
        <Typography className={classes.title}>Rooster</Typography>
        <Box display="flex" alignItems="center">
          <Box ml={2}>
            <FormControlLabel
              control={pastItemsControl}
              className={classes.toggle}
              label="Items afgelopen maand tonen"
            />
          </Box>
          <Tooltip title="Exporteren naar CSV">
            <IconButton href={csvExportUrl} className={classes.csvExportButton}>
              <FontAwesomeIcon icon={['fal', 'file-csv']} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box bgcolor={colors.primary.ultraLight} p={3}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Tijd</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <span>Module</span>
                    <Box ml={1}>
                      <ModuleSearchDropdown
                        events={events}
                        onChange={handleModuleOptionsChange}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>Opdracht/bijeenkomst</TableCell>
                <TableCell>Locatie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events
                .filter(
                  (item: ScheduleItem) =>
                    filteredModules.length === 0 ||
                    filteredModules.includes(item.eventId),
                )
                .map((item: ScheduleItem) => {
                  const sameDate = prevDate === item.date;
                  prevDate = item.date;

                  return <ScheduleRow item={item} sameDate={sameDate} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Schedule;
