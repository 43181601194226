import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import FileUpload from '../../components/file/FileUpload';
import FormRepository from './FormRepository';
import DialogCloseButton from '../../components/DialogCloseButton';

interface FormImporterDialogProps {
  open: boolean;
  onClose: () => void;
  onImport?: () => void;
}

const FormImporterDialog = (props: FormImporterDialogProps) => {
  const { open, onClose, onImport } = props;
  const [state, setState] = useState<{
    submitError: boolean;
    files: File[];
  }>({
    submitError: false,
    files: [],
  });
  const notifications = useSnackbar();

  const { submitError, files } = state;

  const handleSubmit = () => {
    if (files.length === 0) {
      setState({ ...state, submitError: true });
      return;
    }

    const repository = new FormRepository();

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          const data = JSON.parse(reader.result);
          repository
            .import(data)
            .then(() => {
              notifications.enqueueSnackbar(
                `Formulier aangemaakt op basis van "${file.name}"`,
                { variant: 'success' },
              );

              if (onImport) {
                onImport();
              }
            })
            .catch(() => {
              notifications.enqueueSnackbar(
                `Fout bij aanmaken formulier op basis van "${file.name}"`,
                { variant: 'error' },
              );
            });
        }
      };
      reader.onerror = () => {
        notifications.enqueueSnackbar(
          `Fout bij verwerken bestand "${file.name}"`,
          { variant: 'error' },
        );
      };
      reader.readAsText(file);
    });
  };

  const handleUploadChange = (files: File[]) => {
    setState({ ...state, submitError: false, files });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Formulier importeren
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {submitError && files.length === 0 && (
          <Box mb={2}>
            <Alert severity="warning">
              Selecteer op zijn minst 1 formulier om te importeren.
            </Alert>
          </Box>
        )}
        <FileUpload onChange={handleUploadChange} json />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={['fal', 'file-import']} />}
          onClick={handleSubmit}
        >
          Importeren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormImporterDialog;
