import { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import ApiClient from '../../api/ApiClient';
import {
  Account,
  ApiFilterCriteria,
  CollectionResponse,
  CompleteUser,
  EventLog,
  HydraCollectionResponse,
  Repository,
  ScheduleItem,
  TwoFactorMethod,
  User,
} from '../../types';
import { parseCriteria } from '../../utils/common';

class UserRepository implements Repository<User> {
  find(id: string): Promise<AxiosResponse<User>> {
    return ApiClient.get(`/api/users/${id}`);
  }

  getAccount(): Promise<AxiosResponse<Account>> {
    return ApiClient.get('/api/users/account');
  }

  getCompleteUser(id: string): Promise<AxiosResponse<CompleteUser>> {
    return ApiClient.get(`/api/users/${id}/complete`);
  }

  getSchedule(
    id: string,
    includePastItems: boolean,
  ): Promise<AxiosResponse<ScheduleItem[]>> {
    return ApiClient.get(`/api/users/${id}/schedule`, {
      includePastItems: +includePastItems,
    });
  }

  getEducatorSchedule(
    id: string,
    includePastItems: boolean,
  ): Promise<AxiosResponse<ScheduleItem[]>> {
    return ApiClient.get(`/api/users/${id}/educator-schedule`, {
      includePastItems: +includePastItems,
    });
  }

  getScheduleAtDates(
    id: string,
    dates: Date[],
  ): Promise<AxiosResponse<ScheduleItem[]>> {
    const formattedDates = dates.map((date: Date) =>
      moment(date).format('YYYY-MM-DD'),
    );
    return ApiClient.get(
      `/api/users/${id}/schedule-items?dates=${formattedDates.join(',')}`,
    );
  }

  getLoginEvents(id: string): Promise<HydraCollectionResponse<EventLog>> {
    return ApiClient.get('/api/event_logs', {
      type: 'login',
      user: id,
    });
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page: number = 1,
    cancelToken?: CancelTokenSource,
    perPage: number = 10,
  ): Promise<CollectionResponse<User>> {
    const params = parseCriteria(criteria, {
      page,
      itemsPerPage: perPage,
    });

    return ApiClient.get('/api/users', params);
  }

  updateCompleteUser(user: CompleteUser) {
    return ApiClient.put(`/api/users/${user.id}/complete`, user);
  }

  updateUserRoles(userId: string, data: {}) {
    return ApiClient.put(`/api/users/${userId}/roles`, data);
  }

  delete(id: string) {
    return ApiClient.delete(`/api/users/${id}`);
  }

  updateTwoFactorMethod(
    user: User,
    method: TwoFactorMethod | null,
    twoFactorCode: string | null,
  ) {
    return ApiClient.post(`/api/users/${user.id}/two-factor`, {
      method,
      twoFactorCode,
    });
  }

  getTwoFactorQrCode() {
    return ApiClient.get('/api/users/qr-code');
  }
}

export default UserRepository;
