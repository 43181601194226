import React from 'react';
import { filterXSS } from 'xss';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface SafeHtmlProps {
  html: string;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    '& img': {
      maxWidth: '100%',
    },
    '& figure.image-style-side': {
      float: 'right',
    },
  },
}));

const SafeHtml = ({ html, className = '' }: SafeHtmlProps) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      component="div"
      dangerouslySetInnerHTML={{
        __html: filterXSS(html, {
          whiteList: {
            h1: [],
            h2: [],
            h3: [],
            h4: [],
            h5: [],
            img: ['src', 'class', 'width', 'height'],
            figure: ['class', 'style'],
            figcaption: ['class'],
            strong: [],
            a: ['target', 'rel', 'href'],
            blockquote: [],
            p: [],
            ul: [],
            li: [],
            ol: [],
            table: [],
            tr: [],
            td: [],
            th: [],
            em: [],
            i: [],
            b: [],
            br: [],
          },
        }),
      }}
      className={`${classes.root} ${className}`}
    />
  );
};

export default SafeHtml;
