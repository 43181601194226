import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CompleteUser, Education } from '../../types';
import { matchUuid, testUuid } from '../../utils/common';
import { updateEducation } from '../../actions';
import AppContext from '../../AppContext';

interface EducationMenuProps {
  account: CompleteUser;
  linkClass: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
  },
  educationMenu: {
    marginTop: 40,
  },
}));

const EducationMenu = (props: EducationMenuProps) => {
  const { account, linkClass } = props;
  const [active, setActive] = useState<boolean>(false);
  const [education, setEducation] = useState<Education | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { localStore } = useContext(AppContext);

  const educations =
    account.educations &&
    account.educations.filter((education) => !education.furtherEducation);

  const checkIsActive = () => {
    let isActive = false;

    if (account.educations) {
      if (testUuid('/onderwijs/{id}', history.location.pathname)) {
        isActive = true;
      }

      const furtherEducation = account.educations.find(
        (education) => education.furtherEducation,
      );

      if (
        furtherEducation &&
        new RegExp(`/onderwijs/${furtherEducation.id}`).test(
          history.location.pathname,
        )
      ) {
        isActive = false;
      }
      setActive(isActive);
    }

    return isActive;
  };

  /**
   * Triggers when the menu item is click. Navigates to the education of the user if one is present.
   * Opens up a menu to choose an education if there is more than one education.
   * @param event
   */
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!educations || educations.length === 0) {
      return;
    }

    if (educations && educations.length === 1) {
      setEducation({ ...educations[0] });
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  history.listen(async (location, action) => {
    const isActive = checkIsActive();

    if (action === 'PUSH' && isActive && !education) {
      const education = await localStore.getItem('education');

      setEducation(account.educations?.find((e) => e.id === education) || null);
    }

    if (action === 'PUSH' && !isActive) {
      setEducation(null);
    }
  });

  const handleEducationChange = async () => {
    const isActive = checkIsActive();

    if (!education) {
      if (
        history.location.pathname === '/' ||
        history.location.pathname === '/onderwijs'
      ) {
        const education = await localStore.getItem('education');

        setEducation(
          account.educations?.find((e) => e.id === education) || null,
        );
      }

      const uuidMatches = matchUuid(
        '/onderwijs/{id}',
        history.location.pathname,
      );

      if (isActive && uuidMatches && uuidMatches[1] !== undefined) {
        setEducation(
          account.educations?.find((e) => e.id === uuidMatches[1]) || null,
        );
      }

      return;
    }

    const matches = matchUuid('/onderwijs/{id}', window.location.pathname);
    if (matches && matches[1] === education.id) {
      return;
    }

    await localStore.setItem('education', education.id);
    history.push(`/onderwijs/${education.id}`);
    dispatch(updateEducation(education, localStore));
    handleClose();
  };

  useEffect(() => {
    handleEducationChange();
  }, [education]);

  if (!educations || educations.length === 0) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <Button
        aria-controls="education-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        className={`${linkClass} ${active ? 'active' : ''}`}
        endIcon={
          educations && educations.length > 1 ? (
            <FontAwesomeIcon
              icon={['fal', 'chevron-down']}
              style={{ width: 16, height: 16, marginRight: 6 }}
            />
          ) : null
        }
      >
        {education ? education.name : 'Opleiding'}
      </Button>
      <Menu
        id="education-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        className={classes.educationMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {educations.map((education) => (
          <MenuItem
            value={education.id}
            key={education.id}
            onClick={() => setEducation(education)}
          >
            {education.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default EducationMenu;
