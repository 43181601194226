import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import ApiClient from '../../api/ApiClient';
import DialogCloseButton from '../../components/DialogCloseButton';
import UserRepository from './UserRepository';
import Loader from '../../components/Loader';

interface UserMergeDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

const UserMergeDialog = (props: UserMergeDialogProps) => {
  const { open, onClose, onSuccess, onFail } = props;
  const [state, setState] = useState<{
    previousAfasId: string;
    currentAfasId: string;
    loadingPreviousUser: boolean;
    loadingCurrentUser: boolean;
    previousUserName: string | null;
    currentUserName: string | null;
    errors: { [key: string]: boolean };
  }>({
    previousAfasId: '',
    currentAfasId: '',
    loadingPreviousUser: false,
    loadingCurrentUser: false,
    previousUserName: null,
    currentUserName: null,
    errors: {},
  });
  const {
    loadingPreviousUser,
    loadingCurrentUser,
    previousUserName,
    currentUserName,
    previousAfasId,
    currentAfasId,
    errors,
  } = state;

  const mergeUsers = () => {
    errors.previousAfasId = previousAfasId.length === 0;
    errors.currentAfasId = currentAfasId.length === 0;

    setState((prev) => ({ ...prev, errors }));

    if (errors.previousAfasId || errors.currentAfasId) {
      return;
    }

    ApiClient.post('/api/afas/merge', { previousAfasId, currentAfasId })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onFail) {
          onFail();
        }
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name!]: event.target.value });
  };

  const getNameByAfasId = (afasId: string) =>
    new UserRepository()
      .findBy({
        query: afasId,
      })
      .then((result) => {
        if (!result.data.items.length) {
          return null;
        }

        return result.data.items[0].fullNameLastNameFirst || '';
      });

  const getNameOfPreviousUser = useCallback(async () => {
    if (previousAfasId.length !== 7) {
      setState((prev) => ({ ...prev, previousUserName: null }));
      return;
    }

    setState((prev) => ({ ...prev, loadingPreviousUser: true }));
    const name = await getNameByAfasId(previousAfasId);
    setState((prev) => ({
      ...prev,
      previousUserName: name,
      loadingPreviousUser: false,
    }));
  }, [previousAfasId]);

  const getNameOfCurrentUser = useCallback(async () => {
    if (currentAfasId.length !== 7) {
      setState((prev) => ({ ...prev, currentUserName: null }));
      return;
    }

    setState((prev) => ({ ...prev, loadingCurrentUser: true }));
    const name = await getNameByAfasId(currentAfasId);
    setState((prev) => ({
      ...prev,
      currentUserName: name,
      loadingCurrentUser: false,
    }));
  }, [currentAfasId]);

  useEffect(() => {
    getNameOfPreviousUser();
  }, [getNameOfPreviousUser]);

  useEffect(() => {
    getNameOfCurrentUser();
  }, [getNameOfCurrentUser]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-merge-dialog-title"
      aria-describedby="user-merge-dialog-description"
    >
      <DialogTitle id="user-merge">
        Gebruikers samenvoegen
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="user-merge-dialog-description">
          Wanneer in Profit gebruikers worden samengevoegd verdwijnt er 1
          gebruiker in Profit. In de DLO blijven deze wel bestaan. Door
          hieronder het oorspronkelijke AFAS ID en huidige AFAS ID in te voeren
          worden deze gebruikers ook in DLO samengevoegd.
        </DialogContentText>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item>
              <Box display="flex" flexDirection="column">
                <TextField
                  type="text"
                  name="previousAfasId"
                  value={previousAfasId}
                  onChange={handleChange}
                  error={errors.previousAfasId}
                  label="Oorspronkelijk AFAS ID"
                  variant="outlined"
                  fullWidth
                />
                <Box mt={1}>
                  {loadingPreviousUser && <Loader inline />}
                  {!loadingPreviousUser &&
                    previousUserName !== null &&
                    previousUserName}
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column">
                <TextField
                  type="text"
                  name="currentAfasId"
                  value={currentAfasId}
                  onChange={handleChange}
                  error={errors.currentAfasId}
                  label="Huidig AFAS ID"
                  variant="outlined"
                  fullWidth
                />
                <Box mt={1}>
                  {loadingCurrentUser && <Loader inline />}
                  {!loadingCurrentUser &&
                    currentUserName !== null &&
                    currentUserName}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={mergeUsers} variant="contained" color="secondary">
          Samenvoegen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserMergeDialog;
