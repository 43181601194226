import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyncIssuesRepository from './SyncIssuesRepository';
import { SyncIssue } from './types';
import Loader from '../../components/Loader';
import PageContainer from '../../components/PageContainer';
import SafeHtml from '../../components/SafeHtml';
import StatusIndicator from '../../components/StatusIndicator';
import ApiClient from '../../api/ApiClient';

const SyncIssueDetail = () => {
  const { id } = useParams<{ id: string }>();
  const notifications = useSnackbar();
  const [repository, setRepository] = useState<SyncIssuesRepository>(
    new SyncIssuesRepository(),
  );
  const [issue, setIssue] = useState<SyncIssue | null>(null);
  const [syncing, setSyncing] = useState<boolean>(false);

  useEffect(() => {
    repository
      .find(id)
      .then((response) => {
        setIssue(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het ophalen van sync issue.', {
          variant: 'error',
        });
      });
  }, [repository]);

  /**
   * Sync user with AFAS.
   */
  const handleSync = () => {
    if (!issue) {
      return;
    }

    notifications.enqueueSnackbar('Gebruiker wordt gesynchroniseerd...');

    setSyncing(true);

    ApiClient.syncUser(issue.afasId)
      .then(() => {
        notifications.enqueueSnackbar('Gebruiker is gesynchroniseerd.', {
          variant: 'success',
        });
        setRepository(new SyncIssuesRepository());
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het synchroniseren van de gebruiker.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        setSyncing(false);
      });
  };

  const handleResolve = () => {
    if (issue) {
      repository
        .resolve(issue.id)
        .then(() => {
          notifications.enqueueSnackbar('Issue is gemarkeerd als opgelost.', {
            variant: 'success',
          });
          setRepository(new SyncIssuesRepository());
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij markeren van issue alszijnde opgelost.',
            { variant: 'error' },
          );
        });
    }
  };

  if (!issue) {
    return <Loader />;
  }

  const actions = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={2}>
        <Button
          onClick={handleResolve}
          startIcon={<FontAwesomeIcon icon={['fal', 'check']} />}
        >
          Opgelost
        </Button>
      </Box>
      <Button
        onClick={handleSync}
        startIcon={<FontAwesomeIcon icon={['fal', 'sync']} spin={syncing} />}
        variant="contained"
        color="secondary"
      >
        Opnieuw proberen
      </Button>
    </Box>
  );

  return (
    <PageContainer title={`Issue: ${issue.afasId}`} actions={actions}>
      <Box mt={2}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <strong>Status</strong>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <StatusIndicator
                      indicator={issue.state === 'resolved' ? 'green' : 'red'}
                      variant="circle"
                    />
                    <Box>
                      {issue.state === 'resolved' && 'Opgelost'}
                      {issue.state === 'regression' && 'Opnieuw geopend'}
                      {issue.state === 'open' && 'Open'}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <strong>Foutmelding</strong>
                </TableCell>
                <TableCell>{issue.message}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <strong>Trace</strong>
                </TableCell>
                <TableCell>
                  <SafeHtml
                    html={issue.trace.replaceAll(/#([^0])/g, '<br><br>#$1')}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </PageContainer>
  );
};

export default SyncIssueDetail;
