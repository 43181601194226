export type ModuleType =
  | 'IO'
  | 'IKO'
  | 'PRK'
  | 'EKO'
  | 'STA'
  | 'EPA'
  | 'AANVRAAG'
  | 'BEG';

const ModuleTypes: { [key in ModuleType]: string } = {
  IO: 'Instituutsonderwijs',
  IKO: 'Intern keuzeonderwijs',
  PRK: 'Praktijkopleiding',
  EKO: 'Extern keuzeonderwijs',
  STA: 'Stages',
  EPA: 'EPA/KBA',
  AANVRAAG: 'Aanvraag',
  BEG: 'Begeleiding',
};

export default ModuleTypes;
