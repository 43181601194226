import { Dispatch } from 'redux';

import ApiClient from './ApiClient';
import {
  loginUser,
  logoutUser,
  requestTwoFactorCode,
  requestTwoFactorConfiguration,
  setAccount,
} from '../actions';

class UserAuthenticator {
  private dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  login(
    email: string,
    password: string,
    failureCallback?: (err?: any) => void,
  ) {
    ApiClient.login(email, password)
      .then((response) => {
        if (
          response.data['2fa_complete'] !== undefined &&
          !response.data['2fa_complete'] &&
          response.data.email
        ) {
          this.dispatch(
            requestTwoFactorCode(
              response.data.email,
              response.data.auth_method,
            ),
          );
        } else {
          this.dispatch(loginUser());
        }
      })
      .catch((err) => {
        if (
          !err.response.data['2fa_configuration_required'] &&
          err.response.data.account
        ) {
          this.dispatch(setAccount(err.response.data.account));
          this.dispatch(requestTwoFactorConfiguration());
        }

        if (failureCallback) {
          failureCallback(err);
        }
      });
  }

  checkTwoFactorCode(code: string, failureCallback?: (err?: any) => void) {
    ApiClient.post('/2fa_check', { _auth_code: code })
      .then(() => {
        this.dispatch(loginUser());
      })
      .catch((err) => {
        if (failureCallback) {
          failureCallback(err);
        }
      });
  }

  logout(callback?: () => void) {
    ApiClient.post('/api/logout').then(() => {
      this.dispatch(logoutUser());

      if (callback) {
        callback();
      }
    });
  }
}

export default UserAuthenticator;
