import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Education, EducationModule } from '../../../types';
import DataTable from '../../../components/DataTable';
import StatusFlagIcon from '../StatusFlagIcon';
import SearchContext from '../../../components/search/SearchContext';
import SearchInput from '../../../components/search/SearchInput';
import EducationRepository from '../EducationRepository';
import statusLabels from '../statusLabels';
import EducationModuleRepository from './EducationModuleRepository';

interface OptionalEducationTableDialogProps {
  education: Education;
  open: boolean;
  onClose: () => void;
}

const OptionalEducationTableDialog = (
  props: OptionalEducationTableDialogProps,
) => {
  const { education, open, onClose } = props;
  const [query, setQuery] = useState<string>('');

  const [modules, setModules] = useState<EducationModule[] | undefined>(
    undefined,
  );

  const repository = useMemo(
    () => new EducationModuleRepository(modules || []),
    [modules],
  );

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns = [
    {
      name: 'Naam',
      field: 'publishedName',
      sortable: true,
    },
    {
      name: 'Punten',
      field: 'points',
      sortable: false,
    },
    {
      name: 'Type',
      field: 'type',
      sortable: false,
      render: (module: EducationModule) => {
        if (module.type === 'EKO') {
          return 'Extern';
        }

        if (module.type === 'IKO') {
          return 'Intern';
        }

        return null;
      },
    },
    {
      name: 'Status',
      field: 'status',
      sortable: false,
      filter: {
        type: 'checkbox' as const,
        options: Object.entries(statusLabels).map(([, label]) => ({
          label,
          value: label,
        })),
      },
      render: (module: EducationModule) => <StatusFlagIcon module={module} />,
    },
  ];

  useEffect(() => {
    new EducationRepository()
      .getModules(education.id, ['IKO', 'EKO'])
      .then((response) => setModules(response.data as EducationModule[]));
  }, [education.id]);

  if (!modules) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <SearchContext.Provider value={searchContextValue}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <SearchInput />
          </Box>
          <DataTable
            id="optional-education-table"
            repository={repository}
            columns={columns}
            defaultFilters={{
              query: '',
              filters: {},
              order: [
                {
                  field: 'publishedName',
                  order: 'asc',
                },
              ],
            }}
          />
        </SearchContext.Provider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Sluiten</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionalEducationTableDialog;
