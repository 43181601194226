class URLHashParameters {
  set(param: string, value: string) {
    const params = this.getParams();
    params[param] = value;

    this.setParams(params);
  }

  remove(param: string) {
    const params = this.getParams();
    delete params[param];

    this.setParams(params);
  }

  get(param: string, defaultValue: string | null = null) {
    return this.getParams()[param] || defaultValue || null;
  }

  getParams() {
    const hash = window.location.hash.substr(1);

    if (hash.length === 0) {
      return {};
    }

    return hash.split('&').reduce((result: { [key: string]: string }, item) => {
      const parts = item.split('=');
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      result[parts[0]] = parts[1];
      return result;
    }, {});
  }

  private setParams(params: { [key: string]: string }) {
    window.location.hash = Object.entries(params)
      .map((param) => `${param[0]}=${param[1]}`)
      .join('&');
  }
}

export default new URLHashParameters();
