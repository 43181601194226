import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { CompleteUser } from '../../types';
import TabPanel from '../../components/TabPanel';
import UserRepository from './UserRepository';
import settings from '../../config/settings';
import { colors } from '../../config/theme';

import CompleteUserForm from './CompleteUserForm';
import ChangePasswordForm from './ChangePasswordForm';
import AvatarForm from './AvatarForm';
import { UserState } from '../../reducers/user';
import CertificateList from './certificates/CertificateList';
import NotificationPreferences from './notifications/NotificationPreferences';
import URLHashParameters from '../../utils/URLHashParameters';
import TwoFactorConfiguration from './TwoFactorConfiguration';
import { refreshAccount } from '../../actions';
import AppContext from '../../AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    paddingTop: settings.header.height + theme.spacing(3),
    paddingBottom: 80,
    width: '100%',
    background: colors.primary.ultraLight,
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    marginTop: -48,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionHeading: {
    marginBottom: theme.spacing(3),
  },
  certificateSectionHeading: {
    marginBottom: theme.spacing(1),
  },
  nameContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  name: {
    width: '100%',
  },
}));

const MyAccount = () => {
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));
  const [state, setState] = useState<{
    tab: string;
  }>({
    tab: URLHashParameters.get(
      'tab',
      roleViewManager.isELearningView() ? 'certificaten' : 'profiel',
    ) as string,
  });
  const [user, setUser] = useState<CompleteUser | null>(null);

  useEffect(() => {
    if (account && account.id) {
      new UserRepository()
        .getCompleteUser(account.id)
        .then((res) => setUser(res.data))
        .catch(() =>
          notifications.enqueueSnackbar(
            'Er is iets fout gegaan bij het ophalen van je profiel!',
            { variant: 'error' },
          ),
        );
    }
  }, [notifications, account]);

  if (!account) {
    return <div />;
  }

  const changeTab = (event: ChangeEvent<{}>, newValue: string) => {
    setState({ ...state, tab: newValue });
    URLHashParameters.set('tab', newValue);
  };

  const submit = (e: FormEvent, user: CompleteUser) => {
    e.preventDefault();

    if (!user) {
      return;
    }

    new UserRepository()
      .updateCompleteUser(user)
      .then(() => {
        dispatch(refreshAccount());
        notifications.enqueueSnackbar('Je profiel is succesvol gewijzigd!', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het wijzigen van je profiel!',
          { variant: 'error' },
        );
      });
  };

  return (
    <div>
      <div className={classes.header}>
        <Container className={classes.headerInner}>
          {account && <AvatarForm user={account} />}
          <div className={classes.nameContainer}>
            <Typography component="h1" variant="h6" className={classes.name}>
              {account.fullName}
            </Typography>
          </div>
        </Container>
      </div>

      <Container className={classes.content}>
        <Tabs value={state.tab} onChange={changeTab} aria-label="profile tabs">
          <Tab
            label="Profiel"
            id="profile-tab-profiel"
            value="profiel"
            aria-controls="profile-tabpanel-profiel"
          />
          <Tab
            label="Authenticatie"
            id="profile-tab-authenticatie"
            value="authenticatie"
            aria-controls="profile-tabpanel-authenticatie"
          />
          {!roleViewManager.isELearningView() && (
            <Tab
              label="Notificaties"
              id="profile-tab-notificaties"
              value="notificaties"
              aria-controls="profile-tabpanel-notificaties"
            />
          )}
          <Tab
            label="Certificaten"
            id="profile-tab-certificaten"
            value="certificaten"
            aria-controls="profile-tabpanel-certificaten"
          />
        </Tabs>

        <TabPanel value={state.tab} index="profiel">
          {user && <CompleteUserForm user={user} submit={submit} />}
        </TabPanel>
        <TabPanel value={state.tab} index="authenticatie">
          <Grid container className={classes.container} spacing={4}>
            <Grid item container xs={12} md={7}>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Meervoudige authenticatie
                </Typography>
                {user && (
                  <Box mb={4}>
                    <TwoFactorConfiguration user={account} context="profile" />
                  </Box>
                )}
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Wachtwoord wijzigen
                </Typography>
                {user && <ChangePasswordForm user={user} />}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={state.tab} value="notificaties">
          <Grid container className={classes.container} spacing={4}>
            <Grid item container xs={12} md={7}>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Notificaties instellen
                </Typography>
                <Box position="relative" mt={2}>
                  <NotificationPreferences />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={state.tab} index="certificaten">
          <Grid container className={classes.container} spacing={4}>
            <Grid item container xs={12}>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.certificateSectionHeading}
                >
                  Certificaten
                </Typography>
                <CertificateList user={account} />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Container>
    </div>
  );
};

export default MyAccount;
