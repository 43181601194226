import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { User } from '../../../types';

interface UserEducationButtonProps {
  user: User;
  className?: string;
}

const UserEducationButton = (props: UserEducationButtonProps) => {
  const { user, className } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const educations = user.educations || [];

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (educations.length === 1) {
      history.push(`/gebruikers/onderwijs/${educations[0].id}`);
      return;
    }

    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Menu
        id={`education-menu-${user.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {educations.map((education) => {
          const onClick = () =>
            history.push(`/gebruikers/onderwijs/${education.id}`);

          return (
            <MenuItem value={education.id} key={education.id} onClick={onClick}>
              {education.name}
            </MenuItem>
          );
        })}
      </Menu>
      {educations.length > 0 && (
        <Tooltip title="Onderwijs bekijken">
          <IconButton size="small" onClick={handleOpen} className={className}>
            <FontAwesomeIcon icon={['fal', 'graduation-cap']} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default UserEducationButton;
