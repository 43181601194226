import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CertificateRepository from './CertificateRepository';
import { Certificate, User } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderCollapse: 'initial',
    borderSpacing: `0 ${theme.spacing(1)}px`,
    padding: 2,
    paddingTop: 0,
  },
  tr: {
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.06)',
  },
  th: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    borderBottom: 'none',
  },
  td: {
    padding: theme.spacing(1),
    background: '#FFF',
  },
}));

interface CertificateListProps {
  user: User;
}

const CertificateList = (props: CertificateListProps) => {
  const { user } = props;
  const classes = useStyles();
  const [certificates, setCertificates] = useState<Certificate[]>([]);

  useEffect(() => {
    new CertificateRepository().findAll(user).then((response) => {
      setCertificates(response.data);
    });
  }, [user]);

  return certificates.length >= 1 ? (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>Naam</TableCell>
            <TableCell className={classes.th}>Datum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certificates.map((certificate) => (
            <TableRow key={certificate.eventId} className={classes.tr}>
              <TableCell className={classes.td}>{certificate.name}</TableCell>
              <TableCell className={classes.td}>
                {moment(certificate.endDate).format('DD-MM-YYYY')}
              </TableCell>
              <TableCell className={classes.td}>
                <Box display="flex" justifyContent="flex-end">
                  <Tooltip title="Downloaden">
                    <IconButton
                      href={CertificateRepository.getCertificateDownloadUrl(
                        user,
                        certificate,
                      )}
                      size="small"
                    >
                      <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>Geen resultaten</p>
  );
};

export default CertificateList;
