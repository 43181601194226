import React, { useContext } from 'react';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AppContext from '../AppContext';

interface DebugPopoverProps {
  id: string;
  anchorEl: Element | null;
  onClose: () => void;
  properties: { name: string; value: string | number | undefined }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  infoHeader: {
    fontWeight: 700,
    fontSize: 17,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  infoBody: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

const DebugPopover = (props: DebugPopoverProps) => {
  const classes = useStyles();
  const { id, anchorEl, onClose, properties } = props;
  const { roleViewManager } = useContext(AppContext);

  if (!roleViewManager.isAdminOrImitatingAdmin()) {
    return <div />;
  }

  return (
    <Popover
      id={`debug-popover-${id}`}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={1}
    >
      <Typography variant="h6" component="h3" className={classes.infoHeader}>
        Debug informatie
      </Typography>
      <Typography variant="body1" className={classes.infoBody}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {properties.map(({ name, value }) => (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>
    </Popover>
  );
};

export default DebugPopover;
