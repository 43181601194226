import { AxiosResponse, CancelTokenSource } from 'axios';

import {
  ApiFilterCriteria,
  CollectionResponse,
  NewsItem,
  Repository,
  User,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class NewsRepository implements Repository<NewsItem> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
    perPage?: number,
  ): Promise<CollectionResponse<NewsItem>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });
    return ApiClient.get('/api/news/management', params);
  }

  authors(): Promise<AxiosResponse<User[]>> {
    return ApiClient.get('/api/news-authors');
  }

  duplicate(id: string): Promise<AxiosResponse> {
    return ApiClient.post(`/api/news/${id}/duplicate`);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/news/${id}`);
  }
}

export default NewsRepository;
