import React, { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SimpleTable from '../../../components/SimpleTable';
import { Assignment, Education } from '../../../types';
import EducationRepository, {
  DownloadSummaryItem,
} from '../EducationRepository';
import { Column } from '../../../components/DataTable';
import FileChip from '../../../components/file/FileChip';
import TableContainer from '../../../components/TableContainer';

const useStyles = makeStyles((theme: Theme) => ({
  pdfButton: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

interface DownloadSummaryDetailsProps {
  education: Education;
  item: DownloadSummaryItem;
}

const DownloadSummaryDetails = (props: DownloadSummaryDetailsProps) => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);

  const classes = useStyles();
  const repository = useMemo(() => new EducationRepository(), []);

  useEffect(() => {
    repository
      .getDownloadSummaryDetails(props.education.id, props.item.identifier)
      .then((response) => setAssignments(response.data));
  }, [repository, props.education.id, props.item.identifier]);

  const columns: Column[] = [
    {
      name: 'Module',
      field: 'module',
      render: (assignment: Assignment) =>
        assignment.module?.publishedName || '',
    },
    {
      name: 'Opdracht',
      field: 'assignment',
      render: (assignment: Assignment) => (
        <Box display="flex">
          <Tooltip title="PDF downloaden">
            <IconButton
              className={classes.pdfButton}
              href={`${process.env.REACT_APP_API_URL}/api/assignment/${assignment.id}/pdf`}
            >
              <FontAwesomeIcon icon={['fal', 'file-pdf']} />
            </IconButton>
          </Tooltip>
          <Box>{assignment.name}</Box>
        </Box>
      ),
    },
    {
      name: 'Bestanden',
      field: 'files',
      render: (assignment: Assignment) => {
        if (assignment.mostRecentEntry?.files === undefined) {
          return null;
        }

        return (
          <>
            {assignment.mostRecentEntry.files.map((file) => (
              <Box mb={0.5}>
                <FileChip file={file} />
              </Box>
            ))}
          </>
        );
      },
    },
  ];

  const downloadUrl =
    `${process.env.REACT_APP_API_URL}` +
    `/api/education/${props.education.id}/download-summary-bundled-pdf` +
    `?identifier=${props.item.identifier}`;

  return (
    <>
      <Typography variant="h4">{props.item.category}</Typography>
      <TableContainer>
        <SimpleTable items={assignments} columns={columns} />
        <Button
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            window.location.href = downloadUrl;
          }}
        >
          <Box display="flex">
            <FontAwesomeIcon size="lg" icon={['fal', 'file-pdf']} />
            <Box ml={1}>
              {`Gebundelde PDF downloaden${
                assignments.length >= 10
                  ? ' (geschatte tijd: enkele minuten)'
                  : ''
              }`}
            </Box>
          </Box>
        </Button>
      </TableContainer>
    </>
  );
};

export default DownloadSummaryDetails;
