import { createContext, Dispatch, SetStateAction } from 'react';
import { FormContextFilesState, FormContextState } from './Form';

const FormContext = createContext<{
  formData: FormContextState;
  setFormData: Dispatch<SetStateAction<FormContextState>> | null;
  files: FormContextFilesState;
  setFiles: Dispatch<SetStateAction<FormContextFilesState>> | null;
}>({
  formData: {},
  setFormData: null,
  files: {},
  setFiles: null,
});

export default FormContext;
