import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import MoodleLoader from '../../components/MoodleLoader';
import AppContext from '../../AppContext';

const EducatorEventMoodleLoader = () => {
  const { userId, eventId } = useParams<{ userId: string; eventId: string }>();

  const { roleViewManager } = useContext(AppContext);

  return (
    <MoodleLoader
      endpoint={`/api/users/${userId}/educator-event/moodle-redirect`}
      params={{
        eventId,
        requestContext: roleViewManager.getCurrentView(),
      }}
    />
  );
};

export default EducatorEventMoodleLoader;
