import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ScheduleItem } from '../../types';
import ParticipantsRepository from './ParticipantsRepository';
import DataTable from '../../components/DataTable';
import DialogCloseButton from '../../components/DialogCloseButton';

interface ParticipantsDialogProps {
  open: boolean;
  onClose: () => void;
  scheduleItem: ScheduleItem;
}

const ParticipantsDialog = (props: ParticipantsDialogProps) => {
  const { open, onClose, scheduleItem } = props;
  const { sessionId, eventId } = scheduleItem;
  const repository = new ParticipantsRepository(sessionId, eventId);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const columns = [
    {
      name: 'Naam',
      field: 'name',
      sortable: false,
    },
    {
      name: 'Specialisme',
      field: 'specialty',
      sortable: false,
    },
    {
      name: 'Deelname vanuit',
      field: 'cohort',
      sortable: false,
    },
  ];

  const handleLoad = (items: any, totalCount: number) => {
    setTotalCount(totalCount);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Deelnemers
        {totalCount && (
          <Box display="inline-block" ml={1}>
            {`(${totalCount})`}
          </Box>
        )}
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <DataTable
          id="participants-dialog"
          repository={repository}
          columns={columns}
          onLoad={handleLoad}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ParticipantsDialog;
