import React, { useContext } from 'react';
import { Box, Chip, Theme } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';

import { File } from '../../types';
import FileIcon from './FileIcon';
import ApiClient from '../../api/ApiClient';
import AppContext from '../../AppContext';
import { getFileURL } from '../../utils/common';

interface FileChipProps {
  file: File;
  onDelete?: (file: File) => void;
  onChange?: (file: File) => void;
}

export const useStyles = makeStyles((theme: Theme) => ({
  read: {
    color: theme.palette.text.primary,
    background: green[300],
    '&:focus': {
      background: green[300],
    },
    '&:hover': {
      background: green[400],
    },
  },
  unread: {
    color: theme.palette.text.primary,
    background: grey[300],
    '&:focus': {
      background: grey[300],
    },
    '&:hover': {
      background: grey[400],
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

const FileChip = (props: FileChipProps) => {
  const { file, onChange, onDelete } = props;
  const { roleViewManager } = useContext(AppContext);
  const notifications = useSnackbar();
  const classes = useStyles();

  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };

  const handleDownloadFile = () => {
    window.open(getFileURL(file), '_blank', 'noreferrer,noopener');
  };

  const ownsFile = file.user && file.user.id === roleViewManager.getUser().id;
  const deletable = file.deletable && onDelete;

  const className = ownsFile ? '' : file.read ? classes.read : classes.unread;

  let deleteCallback;

  if (deletable) {
    deleteCallback = handleDelete;
  }

  if (!ownsFile && roleViewManager.isEducatorView()) {
    deleteCallback = () => {
      if (!onChange) {
        return;
      }

      const read = !file.read;
      onChange({ ...file, read });

      ApiClient.post(`/api/file/${file.id}/${read ? 'read' : 'unread'}`).catch(
        () => {
          notifications.enqueueSnackbar(
            'Fout bij bestand markeren als gelezen.',
            { variant: 'error' },
          );
          onChange({ ...file, read: !read });
        },
      );
    };
  }

  const deleteIcon = (
    <Box
      ml={2}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      {deletable ? (
        <FontAwesomeIcon icon={['fal', 'times']} />
      ) : (
        <FontAwesomeIcon
          icon={['fal', file.read ? 'times' : 'check']}
          className={classes.icon}
        />
      )}
    </Box>
  );

  return (
    <Chip
      label={file.name}
      color="primary"
      className={className}
      icon={<FileIcon file={file} />}
      onClick={handleDownloadFile}
      onDelete={deleteCallback}
      deleteIcon={deleteIcon}
    />
  );
};

export default FileChip;
