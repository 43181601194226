import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Assignment } from '../../../types';
import EducationContext from '../EducationContext';

export interface AssignmentDeliveryState {
  po: boolean;
  io: boolean;
  teacher: string;
  educators: { id: string; name: string }[];
}

export interface AssignmentEducatorOption {
  id: string;
  name: string;
}

interface AssignmentDeliveryProps {
  po?: boolean;
  io?: boolean;
  teacher?: boolean;
  assignment?: Assignment;
  onEducatorOptionsChange?: (hasEducatorOptions: boolean) => void;
  onChange?: (educators: AssignmentEducatorOption[]) => void;
  error?: boolean;
}

const useStyles = makeStyles(() => ({
  autocomplete: {
    maxWidth: 450,
  },
}));

const AssignmentDelivery = ({
  assignment = undefined,
  po = false,
  io = false,
  teacher = false,
  onEducatorOptionsChange = undefined,
  onChange = undefined,
  error = false,
}: AssignmentDeliveryProps) => {
  const [educators, setEducators] = useState<AssignmentEducatorOption[]>([]);
  const { education } = useContext(EducationContext);
  const classes = useStyles();

  const handleAutocompleteChange = (event: any, options: any) =>
    setEducators(options);

  useEffect(() => {
    if (onChange) {
      onChange(educators);
    }
  }, [educators]);

  const educatorOptions = useMemo(() => {
    let result: AssignmentEducatorOption[] = [];

    const educationEducators: { id: string; name: string }[] = [];

    if (teacher && assignment && assignment.teachers) {
      result = assignment.teachers
        .sort((a, b) =>
          a.fullNameLastNameFirst.localeCompare(b.fullNameLastNameFirst),
        )
        .map((t) => ({
          id: t.id,
          name: t.fullNameLastNameFirst,
        }));
    }

    if (io && education && education.instituteEducators.length > 0) {
      education.instituteEducators.forEach((e) =>
        educationEducators.push({
          id: e.id,
          name: `${e.fullNameLastNameFirst} (NSPOH opleider)`,
        }),
      );
    }

    if (po && education && education.practicalEducators.length > 0) {
      education.practicalEducators.forEach((e) =>
        educationEducators.push({
          id: e.id,
          name: `${e.fullNameLastNameFirst} (Praktijkopleider)`,
        }),
      );
    }

    return [
      ...result,
      ...educationEducators.sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }, [io, po, education]);

  useEffect(() => {
    if (onEducatorOptionsChange) {
      onEducatorOptionsChange(educatorOptions.length !== 0);
    }
  });

  return (
    <>
      {error && (
        <Box mb={2}>
          <Alert severity="error">
            Je dient minimaal 1 beoordelaar te selecteren.
          </Alert>
        </Box>
      )}
      {educatorOptions.length > 0 && (
        <Box mt={5} mb={4} display="block">
          <Autocomplete
            id="assignment-delivery-educators"
            getOptionLabel={(option) => option.name}
            options={educatorOptions}
            onChange={handleAutocompleteChange}
            value={educators}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                placeholder="Selecteer een beoordelaar..."
              />
            )}
            className={classes.autocomplete}
            multiple
            autoComplete
          />
        </Box>
      )}
    </>
  );
};

export default AssignmentDelivery;
