import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from './types';
import SafeHtml from '../../components/SafeHtml';
import FileIcon from '../../components/file/FileIcon';
import { getFileURL } from '../../utils/common';

interface FormDescriptionProps {
  form: Form;
}

const FormDescription = (props: FormDescriptionProps) => {
  const { form } = props;
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleToggle = () => setExpanded(!expanded);

  if (form.description?.length === 0 && form.attachments.length === 0) {
    return <div />;
  }

  const chevronIcon = (
    <FontAwesomeIcon icon={['fal', expanded ? 'chevron-up' : 'chevron-down']} />
  );

  return (
    <div>
      <Button onClick={handleToggle} size="small" startIcon={chevronIcon}>
        {expanded ? 'Beschrijving verbergen' : 'Beschrijving tonen'}
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {form.description && <SafeHtml html={form.description} />}
        {form.attachments.length > 0 && (
          <Box p={2}>
            <FormLabel>Bijlagen</FormLabel>
            <List>
              {form.attachments.map((attachment) => (
                <ListItem key={attachment.id}>
                  <ListItemIcon>
                    <FileIcon file={attachment} />
                  </ListItemIcon>
                  <ListItemText>
                    <a
                      href={getFileURL(attachment)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {attachment.name}
                    </a>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Collapse>
    </div>
  );
};

export default FormDescription;
