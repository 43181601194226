import React from 'react';
import { Grid } from '@material-ui/core';
import { File } from '../../types';
import FileChip from './FileChip';

interface FileChipsProps {
  files: File[];
  onDelete?: (file: File) => void;
  onFileChange?: (file: File) => void;
}

const FileChips = (props: FileChipsProps) => {
  const { files, onDelete, onFileChange } = props;

  return (
    <Grid container spacing={1}>
      {files.map((file) => (
        <Grid item key={file.id}>
          <FileChip
            key={file.id}
            file={file}
            onChange={onFileChange}
            onDelete={onDelete}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FileChips;
