import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormEntryFieldOption } from './types';

export interface LikertSection {
  row: number;
  description: string;
}

interface LikertProps {
  rows: string[];
  columns: string[];
  multiple?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (selected: string[]) => void;
  initialSelected?: FormEntryFieldOption[];
  sections?: LikertSection[];
}

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    width: 40,
    height: 40,
  },
  section: {
    width: '100%',
    background: theme.palette.primary.light,
    padding: theme.spacing(2),
    '& > td': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

interface LikertSelectedState {
  single: { [key: number]: string };
  multiple: { [key: string]: boolean };
}

const Likert = (props: LikertProps) => {
  const classes = useStyles();
  const {
    className,
    rows,
    columns,
    multiple,
    disabled,
    onChange,
    initialSelected,
  } = props;
  const sections = props.sections || [];
  const isDisabled = disabled === undefined ? false : disabled;
  const [dirty, setDirty] = useState(false);
  const [initialState, setInitialState] = useState<LikertSelectedState | null>(
    null,
  );

  const [selected, setSelected] = useState<LikertSelectedState>({
    single: {},
    multiple: {},
  });

  const handleSingleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({
      ...selected,
      single: {
        ...selected.single,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleMultipleChange = (
    event: React.ChangeEvent<{ name: string; checked: boolean }>,
  ) => {
    setSelected({
      ...selected,
      multiple: {
        ...selected.multiple,
        [event.target.name]: event.target.checked,
      },
    });
  };

  useEffect(() => {
    if (!initialState) {
      return;
    }

    let isDirty = dirty;

    if (JSON.stringify(selected) !== JSON.stringify(initialState)) {
      isDirty = true;
      setDirty(true);
    }

    if (!onChange || !isDirty) {
      return;
    }

    if (multiple) {
      onChange(
        Object.entries(selected.multiple)
          .filter(([, selected]) => selected)
          .map(([field]) => field),
      );
    } else {
      onChange(Object.entries(selected.single).map((select) => select[1]));
    }
  }, [selected]);

  useEffect(() => {
    if (initialSelected) {
      const singleSelected: { [key: number]: string } = {};
      const multipleSelected: { [key: string]: boolean } = {};

      initialSelected.forEach((option) => {
        if (!option.selected) {
          return;
        }

        singleSelected[parseInt(option.option.label.split('_')[1], 10)] =
          option.option.label;
        multipleSelected[option.option.label] = true;
      });

      const state = {
        single: singleSelected,
        multiple: multipleSelected,
      };

      setSelected(state);
      setInitialState(state);
    } else {
      setInitialState(selected);
    }
  }, []);

  return (
    <TableContainer className={className} component={Paper}>
      <Table aria-label="likert table">
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((column) => (
              <TableCell key={column} align="center">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIdx) => {
            const section = sections.find((section) => section.row === rowIdx);

            return (
              <React.Fragment key={row}>
                {section !== undefined && (
                  <TableRow className={classes.section}>
                    <TableCell colSpan={columns.length + 1}>
                      {section.description}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>{row}</TableCell>
                  {multiple &&
                    columns.map((column, colIdx) => (
                      <TableCell
                        key={column}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <Checkbox
                          onChange={handleMultipleChange}
                          checked={
                            selected.multiple[`field_${rowIdx}_${colIdx}`] ||
                            false
                          }
                          name={`field_${rowIdx}_${colIdx}`}
                          disabled={isDisabled}
                        />
                      </TableCell>
                    ))}
                  {!multiple &&
                    columns.map((column, colIdx) => (
                      <TableCell
                        key={column}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <RadioGroup
                          style={{ display: 'block' }}
                          value={selected.single[rowIdx] || null}
                          onChange={handleSingleChange}
                        >
                          <Radio
                            disabled={isDisabled}
                            name={rowIdx.toString()}
                            value={`field_${rowIdx}_${colIdx}`}
                            className={classes.radio}
                          />
                        </RadioGroup>
                      </TableCell>
                    ))}
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Likert;
