import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Button, Theme, Box } from '@material-ui/core';

import { Assignment } from '../../../types';
import ProductViewDialog from './ProductViewDialog';
import ProductContext from './ProductContext';
import { sortAssignments } from '../assignment/helpers';

interface ProductPanelAssignmentsProps {
  assignments: Assignment[];
  selectable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  assignmentRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  assignmentRowNoSelect: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

const ProductPanel = (props: ProductPanelAssignmentsProps) => {
  const { assignments, selectable } = props;
  const classes = useStyles();
  const { productContext, setProductContext } = useContext(ProductContext);
  const [state, setState] = useState<{
    product: Assignment | null;
    dialogOpen: boolean;
  }>({
    product: null,
    dialogOpen: false,
  });

  const { product, dialogOpen } = state;
  const { selectedAssignments } = productContext;

  const openDialog = (assignment: Assignment) => {
    setState({ ...state, product: assignment, dialogOpen: true });
  };

  const handleClose = () => {
    setState({ ...state, product: null, dialogOpen: false });
  };

  const handleAddProduct = (assignment: Assignment) => {
    if (!setProductContext) {
      return;
    }

    productContext.selectedAssignments.push(assignment);
    setProductContext({
      ...productContext,
      selectedAssignments: [...productContext.selectedAssignments],
    });
  };

  const handleRemoveProduct = (assignment: Assignment) => {
    if (!setProductContext) {
      return;
    }

    const index = productContext.selectedAssignments.findIndex(
      (a) => a.id === assignment.id,
    );
    productContext.selectedAssignments.splice(index, 1);
    setProductContext({
      ...productContext,
      selectedAssignments: [...productContext.selectedAssignments],
    });
  };

  return (
    <>
      <Box>
        {sortAssignments(assignments).map((assignment) => (
          <Box
            key={assignment.id}
            className={`${classes.assignmentRow} ${
              selectable ? '' : classes.assignmentRowNoSelect
            }`}
          >
            <Button onClick={() => openDialog(assignment)}>
              {assignment.name}
            </Button>
            {selectable &&
              !selectedAssignments.find((a) => a.id === assignment.id) && (
                <IconButton onClick={() => handleAddProduct(assignment)}>
                  <FontAwesomeIcon icon={['fal', 'plus-circle']} />
                </IconButton>
              )}
            {selectable &&
              selectedAssignments.find((a) => a.id === assignment.id) && (
                <IconButton onClick={() => handleRemoveProduct(assignment)}>
                  <FontAwesomeIcon icon={['fal', 'minus-circle']} />
                </IconButton>
              )}
          </Box>
        ))}
      </Box>
      {product && (
        <ProductViewDialog
          product={product}
          onClose={handleClose}
          open={dialogOpen}
        />
      )}
    </>
  );
};

export default ProductPanel;
