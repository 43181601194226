import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import ApiClient from '../../../api/ApiClient';
import { Education, EducationMaterial } from '../../../types';
import FileUpload from '../../../components/file/FileUpload';
import { refreshAccount } from '../../../actions';

interface MaterialUploaderProps {
  education: Education;
  open: boolean;
  onClose: () => void;
  onUpload?: () => void;
  material?: EducationMaterial;
}

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const MaterialUploader = (props: MaterialUploaderProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const { onClose, open, education, onUpload, material } = props;
  const [title, setTitle] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);

  const handleFilesChange = (files: File[]) => setFiles(files);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('title', title);

    files.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });

    const endpoint = material
      ? `/api/education/${education.id}/materials/${material.id}`
      : `/api/education/${education.id}/materials`;

    ApiClient.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        notifications.enqueueSnackbar('Materiaal is succesvol aangemaakt!', {
          variant: 'success',
        });
        dispatch(refreshAccount(onUpload));
      })
      .catch((err) => {
        const newInvalidFiles: File[] = [];

        err.response.data.violations.forEach(
          (violation: { propertyPath: string }) => {
            const matches = violation.propertyPath.match(/files\[(.*)\]/);

            if (matches === null) {
              return;
            }

            newInvalidFiles.push(files[parseInt(matches[1], 10)]);
          },
        );

        setInvalidFiles(newInvalidFiles);
      });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="material-uploader-form"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="professional-activities-form">
        {material && material.title}
        {!material && <span>Eigen materiaal aanmaken</span>}
      </DialogTitle>
      <DialogContent>
        {!material && (
          <TextField
            value={title}
            onChange={handleTitleChange}
            label="Titel"
            fullWidth
            className={classes.margin}
          />
        )}

        <FileUpload
          onChange={handleFilesChange}
          invalidFiles={invalidFiles}
          multiple
          documents
          video
          audio
          images
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpload}
          className={classes.margin}
        >
          {material ? 'Toevoegen' : 'Aanmaken'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default MaterialUploader;
