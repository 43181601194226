import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@material-ui/core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Step, Steps, Wizard } from 'react-albus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { green, red } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import DialogCloseButton from '../../../components/DialogCloseButton';
import { Module, RegisterEvent } from '../../../types';
import Event from './Event';
import EventSelectionFeedback from './EventSelectionFeedback';
import Loader from '../../../components/Loader';
import EventRegisterRepository from './EventRegisterRepository';
import AppContext from '../../../AppContext';
import ModuleRepository from './ModuleRepository';
import { refreshAccount } from '../../../actions';

interface EventRegisterWizardProps {
  module: Module;
  open: boolean;
  onClose: (event?: object, reason?: string) => void;
}

// stores the selection of workshops for a workshop event
export type EventWorkshopSelection = {
  selectedIds: number[];
  unselectedIds: number[];
};

// stores the workshop selections of all workshop events loaded by the wizard, indexed by the event id
export type WizardWorkshopSelection = {
  [key: number]: EventWorkshopSelection;
};

const useStyles = makeStyles((theme: Theme) => ({
  successIcon: {
    color: green[300],
  },
  error: {
    color: red[300],
  },
  summationList: {
    marginTop: 0,
    paddingLeft: theme.spacing(2),
  },
}));

const EventRegisterWizard = (props: EventRegisterWizardProps) => {
  const { module, open, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedEvent, setSelectedEvent] = useState<RegisterEvent | null>(
    null,
  );
  const [workshopSelections, setWorkshopSelections] =
    useState<WizardWorkshopSelection>({});
  const [wizardLoaded, setWizardLoaded] = useState<boolean>(false);
  const [events, setEvents] = useState<RegisterEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<'availability' | 'unknown' | null>(null);
  const { appState, setAppState } = useContext(AppContext);

  const handleSelectEvent = (event: RegisterEvent) => setSelectedEvent(event);

  const handleWorkshopsUpdateEvent = (
    workshopSelection: EventWorkshopSelection,
    eventId: number,
  ) => {
    setWorkshopSelections((prevSelection) => ({
      ...prevSelection,
      [eventId]: workshopSelection,
    }));
  };

  const refreshEvent = (eventId: number) => {
    new EventRegisterRepository()
      .findEventOfModule(module.id, eventId)
      .then((response) => {
        setSelectedEvent(response.data);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  };

  const handleRegister = (push: (id?: string) => void) => {
    if (!selectedEvent) {
      return;
    }

    let workshopIds: { [key: number]: boolean } = {};
    if (selectedEvent.workshops) {
      const selections = workshopSelections[selectedEvent.eventId];

      // merge selected and unselected workshop ids into one object, indicating selection using a boolean value
      workshopIds = {
        ...Object.fromEntries(selections.selectedIds.map((id) => [id, true])),
        ...Object.fromEntries(
          selections.unselectedIds.map((id) => [id, false]),
        ),
      };
    }

    setError(null);
    push('validate');

    new ModuleRepository()
      .registerForEvent(module.id, selectedEvent.eventId, workshopIds)
      .then(() => {
        dispatch(
          refreshAccount(() => {
            push('success');
          }),
        );

        refreshEvent(selectedEvent.eventId);
      })
      .catch((err) => {
        if (err.response.data.error === 'event_unavailable') {
          setError('availability');
        } else {
          setError('unknown');
        }

        push('error');
      });
  };

  // Prevent animation from starting on initial load.
  useEffect(() => {
    if (selectedEvent) {
      setWizardLoaded(true);
    }
  }, [selectedEvent]);

  // Fetch events on open.
  useEffect(() => {
    if (!open) {
      return;
    }

    new EventRegisterRepository()
      .findAvailableEventsOfModule(module.id)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Box pr={5}>{`Uitvoering kiezen voor "${module.publishedName}"`}</Box>
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Loader inline />
          </Box>
        )}
        {!loading && (
          <Wizard
            render={({ step }) => (
              <div className={`${wizardLoaded ? 'wizard-loaded' : ''}`}>
                <TransitionGroup>
                  <CSSTransition
                    key={step.id}
                    classNames="wizard"
                    timeout={{ enter: 500, exit: 500 }}
                  >
                    <div>
                      <Steps key={step.id} step={step}>
                        <Step
                          id="select-event"
                          render={({ push }) => (
                            <Box mb={2}>
                              {events.length === 0 && (
                                <Typography variant="body1">
                                  Er zijn geen uitvoeringen gevonden.
                                  <br />
                                  Probeer het later nog eens of neem contact op
                                  met de programma assistent van de opleiding.
                                </Typography>
                              )}
                              {events.length > 0 && (
                                <>
                                  <Typography variant="body1" component="div">
                                    <ul className={classes.summationList}>
                                      <li>
                                        We adviseren je het inschrijfproces zo
                                        snel mogelijk te doorlopen omdat
                                        validatie van het aantal beschikbare
                                        plekken pas definitief gedaan wordt als
                                        je op de knop inschrijven rechtsonder
                                        geklikt hebt.
                                      </li>
                                      <li>
                                        Mogelijke conflicten met ander onderwijs
                                        worden via een waarschuwing weergegeven.
                                        Dringend advies je dan niet in te
                                        schrijven voor die uitvoering. Geen
                                        alternatieven? Neem dan contact op met
                                        de programma assistent van de opleiding.
                                      </li>
                                    </ul>
                                  </Typography>
                                  <Typography variant="body1">
                                    Er {events.length === 1 ? 'is' : 'zijn'}{' '}
                                    <strong>{events.length}</strong>{' '}
                                    {events.length === 1
                                      ? 'uitvoering'
                                      : 'uitvoeringen'}{' '}
                                    gevonden. Selecteer 1 uitvoering om je op in
                                    te schrijven.
                                  </Typography>
                                </>
                              )}
                              <Box mt={2} mb={2}>
                                {events
                                  .sort((a, b) =>
                                    new Date(a.startDate) >
                                    new Date(b.startDate)
                                      ? 1
                                      : -1,
                                  )
                                  .map((event) => (
                                    <Event
                                      key={event.eventId}
                                      event={event}
                                      selected={Boolean(
                                        selectedEvent &&
                                          selectedEvent.eventId ===
                                            event.eventId,
                                      )}
                                      onSelect={handleSelectEvent}
                                      onWorkshopsUpdate={
                                        handleWorkshopsUpdateEvent
                                      }
                                    />
                                  ))}
                              </Box>
                              {selectedEvent && (
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleRegister(push)}
                                  >
                                    Inschrijven
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          )}
                        />
                        <Step
                          id="validate"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" justifyContent="center">
                                <Loader inline />
                              </Box>
                              <Typography variant="body1" align="center">
                                De beschikbaarheid van de gekozen uitvoering
                                wordt gecontroleerd.
                                <br />
                                Een moment geduld alstublieft.
                              </Typography>
                            </Box>
                          )}
                        />
                        <Step
                          id="success"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Box mr={1}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'check-circle']}
                                    className={classes.successIcon}
                                  />
                                </Box>
                                <div>
                                  Je bent succesvol ingeschreven voor de
                                  volgende uitvoering:
                                </div>
                              </Box>
                              <Box mt={2} mb={2}>
                                {selectedEvent && (
                                  <Event
                                    event={selectedEvent}
                                    selectable={false}
                                    readOnly
                                  />
                                )}
                              </Box>
                              {selectedEvent && (
                                <EventSelectionFeedback event={selectedEvent} />
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  color="default"
                                  variant="outlined"
                                  onClick={onClose}
                                >
                                  Sluiten
                                </Button>
                              </Box>
                            </Box>
                          )}
                        />
                        <Step
                          id="error"
                          render={() => (
                            <Box mb={2}>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Box mr={1}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'exclamation-circle']}
                                    className={classes.error}
                                  />
                                </Box>
                                <div>
                                  Er is een fout opgetreden bij het inschrijven
                                  op de volgende uitvoering:
                                </div>
                              </Box>
                              <Box mt={2} mb={2}>
                                {selectedEvent && (
                                  <Event
                                    event={selectedEvent}
                                    selectable={false}
                                  />
                                )}
                              </Box>
                              {error === 'unknown' && (
                                <Box mt={2} mb={2}>
                                  Je inschrijving kan niet verwerkt worden. Neem
                                  contact op met de programma assistent van de
                                  opleiding.
                                </Box>
                              )}
                              {error === 'availability' && (
                                <Box mt={2} mb={2}>
                                  Er zijn geen plekken beschikbaar. Probeer het
                                  later nog eens of neem contact op met de
                                  programma assistent van de opleiding.
                                </Box>
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  color="default"
                                  variant="outlined"
                                  onClick={onClose}
                                >
                                  Sluiten
                                </Button>
                              </Box>
                            </Box>
                          )}
                        />
                      </Steps>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EventRegisterWizard;
