import React from 'react';

import { FormEntryField } from '../types';
import SafeHtml from '../../../components/SafeHtml';

const TextareaDisplay = (props: FormEntryField) => {
  const { value } = props;

  return (
    <div>
      <SafeHtml html={value} />
    </div>
  );
};

export default TextareaDisplay;
