import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, TextField } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { Flow as IFlow } from 'flowjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import PageContainer from '../../../components/PageContainer';
import { FaqCategory, FaqDTO } from './types';
import HtmlEditor from '../../../components/HtmlEditor';
import FaqRepository from './FaqRepository';
import { ROUTE_FAQ_MANAGEMENT } from '../index';
import Loader from '../../../components/Loader';
import FaqCategoryRepository from './FaqCategoryRepository';
import {
  DataCollection,
  File as FileInterface,
  FormDataViolation,
} from '../../../types';
import ViolationsWarning from '../../../components/form/ViolationsWarning';
import { hasViolationsForField } from '../../../utils/form';
import FileUpload from '../../../components/file/FileUpload';
import { createFlow } from '../../../utils/common';
import FlowProgress from '../../../components/file/FlowProgress';
import FileChips from '../../../components/file/FileChips';

const FaqForm = () => {
  const { id } = useParams<{ id: string }>();
  const notifications = useSnackbar();
  const history = useHistory();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [faq, setFaq] = useState<FaqDTO>({
    title: '',
    content: '',
    categoryId: '',
    attachments: [],
  });
  const [category, setCategory] = useState<FaqCategory | null>(null);
  const [categories, setCategories] = useState<FaqCategory[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<FileInterface[]>([]);
  const [violations, setViolations] = useState<FormDataViolation[]>([]);
  const faqRepository = new FaqRepository();

  const { title, content } = faq;

  const flow: IFlow = createFlow();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFaq({ ...faq, [e.target.name as string]: e.target.value });
  };

  const handleContentChange = (event: any, editor: any) => {
    setFaq({ ...faq, content: editor.getData() });
  };

  const handleCategoryChange = (event: any, option: any) => setCategory(option);

  const handleUploadChange = (files: File[]) => setFiles(files);

  const handleExistingFileDelete = (file: FileInterface) => {
    const index = existingFiles.findIndex((f) => f.id === file.id);
    existingFiles.splice(index, 1);
    setExistingFiles([...existingFiles]);
  };

  const handleSubmit = () => {
    setViolations([]);

    const data = { ...faq };

    if (category) {
      data.categoryId = category.id;
    }

    data.attachments = [...existingFiles.map((file) => file.id)];

    const doSubmit = (faq: FaqDTO) => {
      const promise = id
        ? faqRepository.update(id, faq)
        : faqRepository.create(faq);

      promise
        .then(() => {
          history.push(ROUTE_FAQ_MANAGEMENT);
          notifications.enqueueSnackbar('FAQ item is succesvol aangemaakt!', {
            variant: 'success',
          });
        })
        .catch((err) => {
          if (err.response.data.detail === 'validation_failed') {
            setViolations(err.response.data.violations);
          }

          notifications.enqueueSnackbar('Fout bij aanmaken van het FAQ item.', {
            variant: 'error',
          });
        });
    };

    if (files.length) {
      files.forEach((f) => flow.addFile(f));

      flow.on('fileSuccess', (flowFile, message) => {
        const response: FileInterface = JSON.parse(message);
        data.attachments = [...data.attachments, response.id];
      });
      flow.on('complete', () => doSubmit(data));

      flow.upload();
    } else {
      doSubmit(data);
    }
  };

  const init = async () => {
    let categoryResponse: AxiosResponse<DataCollection<FaqCategory>>;

    try {
      categoryResponse = await new FaqCategoryRepository().findBy(
        {},
        1,
        undefined,
        9999,
      );
      setCategories(categoryResponse.data.items);
    } catch (e) {
      notifications.enqueueSnackbar('Fout bij ophalen van categorieen.', {
        variant: 'error',
      });

      return;
    }

    if (!id) {
      setLoaded(true);
      return;
    }

    try {
      const response = await faqRepository.find(id);

      setFaq({
        title: response.data.title,
        content: response.data.content,
        categoryId: response.data.category.id,
        attachments: response.data.attachments.map((a) => a.id),
      });
      setExistingFiles(response.data.attachments);
      setCategory(
        categoryResponse.data.items.find(
          (c) => c.id === response.data.category.id,
        ) || null,
      );
    } catch (e) {
      notifications.enqueueSnackbar('Fout bij ophalen van FAQ item.', {
        variant: 'error',
      });

      return;
    }

    setLoaded(true);
  };

  /**
   * On form load.
   */
  useEffect(() => {
    init();
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <PageContainer title={id ? 'Bewerken' : 'Aanmaken'} marginBottom>
      <Box maxWidth={600}>
        <Paper>
          <Box p={2}>
            <Box mb={3}>
              <TextField
                label="Titel"
                name="title"
                value={title}
                error={hasViolationsForField('title', violations)}
                onChange={handleChange}
                fullWidth
                required
              />
            </Box>
            <Box mt={3} mb={3}>
              <Autocomplete
                id="faq-category"
                getOptionLabel={(option) => option.title}
                options={categories}
                onChange={handleCategoryChange}
                value={category}
                noOptionsText="Geen categorieen beschikbaar..."
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    error={hasViolationsForField('categoryId', violations)}
                    placeholder="Selecteer een categorie..."
                  />
                )}
                autoComplete
              />
            </Box>
            <Box mt={3} mb={3}>
              <HtmlEditor
                data={content}
                onChange={handleContentChange}
                error={hasViolationsForField('content', violations)}
              />
            </Box>
            <Box mt={3} mb={3}>
              {existingFiles.length > 0 && (
                <Box mb={2}>
                  <FileChips
                    files={existingFiles}
                    onDelete={handleExistingFileDelete}
                  />
                </Box>
              )}
              <FileUpload
                onChange={handleUploadChange}
                documents
                video
                audio
                images
              />
            </Box>
            {violations.length > 0 && <ViolationsWarning />}
            <FlowProgress flow={flow} />
            <Box mt={3}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
                onClick={handleSubmit}
              >
                Opslaan
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </PageContainer>
  );
};

export default FaqForm;
