import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import SubAppBar from '../../components/SubAppBar';
import { User } from '../../types';
import UserRepository from './UserRepository';
import Loader from '../../components/Loader';
import EventOverview from '../educator-events/EventOverview';
import AgendaOverview from '../agenda/AgendaOverview';
import MyParticipantsOverview from '../my-participants/MyParticipantsOverview';
import EducatorUserContext from './EducatorUserContext';

type EducatorView = 'events' | 'agenda' | 'participants';

const EducatorUserView = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<{
    educator: User | null;
    view: EducatorView;
    participantsLoaded: boolean;
    eventsLoaded: boolean;
    agendaLoaded: boolean;
  }>({
    educator: null,
    view: 'events',
    participantsLoaded: false,
    eventsLoaded: false,
    agendaLoaded: false,
  });

  const { educator, view, participantsLoaded, eventsLoaded, agendaLoaded } =
    state;

  const contextValue = useMemo(() => ({ educator }), [educator]);

  const updateView = (view: EducatorView) => {
    setState({
      ...state,
      [`${view}Loaded`]: true,
      view,
    });
  };

  const handleOpenParticipants = () => updateView('participants');
  const handleOpenEvents = () => updateView('events');
  const handleOpenAgenda = () => updateView('agenda');

  const isEducatorView = (user: User) => {
    // consider the view to be an educator view if
    // - the user we're viewing is a practical trainer or an institute educator
    // - we have permissions to view educators
    return (
      (user.roles.includes('ROLE_PRACTICAL_TRAINER') ||
        user.roles.includes('ROLE_INSTITUTE_EDUCATOR')) &&
      user.permissions.includes('VIEW_EDUCATOR')
    );
  };

  const isTeacherView = (user: User) => {
    // consider the view to be a teacher view if
    // - the user we're viewing is a teacher
    // - we have permissions to view teachers
    return (
      user.roles.includes('ROLE_TEACHER') &&
      user.permissions.includes('VIEW_TEACHER')
    );
  };

  const showMyParticipants = (user: User) => isEducatorView(user);
  const showModules = (user: User) => isTeacherView(user);
  const showAgenda = (user: User) =>
    isTeacherView(user) || isEducatorView(user);

  useEffect(() => {
    new UserRepository().find(id).then((response) => {
      const educator = response.data;

      const initialView = showMyParticipants(educator)
        ? 'participants'
        : 'events';

      setState({
        ...state,
        educator,
        view: initialView,
        [`${initialView}Loaded`]: true,
      });
    });
  }, [id]);

  if (!educator) {
    return <Loader />;
  }

  return (
    <EducatorUserContext.Provider value={contextValue}>
      <SubAppBar
        title={educator.fullNameLastNameFirst}
        previousRoute="/gebruikers"
      >
        <>
          {showMyParticipants(educator) && (
            <Button
              className={view === 'participants' ? 'active' : ''}
              onClick={handleOpenParticipants}
            >
              Deelnemers
            </Button>
          )}
          {showModules(educator) && (
            <Button
              className={view === 'events' ? 'active' : ''}
              onClick={handleOpenEvents}
            >
              Modulen
            </Button>
          )}
          {showAgenda(educator) && (
            <Button
              className={view === 'agenda' ? 'active' : ''}
              onClick={handleOpenAgenda}
            >
              Agenda
            </Button>
          )}
        </>
      </SubAppBar>
      {showMyParticipants(educator) && (
        <Box display={view === 'participants' ? 'block' : 'none'}>
          {participantsLoaded && (
            <MyParticipantsOverview educatorId={educator.id} />
          )}
          {!participantsLoaded && <Loader />}
        </Box>
      )}
      {showModules(educator) && (
        <Box display={view === 'events' ? 'block' : 'none'}>
          {eventsLoaded && <EventOverview educatorId={educator.id} />}
          {!eventsLoaded && <Loader />}
        </Box>
      )}
      {showAgenda(educator) && (
        <Box display={view === 'agenda' ? 'block' : 'none'}>
          {agendaLoaded && (
            <AgendaOverview
              userId={educator.id}
              breadcrumbs={false}
              forceView="educator"
            />
          )}
          {!agendaLoaded && <Loader />}
        </Box>
      )}
    </EducatorUserContext.Provider>
  );
};

export default EducatorUserView;
