import React from 'react';

import { Route, Router } from '../../routing';
import MyParticipantsOverview from './MyParticipantsOverview';
import EducationEducatorView from '../education/EducationEducatorView';

const MY_PARTICIPANTS_ROUTE = '/mijn-deelnemers';

const myParticipants = new Route(
  'Mijn deelnemers',
  MY_PARTICIPANTS_ROUTE,
  <MyParticipantsOverview />,
)
  .requireLogin()
  .role([
    'ROLE_PRACTICAL_TRAINER',
    'ROLE_INSTITUTE_EDUCATOR',
    'ROLE_PROGRAM_ASSISTANT',
  ]);
Router.addRoute(myParticipants);

const viewParticipant = new Route(
  'Deelnemer',
  '/:id',
  (
    <EducationEducatorView
      previousRoute={MY_PARTICIPANTS_ROUTE}
      showCertificates={false}
    />
  ),
).requireLogin();
myParticipants.addChild(viewParticipant);
