import React from 'react';

import { Route, Router } from '../../routing';
import EducationMoodleLoader from './EducationMoodleLoader';
import EducationOverview from './EducationOverview';
import ELearningOverview from './ELearningOverview';
import MissedEducationMoodleLoader from './MissedEducationMoodleLoader';
import ModuleOverview from './module/ModuleOverview';
import AssignmentView from './assignment/AssignmentView';
import AssignmentFormView from './assignment/AssignmentFormView';
import FreeELearningsMoodleLoader from './FreeELearningsMoodleLoader';

const education = new Route(
  'Onderwijs',
  '/',
  <EducationOverview />,
).requireLogin();
Router.addRoute(education);

const freeELearning = new Route(
  'Gratis E-learning',
  '/e-learnings',
  <ELearningOverview />,
).requireLogin();
Router.addRoute(freeELearning);

const modules = new Route(
  'Onderwijs',
  '/onderwijs/:id',
  <ModuleOverview />,
).requireLogin();
Router.addRoute(modules);

const assignmentForm = new Route(
  'Opdracht',
  '/onderwijs/opdracht/:id',
  <AssignmentFormView />,
);
Router.addRoute(assignmentForm);

const assignmentView = new Route(
  'Opdracht',
  '/onderwijs/opdracht/:id/bekijken',
  <AssignmentView />,
).requireLogin();
Router.addRoute(assignmentView);

const moodleForEducation = new Route(
  'Leermateriaal',
  '/onderwijs/:educationId/leermateriaal',
  <EducationMoodleLoader />,
).requireLogin();
Router.addRoute(moodleForEducation);

const moodleForEducationAndEvent = new Route(
  'Leermateriaal',
  '/onderwijs/:educationId/evenement/:eventId/leermateriaal',
  <EducationMoodleLoader />,
).requireLogin();
Router.addRoute(moodleForEducationAndEvent);

const moodleForMissedEducation = new Route(
  'Leermateriaal',
  '/onderwijs/:educationId/inhaal-evenement/:eventId/leermateriaal',
  <MissedEducationMoodleLoader />,
).requireLogin();
Router.addRoute(moodleForMissedEducation);

const moodleForFreeELearnings = new Route(
  'Leermateriaal',
  '/e-learnings/moodle',
  <FreeELearningsMoodleLoader />,
).requireLogin();
Router.addRoute(moodleForFreeELearnings);
