import { createContext, Dispatch, SetStateAction } from 'react';
import { Education } from '../../types';
import { EducatorEducationView } from './EducationEducatorView';

const EducationEducatorContext = createContext<{
  educatorEducation: Education | null;
  setEducatorEducation: Dispatch<SetStateAction<Education | null>> | null;
  educatorView: EducatorEducationView | null;
  setEducatorView: Dispatch<SetStateAction<EducatorEducationView>> | null;
}>({
  educatorEducation: null,
  setEducatorEducation: null,
  educatorView: null,
  setEducatorView: null,
});

export default EducationEducatorContext;
