import React, { useEffect, useState } from 'react';
import validator from 'validator';
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EmailListBuilderProps {
  addresses: string[];
  className?: string;
  onChange?: ((addresses: string[]) => void) | null;
  children?: React.ReactNode | React.ReactNode[];
  elevation?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
  input: {
    minWidth: 300,
  },
}));

const EmailListBuilder = (props: EmailListBuilderProps) => {
  const { className = '', onChange = null, elevation = 0, children } = props;
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<string[]>(props.addresses);

  const addEmail = () => {
    if (addresses.includes(email) || !validator.isEmail(email)) {
      setIsValid(false);
      return;
    }

    setAddresses([...addresses, email]);
    setEmail('');
  };

  const removeAddress = (index: number) => {
    addresses.splice(index, 1);
    setAddresses([...addresses]);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsValid(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && email.length > 0) {
      addEmail();
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(addresses);
    }
  }, [addresses]);

  useEffect(() => {
    setAddresses(props.addresses);
  }, [props.addresses]);

  return (
    <Paper className={className} elevation={elevation}>
      {children}
      {addresses.length > 0 && (
        <Grid container spacing={1} className={classes.grid}>
          {addresses.map((address, index) => (
            <Grid item>
              <Chip
                label={address}
                color="primary"
                icon={<FontAwesomeIcon icon={['fal', 'envelope']} />}
                onDelete={() => removeAddress(index)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <TextField
        value={email}
        onChange={handleEmailChange}
        size="medium"
        type="email"
        onKeyPress={handleKeyPress}
        error={!isValid}
        placeholder="Vul een e-mailadres in..."
        InputProps={{
          endAdornment: (
            <IconButton onClick={addEmail} size="small">
              <FontAwesomeIcon icon={['fal', 'plus']} />
            </IconButton>
          ),
        }}
        className={classes.input}
      />
    </Paper>
  );
};

export default EmailListBuilder;
