import { AxiosResponse } from 'axios';

import { ApiKey, CollectionResponse, Repository } from '../../types';
import ApiClient from '../../api/ApiClient';

class ApiKeyRepository implements Repository<ApiKey> {
  findBy(): Promise<CollectionResponse<ApiKey>> {
    return ApiClient.get('/api/api-keys');
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/api-keys/${id}`);
  }

  create(name: string, roles: string[]) {
    return ApiClient.post('/api/api-keys', {
      name,
      roles,
      enabled: true,
    });
  }

  update(apiKey: ApiKey) {
    return ApiClient.post(`/api/api-keys/${apiKey.id}`, { ...apiKey });
  }
}

export default ApiKeyRepository;
