import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import Popover from '@material-ui/core/Popover';
import AppContext from '../../AppContext';
import { UserState } from '../../reducers/user';
import { ScheduleItem } from '../../types';
import ParticipantsDialog from './ParticipantsDialog';
import TeachersPopover from './TeachersPopover';
import ZoomMeeting from '../../components/ZoomMeeting';
import ZoomInfo from '../../components/ZoomInfo';
import ModuleRepository from '../education/module/ModuleRepository';
import EducationEducatorContext from '../education/EducationEducatorContext';
import EducatorUserContext from '../users/EducatorUserContext';

interface ScheduleRowProps {
  item: ScheduleItem;
  sameDate: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tr: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    '& td': {
      color: theme.palette.common.black,
    },
  },
  trSpacer: {
    height: '20px',
  },
  noWhitespaceWrap: {
    whiteSpace: 'nowrap',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  sessionName: {
    wordBreak: 'break-word',
  },
}));

const ScheduleRow = (props: ScheduleRowProps) => {
  const classes = useStyles();
  const history = useHistory();
  const notifications = useSnackbar();
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));
  const { sameDate } = props;
  const {
    start,
    end,
    event,
    sessionName,
    eventId,
    locationDescription,
    meetingId,
    meetingPassword,
    street,
    number,
    postalCode,
    city,
    allDay,
    sessionId,
    moduleId,
    moodleCourseId,
  } = props.item;

  const [participantsDialogOpen, setParticipantsDialogOpen] =
    useState<boolean>(false);
  const { roleViewManager } = useContext(AppContext);
  const { educatorEducation } = useContext(EducationEducatorContext);
  const { educator: educatorUser } = useContext(EducatorUserContext);

  const handleOpenParticipantsDialog = () => setParticipantsDialogOpen(true);
  const handleCloseParticipantsDialog = () => setParticipantsDialogOpen(false);

  let addressUrl;

  if (street && number && postalCode && city) {
    const addressUri = encodeURIComponent(
      `${street} ${number} ${postalCode} ${city}`,
    );
    addressUrl = `https://maps.google.com?daddr=${addressUri}`;
  }

  const handleOpenEducation = () => {
    if (!moduleId) {
      return;
    }

    new ModuleRepository()
      .findByModuleId(moduleId)
      .then((response) => {
        if (response.data.education) {
          history.push(`/onderwijs/${response.data.education.id}`);
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het ophalen van het onderwijs.',
          { variant: 'error' },
        );
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moodleUrl = useMemo(() => {
    if (!account) {
      return null;
    }

    let userId;
    if (educatorEducation?.id) {
      // case where someone (e.g. an admin) is viewing someone else's agenda
      userId = educatorEducation.user.id;
    } else if (educatorUser?.id) {
      // case where an admin is viewing an educator
      userId = educatorUser.id;
    } else {
      userId = account.id;
    }

    return `/agenda/user/${userId}/evenement/${eventId}/leermateriaal`;
  }, [educatorEducation, educatorUser, account, eventId]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment key={`${start}-${eventId}`}>
      <ParticipantsDialog
        open={participantsDialogOpen}
        onClose={handleCloseParticipantsDialog}
        scheduleItem={props.item}
      />

      {!sameDate && <tr className={classes.trSpacer} />}
      <TableRow classes={{ root: classes.tr }}>
        <TableCell className={classes.noWhitespaceWrap}>
          {moment(start).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell className={classes.noWhitespaceWrap}>
          {!allDay && (
            <>
              {moment(start).format('HH:mm')}
              {' - '}
              {moment(end).format('HH:mm')}
            </>
          )}
          {allDay && <Chip label="Deadline" color="secondary" />}

          {roleViewManager.isParticipantView() && sessionId > 0 && (
            <TeachersPopover scheduleItem={props.item} />
          )}
        </TableCell>
        <TableCell>{event}</TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" style={{ maxWidth: 400 }}>
            {!allDay && (
              <FontAwesomeIcon
                className={classes.secondaryColor}
                icon={['fal', 'calendar-alt']}
              />
            )}
            {allDay && (
              <FontAwesomeIcon
                className={classes.primaryColor}
                icon={['fal', 'info-circle']}
              />
            )}
            <Box pl={1} className={classes.sessionName}>
              {sessionName}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {locationDescription ? (
              <>
                <FontAwesomeIcon
                  className={classes.secondaryColor}
                  icon={['fas', 'map-marker-alt']}
                />
                <Box pl={1} display="inline-block">
                  {locationDescription}
                </Box>
              </>
            ) : (
              <span>-</span>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="flex-end">
            {educatorEducation == null &&
              educatorUser == null &&
              roleViewManager.isEducatorView() && (
                <Tooltip title="Deelnemers bekijken">
                  <IconButton onClick={handleOpenParticipantsDialog}>
                    <FontAwesomeIcon size="sm" icon={['fal', 'user']} />
                  </IconButton>
                </Tooltip>
              )}
            {addressUrl && (
              <Tooltip title="Openen in Google Maps">
                <IconButton
                  href={addressUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
                </IconButton>
              </Tooltip>
            )}
            {meetingId && meetingPassword && (
              <Box>
                <Tooltip title="Zoom opties">
                  <IconButton onClick={handleClick}>
                    <FontAwesomeIcon icon={['fal', 'video']} />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <ZoomMeeting
                    meetingId={meetingId}
                    password={meetingPassword}
                  />
                  <ZoomInfo meetingId={meetingId} password={meetingPassword} />
                </Popover>
              </Box>
            )}
            {roleViewManager.isParticipantView() && moduleId && (
              <Tooltip title="Opleidingsgegevens">
                <IconButton onClick={handleOpenEducation}>
                  <FontAwesomeIcon size="sm" icon={['fal', 'graduation-cap']} />
                </IconButton>
              </Tooltip>
            )}
            {moodleUrl && (
              <Tooltip title="Leermateriaal">
                <IconButton
                  component={Link}
                  to={moodleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!moodleCourseId}
                >
                  <FontAwesomeIcon size="sm" icon={['fal', 'books']} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ScheduleRow;
