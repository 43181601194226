import { AxiosResponse, CancelTokenSource } from 'axios';
import {
  ApiFilterCriteria,
  Assignment,
  CollectionResponse,
  EvaluationOption,
  Repository,
  User,
} from '../../types';
import ApiClient from '../../api/ApiClient';
import { FieldState } from '../yard-forms/fields/FieldProps';
import { FilterColumnOption } from '../../components/FilterColumn';
import { parseCriteria } from '../../utils/common';

export interface EducationPointsSummary {
  label: string;
  total: number;
  achieved: number;
  notYetAchieved: number;
  remaining: number;
  percentage: number;
}

class EvaluationRepository implements Repository<Assignment> {
  find(id: string): Promise<AxiosResponse<Assignment>> {
    return ApiClient.get(`/api/educators/assignments/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
  ): Promise<CollectionResponse<Assignment>> {
    const params = parseCriteria(criteria, { page: page || 1 });
    return ApiClient.get(
      '/api/educators/assignments',
      params,
      {},
      { cancelToken: cancelToken?.token },
    );
  }

  getOptions(): Promise<AxiosResponse<EvaluationOption[]>> {
    return ApiClient.get('/api/educators/evaluation-options');
  }

  getModules(query: string): Promise<AxiosResponse<FilterColumnOption[]>> {
    return ApiClient.get('/api/educators/evaluation-modules', { query });
  }

  getParticipants(query: string): Promise<AxiosResponse<FilterColumnOption[]>> {
    return ApiClient.get('/api/educators/evaluation-participants', { query });
  }

  getEvaluators(): Promise<AxiosResponse<User[]>> {
    return ApiClient.get('/api/educators/evaluation-evaluators');
  }

  evaluate(
    assignment: Assignment,
    valuation: string | null = null,
    grade: number | null = null,
    formData?: { [key: string]: FieldState },
  ) {
    return ApiClient.post(`/api/educators/assignments/${assignment.id}`, {
      formData,
      valuation,
      grade,
    });
  }

  reject(assignment: Assignment, formData?: { [key: string]: FieldState }) {
    return ApiClient.post(
      `/api/educators/assignments/${assignment.id}/rejected`,
      { formData },
    );
  }

  deleteEvaluation(assignment: Assignment): Promise<AxiosResponse<Assignment>> {
    return ApiClient.delete(
      `/api/educators/assignments/${assignment.id}/evaluations`,
    );
  }

  pointsSummaries(
    assignment: Assignment,
  ): Promise<AxiosResponse<EducationPointsSummary[]>> {
    return ApiClient.get(
      `/api/educators/assignments/${assignment.id}/education-points`,
    );
  }
}

export default EvaluationRepository;
