import React, { useContext, useMemo } from 'react';

import { Box, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { colors } from '../../../config/theme';
import { PresenceItem } from '../../../types';
import { getPresenceItemMessage } from '../common';
import EducationContext from '../EducationContext';
import columnConfig from '../columnConfig';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    background: colors.primary.ultraLight,
    borderBottom: `1px solid ${colors.primary.ultraLight}`,
    paddingTop: theme.spacing(1),
    '&:first-child': {
      paddingTop: theme.spacing(0),
    },
  },
  content: {
    display: 'flex',
    width: '100%',
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'top',
    fontSize: 14,
    width: '100%',
    maxWidth: `calc(100%
                      - 20%
                      - ${columnConfig.assignmentsColumn.minWidth}px
                      - ${columnConfig.statusColumn.width}px
                      + 16px
               )`,
    padding: theme.spacing(1),
    wordBreak: 'break-word',
  },
  statusColumn: {
    width:
      columnConfig.statusColumn.width + columnConfig.assignmentsColumn.minWidth,
    paddingTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  moodle: {
    marginRight: theme.spacing(2.75),
    marginLeft: 'auto',
    padding: theme.spacing(1),
  },
}));

interface AssignmentRowProps {
  presenceItem: PresenceItem;
}

const PresenceItemRow = (props: AssignmentRowProps) => {
  const { presenceItem } = props;

  const classes = useStyles();

  const { education } = useContext(EducationContext);

  const moodleUrl = useMemo(() => {
    if (!education?.id || !presenceItem.catchingUpEventId) {
      return null;
    }

    return `/onderwijs/${education.id}/inhaal-evenement/${presenceItem.catchingUpEventId}/leermateriaal`;
  }, [presenceItem.catchingUpEventId, education]);

  const message = useMemo(
    () => getPresenceItemMessage(presenceItem),
    [presenceItem],
  );

  return (
    <Box display="flex" justifyContent="space-between" className={classes.row}>
      <Box className={classes.content}>
        <div className={classes.nameColumn}>
          <FontAwesomeIcon
            icon={['fal', 'history']}
            style={{
              width: 20,
              height: 20,
            }}
          />
          <Box ml={1} mr={8}>
            {message}
          </Box>
        </div>
        <div className={classes.statusColumn}>
          <FontAwesomeIcon
            icon={
              presenceItem.compensationProcessedAt ? 'flag' : ['fal', 'flag']
            }
            className={classes.icon}
          />
        </div>
        <div className={classes.moodle}>
          {moodleUrl && (
            <IconButton
              component={Link}
              to={moodleUrl}
              size="small"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fal', 'books']} />
            </IconButton>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default PresenceItemRow;
