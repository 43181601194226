import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@material-ui/core';

import StyledAccordion from '../../../components/StyledAccordion';
import { Assignment, Module } from '../../../types';
import ProductPanelAssignments from './ProductPanelAssignments';
import { ModuleType } from '../module/ModuleTypes';

interface AssignmentGroup {
  module: Module;
  assignments: Assignment[];
}

interface ProductPanelProps {
  type: ModuleType;
  name: string;
  assignments: Assignment[];
  selectable?: boolean;
  summaryClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  nameColumn: {
    width: '100%',
  },
  assignmentGroup: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

const ProductPanel = (props: ProductPanelProps) => {
  const { type, name, assignments, selectable, summaryClassName } = props;
  const classes = useStyles();

  let panelContents: JSX.Element;
  // group assignments per module for certain module types
  if (['KO', 'IO'].includes(type)) {
    const assignmentGroups: { [key: string]: AssignmentGroup } = {};

    assignments.forEach((assignment: Assignment) => {
      // ignore the case where the assignment doesn't have a module, which should not happen
      if (assignment.module === undefined) {
        return;
      }

      // extract the module from the assignment
      const { module } = assignment;

      if (assignmentGroups[module.id] === undefined) {
        assignmentGroups[module.id] = {
          module,
          assignments: [],
        };
      }

      assignmentGroups[module.id].assignments.push(assignment);
    });

    panelContents = (
      <>
        {Object.values(assignmentGroups).map(
          (assignmentGroup: AssignmentGroup) => (
            <Box
              key={assignmentGroup.module.id}
              className={classes.assignmentGroup}
            >
              <Box mb={1}>{assignmentGroup.module.publishedName}</Box>
              <ProductPanelAssignments
                assignments={assignmentGroup.assignments}
                selectable={selectable}
              />
            </Box>
          ),
        )}
      </>
    );
  } else {
    panelContents = (
      <ProductPanelAssignments
        assignments={assignments}
        selectable={selectable}
      />
    );
  }

  const summary = <div className={classes.nameColumn}>{name}</div>;

  return (
    <StyledAccordion
      id="product-panel-content"
      summary={summary}
      summaryClassName={summaryClassName || ''}
      chevron
      expandable={assignments.length > 0}
    >
      <Box>{panelContents}</Box>
    </StyledAccordion>
  );
};

export default ProductPanel;
