import React from 'react';

import { FormEntryField } from '../types';
import ChoiceList from '../fields/ChoiceList';

const ChoiceListEntryDisplay = (props: FormEntryField) => {
  const { field } = props;

  return (
    <ChoiceList
      id={field.id}
      label={field.label}
      description={field.description}
      metadata={field.metadata}
      options={field.options || []}
      entryField={props}
      disabled
    />
  );
};

export default ChoiceListEntryDisplay;
