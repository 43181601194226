import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

import { EditorProps } from './EditorProps';
import HtmlEditor from '../../../components/HtmlEditor';

const TextareaEditor = (props: EditorProps) => {
  const { field, setPreview, onChange } = props;
  const [placeholder, setPlaceholder] = useState<string>(
    field.metadata.placeholder || '',
  );

  const handlePlaceholderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPlaceholder(event.target.value);
  };

  useEffect(() => {
    field.metadata = { ...field.metadata, placeholder };
    onChange(field);

    setPreview(
      <div>
        <HtmlEditor placeholder={placeholder} disabled />
      </div>,
    );
  }, [field, placeholder]);

  return (
    <div>
      <TextField
        label="Placeholder"
        value={placeholder}
        onChange={handlePlaceholderChange}
      />
    </div>
  );
};

export default TextareaEditor;
