import React, { useState } from 'react';

import { FormEntryField, ProductFormEntryField } from '../types';
import ProductChips from '../../education/portfolio/ProductChips';
import { File as FileInterface } from '../../../types';

const ProductEntryDisplay = (props: FormEntryField) => {
  const { assignments } = props as ProductFormEntryField;
  const [files, setFiles] = useState<FileInterface[]>(
    (props as ProductFormEntryField).files,
  );

  const handleFileChange = (file: FileInterface) => {
    const index = files.findIndex((f) => f.id === file.id);
    files[index] = file;
    setFiles([...files]);
  };

  return (
    <ProductChips
      assignments={assignments}
      files={files}
      onFileChange={handleFileChange}
    />
  );
};

export default ProductEntryDisplay;
