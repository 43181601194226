import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ZoomMeetingProps {
  meetingId: number;
  password: string;
  icon?: boolean;
}

const ZoomMeeting = (props: ZoomMeetingProps) => {
  const { meetingId, password, icon } = props;
  const href = `zoommtg://zoom.us/join?confno=${meetingId}&pwd=${password}`;

  if (icon) {
    return (
      <Tooltip title="Zoom meeting starten">
        <IconButton href={href}>
          <FontAwesomeIcon icon={['fal', 'video']} size="sm" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button
      href={href}
      startIcon={<FontAwesomeIcon icon={['fal', 'video']} />}
      color="secondary"
      variant="contained"
    >
      Zoom starten
    </Button>
  );
};

export default ZoomMeeting;
