import { AxiosResponse, CancelTokenSource } from 'axios';

import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import { FaqCategory, FaqCategoryDTO } from './types';
import { parseCriteria } from '../../../utils/common';
import ApiClient from '../../../api/ApiClient';

class FaqCategoryRepository implements Repository<FaqCategory> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
    perPage?: number,
  ): Promise<CollectionResponse<FaqCategory>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });
    return ApiClient.get('/api/faqs/categories', params);
  }

  create(category: FaqCategoryDTO): Promise<AxiosResponse<FaqCategory>> {
    return ApiClient.post('/api/faqs/categories', category);
  }

  update(
    id: string,
    category: FaqCategoryDTO,
  ): Promise<AxiosResponse<FaqCategory>> {
    return ApiClient.post(`/api/faqs/categories/${id}`, category);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/faqs/categories/${id}`);
  }

  move(id: string, direction: 'up' | 'down'): Promise<AxiosResponse> {
    return ApiClient.post(`/api/faqs/categories/${id}/move`, { direction });
  }
}

export default FaqCategoryRepository;
