import React from 'react';

import { Router, Route } from '../../routing';
import UsersOverview from './UsersOverview';
import EditUser from './EditUser';
import MyAccount from './MyAccount';
import ViewUser from './ViewUser';
import ForgotPassword from './ForgotPassword';
import UserActivation from './UserActivation';
import EducationEducatorView from '../education/EducationEducatorView';
import EducatorUserView from './EducatorUserView';
import Reset2FAMethod from './Reset2FAMethod';

const users = new Route(
  'Gebruikers',
  '/gebruikers',
  <UsersOverview />,
).permission('LIST_USERS');
users.addChild(new Route('Gebruiker bekijken', '/:id', <ViewUser />));
users.addChild(new Route('Gebruiker bewerken', '/:id/bewerken', <EditUser />));
users.addChild(
  new Route(
    'Opleiding',
    '/onderwijs/:id',
    <EducationEducatorView previousRoute="/gebruikers" showCertificates />,
  ),
);
users.addChild(new Route('Opleider', '/opleider/:id', <EducatorUserView />));
Router.addRoute(users);

const account = new Route('Profiel', '/account', <MyAccount />).requireLogin();
Router.addRoute(account);

Router.addRoute(
  new Route(
    'Wachtwoord vergeten',
    '/wachtwoord-vergeten',
    <ForgotPassword />,
  ).anonymous(),
);

Router.addRoute(
  new Route(
    'Verificatiecode wijzigen',
    '/verificatiemethode-wijzigen',
    <Reset2FAMethod />,
  ).anonymous(),
);

Router.addRoute(
  new Route('Account activeren', '/activeren', <UserActivation />).anonymous(),
);
