import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Education, EducationModule } from '../../../types';
import { colors } from '../../../config/theme';
import PieChart from '../../../components/data-visualization/PieChart';
import EducationRepository from '../EducationRepository';
import WidgetColorLabel from './WidgetColorLabel';

interface FinishedModulesWidgetProps {
  education: Education;
}

const useStyles = makeStyles(() => ({
  percentage: {
    fontSize: 16,
    fontWeight: 700,
  },
  indicatorFinished: {
    background: colors.coldPurple,
  },
  indicatorTodo: {
    background: colors.botticelli,
  },
}));

const FinishedModulesWidget = (props: FinishedModulesWidgetProps) => {
  const { education } = props;
  const classes = useStyles();

  const [modules, setModules] = useState<EducationModule[] | undefined>(
    undefined,
  );

  const finishedModulesCount = modules
    ? modules.filter((m) => m.finished).length
    : 0;
  const todoModulesCount = modules ? modules.length - finishedModulesCount : 0;
  const percentage =
    modules && modules.length > 0
      ? Math.round((finishedModulesCount / modules.length) * 100)
      : 0;

  useEffect(() => {
    const widget = document.querySelector('#finished-modules-widget');
    if (widget) {
      widget.childNodes.forEach((n) => n.remove());
    }

    new PieChart('#finished-modules-widget', 100, 100)
      .text(false)
      .colors([colors.coldPurple, colors.botticelli])
      .innerRadius(100 / 2 - 8)
      .plot(
        [
          { label: 'Finished', amount: finishedModulesCount },
          { label: 'Todo', amount: todoModulesCount },
        ],
        'label',
        'amount',
      );
  }, [finishedModulesCount, todoModulesCount]);

  useEffect(() => {
    new EducationRepository()
      .getModules(education.id, ['IO'])
      .then((response) => setModules(response.data as EducationModule[]));
  }, [education.id]);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      <Box mb={2} width="100%" textAlign="center">
        <div className={classes.percentage}>{`${percentage}%`}</div>
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        <div id="finished-modules-widget" style={{ position: 'relative' }} />
      </Box>
      <Box mt={2} mb={2}>
        <WidgetColorLabel
          label={`${finishedModulesCount} modules behaald`}
          color={colors.coldPurple}
        />
        <WidgetColorLabel
          label={`${todoModulesCount} modules behalen`}
          color={colors.botticelli}
        />
      </Box>
    </Box>
  );
};

export default FinishedModulesWidget;
