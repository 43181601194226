import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { CompleteUser } from '../../types';
import UserRepository from './UserRepository';
import Roles, { RoleInterface } from './Roles';

const UserRolesForm = (props: { user: CompleteUser }) => {
  const repository = new UserRepository();

  const { user: initialUser } = props;
  const [user, setUser] = useState<CompleteUser>(initialUser);

  const notifications = useSnackbar();

  const onToggleRole = (role: keyof RoleInterface) => {
    if (!user) return;

    let { roles } = user;

    // Update the GUI immediately, but revert if the API call returns an error
    const oldRoles = [...roles];

    if (roles.includes(role)) {
      roles = user.roles.filter((r: string) => r !== role);
    } else {
      roles.push(role);
    }

    setUser({ ...user, roles });

    repository.updateUserRoles(user.id!, { roles }).catch(() => {
      setUser({ ...user, roles: oldRoles });

      notifications.enqueueSnackbar(
        'Er is een fout opgetreden, de wijziging is niet opgeslagen!',
        { variant: 'error' },
      );
    });
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {Object.entries(Roles).map(([role, label]) => {
          const control = (
            <Switch
              checked={user.roles.includes(role as keyof RoleInterface)}
              onChange={() => onToggleRole(role as keyof RoleInterface)}
              color="primary"
            />
          );

          return (
            <FormControlLabel key={role} control={control} label={label} />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default UserRolesForm;
