import React, { useContext } from 'react';
import { Box, Chip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../../AppContext';
import { Assignment } from '../../../types';
import { useStyles } from '../../../components/file/FileChip';

interface AssignmentChipProps {
  assignment: Assignment;
  onClick?: () => void;
  onDelete?: (file: Assignment) => void;
}

const AssignmentChip = (props: AssignmentChipProps) => {
  const { assignment, onClick, onDelete } = props;
  const { roleViewManager } = useContext(AppContext);
  const classes = useStyles();

  const handleDelete = () => {
    if (onDelete) {
      onDelete(assignment);
    }
  };

  const ownsAssignment =
    assignment.user && assignment.user.id === roleViewManager.getUser().id;

  let deleteCallback;

  if (ownsAssignment && onDelete) {
    deleteCallback = handleDelete;
  }

  const deleteIcon = (
    <Box
      ml={2}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      {ownsAssignment ? (
        <FontAwesomeIcon icon={['fal', 'times']} />
      ) : (
        <FontAwesomeIcon icon={['fal', 'check']} className={classes.icon} />
      )}
    </Box>
  );

  return (
    <Chip
      label={assignment.name}
      color="primary"
      icon={<FontAwesomeIcon icon={['fal', 'clipboard-list-check']} />}
      onClick={onClick}
      onDelete={deleteCallback}
      deleteIcon={deleteIcon}
    />
  );
};

export default AssignmentChip;
