import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import AssignmentForm from './AssignmentForm';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const AssignmentFormView = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AssignmentForm id={id} />
    </div>
  );
};

export default AssignmentFormView;
