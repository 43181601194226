import React, { useContext, useEffect } from 'react';

import { Typography } from '@material-ui/core';

import AppContext from '../AppContext';
import { RoleView } from '../RoleViewManager';
import ApiClient from '../api/ApiClient';
import Loader from './Loader';

interface MoodleLoaderProps {
  endpoint: string;
  params?: {
    eventId?: string;
    requestContext: RoleView;
  };
}

const MoodleLoader = (props: MoodleLoaderProps) => {
  const { appState, setAppState } = useContext(AppContext);

  useEffect(() => {
    ApiClient.get(props.endpoint, props.params, {
      Accept: 'application/json',
    })
      .then((response) => {
        const { loginUrl } = response.data;
        if (loginUrl) {
          window.location.href = loginUrl;
        }
      })
      .catch((err) => {
        if (err.response && setAppState && appState) {
          setAppState({
            ...appState,
            errorStatusCode: err.response.status,
            errorDetails: err.response.data.message && (
              <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                {err.response.data.message}
              </Typography>
            ),
          });
        }
      });
  }, []);

  return <Loader />;
};

export default MoodleLoader;
