import { FormDataViolation } from '../types';

// eslint-disable-next-line import/prefer-default-export
export function hasViolationsForField(
  fieldId: string,
  violations: FormDataViolation[],
): boolean {
  return (
    violations.find((violation) => violation.propertyPath === fieldId) !==
    undefined
  );
}
