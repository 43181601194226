import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBrowser,
  faSave,
  faUsers,
  faSort,
  faSortUp,
  faSortDown,
  faEraser,
  faHandsHelping,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faPlus,
  faEdit,
  faTrash,
  faSync,
  faSearch,
  faUser,
  faUserCircle,
  faCamera,
  faGhost,
  faFilter,
  faCheck,
  faEye,
  faEyeSlash,
  faTimes,
  faImage,
  faCircle,
  faEnvelope,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faBook,
  faBooks,
  faCalendarAlt,
  faFlag as faFlagLight,
  faInfoCircle,
  faMinus,
  faGripVertical,
  faPollH,
  faBallotCheck,
  faFileAlt,
  faText,
  faCloudDownload,
  faGraduationCap as faGraduationCapLight,
  faPaperPlane,
  faPlusCircle,
  faMinusCircle,
  faClipboardListCheck,
  faFileWord,
  faExclamationCircle,
  faFile,
  faFilePdf,
  faFileExcel,
  faFileAudio,
  faFilePowerpoint,
  faFileVideo,
  faStickyNote,
  faAbacus,
  faMoneyCheckEdit,
  faMoneyBillWave,
  faRedo,
  faClipboard,
  faAlignLeft,
  faTools,
  faBan,
  faLayerGroup,
  faFileImport,
  faFileExport,
  faFingerprint,
  faSignature,
  faCopy,
  faCog,
  faCodeMerge,
  faCheckCircle,
  faPauseCircle,
  faUserGraduate,
  faCar,
  faTrain,
  faWalking,
  faBicycle,
  faVideo,
  faMapMarkerAlt as faMapMarkerAltLight,
  faHeading,
  faFileCsv,
  faUserCheck,
  faAward,
  faBug,
  faStoreAlt,
  faHourglassHalf,
  faUndo,
  faPencil,
  faGlasses,
  faComments,
  faChalkboardTeacher,
  faHistory,
  faThList,
} from '@fortawesome/pro-light-svg-icons';
import {
  faGraduationCap,
  faFlag,
  faAward as faAwardSolid,
  faMapMarkerAlt,
  faChevronRight as faChevronRightSolid,
  faArrowLeft,
  faArrowRight,
  faCircle as faCircleSolid,
  faInfo,
  faBell,
  faMinus as faMinusSolid,
  faTrash as faTrashSolid,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faBars,
  faBrowser,
  faPlus,
  faSave,
  faEdit,
  faImage,
  faTrash,
  faTrashSolid,
  faSync,
  faSearch,
  faUser,
  faUsers,
  faInfo,
  faUserCircle,
  faCamera,
  faGhost,
  faFilter,
  faCheck,
  faEye,
  faEyeSlash,
  faTimes,
  faCircle,
  faEnvelope,
  faGraduationCap,
  faGraduationCapLight,
  faChevronRight,
  faChevronDown,
  faChevronRightSolid,
  faBook,
  faBooks,
  faCalendarAlt,
  faFlag,
  faAward,
  faFlagLight,
  faInfoCircle,
  faGripVertical,
  faMinus,
  faPollH,
  faBallotCheck,
  faText,
  faSort,
  faSortUp,
  faSortDown,
  faFileAlt,
  faCloudDownload,
  faMapMarkerAlt,
  faPaperPlane,
  faPlusCircle,
  faMinusCircle,
  faClipboardListCheck,
  faFileWord,
  faExclamationCircle,
  faFile,
  faFilePdf,
  faFileExcel,
  faFileAudio,
  faFilePowerpoint,
  faFileVideo,
  faStickyNote,
  faArrowLeft,
  faAbacus,
  faMoneyCheckEdit,
  faMoneyBillWave,
  faRedo,
  faClipboard,
  faAlignLeft,
  faTools,
  faCircleSolid,
  faBan,
  faLayerGroup,
  faFileImport,
  faFileExport,
  faFingerprint,
  faSignature,
  faEraser,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faBell,
  faCopy,
  faCodeMerge,
  faArrowRight,
  faCheckCircle,
  faPauseCircle,
  faUserGraduate,
  faCar,
  faTrain,
  faWalking,
  faBicycle,
  faVideo,
  faMinusSolid,
  faMapMarkerAltLight,
  faHandsHelping,
  faHeading,
  faFileCsv,
  faUserCheck,
  faUpload,
  faAwardSolid,
  faCog,
  faBug,
  faChevronUp,
  faStoreAlt,
  faHourglassHalf,
  faUndo,
  faPencil,
  faGlasses,
  faComments,
  faChalkboardTeacher,
  faHistory,
  faThList,
);
