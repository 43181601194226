import React from 'react';
import { Box, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ZoomMeeting from '../../../components/ZoomMeeting';
import GoogleMapsDirections from '../../../components/GoogleMapsDirections';
import { ScheduleItem } from '../../../types';
import ZoomInfo from '../../../components/ZoomInfo';

interface EventPopoverProps {
  event: ScheduleItem;
  anchorEl: Element | null;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 16,
  },
}));

const EventPopover = (props: EventPopoverProps) => {
  const classes = useStyles();
  const { event, anchorEl, onClose } = props;

  return (
    <Popover
      id="event-popover"
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        <div>
          <strong className={classes.title}>{event.event}</strong>
        </div>
        <div>{event.locationDescription}</div>
        {event.street && event.number && event.postalCode && event.city && (
          <GoogleMapsDirections
            address={{
              street: event.street,
              houseNumber: event.number,
              postalCode: event.postalCode,
              city: event.city,
            }}
          />
        )}
        {event.meetingId !== null && event.meetingPassword !== null && (
          <Box mt={2}>
            <Box component="span" mt={2} mr={2}>
              <ZoomMeeting
                meetingId={event.meetingId}
                password={event.meetingPassword}
              />
            </Box>
            <ZoomInfo
              meetingId={event.meetingId}
              password={event.meetingPassword}
            />
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default EventPopover;
