import React, { useContext } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import InternshipTypes from '../../education/InternshipTypes';
import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';

const Internship = (props: FieldProps) => {
  const { id, entryField, disabled, metadata } = props;
  const { formData, setFormData } = useContext(FormContext);

  const allowedTypes = metadata.types || [];

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    setFormData!({
      ...formData,
      [id]: {
        valid: value.length > 0,
        value,
      },
    });
  };

  return (
    <Select
      onChange={handleTypeChange}
      displayEmpty
      defaultValue={entryField ? entryField.value : ''}
      disabled={disabled}
      MenuProps={{ elevation: 1 }}
    >
      <MenuItem disabled value="">
        Selecteer een type stage...
      </MenuItem>
      {Object.entries(InternshipTypes)
        .filter(([id]) => allowedTypes.includes(parseInt(id, 10)))
        .map(([id, label]) => (
          <MenuItem value={id}>{label}</MenuItem>
        ))}
    </Select>
  );
};

export default Internship;
