import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { EditorProps } from './EditorProps';
import InternshipTypes, {
  InternshipTypeLabels,
} from '../../education/InternshipTypes';

const InternshipEditor = (props: EditorProps) => {
  const { field, setPreview, onChange } = props;
  const [enabledTypes, setEnabledTypes] = useState<
    (keyof InternshipTypeLabels)[]
  >(field ? field.metadata.types || [] : []);

  useEffect(() => {
    field.metadata = {
      types: enabledTypes,
    };
    onChange(field);

    setPreview(
      <div>
        <Select value="" displayEmpty disabled>
          <MenuItem value="">Selecteer een stage type...</MenuItem>
        </Select>
      </div>,
    );
  }, [field, enabledTypes]);

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const name = parseInt(event.target.name, 10) as keyof InternshipTypeLabels;

    if (checked) {
      setEnabledTypes([name, ...enabledTypes]);
    } else {
      setEnabledTypes(enabledTypes.filter((t) => t !== name));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Stagetypes</FormLabel>
      <FormGroup row>
        {Object.entries(InternshipTypes).map(([id, label]) => {
          const control = (
            <Checkbox
              name={id}
              checked={enabledTypes.includes(
                parseInt(id, 10) as keyof InternshipTypeLabels,
              )}
              onChange={handleCheckChange}
              color="primary"
            />
          );

          return <FormControlLabel control={control} label={label} />;
        })}
      </FormGroup>
    </FormControl>
  );
};

export default InternshipEditor;
