import React from 'react';

import { ReactComponent as AccessDeniedIllustration } from '../../assets/images/authentication.svg';
import ErrorPage from './ErrorPage';

const Error403 = () => (
  <ErrorPage
    title="Oeps!"
    description="Je hebt geen toestemming om deze pagina te bekijken."
    illustration={AccessDeniedIllustration}
  />
);

export default Error403;
