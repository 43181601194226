import { createContext } from 'react';
import { User } from '../../types';

const EducatorUserContext = createContext<{
  educator: User | null;
}>({
  educator: null,
});

export default EducatorUserContext;
