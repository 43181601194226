import { RoleInterface } from '../modules/users/Roles';
import { GlobalUserPermission } from '../types';

export default class Route {
  private title: string;

  private readonly path: string;

  private readonly element: JSX.Element;

  private parent: Route | null = null;

  private children: Route[] = [];

  private allowedRoles: (keyof RoleInterface)[] = [];

  private allowedPermissions: GlobalUserPermission[] = [];

  isRequiringLogin: boolean = false;

  isAnonymous: boolean = false;

  constructor(title: string, path: string, element: JSX.Element) {
    this.title = title;
    this.path = path;
    this.element = element;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title: string) {
    this.title = title;

    return this;
  }

  getPath() {
    let { path } = this;

    if (this.parent) {
      path = this.parent.getPath() + path;
    }

    return path;
  }

  getElement() {
    return this.element;
  }

  getChildren() {
    return this.children;
  }

  getParent() {
    return this.parent;
  }

  setParent(node: Route) {
    this.parent = node;
  }

  addChild(node: Route) {
    node.setParent(this);

    if (this.isRequiringLogin) {
      node.requireLogin();
    }

    this.children.push(node);
  }

  requireLogin() {
    this.isRequiringLogin = true;
    return this;
  }

  role(role: keyof RoleInterface | (keyof RoleInterface)[]) {
    this.requireLogin();

    if (typeof role === 'string') {
      this.allowedRoles.push(role);

      return this;
    }

    this.allowedRoles = this.allowedRoles.concat(role);

    return this;
  }

  permission(permission: GlobalUserPermission | GlobalUserPermission[]) {
    this.requireLogin();

    if (typeof permission === 'string') {
      this.allowedPermissions.push(permission);

      return this;
    }

    this.allowedPermissions = this.allowedPermissions.concat(permission);

    return this;
  }

  getRoles() {
    return this.allowedRoles;
  }

  getPermissions() {
    return this.allowedPermissions;
  }

  anonymous() {
    this.isAnonymous = true;
    return this;
  }
}
