import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Assignment } from '../../../types';

interface AssignmentLinkProps {
  assignment: Assignment;
}

const useStyles = makeStyles((theme: Theme) => ({
  assignmentLink: {
    position: 'relative',
    display: 'inline-block',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',

      '&::before': {
        height: 5,
      },
    },
    '& span': {
      position: 'relative',
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      bottom: 2,
      left: 0,
      background: grey[500],
      width: '100%',
      height: 0,
      opacity: 0.3,
      transition: theme.transitions.create('height'),
    },
  },
  assignmentLinkDisabled: {
    color: grey[500],
  },
}));

const AssignmentLink = (props: AssignmentLinkProps) => {
  const classes = useStyles();
  const { assignment } = props;

  let assignmentUrl: string | null = null;

  if (assignment.form && assignment.editable) {
    assignmentUrl = `/onderwijs/opdracht/${assignment.id}`;
  }

  if (
    assignment.form &&
    !assignment.editable &&
    assignment.entries.length > 0
  ) {
    assignmentUrl = `/onderwijs/opdracht/${assignment.id}/bekijken`;
  }

  return (
    <>
      {assignmentUrl && (
        <Link to={assignmentUrl} className={classes.assignmentLink}>
          <span>{assignment.name}</span>
        </Link>
      )}
      {!assignmentUrl && (
        <span className={classes.assignmentLinkDisabled}>
          {assignment.name}
        </span>
      )}
    </>
  );
};

export default AssignmentLink;
