import React from 'react';

import { Router, Route } from '../../routing';
import EducatorEventMoodleLoader from './EducatorEventMoodleLoader';
import EventOverview from './EventOverview';

const events = new Route('Evenementen', '/modulen', <EventOverview />)
  .requireLogin()
  .role([
    'ROLE_PROGRAM_ASSISTANT',
    'ROLE_INSTITUTE_EDUCATOR',
    'ROLE_ADMIN',
    'ROLE_TEACHER',
  ])
  .permission('VIEW_MODULES');
Router.addRoute(events);

const moodle = new Route(
  'Leermateriaal',
  '/modulen/user/:userId/evenement/:eventId/leermateriaal',
  <EducatorEventMoodleLoader />,
)
  .requireLogin()
  .role([
    'ROLE_PROGRAM_ASSISTANT',
    'ROLE_INSTITUTE_EDUCATOR',
    'ROLE_ADMIN',
    'ROLE_TEACHER',
  ])
  .permission('VIEW_MODULES');
Router.addRoute(moodle);
