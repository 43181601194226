import React from 'react';
import { Box, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

interface ZoomMeetingProps {
  meetingId: number;
  password: string;
}

const ZoomInfo = (props: ZoomMeetingProps) => {
  const { meetingId, password } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box component="span" m={2}>
      <Button
        onClick={handleClickOpen}
        startIcon={
          <FontAwesomeIcon icon={['fal', 'info-circle']} color="white" />
        }
        color="primary"
        variant="contained"
      >
        Zoom
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Gebruik de volgende gegevens om zelf een Zoom meeting te starten:
            <br />
            <Box mt={2}>
              <strong>Adres:</strong>{' '}
              <a href="https://zoom.us/join" target="_blank" rel="noreferrer">
                https://zoom.us/join
              </a>
              <br />
              <strong>Meeting ID:</strong> {meetingId}
              <br />
              <strong>Password:</strong> {password}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Sluiten
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ZoomInfo;
