import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme } from '@material-ui/core';

interface HiddenTextProps {
  children: string;
  length?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    marginRight: theme.spacing(2),
  },
  dot: {
    fontSize: 9,
    '&:not(:last-child)': {
      marginRight: 3,
    },
  },
}));

const HiddenText = (props: HiddenTextProps) => {
  const { children } = props;
  const classes = useStyles();
  const [visible, setVisible] = useState<boolean>(false);

  const length = props.length || 16;
  const hiddenDots = [];

  for (let i = 0; i < length; ++i) {
    hiddenDots.push(<FontAwesomeIcon icon="circle" className={classes.dot} />);
  }

  const toggleVisibility = () => setVisible(!visible);

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        {!visible && hiddenDots}
        {visible && children}
      </div>
      <IconButton size="small">
        <FontAwesomeIcon
          icon={['fal', visible ? 'eye-slash' : 'eye']}
          onClick={toggleVisibility}
        />
      </IconButton>
    </div>
  );
};

export default HiddenText;
