import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Box, IconButton, Popover, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import ConditionalWrapper from '../../../components/ConditionalWrapper';
import { ScheduleItem } from '../../../types';

export interface EventOption {
  id: number;
  label: string;
}

interface ModuleSearchDropdownProps {
  events: ScheduleItem[];
  onChange?: (options: EventOption[]) => void;
}

const useStyles = makeStyles(() => ({
  autocomplete: {
    minWidth: 300,
  },
}));

const ModuleSearchDropdown = (props: ModuleSearchDropdownProps) => {
  const { events, onChange } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<EventOption[]>([]);
  const open = Boolean(anchorEl);

  const handleOpen = async (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const options = useMemo(
    (): EventOption[] =>
      events
        // Get unique events
        .filter(
          (item, index, self) =>
            self.findIndex((i) => i.eventId === item.eventId) === index,
        )
        // Map to options
        .map((item) => ({
          id: item.eventId,
          label: item.event,
        }))
        // Sort by name
        .sort((a, b) => a.label.localeCompare(b.label)),
    [events],
  );

  const handleAutocompleteChange = (event: any, options: any) =>
    setSelectedOptions(options);

  useEffect(() => {
    if (onChange) {
      onChange(selectedOptions);
    }
  }, [selectedOptions]);

  return (
    <>
      <IconButton size="small" onClick={handleOpen}>
        <ConditionalWrapper
          condition={selectedOptions.length > 0}
          wrapper={(children) => (
            <Badge color="secondary" variant="dot">
              {children}
            </Badge>
          )}
        >
          <FontAwesomeIcon icon={['fal', 'chevron-down']} />
        </ConditionalWrapper>
      </IconButton>
      <Popover
        id={open ? 'module-search-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box p={2}>
          <Autocomplete
            id="module-search-input"
            getOptionLabel={(option) => option.label}
            options={options}
            onChange={handleAutocompleteChange}
            noOptionsText="Geen opties beschikbaar."
            value={selectedOptions}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                label="Module"
                placeholder="Zoek een module..."
              />
            )}
            className={classes.autocomplete}
            multiple
            autoComplete
          />
        </Box>
      </Popover>
    </>
  );
};

export default ModuleSearchDropdown;
