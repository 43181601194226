import { Account, GlobalUserPermission, User } from './types';
import { RoleInterface } from './modules/users/Roles';

export type RoleView = 'admin' | 'educator' | 'participant' | 'e-learning';

/**
 * This class manages the role-specific views the user can view.
 */
class RoleViewManager {
  /**
   * The current loggedin user.
   * @private
   */
  private user: Account | User;

  /**
   * The current view that the user selected.
   * @private
   */
  private currentView: RoleView;

  private readonly localStore: LocalForage | undefined;

  private readonly viewLabels: { [key in RoleView]: string } = {
    admin: 'Admin',
    educator: 'Docent/Opleider',
    participant: 'Deelnemer',
    'e-learning': 'Deelnemer',
  };

  private readonly initialRoutes: { [key in RoleView]: string } = {
    admin: '/gebruikers',
    educator: '/agenda',
    participant: '/',
    'e-learning': '/e-learnings',
  };

  constructor(user: User, view?: RoleView, localStore?: LocalForage) {
    this.user = user;
    this.currentView = view || this.getViewFromRoles(this.user.roles);
    this.localStore = localStore;
  }

  /**
   * Determine if the user is viewing as an admin.
   */
  isAdminView(): boolean {
    return this.currentView === 'admin' && this.hasAdminRole();
  }

  /**
   * Determine if the user is an admin or imitating admin.
   */
  isAdminOrImitatingAdmin(): boolean {
    return this.isAdminView() || this.user.isImitatingAdmin === true;
  }

  /**
   * Determine if the user is viewing as an educator.
   */
  isEducatorView(): boolean {
    return this.currentView === 'educator' && this.hasEducatorRole();
  }

  /**
   * Determine if the user is viewing as a participant.
   */
  isParticipantView(): boolean {
    return this.currentView === 'participant' && this.hasParticipantRole();
  }

  /**
   * Determine if the user is viewing as an e-learning user.
   */
  isELearningView(): boolean {
    return this.currentView === 'e-learning' && this.hasELearningRole();
  }

  /**
   * Get the current view.
   */
  getCurrentView(): RoleView {
    return this.currentView;
  }

  /**
   * Get all the role-specific views the user can view.
   */
  getViews(): RoleView[] {
    const views: RoleView[] = [];

    if (this.hasParticipantRole()) {
      views.push('participant');
    }

    if (this.hasELearningRole()) {
      views.push('e-learning');
    }

    if (this.hasEducatorRole()) {
      views.push('educator');
    }

    if (this.hasAdminRole()) {
      views.push('admin');
    }

    return views;
  }

  /**
   * Get label of the current selected view.
   */
  getViewLabel(view?: RoleView): string {
    if (view === undefined) {
      return this.viewLabels[this.currentView];
    }

    return this.viewLabels[view];
  }

  /**
   * Get initial route of the current selected view.
   */
  getInitialRoute(view?: RoleView): string {
    if (view === undefined) {
      return this.initialRoutes[this.currentView];
    }

    return this.initialRoutes[view];
  }

  /**
   * @param view The view to switch to.
   * @param persist Whether the view should be persisted to the local store.
   */
  async setCurrentView(
    view: RoleView,
    persist: boolean = true,
  ): Promise<void | string | null> {
    this.currentView = view;

    if (this.localStore && persist) {
      return this.localStore.setItem('view', view).catch(() => {});
    }

    return null;
  }

  /**
   * Set the current view from a specific role.
   * @param roles
   */
  setCurrentViewFromRoles(roles: (keyof RoleInterface)[]): void {
    this.setCurrentView(this.getViewFromRoles(roles));
  }

  /**
   * Determines if the current
   * @param role
   */
  currentViewSupportsRole(role: keyof RoleInterface) {
    return this.canAccessView(this.currentView, [role]);
  }

  canAccessView(view: RoleView, roles: (keyof RoleInterface)[]) {
    if (view === 'e-learning') {
      return roles.includes('ROLE_FREE_E_LEARNING_USER');
    }

    if (view === 'participant') {
      return roles.includes('ROLE_PARTICIPANT');
    }

    if (view === 'educator') {
      return roles.includes('ROLE_EDUCATOR');
    }

    return roles.includes('ROLE_ADMIN');
  }

  /**
   * Determine if the user has any of the given roles.
   * @param role A role or array of roles to check for.
   */
  hasRole(role: keyof RoleInterface | (keyof RoleInterface)[]): boolean {
    if (this.user.roles.includes('ROLE_ADMIN')) {
      return true;
    }

    if (typeof role === 'string') {
      return this.user.roles.includes(role);
    }

    return this.user.roles.find((r) => role.includes(r)) !== undefined;
  }

  /**
   * Determine if the user has any of the given permissions.
   * @param permission A permission or an array of permissions to check for.
   */
  hasPermission(
    permission: GlobalUserPermission | GlobalUserPermission[],
  ): boolean {
    if (!('globalPermissions' in this.user)) {
      return false;
    }

    if (this.user.roles.includes('ROLE_ADMIN')) {
      return true;
    }

    if (typeof permission === 'string') {
      return this.user.globalPermissions.includes(permission);
    }

    return (
      this.user.globalPermissions.find((r) => permission.includes(r)) !==
      undefined
    );
  }

  getUser(): User {
    return this.user;
  }

  async loadViewFromLocalStore(callback?: () => void) {
    if (!this.localStore) {
      return;
    }

    await this.localStore
      .getItem<RoleView>('view')
      .then((view) => {
        if (view && !this.canAccessView(view, this.user.roles)) {
          this.setCurrentView(this.getViewFromRoles(this.user.roles), false);
          return;
        }

        if (view && view !== this.getCurrentView()) {
          this.setCurrentView(view);
        }
      })
      .finally(callback);
  }

  /**
   * Determine if the user has an admin role.
   * @private
   */
  private hasAdminRole(): boolean {
    return this.user.roles.includes('ROLE_ADMIN');
  }

  /**
   * Determine if the user has an educator role.
   * @private
   */
  private hasEducatorRole(): boolean {
    return this.user.roles.includes('ROLE_EDUCATOR');
  }

  /**
   * Determine if the user has the participant role.
   * @private
   */
  private hasParticipantRole(): boolean {
    return this.user.roles.includes('ROLE_PARTICIPANT');
  }

  /**
   * Determine if the user has the free e-learning role.
   * @private
   */
  private hasELearningRole(): boolean {
    return this.user.roles.includes('ROLE_FREE_E_LEARNING_USER');
  }

  /**
   * Determine the view for a specific role.
   * @param roles
   * @private
   */
  private getViewFromRoles(roles: (keyof RoleInterface)[]): RoleView {
    if (roles.includes('ROLE_ADMIN')) {
      return 'admin';
    }

    if (roles.includes('ROLE_EDUCATOR')) {
      return 'educator';
    }

    if (roles.includes('ROLE_PARTICIPANT')) {
      return 'participant';
    }

    return 'e-learning';
  }
}

export default RoleViewManager;
