import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Badge,
  Button,
  Box,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExternalInviteDialog from './ExternalInviteDialog';
import { Assignment } from '../../../types';
import ConditionalWrapper from '../../../components/ConditionalWrapper';
import FieldLabel from '../../yard-forms/components/FieldLabel';

interface AssignmentCollaborationButtonProps {
  assignment: Assignment;
  variant?: 'icon' | 'complete';
}

const AssignmentCollaborationButton = (
  props: AssignmentCollaborationButtonProps,
) => {
  const { assignment } = props;
  const variant = props.variant || 'icon';
  const [externalInviteDialogOpen, setExternalInviteDialogOpen] =
    useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleExternalInviteDialogOpen = () =>
    setExternalInviteDialogOpen(true);
  const handleExternalInviteDialogClose = () =>
    setExternalInviteDialogOpen(false);

  const handleExternalInviteSubmit = () => setSubmitted(true);

  if (!assignment) {
    return <div />;
  }

  return (
    <>
      <Grid item>
        <ExternalInviteDialog
          assignment={assignment}
          open={externalInviteDialogOpen}
          onClose={handleExternalInviteDialogClose}
          onSubmit={handleExternalInviteSubmit}
        />
        <ConditionalWrapper
          condition={variant === 'complete'}
          wrapper={(children) => (
            <Box mt={3} mb={4}>
              <FieldLabel>
                Wil je dat iemand een bijdrage levert aan deze opdracht? Stuur
                een verzoek tot bijdrage.
              </FieldLabel>
              <Box mt={2}>{children}</Box>
              <Box mt={2} fontSize="90%">
                Wacht met inleveren tot je een notificatie (rechtsboven naast je
                profiel) hebt ontvangen dat een bijdrage is geleverd. Pas daarna
                selecteer je een beoordelaar en lever je de opdracht in.
              </Box>
              <hr />
            </Box>
          )}
        >
          <ConditionalWrapper
            condition={Boolean(
              (assignment.accessTokens && assignment.accessTokens.length > 0) ||
                submitted,
            )}
            wrapper={(children) => (
              <Badge
                badgeContent={<FontAwesomeIcon icon={['fal', 'check']} />}
                color="secondary"
              >
                {children}
              </Badge>
            )}
          >
            <>
              {variant === 'icon' && (
                <Tooltip title="Verzoek tot bijdrage">
                  <IconButton
                    size="small"
                    onClick={handleExternalInviteDialogOpen}
                  >
                    <FontAwesomeIcon icon={['fad', 'hands-helping']} />
                  </IconButton>
                </Tooltip>
              )}
              {variant === 'complete' && (
                <Button
                  onClick={handleExternalInviteDialogOpen}
                  variant="contained"
                  color="primary"
                  startIcon={
                    <FontAwesomeIcon icon={['fad', 'hands-helping']} />
                  }
                >
                  Verzoek tot bijdrage
                </Button>
              )}
            </>
          </ConditionalWrapper>
        </ConditionalWrapper>
      </Grid>
    </>
  );
};

export default AssignmentCollaborationButton;
