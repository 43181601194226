import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Education, EducationPeriod } from '../../../types';
import DataTable from '../../../components/DataTable';
import EducationPeriodRepository from '../EducationPeriodRepository';
import FinishedModulesWidget from './FinishedModulesWidget';
import OptionalEducationWidget from './OptionalEducationWidget';
import ModuleOverviewTableDialog from './ModuleOverviewTableDialog';
import OptionalEducationTableDialog from './OptionalEducationTableDialog';
import InternshipsWidget from './InternshipsWidget';
import InternshipTableDialog from './InternshipTableDialog';

interface EducationInformationProps {
  education: Education;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: 0,
  },
  table: {
    '& tr > td:first-child': {
      paddingRight: theme.spacing(3),
      fontWeight: 600,
    },
  },
}));

const EducationInformation = (props: EducationInformationProps) => {
  const { education } = props;
  const [moduleOverviewTableOpen, setModuleOverviewTableOpen] =
    useState<boolean>(false);
  const [optionalEducationTableOpen, setOptionalEducationTableOpen] =
    useState<boolean>(false);
  const [internshipTable, setInternshipTable] = useState<boolean>(false);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [hasEducationPeriods, setHasEducationPeriods] =
    useState<boolean>(false);
  const classes = useStyles();

  const hasInternships = Object.entries(education.internshipHours).length > 0;

  const handleOpenModuleOverviewTable = () => setModuleOverviewTableOpen(true);
  const handleCloseModuleOverviewTable = () =>
    setModuleOverviewTableOpen(false);

  const handleOpenOptionalEducationTable = () =>
    setOptionalEducationTableOpen(true);
  const handleCloseOptionalEducationTable = () =>
    setOptionalEducationTableOpen(false);

  const handleOpenInternshipTable = () => setInternshipTable(true);
  const handleCloseInternshipTable = () => setInternshipTable(false);

  const toggleMoreInfo = () => setShowMoreInfo(!showMoreInfo);

  const educationPeriodRepository = useMemo(
    () => new EducationPeriodRepository(education),
    [education],
  );

  useEffect(() => {
    educationPeriodRepository
      .findBy()
      .then((response) => {
        setHasEducationPeriods(response.data.totalItems > 0);
      })
      .catch(() => {});
  }, [education]);

  const columns = [
    {
      name: 'Opleidingsperiodes',
      field: 'name',
    },
    {
      name: 'Instelling',
      field: 'institution',
    },
    {
      name: 'Status plaatsing',
      field: 'matchStatus',
    },
    {
      name: 'Startdatum',
      field: 'startDate',
      render: (period: EducationPeriod) => {
        if (!period.startDate) {
          return '';
        }

        return moment(period.startDate).format('DD-MM-YYYY');
      },
    },
    {
      name: 'Einddatum (t/m)',
      field: 'startDate',
      render: (period: EducationPeriod) => {
        if (!period.endDate) {
          return '';
        }

        return moment(period.endDate).format('DD-MM-YYYY');
      },
    },
    {
      name: '(Praktijk)opleider',
      field: 'practicalTrainer',
    },
  ];

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={hasInternships ? 12 : 6}>
          <Paper elevation={1} style={{ display: 'flex', height: '100%' }}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Box mb={2}>
                  <h2 className={classes.title}>Opleidingsgegevens</h2>
                </Box>
                <table className={classes.table}>
                  <tbody>
                    {education.email && (
                      <tr>
                        <td>E-mail opleiding:</td>
                        <td>{education.email}</td>
                      </tr>
                    )}
                    {education.instituteEducators.length > 0 && (
                      <tr>
                        <td>
                          {`NSPOH ${
                            education.instituteEducators.length > 1
                              ? 'opleiders'
                              : 'opleider'
                          }`}
                        </td>
                        <td>
                          {education.instituteEducators.map((e) => (
                            <div>
                              {`${e.fullNameLastNameFirst} (${e.email})`}
                            </div>
                          ))}
                        </td>
                      </tr>
                    )}
                    {education.programAssistantName && (
                      <tr>
                        <td>Programma assistent:</td>
                        <td>{education.programAssistantName}</td>
                      </tr>
                    )}
                    {education.practicalEducators.length > 0 && (
                      <tr>
                        <td>
                          {`Praktijk${
                            education.practicalEducators.length > 1
                              ? 'opleiders'
                              : 'opleider'
                          }:`}
                        </td>
                        <td>
                          {education.practicalEducators.map((e) => (
                            <div>
                              {`${e.fullNameLastNameFirst} (${e.email})`}
                            </div>
                          ))}
                        </td>
                      </tr>
                    )}
                    {education.fullTimePercentage && (
                      <tr>
                        <td>Deeltijdfactor:</td>
                        <td>{`${education.fullTimePercentage}%`}</td>
                      </tr>
                    )}
                    {showMoreInfo && (
                      <>
                        {education.startDate && (
                          <tr>
                            <td>Startdatum:</td>
                            <td>
                              {moment(education.startDate).format('DD-MM-YYYY')}
                            </td>
                          </tr>
                        )}
                        {education.electiveCourseHours && (
                          <tr>
                            <td>Aantal uren keuzeonderwijs:</td>
                            <td>{education.electiveCourseHours}</td>
                          </tr>
                        )}
                        {education.electiveCourseBudget && (
                          <tr>
                            <td>Budget extern keuze onderwijs:</td>
                            <td>
                              {(
                                education.electiveCourseBudget / 100
                              ).toLocaleString('nl-NL', {
                                style: 'currency',
                                currency: 'EUR',
                              })}
                            </td>
                          </tr>
                        )}
                        {education.interruptionStartDate && (
                          <tr>
                            <td>Start onderbreking:</td>
                            <td>
                              {moment(education.interruptionStartDate).format(
                                'DD-MM-YYYY',
                              )}
                            </td>
                          </tr>
                        )}
                        {education.interruptionEndDate && (
                          <tr>
                            <td>Eind onderbreking:</td>
                            <td>
                              {moment(education.interruptionEndDate).format(
                                'DD-MM-YYYY',
                              )}
                            </td>
                          </tr>
                        )}
                        {education.deregistrationDate && (
                          <tr>
                            <td>Uitschrijfdatum:</td>
                            <td>
                              {moment(education.deregistrationDate).format(
                                'DD-MM-YYYY',
                              )}
                            </td>
                          </tr>
                        )}
                        {education.rgsEndDate && (
                          <tr>
                            <td>RGS einddatum:</td>
                            <td>
                              {moment(education.rgsEndDate).format(
                                'DD-MM-YYYY',
                              )}
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </Box>
              <Box textAlign="center">
                <Button onClick={toggleMoreInfo}>
                  {showMoreInfo ? 'Minder informatie' : 'Meer informatie'}
                </Button>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<FontAwesomeIcon icon={['fal', 'th-list']} />}
                      href={`${process.env.REACT_APP_API_URL}/api/education/${education.id}/presence-overview`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Presentieoverzicht
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={
                        <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                      }
                      href={`${process.env.REACT_APP_API_URL}/api/education/${education.id}/overview`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Opleidingsoverzicht
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<FontAwesomeIcon icon={['fal', 'books']} />}
                      component={Link}
                      to={`/onderwijs/${education.id}/leermateriaal`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Bekijk studiegids
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper elevation={1}>
            <Box p={2}>
              <Box textAlign="center">
                <h2 className={classes.title}>Afgeronde modules</h2>
                <Box height={240}>
                  <FinishedModulesWidget education={education} />
                </Box>
                <Button onClick={handleOpenModuleOverviewTable}>
                  Bekijk alles
                </Button>
              </Box>
            </Box>
          </Paper>
          <ModuleOverviewTableDialog
            education={education}
            open={moduleOverviewTableOpen}
            onClose={handleCloseModuleOverviewTable}
          />
        </Grid>
        {education.electiveCourseHours && education.electiveCourseHours > 0 && (
          <Grid item md={3}>
            <Paper elevation={1}>
              <Box p={2}>
                <Box textAlign="center">
                  <h2 className={classes.title}>
                    Totaal punten keuzeonderwijs
                  </h2>
                  <Box height={240}>
                    <OptionalEducationWidget education={education} />
                  </Box>
                  <Button onClick={handleOpenOptionalEducationTable}>
                    Bekijk alles
                  </Button>
                </Box>
              </Box>
            </Paper>
            <OptionalEducationTableDialog
              education={education}
              open={optionalEducationTableOpen}
              onClose={handleCloseOptionalEducationTable}
            />
          </Grid>
        )}
        {hasInternships && (
          <Grid item md={6}>
            <Paper elevation={1}>
              <Box p={2}>
                <Box mb={1}>
                  <h2 className={classes.title}>Stagepunten</h2>
                </Box>
                <InternshipsWidget education={education} />
                <Box mt={2}>
                  <Button onClick={handleOpenInternshipTable}>
                    Bekijk alles
                  </Button>
                </Box>
              </Box>
            </Paper>
            <InternshipTableDialog
              education={education}
              open={internshipTable}
              onClose={handleCloseInternshipTable}
            />
          </Grid>
        )}
      </Grid>
      {hasEducationPeriods && (
        <Box mt={3}>
          <Paper elevation={1}>
            <Box p={2}>
              <DataTable
                id="education-period"
                repository={educationPeriodRepository}
                columns={columns}
                contained={false}
              />
            </Box>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default EducationInformation;
