import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

interface PasswordInputProps {
  id: string;
  error: boolean | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  outlined?: boolean;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

const PasswordInput = (props: PasswordInputProps) => {
  const classes = useStyles();
  const { id, error, onChange, label, className, outlined } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleToggle = () => setShowPassword(!showPassword);

  const adornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleToggle}
      >
        <FontAwesomeIcon
          icon={['fal', showPassword ? 'eye-slash' : 'eye']}
          fixedWidth
        />
      </IconButton>
    </InputAdornment>
  );

  return outlined ? (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.formControl}`}
    >
      <InputLabel htmlFor={id} error={error}>
        {label || 'Wachtwoord'}
      </InputLabel>
      <OutlinedInput
        id={id}
        data-testid={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error={error}
        endAdornment={adornment}
        fullWidth
        notched
      />
    </FormControl>
  ) : (
    <FormControl className={`${className} ${classes.formControl}`}>
      <InputLabel htmlFor={id}>{label || 'Wachtwoord'}</InputLabel>
      <Input
        id={id}
        data-testid={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error={error}
        endAdornment={adornment}
        fullWidth
      />
    </FormControl>
  );
};

export default PasswordInput;
