import React, { useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import PageContainer from '../../components/PageContainer';
import { Education } from '../../types';
import ModuleOverview from './module/ModuleOverview';
import AgendaOverview from '../agenda/AgendaOverview';
import EducationEducatorContext from './EducationEducatorContext';
import SubAppBar from '../../components/SubAppBar';
import CertificateList from '../users/certificates/CertificateList';

interface EducationEducatorViewProps {
  previousRoute?: string;
  showCertificates: boolean;
}

export type EducatorEducationView = 'education' | 'agenda' | 'certificates';

const EducationEducatorView = (props: EducationEducatorViewProps) => {
  const { previousRoute, showCertificates } = props;
  const [education, setEducation] = useState<Education | null>(null);
  const [view, setView] = useState<EducatorEducationView>('education');

  const handleOpenEducation = () => setView('education');
  const handleOpenAgenda = () => setView('agenda');
  const handleOpenCertificates = () => setView('certificates');

  const contextValue = useMemo(
    () => ({
      educatorEducation: education,
      setEducatorEducation: setEducation,
      educatorView: view,
      setEducatorView: setView,
    }),
    [education, setEducation, view, setView],
  );

  return (
    <EducationEducatorContext.Provider value={contextValue}>
      {education && (
        <SubAppBar
          title={education.user.fullNameLastNameFirst}
          previousRoute={previousRoute}
        >
          <Button
            color="inherit"
            className={`${view === 'education' && 'active'}`}
            onClick={handleOpenEducation}
          >
            {education.name}
          </Button>
          <Button
            color="inherit"
            className={`${view === 'agenda' && 'active'}`}
            onClick={handleOpenAgenda}
          >
            Agenda
          </Button>
          <>
            {showCertificates && (
              <Button
                className={view === 'certificates' ? 'active' : ''}
                onClick={handleOpenCertificates}
              >
                Certificaten
              </Button>
            )}
          </>
        </SubAppBar>
      )}
      <Box display={view === 'education' ? 'block' : 'none'}>
        <ModuleOverview readOnly />
      </Box>
      {education && (
        <>
          <Box display={view === 'agenda' ? 'block' : 'none'}>
            <AgendaOverview
              userId={education.user.id}
              breadcrumbs={false}
              forceView="participant"
            />
          </Box>
          <>
            {showCertificates && (
              <Box display={view === 'certificates' ? 'block' : 'none'}>
                <PageContainer breadcrumbs={false}>
                  <CertificateList user={education.user} />
                </PageContainer>
              </Box>
            )}
          </>
        </>
      )}
    </EducationEducatorContext.Provider>
  );
};

export default EducationEducatorView;
