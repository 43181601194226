import { AxiosResponse, CancelTokenSource } from 'axios';

import {
  ApiFilterCriteria,
  CollectionResponse,
  EducatorEvent,
} from '../../types';
import ApiClient from '../../api/ApiClient';
import { parseCriteria } from '../../utils/common';

class EducatorEventRepository {
  private readonly onlyOwnEvents: boolean;

  private readonly educatorId?: string;

  constructor(onlyOwnEvents: boolean = true, educatorId?: string) {
    this.onlyOwnEvents = onlyOwnEvents;
    this.educatorId = educatorId;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
  ): Promise<
    | CollectionResponse<EducatorEvent>
    | AxiosResponse<{
        items: EducatorEvent[];
        skipEducators: number;
        skipTeachers: number;
      }>
  > {
    const params = parseCriteria(criteria, {
      page: page || 1,
      onlyOwnEvents: this.onlyOwnEvents ? 1 : 0,
    });

    return ApiClient.get(
      `/api/educator-events${
        this.educatorId !== undefined ? `/${this.educatorId}` : ''
      }`,
      params,
      {},
      { cancelToken: cancelToken?.token },
    );
  }

  sync(eventId: number): Promise<AxiosResponse<EducatorEvent>> {
    return ApiClient.post(`/api/educator-events/${eventId}/sync`);
  }
}

export default EducatorEventRepository;
