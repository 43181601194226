import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import DataTable from '../../components/DataTable';
import FormCategoryRepository from './FormCategoryRepository';
import { FormCategory } from '../yard-forms/types';
import DialogCloseButton from '../../components/DialogCloseButton';

interface FormCategoryManagerDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  content: {
    minWidth: 400,
  },
  nameControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const FormCategoryManagerDialog = (props: FormCategoryManagerDialogProps) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const [name, setName] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean | null>(null);
  const notifications = useSnackbar();
  const [repository, setRepository] = useState<FormCategoryRepository>(
    new FormCategoryRepository(),
  );
  const [changedCategories, setChangedCategories] = useState<FormCategory[]>(
    [],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setValid(true);
    }

    setName(e.target.value);
  };

  const markCategoryAsChanged = (category: FormCategory) => {
    if (!changedCategories.find((c) => c.id === category.id)) {
      changedCategories.push(category);
      setChangedCategories([...changedCategories]);
    }
  };

  const doChangeCategory = (category: FormCategory) => {
    const changedCategory = changedCategories.find((c) => c.id === category.id);
    if (!changedCategory) {
      return;
    }

    repository.update(changedCategory, changedCategory.name).then(() => {
      notifications.enqueueSnackbar('De categorie is gewijzigd!', {
        variant: 'success',
      });
      setChangedCategories(
        changedCategories.filter((c) => c.id !== category.id),
      );
      setRepository(new FormCategoryRepository());
    });
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: FormCategory,
  ) => {
    const index = changedCategories.findIndex((c) => c.id === category.id);
    if (index === -1) {
      return;
    }

    changedCategories[index] = { ...category, name: event.target.value };
    setChangedCategories([...changedCategories]);
  };

  const handleAddCategory = () => {
    if (name === null || name.length === 0) {
      setValid(false);
      return;
    }

    repository
      .create(name)
      .then(() => {
        // Refreshes data table.
        setRepository(new FormCategoryRepository());
        setName(null);
        notifications.enqueueSnackbar('De categorie is aangemaakt!', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij aanmaken van categorie!', {
          variant: 'error',
        });
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Categori&euml;n beheren
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={2}>
          <FormControl className={classes.nameControl}>
            <TextField
              value={name}
              onChange={handleChange}
              label="Naam"
              error={valid !== null && !valid}
              fullWidth
            />
            <IconButton onClick={handleAddCategory} size="small">
              <FontAwesomeIcon icon={['fal', 'plus']} />
            </IconButton>
          </FormControl>
        </Box>
        <DataTable
          id="form-category-manager"
          repository={repository}
          columns={[
            {
              name: 'Naam',
              field: 'name',
              sortable: true,
              render: (category: FormCategory) => {
                const onCategoryChange = (
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => handleCategoryChange(event, category);

                if (changedCategories.find((c) => c.id === category.id)) {
                  return (
                    <TextField
                      onChange={onCategoryChange}
                      defaultValue={category.name}
                    />
                  );
                }

                return <div>{category.name}</div>;
              },
            },
            {
              name: 'Formulieren',
              field: 'count',
            },
          ]}
          actions={(category: FormCategory, className: string) => {
            if (changedCategories.find((c) => c.id === category.id)) {
              return (
                <IconButton
                  className={className}
                  size="small"
                  onClick={() => doChangeCategory(category)}
                >
                  <FontAwesomeIcon icon={['fal', 'check']} />
                </IconButton>
              );
            }

            return (
              <IconButton
                className={className}
                size="small"
                onClick={() => markCategoryAsChanged(category)}
              >
                <FontAwesomeIcon icon={['fal', 'edit']} />
              </IconButton>
            );
          }}
          deletable
        />
      </DialogContent>
    </Dialog>
  );
};

export default FormCategoryManagerDialog;
