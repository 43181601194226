import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  GridList,
  GridListTile,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';

import NewsRepository from '../../modules/info/news/NewsRepository';

interface ImageSuggestSearchProps {
  onChange: (url: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  buttonPrevious: {
    marginRight: theme.spacing(1),
  },
  image: {
    border: '3px solid transparent',
    opacity: 0.7,
  },
  imageActive: {
    borderColor: theme.palette.primary.main,
    opacity: 1,
  },
  imageUnsplashInput: {
    marginTop: theme.spacing(2),
    maxWidth: 420,
    width: '100%',
  },
  grid: {
    paddingTop: theme.spacing(4),
  },
}));

const ImageSuggestSearch = (props: ImageSuggestSearchProps) => {
  const { onChange } = props;
  const notifications = useSnackbar();
  const classes = useStyles();

  const PAGER = 9;
  const { page } = useParams<{ page: string }>();
  const [currentPage, setCurrentPage] = useState<number>(
    page ? parseInt(page, 10) : 1,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTile, setActiveTile] = useState<number | null>(null);

  const [images, setImages] = useState([]);

  const news = new NewsRepository();

  /**
   * Fetch data from backend.
   */
  const fetch = async () => {
    await news
      .imageSearch({ query: searchTerm }, currentPage, PAGER)
      .then(({ data }) => {
        if (data.images) {
          setImages(data.images);
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van fotobibliotheek',
          {
            variant: 'error',
          },
        );
      });
  };

  /**
   * Load the next set of images.
   */
  const loadNext = () => {
    const current = currentPage;
    setCurrentPage(current + 1);
  };

  /**
   * Load the previous set of images.
   */
  const loadPrevious = () => {
    const current = currentPage;
    setCurrentPage(current - 1);
  };

  /**
   * Reset pagination.
   */
  const resetPaginator = () => {
    setCurrentPage(1);
  };

  /**
   * Search
   */
  const doSearch = useCallback(
    debounce((query: string) => {
      resetPaginator();
      setSearchTerm(query);
    }, 500),
    [searchTerm],
  );

  /**
   * Handle search term change.
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    doSearch(value);
  };

  const pickImage = (query: string, index: number) => {
    setActiveTile(index);
    onChange(query);
  };

  useEffect(() => {
    fetch();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    setActiveTile(null);
  }, [currentPage]);

  return (
    <>
      <TextField
        className={classes.imageUnsplashInput}
        label="Geef een zoekterm op..."
        onChange={handleSearch}
        variant="outlined"
      />
      <GridList className={classes.grid} cellHeight={160} cols={3}>
        {images.map((image: { full: string; thumb: string }, index) => (
          <GridListTile
            className={`${classes.image} ${
              activeTile === index ? classes.imageActive : ''
            }`}
            onClick={() => pickImage(image.full, index)}
            key={`image-${index}`}
            cols={1}
          >
            <img src={image.thumb} alt="" />
          </GridListTile>
        ))}
      </GridList>
      {images && images.length >= PAGER && (
        <Box className={classes.button} display="flex" justifyContent="center">
          {currentPage > 1 && (
            <Button
              className={classes.buttonPrevious}
              onClick={() => loadPrevious()}
              variant="outlined"
              color="primary"
            >
              Vorige
            </Button>
          )}
          <Button onClick={() => loadNext()} variant="outlined" color="primary">
            Volgende
          </Button>
        </Box>
      )}
    </>
  );
};

export default ImageSuggestSearch;
