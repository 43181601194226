import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

interface AssignmentSubmitMessageWarningDialogProps {
  open: boolean;
  message: string;
  onSubmitWithMessage?: () => void;
  onSubmit?: () => void;
}

const AssignmentSubmitMessageWarningDialog = ({
  open,
  message,
  onSubmitWithMessage,
  onSubmit,
}: AssignmentSubmitMessageWarningDialogProps) => (
  <Dialog open={open}>
    <DialogContent>
      <Box mb={2}>
        Er staat een bericht open dat nog niet is verzonden. Wil je deze alsnog
        verzenden?
      </Box>
      <Box>{`"${message}"`}</Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onSubmit}>Doorgaan zonder bericht</Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onSubmitWithMessage}
      >
        Verzenden en doorgaan
      </Button>
    </DialogActions>
  </Dialog>
);

export default AssignmentSubmitMessageWarningDialog;
