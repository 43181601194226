import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Autocomplete } from '@material-ui/lab';
import EvaluationRepository from '../evaluation/EvaluationRepository';
import { EvaluationOption } from '../../types';
import AppContext from '../../AppContext';

export interface EvaluationTypes {
  valuation: boolean;
  grade: boolean;
}

interface EvaluationTypeSelectProps {
  onChange: (types: EvaluationTypes, options: string[]) => void;
  className?: string;
  valuation?: boolean;
  grade?: boolean;
  enabledOptions?: string[];
  readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  evaluationOptions: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const EvaluationTypeSelect = (props: EvaluationTypeSelectProps) => {
  const { className, onChange } = props;
  const readOnly = props.readOnly !== undefined ? props.readOnly : false;
  const classes = useStyles();
  const [state, setState] = useState<EvaluationTypes>({
    valuation: props.valuation || false,
    grade: props.grade || false,
  });
  const { appState, setAppState } = useContext(AppContext);
  const [evaluationOptions, setEvaluationOptions] = useState<
    EvaluationOption[]
  >([]);
  const [enabledOptions, setEnabledOptions] = useState<EvaluationOption[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleEvaluationOptionChange = (event: any, options: any) => {
    setEnabledOptions(options);
  };

  useEffect(() => {
    new EvaluationRepository()
      .getOptions()
      .then((response) => {
        setEvaluationOptions(response.data);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  }, []);

  useEffect(() => {
    if (evaluationOptions.length === 0) {
      return;
    }

    onChange(
      state,
      enabledOptions.map((option) => option.id),
    );
  }, [state, enabledOptions]);

  useEffect(() => {
    if (evaluationOptions.length === 0) {
      return;
    }

    const initialOptions: EvaluationOption[] = [];

    evaluationOptions.forEach((option) => {
      if (props.enabledOptions?.includes(option.id)) {
        initialOptions.push(option);
      }
    });

    setEnabledOptions(initialOptions);
  }, [evaluationOptions]);

  const valuationControl = (
    <Checkbox
      checked={state.valuation}
      onChange={handleChange}
      name="valuation"
      disabled={readOnly}
    />
  );

  const gradeControl = (
    <Checkbox
      checked={state.grade}
      onChange={handleChange}
      name="grade"
      disabled={readOnly}
    />
  );

  return (
    <div className={className}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Beoordelingstype</FormLabel>
        <FormGroup>
          <FormControlLabel control={valuationControl} label="Beoordeling" />
          {state.valuation && (
            <div className={classes.evaluationOptions}>
              <Autocomplete
                options={evaluationOptions}
                getOptionLabel={(option) => option.label}
                onChange={handleEvaluationOptionChange}
                value={enabledOptions}
                renderInput={(params) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...params} label="Type" variant="outlined" />
                )}
                disabled={readOnly}
                style={{ width: '100%' }}
                multiple
              />
            </div>
          )}
          <FormControlLabel control={gradeControl} label="Cijfer" />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default EvaluationTypeSelect;
