import React, { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@material-ui/core';

import { Column } from '../../../components/DataTable';
import SimpleTable from '../../../components/SimpleTable';
import TableContainer from '../../../components/TableContainer';
import { Education } from '../../../types';
import EducationRepository, {
  DownloadSummaryItem,
} from '../EducationRepository';
import DownloadSummaryDetails from './DownloadSummaryDetails';

interface DownloadProps {
  education: Education;
}

const Downloads = (props: DownloadProps) => {
  const [summaryItems, setSummaryItems] = useState<DownloadSummaryItem[]>([]);
  const [item, setItem] = useState<DownloadSummaryItem | null>(null);

  const repository = useMemo(() => new EducationRepository(), []);

  useEffect(() => {
    repository
      .getDownloadSummary(props.education.id)
      .then((response) => setSummaryItems(response.data));
  }, [repository, props.education.id]);

  const columns: Column[] = [
    {
      name: 'Onderdeel',
      field: 'category',
      render: (item: DownloadSummaryItem) => item.category,
    },
    {
      name: 'Opdrachten',
      field: 'assignments',
      render: (item: DownloadSummaryItem) => item.numberOfAssignments,
    },
    {
      name: 'Bestanden',
      field: 'files',
      render: (item: DownloadSummaryItem) => item.numberOfFiles,
    },
    {
      name: '',
      field: 'actions',
      render: (item: DownloadSummaryItem) => (
        <Tooltip title="Onderdeel bekijken">
          <IconButton size="small" onClick={() => setItem(item)}>
            <FontAwesomeIcon icon={['fal', 'search']} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Box mb={2}>
        Hier kun je de gebruikte opdrachten, en geüploade bestanden downloaden.
        Met een klik op het vergrootglas wordt per onderdeel getoond welke
        opdrachten er zijn en of deze bestanden bevatten. Je kunt de opdrachten
        per stuk downloaden of een gebundelde PDF download starten per
        onderdeel. Bestanden dien je altijd per stuk te downloaden.
      </Box>
      <TableContainer>
        <SimpleTable items={summaryItems} columns={columns} />
      </TableContainer>
      {item && (
        <DownloadSummaryDetails education={props.education} item={item} />
      )}
    </>
  );
};

export default Downloads;
