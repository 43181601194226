import React from 'react';
import { FormEntryField } from '../types';
import InternshipTypes, {
  InternshipTypeLabels,
} from '../../education/InternshipTypes';

const InternshipEntryDisplay = (props: FormEntryField) => {
  const { value } = props;

  return (
    <div>
      {InternshipTypes[parseInt(value, 10) as keyof InternshipTypeLabels]}
    </div>
  );
};

export default InternshipEntryDisplay;
