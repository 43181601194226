import * as d3 from 'd3';

class PieChart {
  protected svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;

  protected selector: string;

  protected width: number;

  protected height: number;

  private options: {
    text: boolean;
    colors: readonly string[];
    innerRadius: number;
  } = {
    text: true,
    colors: d3.schemeTableau10,
    innerRadius: 0,
  };

  constructor(selector: string, width: number, height: number) {
    this.selector = selector;
    this.width = width;
    this.height = height;

    this.svg = d3
      .select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height);
  }

  text(show: boolean) {
    this.options.text = show;
    return this;
  }

  colors(scheme: readonly string[]) {
    this.options.colors = scheme;
    return this;
  }

  innerRadius(radius: number) {
    this.options.innerRadius = radius;
    return this;
  }

  plot(data: any, category: string, value: string) {
    const { width, height } = this;
    const pie = d3
      .pie()
      .value((d: any) => d[value])
      .sort(null)
      .startAngle(0)
      .endAngle(2 * Math.PI)(data);

    const arc = d3
      .arc()
      .innerRadius(this.options.innerRadius)
      .outerRadius(width / 2)
      .cornerRadius(0);

    const color = d3
      .scaleOrdinal(this.options.colors)
      .domain(pie.map((d: any) => d.index));

    const g = this.svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    g.selectAll('path')
      .data(pie)
      .enter()
      .append('path')
      .attr('fill', (d: any) => color(d.index))
      .transition()
      .duration(1300)
      .ease(d3.easeCubicInOut)
      .attrTween('d', (d: any) => {
        const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);

        return (t: number) => arc(i(t))!;
      });

    if (this.options.text) {
      g.selectAll('text')
        .data(pie)
        .enter()
        .append('text')
        .text((d: any) => d.data[category])
        .attr('x', (d: any) => arc.innerRadius(this.width / 5).centroid(d)[0])
        .attr('y', (d: any) => arc.innerRadius(this.width / 5).centroid(d)[1])
        .attr('font-size', 14)
        .attr('text-anchor', 'middle');
    }
  }
}

export default PieChart;
