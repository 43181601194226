import React from 'react';

type TabPanelProps = {
  value: string;
  index: string;
  children: React.ReactElement;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return value === index ? children : null;
};

export default TabPanel;
