import React from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ViolationsWarning = () => (
  <Box mb={2}>
    <Alert severity="error">
      Een aantal velden zijn niet correct ingevuld.
    </Alert>
  </Box>
);

export default ViolationsWarning;
