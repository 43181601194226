import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import AssignmentRow from '../assignment/AssignmentRow';
import StyledAccordion from '../../../components/StyledAccordion';
import { GuidanceModule, Module } from '../../../types';
import EducationContext from '../EducationContext';
import ModuleRepository from '../module/ModuleRepository';
import { refreshAccount } from '../../../actions';
import columnConfig from '../columnConfig';
import FormPreviewIcon from '../../forms/FormPreviewIcon';
import { sortAssignments } from '../assignment/helpers';
import DebugPopover from '../../../components/DebugPopover';

interface GuidanceAccordionProps {
  module: GuidanceModule;
  expanded: boolean;
  onChange: (module: Module, expanded: boolean) => void;
  onRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    '&[aria-expanded="true"]': {
      '& $icon': {
        color: '#FFF',
      },
    },
  },
  icon: {
    color: theme.palette.primary.dark,
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rows: {
    width: '100%',
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(100% 
                      - 20%
                      - ${columnConfig.typeColumn.minWidth}px
                      - ${columnConfig.assignmentsColumn.minWidth}px
                      - 50px
               )`,
    wordBreak: 'break-word',
  },
  assignmentsColumn: {
    maxWidth: columnConfig.assignmentsColumn.minWidth,
    width: '100%',
  },
  optionsColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
}));

const GuidanceAccordion = (props: GuidanceAccordionProps) => {
  const { module, expanded, onChange, onRefresh } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const [debugEl, setDebugEl] = useState<HTMLButtonElement | null>(null);
  const [locked, setLocked] = useState<boolean>(false);
  const { educationPermissions } = useContext(EducationContext);

  const finishedAssignmentsCount = module.assignments.filter(
    (a) => a.finished,
  ).length;

  const handleAddModule = (e: React.MouseEvent, module: GuidanceModule) => {
    e.stopPropagation();

    setLocked(true);

    new ModuleRepository()
      .createAssignment(module)
      .then(() => {
        notifications.enqueueSnackbar('Nieuwe opdracht aangemaakt!', {
          variant: 'success',
        });
        dispatch(refreshAccount());
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het aanmaken van de opdracht!',
          { variant: 'error' },
        );
      })
      .finally(() => {
        setTimeout(() => {
          onRefresh();
          setLocked(false);
        }, 1000);
      });
  };

  const handleDebugOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDebugEl(e.currentTarget);
  };

  const handleDebugClose = () => setDebugEl(null);

  useEffect(() => {});

  const summary = (
    <div className={classes.row}>
      <Box display="flex" width="100%">
        <div className={classes.nameColumn}>{module.publishedName}</div>
        <div className={classes.assignmentsColumn}>
          {`${finishedAssignmentsCount}/${module.minimumAssignments}`}
        </div>
        <div className={classes.optionsColumn}>
          {educationPermissions.imitatingAdmin && (
            <Box mr={1}>
              <IconButton
                size="small"
                onClick={handleDebugOpen}
                className={classes.icon}
              >
                <FontAwesomeIcon icon={['fal', 'bug']} />
              </IconButton>
              <DebugPopover
                id={module.id}
                anchorEl={debugEl}
                onClose={handleDebugClose}
                properties={[
                  { name: 'ID', value: module.id },
                  { name: 'AFAS ID', value: module.afasId },
                  { name: 'Module ID', value: module.moduleId },
                  { name: 'Type', value: module.type },
                ]}
              />
            </Box>
          )}
          {module.form && (
            <div>
              <FormPreviewIcon formId={module.form.id} />
            </div>
          )}
          {educationPermissions.edit && (
            <div>
              <IconButton
                onClick={(e: React.MouseEvent) => handleAddModule(e, module)}
                size="small"
                className={classes.icon}
                disabled={locked}
              >
                <FontAwesomeIcon icon={['fal', 'plus-circle']} />
              </IconButton>
            </div>
          )}
        </div>
      </Box>
    </div>
  );

  return (
    <StyledAccordion
      id={`guidance-module-panel-${module.id}`}
      summary={summary}
      summaryClassName={classes.summary}
      expandable={module.assignments.length > 0}
      expanded={expanded}
      onChange={(e, expanded) => {
        onChange(module, expanded);
      }}
      chevron
    >
      <div className={classes.rows}>
        {sortAssignments(module.assignments).map((assignment) => (
          <AssignmentRow
            key={assignment.id}
            assignment={assignment}
            variant="guidance"
            onRefresh={onRefresh}
          />
        ))}
      </div>
    </StyledAccordion>
  );
};

export default GuidanceAccordion;
