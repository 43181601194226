import React from 'react';

import { Route, Router } from '../../routing';
import SyncIssuesOverview from './SyncIssuesOverview';
import SyncIssueDetail from './SyncIssueDetail';

// eslint-disable-next-line import/prefer-default-export
export const ROUTE_SYNC_ISSUES = '/afas/synchronisatie-issues';

const syncIssues = new Route(
  'Synchronisatie Issues',
  ROUTE_SYNC_ISSUES,
  <SyncIssuesOverview />,
).requireLogin();
Router.addRoute(syncIssues);

const syncIssueDetail = new Route(
  'Issue',
  '/:id',
  <SyncIssueDetail />,
).requireLogin();
syncIssues.addChild(syncIssueDetail);
