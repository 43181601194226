import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleTypes, { ModuleType } from './module/ModuleTypes';

const useStyles = makeStyles(() => ({
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switches: {
    display: 'flex',
  },
  switch: {
    marginBottom: 0,
  },
  search: {
    minWidth: 300,
    background: '#fff',
  },
  type: {
    minWidth: 200,
    maxWidth: 200,
    marginTop: -10,
  },
  typeFilter: {
    maxHeight: 40,
  },
  placeholder: {
    opacity: 0.5,
  },
}));

export type AssignmentFilters = {
  deadlines: boolean;
  completed: boolean;
  incomplete: boolean;
};

type DataControls = {
  hideFinishedModules?: boolean | null;
  availableModuleTypes?: ModuleType[];
  selectedModuleTypes?: ModuleType[];
  handleHideFinishedModules?:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  minimal?: null | boolean;
  handleMinimize?: (() => void) | undefined;
  handleSearch?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  handleTypeChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  searchTerm?: string;
};

const DataControls = ({
  hideFinishedModules = false,
  availableModuleTypes = [],
  selectedModuleTypes = [],
  handleHideFinishedModules = () => {},
  minimal = false,
  handleMinimize = () => {},
  handleSearch = () => {},
  handleTypeChange = () => {},
  searchTerm = '',
}: DataControls) => {
  const classes = useStyles();

  const hideModulesControl = (
    <Switch
      checked={hideFinishedModules !== null ? hideFinishedModules : false}
      onChange={handleHideFinishedModules}
      color="secondary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );

  const minimizeControl = (
    <Switch
      checked={minimal !== null ? minimal : false}
      onChange={handleMinimize}
      color="secondary"
      name="checkedB"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );

  return (
    <Box className={classes.controls}>
      <div className={classes.switches}>
        {hideFinishedModules !== null && (
          <FormControlLabel
            control={hideModulesControl}
            label="Verberg afgeronde modules"
            className={classes.switch}
          />
        )}
        {minimal !== null && (
          <FormControlLabel
            control={minimizeControl}
            label="Minimale weergave"
            className={classes.switch}
          />
        )}
      </div>
      <Box display="flex" alignItems="center">
        {availableModuleTypes && availableModuleTypes.length > 1 && (
          <Box display="flex" mr={2}>
            <Select
              id="module-type-filter"
              value={selectedModuleTypes}
              MenuProps={{ elevation: 1 }}
              onChange={handleTypeChange}
              displayEmpty
              autoWidth
              className={classes.typeFilter}
              renderValue={(selected) => {
                if ((selected as string[]).length === 0) {
                  return (
                    <span className={classes.placeholder}>
                      Filter op type...
                    </span>
                  );
                }

                return (selected as string[]).join(', ');
              }}
              variant="outlined"
              inputProps={{ size: 'small' }}
              multiple
            >
              <MenuItem value="" disabled className={classes.placeholder}>
                Filter op type...
              </MenuItem>
              {availableModuleTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {`${ModuleTypes[type]} (${type})`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {handleSearch !== null && (
          <FormControl>
            <TextField
              type="text"
              label="Zoeken"
              value={searchTerm}
              className={classes.search}
              onChange={handleSearch}
              data-testid="search-box"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={['fal', 'search']} />
                  </InputAdornment>
                ),
              }}
              placeholder="Zoeken..."
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
};

export default DataControls;
