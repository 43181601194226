import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import DataTable from '../../../components/DataTable';
import DialogCloseButton from '../../../components/DialogCloseButton';
import { FaqCategory, FaqCategoryDTO } from './types';
import FaqCategoryRepository from './FaqCategoryRepository';

interface FaqCategoryManagerDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  content: {
    minWidth: 400,
  },
  nameControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const FaqCategoryManagerDialog = (props: FaqCategoryManagerDialogProps) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const [title, setTitle] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean | null>(null);
  const notifications = useSnackbar();
  const [repository, setRepository] = useState<FaqCategoryRepository>(
    new FaqCategoryRepository(),
  );
  const [changedCategories, setChangedCategories] = useState<FaqCategoryDTO[]>(
    [],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setValid(true);
    }

    setTitle(e.target.value);
  };

  const markCategoryAsChanged = (category: FaqCategoryDTO) => {
    if (!changedCategories.find((c) => c.id === category.id)) {
      changedCategories.push(category);
      setChangedCategories([...changedCategories]);
    }
  };

  const doChangeCategory = (category: FaqCategoryDTO) => {
    const changedCategory = changedCategories.find((c) => c.id === category.id);
    if (!changedCategory || !changedCategory.id) {
      return;
    }

    repository.update(changedCategory.id, changedCategory).then(() => {
      notifications.enqueueSnackbar('De categorie is gewijzigd!', {
        variant: 'success',
      });
      setChangedCategories(
        changedCategories.filter((c) => c.id !== category.id),
      );
      setRepository(new FaqCategoryRepository());
    });
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: FaqCategoryDTO,
  ) => {
    const index = changedCategories.findIndex((c) => c.id === category.id);
    if (index === -1) {
      return;
    }

    changedCategories[index] = { ...category, title: event.target.value };
    setChangedCategories([...changedCategories]);
  };

  const handleAddCategory = () => {
    if (title === null || title.length === 0) {
      setValid(false);
      return;
    }

    repository
      .create({ title })
      .then(() => {
        // Refreshes data table.
        setRepository(new FaqCategoryRepository());
        setTitle(null);
        notifications.enqueueSnackbar('De categorie is aangemaakt!', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij aanmaken van categorie!', {
          variant: 'error',
        });
      });
  };

  const handleMove = (id: string, direction: 'up' | 'down') => {
    repository
      .move(id, direction)
      .then(() => {
        notifications.enqueueSnackbar('Volgorde opgeslagen!', {
          variant: 'success',
        });
        setRepository(new FaqCategoryRepository()); // Reload data table.
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij veranderen van volgorde!', {
          variant: 'error',
        });
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Categori&euml;n beheren
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={2}>
          <FormControl className={classes.nameControl}>
            <TextField
              value={title}
              onChange={handleChange}
              label="Titel"
              error={valid !== null && !valid}
              fullWidth
            />
            <IconButton onClick={handleAddCategory} size="small">
              <FontAwesomeIcon icon={['fal', 'plus']} />
            </IconButton>
          </FormControl>
        </Box>
        <DataTable
          id="faq-category-manager"
          repository={repository}
          columns={[
            {
              name: 'Titel',
              field: 'title',
              sortable: true,
              render: (category: FaqCategory) => {
                const onCategoryChange = (
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => handleCategoryChange(event, category);

                if (changedCategories.find((c) => c.id === category.id)) {
                  return (
                    <TextField
                      onChange={onCategoryChange}
                      defaultValue={category.title}
                    />
                  );
                }

                return <div>{category.title}</div>;
              },
            },
            {
              name: 'Items',
              field: 'itemCount',
            },
          ]}
          actions={(category: FaqCategory, className: string) => {
            let editButton: React.ReactElement;

            if (changedCategories.find((c) => c.id === category.id)) {
              editButton = (
                <IconButton
                  className={className}
                  size="small"
                  onClick={() => doChangeCategory(category)}
                >
                  <FontAwesomeIcon icon={['fal', 'check']} />
                </IconButton>
              );
            } else {
              editButton = (
                <IconButton
                  className={className}
                  size="small"
                  onClick={() => markCategoryAsChanged(category)}
                >
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </IconButton>
              );
            }

            return (
              <div>
                <Tooltip title="Omhoog verplaatsen">
                  <IconButton
                    size="small"
                    className={className}
                    onClick={() => handleMove(category.id, 'up')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-up']} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Omlaag verplaatsen">
                  <IconButton
                    size="small"
                    className={className}
                    onClick={() => handleMove(category.id, 'down')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                  </IconButton>
                </Tooltip>
                {editButton}
              </div>
            );
          }}
          deletable
        />
      </DialogContent>
    </Dialog>
  );
};

export default FaqCategoryManagerDialog;
