import React from 'react';

import { ReactComponent as WarningIllustration } from '../../assets/images/warning_illustration.svg';
import ErrorPage from './ErrorPage';

const Error404 = () => (
  <ErrorPage
    title="Helaas!"
    // eslint-disable-next-line max-len
    description="Het leermateriaal is nog niet beschikbaar, voor vragen kun je contact opnemen met de programma assistent of opleider die vermeld staan onder de i."
    illustration={WarningIllustration}
  />
);

export default Error404;
