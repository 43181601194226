import { AxiosResponse } from 'axios';
import { RegisterEvent } from '../../../types';
import ApiClient from '../../../api/ApiClient';

class EventRegisterRepository {
  findAvailableEventsOfModule(
    moduleId: string,
  ): Promise<AxiosResponse<RegisterEvent[]>> {
    return ApiClient.get(`/api/module/${moduleId}/event/available`);
  }

  findEventOfModule(
    moduleId: string,
    eventId: number,
  ): Promise<AxiosResponse<RegisterEvent>> {
    return ApiClient.get(`/api/module/${moduleId}/event/${eventId}`);
  }
}

export default EventRegisterRepository;
