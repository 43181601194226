import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { EditorProps } from './EditorProps';

const useStyles = makeStyles(() => ({
  typeSelect: {
    minWidth: 120,
  },
}));

const TextEditor = (props: EditorProps) => {
  const classes = useStyles();
  const { field, setPreview, onChange } = props;
  const { metadata } = field;
  const [state, setState] = useState<{
    placeholder: string;
    type: string;
  }>({
    placeholder: metadata.placeholder || '',
    type: metadata.type || 'text',
  });
  const { placeholder, type } = state;

  useEffect(() => {
    field.metadata = { ...field.metadata, placeholder, type };
    onChange(field);

    setPreview(
      <Grid container>
        <Grid item sm={12} md={6}>
          <TextField
            required={field.required}
            placeholder={placeholder}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>,
    );
  }, [field, state]);

  const handlePlaceholderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState({ ...state, placeholder: event.target.value });
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({ ...state, type: event.target.value as string });
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          label="Placeholder"
          value={placeholder}
          onChange={handlePlaceholderChange}
        />
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel>Soort veld</InputLabel>
          <Select
            className={classes.typeSelect}
            value={type}
            onChange={handleTypeChange}
            autoWidth
            displayEmpty
          >
            <MenuItem value="text">Tekst</MenuItem>
            <MenuItem value="email">E-mailadres</MenuItem>
            <MenuItem value="url">Link (URL)</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TextEditor;
