import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';
import { Education } from '../../types';
import { updateEducation } from '../../actions';
import AppContext from '../../AppContext';

interface EducationCardProps {
  education: Education;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    minHeight: 170,
    '&:hover': {
      cursor: 'pointer',
      '& $rightArrow': {
        transform: 'translateY(-50%) translateX(3px)',
      },
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: 18,
  },
  background: {
    position: 'absolute',
    right: -100,
    bottom: -40,
    opacity: 0.07,
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
    transition: theme.transitions.create('all'),
  },
}));

const EducationCard = (props: EducationCardProps) => {
  const { education } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { localStore } = useContext(AppContext);

  const handleClick = () => {
    dispatch(updateEducation(education, localStore));
    history.push(`/onderwijs/${education.id}`);
  };

  return (
    <Card className={classes.card} onClick={handleClick}>
      <CardContent>
        <h2 className={classes.h2}>{education.name}</h2>
      </CardContent>
      <FontAwesomeIcon
        icon={['fal', 'chevron-right']}
        className={classes.rightArrow}
      />
      <div className={classes.background}>
        <FontAwesomeIcon
          icon="graduation-cap"
          style={{ width: 200, height: 200 }}
        />
      </div>
    </Card>
  );
};

export default EducationCard;
