import React from 'react';

import { ReactComponent as ServerDownIllustration } from '../../assets/images/server_down.svg';
import ErrorPage from './ErrorPage';

const Error500 = () => (
  <ErrorPage
    title="Oeps!"
    description="Er is iets fout gegaan. Probeer het later opnieuw."
    illustration={ServerDownIllustration}
  />
);

export default Error500;
