import { AxiosResponse } from 'axios';

import { ApiFilterCriteria, CollectionResponse, Repository } from '../../types';
import ApiClient from '../../api/ApiClient';
import { Form, FormCategory } from '../yard-forms/types';
import { FormBuilderState } from '../yard-forms/FormBuilder';
import { parseCriteria } from '../../utils/common';
import { FormFieldInterface } from '../yard-forms/FormField';
import { EvaluationTypes } from '../yard-forms/EvaluationTypeSelect';

export interface FormSaveRequest {
  id: string;
  title: string;
  description: string | null;
  fields: FormFieldInterface[];
  evaluationType: EvaluationTypes;
  evaluationOptions: string[];
  categories: FormCategory[];
  attachments: string[];
}

class FormRepository implements Repository<Form> {
  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/forms/${id}`);
  }

  find(id: string): Promise<AxiosResponse<Form>> {
    return ApiClient.get(`/api/forms/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<Form>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: 10,
    });

    return ApiClient.get('/api/forms', params);
  }

  create(form: FormSaveRequest) {
    return ApiClient.post('/api/forms/', form);
  }

  update(id: string, form: FormSaveRequest) {
    return ApiClient.put(`/api/forms/${id}`, form);
  }

  import(form: FormBuilderState) {
    return ApiClient.post('/api/forms/import', form);
  }

  duplicate(id: string) {
    return ApiClient.get(`/api/forms/${id}/duplicate`);
  }
}

export default FormRepository;
