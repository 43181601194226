import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import PageContainer from '../../components/PageContainer';
import UserRepository from './UserRepository';
import { CompleteUser, EventLog } from '../../types';
import ApiClient from '../../api/ApiClient';
import Gender from './Gender';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontWeight: 700,
  },
  tableTitleBox: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  tableTitle: {
    fontSize: theme.typography.h5.fontSize,
  },
  neverLogin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ViewUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<CompleteUser | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [loginEvents, setLoginEvents] = useState<EventLog[]>([]);
  const extended = user && !!user.afasId;

  useEffect(() => {
    const repository = new UserRepository();

    repository
      .getCompleteUser(id)
      .then((res) => setUser(res.data))
      .catch(() => history.push('/gebruikers'));

    repository
      .getLoginEvents(id)
      .then((res) => setLoginEvents(res.data['hydra:member']));

    ApiClient.getRoles().then((res) => setRoles(res.data));
  }, [history, id]);

  return (
    <div>
      {user && (
        <PageContainer title={user.fullName}>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <Box className={classes.tableTitleBox}>
                      <h2 className={classes.tableTitle}>Persoonsgegevens</h2>
                    </Box>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.label}>Naam</TableCell>
                          <TableCell>{user.fullName}</TableCell>
                        </TableRow>
                        {extended && (
                          <>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Roepnaam
                              </TableCell>
                              <TableCell>{user.nickname}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Geboortedatum
                              </TableCell>
                              <TableCell>
                                {user.dateOfBirth
                                  ? moment(user.dateOfBirth).format(
                                      'DD-MM-YYYY',
                                    )
                                  : ''}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Geslacht
                              </TableCell>
                              <TableCell>
                                {Gender[user.gender || 'O']}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Registratienummer (bijv. BIG)
                              </TableCell>
                              <TableCell>{user.bigId}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Specialisme
                              </TableCell>
                              <TableCell>{user.specialisation}</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {extended && (
                  <Grid item md={12}>
                    <TableContainer component={Paper}>
                      <Box className={classes.tableTitleBox}>
                        <h2 className={classes.tableTitle}>Contactgegevens</h2>
                      </Box>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.label}>
                              Mobiel
                            </TableCell>
                            <TableCell>{user.phoneMobile}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.label}>
                              Adres
                            </TableCell>
                            <TableCell>
                              {`${user.addressStreet} ${user.addressHouseNumber}${user.addressHouseNumberAddition}`}
                              <br />
                              {`${user.addressPostalCode} ${user.addressTown}`}
                              {user.addressCountryCode !== 'NL' && (
                                <>
                                  <br />
                                  {user.addressCountry}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
                {extended && (
                  <Grid item md={12}>
                    <TableContainer component={Paper}>
                      <Box className={classes.tableTitleBox}>
                        <h2 className={classes.tableTitle}>Voorkeuren</h2>
                      </Box>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.label}>
                              Nieuwsbrief
                            </TableCell>
                            <TableCell>
                              {user.newsletter ? 'Ja' : 'Nee'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.label}>
                              Dieetwensen
                            </TableCell>
                            <TableCell>{user.dietaryPreferences}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <Box className={classes.tableTitleBox}>
                      <h2 className={classes.tableTitle}>Gebruikersgegevens</h2>
                    </Box>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.label}>
                            Gebruikersnaam
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                        </TableRow>
                        {extended && (
                          <>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Relatie-ID
                              </TableCell>
                              <TableCell>{user.afasId}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.label}>
                                Deelnemer-ID
                              </TableCell>
                              <TableCell>{user.participantId}</TableCell>
                            </TableRow>
                          </>
                        )}
                        {user.lastSync && (
                          <TableRow>
                            <TableCell className={classes.label}>
                              Laatste synchronisatie
                            </TableCell>
                            <TableCell>
                              {moment(user.lastSync).format('YYYY-MM-DD HH:mm')}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <Box className={classes.tableTitleBox}>
                      <h2 className={classes.tableTitle}>Rollen</h2>
                    </Box>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.label}>
                            Rollen
                          </TableCell>
                          <TableCell>
                            {Object.entries(roles).length > 0 &&
                              Object.entries(roles)
                                .filter(([role]) =>
                                  user.roles.includes(role as any),
                                )
                                .map((role) => role[1])
                                .join(', ')}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <Box className={classes.tableTitleBox}>
                      <h2 className={classes.tableTitle}>Laatste logins</h2>
                      {loginEvents.length === 0 && (
                        <div className={classes.neverLogin}>
                          Deze gebruiker heeft nog nooit ingelogd.
                        </div>
                      )}
                    </Box>
                    {loginEvents.length > 0 && (
                      <Table>
                        <TableBody>
                          {loginEvents.map((event) => {
                            const formattedDate = moment(event.created).format(
                              'YYYY-MM-DD HH:mm',
                            );
                            const fromNow = moment(event.created)
                              .locale('nl')
                              .fromNow();
                            const contents = `${formattedDate} (${fromNow})`;

                            return (
                              <TableRow>
                                <TableCell>{contents}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageContainer>
      )}
    </div>
  );
};

export default ViewUser;
