import React, { useState } from 'react';
import { Box, IconButton, Popover } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import { Assignment, AssignmentEvaluation } from '../../../types';

interface AssignmentValuationProps {
  assignment: Assignment;
}

const AssignmentValuation = ({ assignment }: AssignmentValuationProps) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setInfoAnchorEl(event.currentTarget);
  const handleClose = () => setInfoAnchorEl(null);

  let evaluation: AssignmentEvaluation | null = null;

  if (
    assignment.evaluations.length > 0 &&
    (assignment.valuation || assignment.grade)
  ) {
    [evaluation] = assignment.evaluations.sort((a, b) =>
      new Date(a.created) > new Date(b.created) ? -1 : 1,
    );
  }

  let valuationText = '';
  if (assignment.grade) {
    const gradeText = `Cijfer: ${assignment.grade}`;

    valuationText = assignment.valuation
      ? `${assignment.valuation} (${gradeText})`
      : gradeText;
  } else if (assignment.valuation) {
    valuationText = assignment.valuation;
  }

  return (
    <div>
      {valuationText}
      {evaluation && (
        <Box ml={1} display="inline-block">
          <IconButton size="small" onClick={handleOpen}>
            <FontAwesomeIcon icon={['fal', 'info-circle']} />
          </IconButton>
          <Popover
            open={Boolean(infoAnchorEl)}
            onClose={handleClose}
            anchorEl={infoAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            elevation={1}
          >
            <Box p={2}>
              {`Beoordelaar: ${evaluation.teacher.fullNameLastNameFirst}`}
              <br />
              {`Datum: ${moment(evaluation.created).format(
                'DD-MM-YYYY HH:mm',
              )}`}
            </Box>
          </Popover>
        </Box>
      )}
    </div>
  );
};

export default AssignmentValuation;
