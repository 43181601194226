import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import FormCategoryRepository from '../forms/FormCategoryRepository';
import { FormCategory } from './types';

interface FormCategorySelectProps {
  initialSelected?: FormCategory[];
  onChange: (categories: FormCategory[]) => void;
  className?: string;
  readOnly?: boolean;
}

const FormCategorySelect = (props: FormCategorySelectProps) => {
  const { initialSelected, onChange, className } = props;
  const readOnly = props.readOnly !== undefined ? props.readOnly : false;
  const [categories, setCategories] = useState<FormCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<FormCategory[]>(
    initialSelected || [],
  );

  useEffect(() => {
    new FormCategoryRepository()
      .findBy(undefined, 1, undefined, 1000)
      .then((response) => {
        setCategories(response.data.items);
      });
  }, []);

  useEffect(() => {
    onChange(selectedCategories);
  }, [selectedCategories]);

  const handleAutocompleteChange = (event: any, options: any) => {
    setSelectedCategories(options);
  };

  return (
    <Autocomplete
      multiple
      id="form-categories"
      getOptionLabel={(option) => option.name}
      options={categories}
      onChange={handleAutocompleteChange}
      noOptionsText="Geen opties beschikbaar."
      value={selectedCategories}
      disabled={readOnly}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="outlined"
          label="Categorie"
          placeholder="Selecteer een categorie..."
        />
      )}
      className={className}
    />
  );
};

export default FormCategorySelect;
