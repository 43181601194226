// @ts-ignore
import Flow from '@flowjs/flow.js';
// eslint-disable-next-line import/no-unresolved
import { Flow as IFlow } from 'flowjs';
import { ApiFilterCriteria, File } from '../types';

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function testUuid(pattern: string, subject: string) {
  const regexPattern = pattern.replace(
    /\{.*?\}/,
    '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}',
  );

  return new RegExp(regexPattern).test(subject);
}

export function matchUuid(pattern: string, subject: string) {
  const regexPattern = pattern.replace(
    /\{.*?\}/,
    '([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
  );

  return subject.match(new RegExp(regexPattern));
}

export function getFileURL(file: File) {
  let url = `${process.env.REACT_APP_API_URL}/api/file/${file.id}`;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    url += `?token=${urlParams.get('token')}`;
  }

  return url;
}

export function getImageURL(
  file: File,
  filter?: 'banner' | 'thumbnail' | 'icon',
) {
  if (!filter) {
    return getFileURL(file);
  }

  let url = `${process.env.REACT_APP_API_URL}/api/file/image/${file.id}/${filter}`;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    url += `?token=${urlParams.get('token')}`;
  }

  return url;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Parses API filter criteria to a params object.
 *
 * @param criteria
 * @param params
 */
export function parseCriteria(
  criteria: ApiFilterCriteria | undefined,
  params: {
    [key: string]: string | number | null | boolean;
  },
) {
  if (!criteria) {
    return params;
  }

  const result: {
    [key: string]: string | number | null | boolean;
  } = {
    ...params,
    query: criteria.query || null,
  };

  if (criteria.filters) {
    Object.entries(criteria.filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.map((v) => v.value).join(',');
      } else {
        result[key] = value;
      }
    });
  }

  if (criteria.order) {
    criteria.order.forEach((item) => {
      result[`order[${item.field}]`] = item.order;
    });
  }

  return result;
}

/**
 * Create a new Flow instance for uploading files.
 */
export function createFlow(): IFlow {
  const query: {
    token?: string;
  } = {};

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    query.token = urlParams.get('token') as string;
  }

  return new Flow({
    target: `${process.env.REACT_APP_API_URL}/api/file`,
    query,
    withCredentials: true,
  });
}

/**
 * Remove HTML tags from a string.
 */
export function stripHtmlTags(str: string) {
  return str.replace(/<[^>]*>?/gm, '');
}

export function round(value: number, precision: number = 0): number {
  const multiplier = 10 ** (precision || 0);

  return Math.round(value * multiplier) / multiplier;
}
