import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Link, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import settings from '../../config/settings';
import { gradients } from '../../config/theme';
import SafeHtml from '../SafeHtml';
import { capitalize } from '../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: `${theme.spacing(6) + settings.header.height}px`,
    paddingBottom: theme.spacing(4),
    background: gradients.primaryLight,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  headerContentFullWidth: {
    maxWidth: '100%',
    width: '100%',
  },
  headerContentSection: {
    width: '100%',
  },
  educationName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 32,
  },
  illustration: {
    '& svg': {
      width: 'auto',
      marginLeft: theme.spacing(7),
    },
  },
  sizeSmall: {
    '& $illustration': {
      '& svg': {
        height: 100,
      },
    },
  },
  sizeMedium: {
    '& $illustration': {
      '& svg': {
        height: 125,
      },
    },
  },
  sizeLarge: {
    '& $illustration': {
      '& svg': {
        height: 150,
      },
    },
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type TextMedia = {
  name: string;
  size?: 'small' | 'medium' | 'large';
  subtitle?: string;
  description?: string | React.ReactElement;
  descriptionSpacing?: number;
  illustration?: React.ReactElement | null;
  callToActionText?: string;
  callToActionLink?: string;
  children?: React.ReactElement | boolean;
  className?: string;
  wrapperClassName?: string;
  fullWidth?: boolean;
};

const TextMedia = ({
  subtitle = '',
  name = '',
  description = '',
  descriptionSpacing = 3,
  illustration = null,
  callToActionText = '',
  callToActionLink = '',
  children,
  size = 'large',
  className = '',
  wrapperClassName = '',
  fullWidth = false,
}: TextMedia) => {
  const classes: any = useStyles();
  const sizeClass = classes[`size${capitalize(size)}`];

  const headerContentClasses = [classes.headerContent];

  if (fullWidth) {
    headerContentClasses.push(classes.headerContentFullWidth);
  }

  return (
    <div className={`${classes.header} ${sizeClass} ${className}`}>
      <Container className={classes.headerContainer}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          className={wrapperClassName}
        >
          <Box className={headerContentClasses.join(' ')} minWidth="65%">
            <div className={classes.headerContentSection}>
              <Typography variant="h6" component="h2">
                {subtitle}
              </Typography>
              <Box mb={3}>
                <Typography
                  variant="h4"
                  component="h1"
                  className={classes.educationName}
                >
                  {name}
                </Typography>
              </Box>
              {description && (
                <Box mb={descriptionSpacing}>
                  {typeof description === 'string' && (
                    <SafeHtml html={description} />
                  )}
                  {typeof description !== 'string' && (
                    <Typography variant="body1" component="div">
                      {description}
                    </Typography>
                  )}
                </Box>
              )}
              {callToActionText && (
                <Typography color="textPrimary">
                  <Link
                    className={classes.link}
                    href={callToActionLink}
                    color="inherit"
                  >
                    <Box mr={1} component="span">
                      {callToActionText}
                    </Box>
                    <FontAwesomeIcon
                      color="#000"
                      size="xs"
                      icon={['fas', 'chevron-right']}
                    />
                  </Link>
                </Typography>
              )}
            </div>
            {children && (
              <div className={classes.headerContentSection}>{children}</div>
            )}
          </Box>
          <div className={classes.illustration}>{illustration}</div>
        </Box>
      </Container>
    </div>
  );
};

export default TextMedia;
