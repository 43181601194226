import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AppContext from '../../../AppContext';
import Loader from '../../../components/Loader';
import { Education, EducationModule, Event, Module } from '../../../types';
import columnConfig from '../columnConfig';
import { filterModulesByName, sortModulesByColumn } from '../EducationHelpers';
import EducationRepository from '../EducationRepository';
import TabPanelHeadSortable from '../TabPanelHeadSortable';
import EventRegisterWizard from './EventRegisterWizard';
import ModuleAccordion from './ModuleAccordion';
import { ModuleType } from './ModuleTypes';
import WorkshopsSelectionWizard from './WorkshopsSelectionWizard';
import DataControls from '../DataControls';

const useStyles = makeStyles((theme: Theme) => ({
  modulePanel: {
    marginBottom: theme.spacing(2),
  },
  sortable: {
    display: 'flex',
    width: '100%',
    fontSize: 17,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  nameColumn: columnConfig.nameColumn,
  typeColumn: columnConfig.typeColumn,
  assignmentsColumn: columnConfig.assignmentsColumn,
  statusColumn: columnConfig.statusColumn,
  startDateColumn: columnConfig.startDateColumn,
  furtherEducationNameColumn: columnConfig.furtherEducation.nameColumn,
  furtherEducationPointsColumn: columnConfig.furtherEducation.pointsColumn,
}));

type SortableColumn = 'publishedName' | 'type' | 'status' | 'date';

interface Ordering {
  order: 'ASC' | 'DESC';
  orderBy: SortableColumn;
}

interface InstituteEducationProps {
  education: Education;
}

const InstituteEducation = (props: InstituteEducationProps) => {
  const classes = useStyles();
  const { localStore } = useContext(AppContext);

  const [allModules, setAllModules] = useState<Module[] | undefined>(undefined);
  const [shownModules, setShownModules] = useState<Module[]>([]);

  const moduleTypes = useMemo(() => {
    if (!allModules) {
      return [];
    }

    // extract module types (no duplicates)
    return allModules
      .map((module) => module.type)
      .filter((type, index, types) => types.indexOf(type) === index);
  }, [allModules]);

  const [selectedModuleTypes, setSelectedModuleTypes] = useState<ModuleType[]>(
    [],
  );

  const [ordering, setOrdering] = useState<Ordering>({
    order: 'ASC',
    orderBy: 'publishedName',
  });

  const loadModules = useCallback(() => {
    new EducationRepository()
      .getInstituteEducationModules(props.education.id)
      .then((response) => setAllModules(response.data));
  }, [props.education.id]);

  const handleSortBy = (column: SortableColumn) => {
    setOrdering((prev) => ({
      orderBy: column,
      order: prev.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };

  const [hideFinishedModules, setHideFinishedModules] =
    useState<boolean>(false);
  const [expandedModules, setExpandedModules] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handlePanelChange = (module: Module, expanded: boolean) => {
    setExpandedModules((prev) => {
      if (expanded) {
        return [...prev, module.id];
      }
      return prev.filter((id: string) => module.id !== id);
    });
  };

  const toggleHideFinishedModules = () => {
    setHideFinishedModules((prev) => !prev);
  };

  const toggleMinimize = useCallback(() => {
    setExpandedModules((prev) =>
      prev.length > 0 ? [] : shownModules.map((m) => m.id),
    );
  }, [shownModules]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as ModuleType[];
    setSelectedModuleTypes(moduleTypes.filter((type) => value.includes(type)));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value as string);
  };

  const filterModules = useCallback(
    (modules: Module[]) => {
      let filteredModules = [...modules];

      if (hideFinishedModules) {
        filteredModules = filteredModules.filter(
          (module: Module) => !module.finished,
        );
      }

      if (selectedModuleTypes.length >= 1) {
        filteredModules = filteredModules.filter((module: Module) =>
          selectedModuleTypes.includes(module.type),
        );
      }

      if (searchTerm.length >= 1) {
        filteredModules = filterModulesByName(modules, searchTerm);
      }

      filteredModules = sortModulesByColumn(
        filteredModules,
        ordering.orderBy,
        ordering.order,
      );

      return filteredModules;
    },
    [hideFinishedModules, searchTerm, selectedModuleTypes, ordering],
  );

  // stores the module that was selected in the event registration wizard
  const [eventRegisterWizardModule, setEventWizardModule] =
    useState<Module | null>(null);

  // stores the module and the event were selected in the workshops registration wizard
  const [workshopsSelectionWizardModule, setWorkshopsSelectionWizardModule] =
    useState<Module | null>(null);
  const [workshopsSelectionWizardEvent, setWorkshopsSelectionWizardEvent] =
    useState<Event | null>(null);

  const handleEventWizardSelection = (module: Module) => {
    setEventWizardModule(module);
  };

  const handleWorkshopsWizardSelection = (module: Module, event: Event) => {
    setWorkshopsSelectionWizardModule(module);
    setWorkshopsSelectionWizardEvent(event);
  };

  const handleEventRegisterWizardClose = (event?: object, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    setEventWizardModule(null);
    loadModules();
  };

  const handleWorkshopsSelectionWizardClose = (
    event?: object,
    reason?: string,
  ) => {
    if (reason === 'backdropClick') {
      return;
    }

    setWorkshopsSelectionWizardModule(null);
    setWorkshopsSelectionWizardEvent(null);
    loadModules();
  };

  useEffect(() => {
    loadModules();
  }, [loadModules]);

  useEffect(() => {
    if (allModules) {
      setShownModules(filterModules(allModules));
    }
  }, [allModules, filterModules]);

  useEffect(() => {
    localStore.setItem(
      `${props.education.id}_io_hide_finished_modules`,
      hideFinishedModules,
    );
  }, [localStore, props.education.id, hideFinishedModules]);

  useEffect(() => {
    localStore
      .getItem<boolean>(`${props.education.id}_io_hide_finished_modules`)
      .then((value) => {
        if (!value) {
          return;
        }

        setHideFinishedModules(value);
      });
  }, [localStore, props.education.id]);

  useEffect(() => {
    localStore.setItem(
      `${props.education.id}_io_expanded_modules`,
      expandedModules,
    );
  }, [localStore, props.education.id, expandedModules]);

  useEffect(() => {
    localStore
      .getItem<string[]>(`${props.education.id}_io_expanded_modules`)
      .then((value) => {
        if (!value) {
          return;
        }

        setExpandedModules(value);
      });
  }, [localStore, props.education.id]);

  if (!allModules) {
    return <Loader />;
  }

  return (
    <>
      {eventRegisterWizardModule &&
        eventRegisterWizardModule.eventRegistrationAllowed && (
          <EventRegisterWizard
            module={eventRegisterWizardModule}
            onClose={handleEventRegisterWizardClose}
            open
          />
        )}
      {workshopsSelectionWizardModule && workshopsSelectionWizardEvent && (
        <WorkshopsSelectionWizard
          module={workshopsSelectionWizardModule}
          eventId={workshopsSelectionWizardEvent.afasId}
          onClose={handleWorkshopsSelectionWizardClose}
          open
        />
      )}
      <Box mb={5}>
        <DataControls
          hideFinishedModules={hideFinishedModules}
          minimal={expandedModules.length === 0}
          handleHideFinishedModules={toggleHideFinishedModules}
          handleMinimize={toggleMinimize}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          availableModuleTypes={moduleTypes}
          selectedModuleTypes={selectedModuleTypes}
          handleTypeChange={handleTypeChange}
        />
      </Box>
      <Box className={classes.sortable}>
        <TabPanelHeadSortable
          className={`${classes.column} ${
            props.education.furtherEducation
              ? classes.furtherEducationNameColumn
              : classes.nameColumn
          }`}
          label="Naam"
          handleSortBy={handleSortBy}
          sortBy="publishedName"
          order={ordering.order}
          orderBy={ordering.orderBy}
        />
        {!props.education.furtherEducation && (
          <TabPanelHeadSortable
            label="Type"
            className={`${classes.column} ${classes.typeColumn}`}
            handleSortBy={handleSortBy}
            sortBy="type"
            order={ordering.order}
            orderBy={ordering.orderBy}
          />
        )}
        <div className={`${classes.column} ${classes.assignmentsColumn}`}>
          Opdrachten
        </div>
        {props.education.furtherEducation && (
          <div
            className={`${classes.column} ${classes.furtherEducationPointsColumn}`}
          >
            Punten
          </div>
        )}
        <TabPanelHeadSortable
          className={`${classes.column} ${classes.statusColumn}`}
          label="Status"
          handleSortBy={handleSortBy}
          sortBy="status"
          order={ordering.order}
          orderBy={ordering.orderBy}
        />
        <TabPanelHeadSortable
          className={`${classes.column} ${classes.startDateColumn}`}
          label="Startdatum"
          handleSortBy={handleSortBy}
          sortBy="date"
          order={ordering.order}
          orderBy={ordering.orderBy}
        />
      </Box>
      <Box>
        {shownModules.map((module: Module) => (
          <ModuleAccordion
            key={module.id}
            className={classes.modulePanel}
            variant={
              props.education?.furtherEducation
                ? 'further-education'
                : 'education'
            }
            module={module as EducationModule}
            expanded={expandedModules.includes(module.id)}
            onChange={handlePanelChange}
            onEventWizardSelection={handleEventWizardSelection}
            onWorkshopsWizardSelection={handleWorkshopsWizardSelection}
          />
        ))}
      </Box>
    </>
  );
};

export default InstituteEducation;
