import { CancelTokenSource } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  MinimalUser,
  Repository,
} from '../../types';
import ApiClient from '../../api/ApiClient';
import { parseCriteria } from '../../utils/common';

class EvaluationUserRepository implements Repository<MinimalUser> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    cancelToken?: CancelTokenSource,
  ): Promise<CollectionResponse<MinimalUser>> {
    const params = parseCriteria(criteria, { page: page || 1 });
    return ApiClient.get(
      '/api/educators/evaluation-educators',
      params,
      {},
      { cancelToken: cancelToken?.token },
    );
  }
}

export default EvaluationUserRepository;
