/* eslint-disable linebreak-style */
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import axios, { CancelTokenSource } from 'axios';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useHistory } from 'react-router-dom';
import { ApiFilterCriteria, Repository } from '../types';
import { colors } from '../config/theme';
import FilterColumn, {
  FilterColumnConfig,
  FilterColumnOption,
  FilterColumnOptionCallback,
  FilterColumnOptionSearchCallback,
} from './FilterColumn';
import ConditionalWrapper from './ConditionalWrapper';
import SearchContext from './search/SearchContext';
import AppContext from '../AppContext';
import ConfirmationDialog from './ConfirmationDialog';

interface Item {
  id: string;

  [key: string]: any;
}

export interface Column {
  // Name of the column as shown in the header.
  name: string;
  // Name of the field to show.
  field: string;
  // Determines if the user can sort on this column.
  sortable?: boolean;
  // Filters associated with the column.
  filter?:
    | {
        type: 'checkbox' | 'autocomplete' | 'datepicker';
        options?: FilterColumnOption[] | FilterColumnOptionCallback;
        searchOptions?: FilterColumnOptionSearchCallback;
        config?: FilterColumnConfig;
      }
    | undefined;
  // Custom rendering function for this column.
  render?: (item: any) => ReactNode;
}

interface DataTableProps {
  // A unique identifier for this data table.
  id: string;
  // The repository to use for retrieving the data.
  repository: Repository<unknown>;
  // All the columns of the data table.
  columns: Column[];
  // Whether items in the data table can be deleted or not.
  deletable?: boolean;
  // Callback when deleting an item fails.
  onDeleteFail?: (item: any) => void;
  // Callback that renders actions that are available for each item.
  actions?: (item: any, className: string, loadItems: () => void) => ReactNode;
  // Callback when the data table loads data.
  onLoad?: (items: any, totalCount: number) => void;
  // Message when the user attempts to delete an item.
  deleteItemMessage?: (item: any) => string;
  // Custom class for the data table.
  className?: string;
  // Whether the data table is contained in a container or not.
  contained?: boolean;
  // Whether the filter state should be persisted.
  persistFilters?: boolean;
  // The filtering that's set on load.
  defaultFilters?: ApiFilterCriteria;
  // Custom display for when there are no results found.
  noResults?: ReactNode;
  // Callback when filters change.
  onFiltersChange?: (filters: ApiFilterCriteria) => void;
  // Callback that renders a table row.
  renderRow?: (
    columns: React.ReactElement,
    item: any,
    rowClassName: string,
    columnClassName: string,
  ) => React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: colors.primary.ultraLight,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  tableWrapper: {
    position: 'relative',
    minHeight: 100,
    width: '100%',
  },
  table: {
    borderCollapse: 'initial',
    borderSpacing: `0 ${theme.spacing(1)}px`,
    paddingTop: 0,
  },
  tableContained: {
    paddingTop: 0,
    padding: theme.spacing(2),
  },
  tableContainerNotContained: {
    width: `calc(100% + ${theme.spacing(2)}px)`,
    padding: theme.spacing(2),
    marginLeft: -1.5 * theme.spacing(2),
  },
  tableBody: {
    position: 'relative',
  },
  tr: {
    boxShadow: theme.shadows[1],
  },
  th: {
    padding: theme.spacing(1),
    borderBottom: 'none',
    fontWeight: theme.typography.fontWeightBold,
    '&:last-of-type': {
      paddingRight: 40,
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  td: {
    position: 'relative',
    padding: theme.spacing(1),
    background: '#FFF',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filtersContent: {
    padding: theme.spacing(2),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(2),
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: `calc(100% - ${theme.spacing(2)}px)`,
    opacity: 1,
  },
  loaderHidden: {
    opacity: 0,
    zIndex: -1,
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(3),
  },
  clearFiltersButton: {
    position: 'absolute',
    top: 15,
    right: 20,
  },
  resetFiltersIcon: {
    position: 'absolute',
    right: -6,
    bottom: -2,
    fontSize: 12,
  },
}));

const DataTable = (props: DataTableProps) => {
  const classes = useStyles();
  const {
    id,
    repository,
    columns,
    deletable,
    onDeleteFail,
    actions,
    deleteItemMessage,
    className,
    contained,
    persistFilters = true,
    defaultFilters,
    noResults,
    onFiltersChange,
    onLoad,
    renderRow,
  } = props;
  const history = useHistory();
  const { query } = useContext(SearchContext);
  const [items, setItems] = useState<Array<unknown>>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { appState, setAppState, localStore } = useContext(AppContext);
  const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
  const [columnWidths, setColumnWidths] = useState<number[] | null>(null);

  const withDelete = deletable || false;
  const isContained = contained === undefined ? true : contained;

  const [paginator, setPaginator] = useState<{
    totalPages: number | null;
    currentPage: number;
  }>({
    totalPages: null,
    currentPage: 1,
  });

  const { currentPage } = paginator;

  const [filters, setFilters] = useState<ApiFilterCriteria>(
    defaultFilters || {
      filters: {},
      order: undefined,
    },
  );

  const [deleteState, setDeleteState] = useState<{
    showDialog: boolean;
    item: Item | null;
  }>({
    showDialog: false,
    item: null,
  });

  const resetPaginator = () =>
    setPaginator({ totalPages: null, currentPage: 1 });

  const handleClearFilters = () => {
    setFilters({
      filters: {},
      order: undefined,
    });
    localStore.removeItem(`datatable-${id}-filters`);
  };

  /**
   * Data Loading
   */
  const loadItems = async (
    query: string,
    filters: ApiFilterCriteria,
    currentPage: number,
  ) => {
    // Cancel all existing tokens.
    cancelTokens.forEach((c) => {
      c.cancel();
    });

    const token = axios.CancelToken.source();
    setCancelTokens([...cancelTokens, token]);
    setLoading(true);

    try {
      const response = await repository.findBy(
        { ...filters, query: query || '' },
        currentPage,
        token,
      );

      let totalItems: number;
      let items;
      let perPage = 10;

      if ('data' in response) {
        const { data } = response;

        if ('hydra:member' in data && 'hydra:totalItems' in data) {
          items = data['hydra:member'];
          totalItems = data['hydra:totalItems'];
        } else {
          items = data.items;
          totalItems = data.totalItems;

          if (data.perPage) {
            perPage = data.perPage;
          }
        }
      } else {
        items = response.items;
        totalItems = response.totalItems;

        if (response.perPage) {
          perPage = response.perPage;
        }
      }

      if (onLoad) {
        onLoad(items, totalItems);
      }

      const totalPages = Math.ceil(totalItems / perPage);

      setItems(items);
      setPaginator({
        currentPage: currentPage <= totalPages ? currentPage : 1,
        totalPages,
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      handleClearFilters();
      resetPaginator();
      if (e.response && setAppState && appState) {
        setAppState({ ...appState, errorStatusCode: e.response.status });
      }
    }

    setTimeout(() => {
      setInitialLoaded(true);
      setLoading(false);
      setCancelTokens(cancelTokens.filter((c) => c !== token));
    }, 0);
  };

  /**
   * Search
   */
  const doSearch = useCallback(
    _.debounce((query, filters, currentPage) => {
      if (!ready) {
        return;
      }

      loadItems(query, filters, currentPage);
    }, 500),
    [ready],
  );

  const calculateColumnWidths = () => {
    const columns = document.querySelectorAll<HTMLTableHeaderCellElement>(
      `.${id}-column`,
    );

    // 17 = padding + 1
    setColumnWidths(Array.from(columns).map((c) => c.offsetWidth - 17));
  };

  useEffect(() => {
    if (!initialLoaded) {
      return;
    }

    window.addEventListener('resize', () => {
      setColumnWidths(null);
      calculateColumnWidths();
    });

    calculateColumnWidths();
  }, [initialLoaded]);

  useEffect(() => {
    doSearch(query, filters, currentPage);
  }, [query, filters, currentPage, doSearch]);

  /**
   * Pagination
   */
  const handlePaginate = (e: ChangeEvent<unknown>, page: number) => {
    setPaginator((prev) => ({ ...prev, currentPage: page }));
  };

  /**
   * Deletion
   */
  const handleDelete = (item: any) => {
    setDeleteState({ item, showDialog: true });
  };

  const handleClose = () => {
    setDeleteState({ item: null, showDialog: false });
  };

  const doDelete = () => {
    if (typeof repository.delete !== 'function') {
      return;
    }

    if (deleteState.item !== null) {
      repository
        .delete(deleteState.item.id)
        .then(() => {
          loadItems(query, filters, currentPage);
        })
        .catch(() => {
          if (onDeleteFail) {
            onDeleteFail(deleteState.item);
          }
        });
    }

    setDeleteState({ item: null, showDialog: false });
  };

  /**
   * Sorting
   */
  const handleSort = (property: string) => () => {
    let order: 'asc' | 'desc' = 'asc';

    if (filters.order && filters.order.length > 0) {
      order = filters.order[0].order === 'asc' ? 'desc' : 'asc';
    }

    resetPaginator();
    setFilters({ ...filters, order: [{ field: property, order }] });
  };

  /**
   * Filtering
   */
  const handleFilterColumn = (
    column: Column,
    options: FilterColumnOption[],
  ) => {
    const updatedFilters = { ...filters };
    const jsonBefore = JSON.stringify(updatedFilters);

    if (updatedFilters.filters === undefined) {
      updatedFilters.filters = {};
    }

    if (options.length === 0) {
      delete updatedFilters.filters[column.field];
    } else {
      updatedFilters.filters[column.field] = options;
    }

    // prevent state updates if nothing has changed
    const jsonAfter = JSON.stringify(updatedFilters);
    if (jsonAfter === jsonBefore) {
      return;
    }

    resetPaginator();
    setFilters(updatedFilters);
  };

  const handleFilterColumnByDate = (
    column: Column,
    date: MaterialUiPickersDate,
  ) => {
    const updatedFilters = { ...filters };
    const jsonBefore = JSON.stringify(updatedFilters);

    if (updatedFilters.filters === undefined) {
      updatedFilters.filters = {};
    }

    if (!date) {
      delete updatedFilters.filters[column.field];
    } else {
      updatedFilters.filters[column.field] = date.format('YYYY-MM-DD');
    }

    // prevent state updates if nothing has changed
    const jsonAfter = JSON.stringify(updatedFilters);
    if (jsonAfter === jsonBefore) {
      return;
    }

    resetPaginator();
    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(filters);
    }
  }, [filters]);

  /**
   * State persistence.
   */
  useEffect(() => {
    if (!persistFilters || !ready) {
      return;
    }

    localStore.setItem(`datatable-${id}-filters`, JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    if (!persistFilters) {
      setReady(true);
      return;
    }

    localStore
      .getItem<string>(`datatable-${id}-filters`)
      .then((storedFiltersStr) => {
        if (storedFiltersStr === null) {
          return;
        }

        const storedFilters = JSON.parse(storedFiltersStr);
        const updatedFilters = { ...filters, ...storedFilters };

        // prevent state updates if nothing has changed
        if (JSON.stringify(filters) === JSON.stringify(updatedFilters)) {
          return;
        }

        setFilters(updatedFilters);
      })
      .finally(() => setReady(true));
  }, []);

  /**
   * Whenever the history changes we refresh the data table, even if the route is the same.
   */
  useEffect(
    () =>
      history.listen(async () => {
        await loadItems(query, filters, currentPage);
        setColumnWidths(null);
        calculateColumnWidths();
      }),
    [history],
  );

  const deleteDialogQuery =
    deleteItemMessage && deleteState.item !== null
      ? deleteItemMessage(deleteState.item)
      : 'Weet je zeker dat je dit item wilt verwijderen?';

  return (
    <Box className={className}>
      <ConfirmationDialog
        title="Verwijdering bevestigen"
        query={deleteDialogQuery}
        isOpen={deleteState.showDialog}
        onClose={handleClose}
        onConfirm={doDelete}
      />

      <ConditionalWrapper
        condition={isContained}
        wrapper={(children) => (
          <Paper className={classes.root}>{children}</Paper>
        )}
      >
        <>
          <div className={classes.tableWrapper}>
            <TableContainer
              className={isContained ? '' : classes.tableContainerNotContained}
            >
              <Table
                className={`${classes.table} ${
                  isContained ? classes.tableContained : ''
                }`}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={`cell-header-${column.field}`}
                        className={`${id}-column ${classes.th}`}
                      >
                        <Box display="flex">
                          {column.sortable && (
                            <TableSortLabel
                              active={
                                filters.order &&
                                filters.order[0].field === column.field
                              }
                              direction={
                                filters.order ? filters.order[0].order : 'asc'
                              }
                              onClick={handleSort(column.field)}
                              data-testid={`column-sort-label-${column.field}`}
                            >
                              {column.name}
                            </TableSortLabel>
                          )}
                          {column.filter !== undefined && (
                            <FilterColumn
                              field={column.field}
                              name={column.name}
                              type={column.filter.type}
                              options={column.filter.options}
                              searchOptions={column.filter.searchOptions}
                              config={column.filter.config || {}}
                              onChange={(options: FilterColumnOption[]) => {
                                return handleFilterColumn(column, options);
                              }}
                              onDateChange={(date: MaterialUiPickersDate) => {
                                return handleFilterColumnByDate(column, date);
                              }}
                              criteria={filters}
                              showLabel={!column.sortable}
                            />
                          )}
                          {!column.sortable &&
                            column.filter === undefined &&
                            column.name}
                        </Box>
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell className={`${id}-column ${classes.th}`} />
                    )}
                    {Object.entries(filters.filters || {}).length > 0 && (
                      <Tooltip title="Filters verwijderen">
                        <IconButton
                          size="small"
                          className={classes.clearFiltersButton}
                          onClick={handleClearFilters}
                        >
                          <Box position="relative">
                            <FontAwesomeIcon icon={['fal', 'filter']} />
                            <FontAwesomeIcon
                              icon="trash"
                              size="sm"
                              className={classes.resetFiltersIcon}
                            />
                          </Box>
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {items.map((item) => {
                    const renderedColumns = (
                      <>
                        {columns.map((column, index) => {
                          if (!column.render) {
                            return (
                              <TableCell
                                key={`${(item as { id: string }).id}-cell-${
                                  column.field
                                }`}
                                className={classes.td}
                                style={{
                                  minWidth: columnWidths
                                    ? columnWidths[index]
                                    : undefined,
                                  maxWidth: columnWidths
                                    ? columnWidths[index]
                                    : undefined,
                                }}
                              >
                                {(item as any)[column.field]}
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell
                              key={`${(item as { id: string }).id}-cell-${
                                column.field
                              }`}
                              className={classes.td}
                              style={{
                                minWidth: columnWidths
                                  ? columnWidths[index]
                                  : undefined,
                                maxWidth: columnWidths
                                  ? columnWidths[index]
                                  : undefined,
                              }}
                            >
                              {column.render(item)}
                            </TableCell>
                          );
                        })}
                        {(actions || withDelete) && (
                          <TableCell
                            key={`${(item as { id: string }).id}-cell-actions`}
                            className={classes.td}
                            style={{
                              maxWidth: columnWidths
                                ? columnWidths[columnWidths.length - 1]
                                : undefined,
                              minWidth: columnWidths
                                ? columnWidths[columnWidths.length - 1]
                                : undefined,
                            }}
                          >
                            <div className={classes.actions}>
                              {actions &&
                                actions(item, classes.iconButton, () =>
                                  loadItems(query, filters, currentPage),
                                )}
                              {withDelete && (
                                <Tooltip title="Verwijderen">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleDelete(item as any)}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'trash']} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </>
                    );

                    if (renderRow) {
                      return renderRow(
                        renderedColumns,
                        item,
                        classes.tr,
                        classes.td,
                      );
                    }

                    return (
                      <TableRow
                        key={(item as { id: string }).id}
                        data-testid="table-row"
                        className={classes.tr}
                      >
                        {renderedColumns}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && items.length === 0 && (
              <div className={classes.noResults}>
                {noResults === undefined && 'Geen resultaten gevonden.'}
                {noResults !== undefined && noResults}
              </div>
            )}
            <div
              className={`${classes.loader} ${
                loading ? '' : classes.loaderHidden
              }`}
            >
              <CircularProgress />
            </div>
          </div>
          {paginator.totalPages !== null && paginator.totalPages > 1 && (
            <Box p={2}>
              <Pagination
                count={paginator.totalPages}
                page={paginator.currentPage}
                onChange={handlePaginate}
              />
            </Box>
          )}
        </>
      </ConditionalWrapper>
    </Box>
  );
};

export default DataTable;
