import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import validator from 'validator';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import Home from '../../Home';
import { useStyles as loginStyles } from '../../Login';
import ApiClient from '../../api/ApiClient';
import PasswordInput from '../../components/password/PasswordInput';
import PasswordValidator from '../../components/password/PasswordValidator';
import UserAuthenticator from '../../api/UserAuthenticator';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.text.primary,
  },
  passwordField: {
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    color: theme.palette.text.primary,
  },
}));

type State = {
  password: string;
  confirmPassword: string;
  errors: {
    email?: boolean | undefined;
    password?: boolean | undefined;
    confirmPassword?: boolean | undefined;
  };
  isValid: boolean;
};

const UserActivation = () => {
  const classes = useStyles();
  const formClasses = loginStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const token = new URLSearchParams(location.search).get('token');

  const [state, setState] = useState<State>({
    password: '',
    confirmPassword: '',
    errors: {},
    isValid: false,
  });
  const { password, confirmPassword, errors, isValid } = state;

  if (!token) {
    history.push('/');
    return <div />;
  }

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errors.password = validator.isEmpty(password);
    errors.confirmPassword = validator.isEmpty(confirmPassword);
    setState({ ...state, errors });

    if (errors.password || errors.confirmPassword) {
      return;
    }

    const data = new FormData();
    data.append('token', token);
    data.append('password', password);

    ApiClient.post('/api/users/activate', data, {})
      .then((response: AxiosResponse<{ email: string }>) => {
        new UserAuthenticator(dispatch).login(response.data.email, password);
        history.push('/');
        notifications.enqueueSnackbar('Je account is succesvol geactiveerd!', {
          variant: 'success',
        });
      })
      .catch((err) => {
        if (err.response.data.error === 'invalid_password') {
          state.errors.password = true;
          state.errors.confirmPassword = true;
          setState({ ...state });
        }
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het activeren van je account.',
          { variant: 'error' },
        );
      });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    state.errors.password = false;
    setState({ ...state, password: e.target.value });
  };

  const changeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    state.errors.confirmPassword = e.target.value !== password;
    setState({ ...state, confirmPassword: e.target.value });
  };

  const handleValidation = (isValid: boolean) => {
    setState({ ...state, isValid });
  };

  return (
    <Home>
      <Box className={formClasses.form}>
        <Typography variant="h6" className={formClasses.formTitle}>
          Account activeren
        </Typography>
        <form onSubmit={submitForm} className={classes.form}>
          <Typography variant="body1" className={classes.description}>
            Voer je wachtwoord in.
          </Typography>
          <PasswordInput
            id="password"
            data-testid="password"
            onChange={changePassword}
            error={errors.password}
            className={classes.passwordField}
          />
          <PasswordInput
            id="confirm-password"
            data-testid="confirmPassword"
            onChange={changeConfirmPassword}
            error={errors.confirmPassword}
            className={classes.field}
            label="Bevestig wachtwoord"
          />
          <PasswordValidator
            password={password}
            onValidate={handleValidation}
          />
          <Button
            variant="contained"
            disabled={!isValid}
            color="secondary"
            type="submit"
            data-testid="submit"
          >
            Activeren
          </Button>
        </form>
      </Box>
    </Home>
  );
};

export default UserActivation;
