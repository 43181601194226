import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSnackbar } from 'notistack';
import DataTable, { Column } from '../../components/DataTable';
import FormRepository from './FormRepository';
import { Form } from '../yard-forms/types';
import StatusIndicator from '../../components/StatusIndicator';
import SearchInput from '../../components/search/SearchInput';
import SearchContext from '../../components/search/SearchContext';
import FormCategoryRepository from './FormCategoryRepository';
import FormCategoryManagerDialog from './FormCategoryManagerDialog';
import FormImporterDialog from './FormImporterDialog';
import CopyId from '../yard-forms/CopyId';
import TextMedia from '../../components/pageheader/TextMedia';
import { ReactComponent as FormsIllustration } from '../../assets/images/forms_illustration.svg';
import AppContext from '../../AppContext';
import { ApiFilterCriteria } from '../../types';
import Loader from '../../components/Loader';

const useStyles = makeStyles(() => ({
  itemActions: {
    display: 'flex',
    alignItems: 'center',
  },
  copyId: {
    marginTop: 0,
    marginBottom: 0,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const FormOverview = () => {
  const history = useHistory();
  const classes = useStyles();
  const notifications = useSnackbar();
  const [query, setQuery] = useState<string>('');
  const [categoryManagerOpen, setCategoryManagerOpen] =
    useState<boolean>(false);
  const [importerOpen, setImporterOpen] = useState<boolean>(false);
  const [repository, setRepository] = useState<FormRepository>(
    new FormRepository(),
  );
  const [state, setState] = useState<{
    loaded: boolean;
    defaultFilters?: ApiFilterCriteria;
  }>({
    loaded: false,
  });
  const { appState, setAppState, roleViewManager, localStore } =
    useContext(AppContext);
  const { loaded, defaultFilters } = state;

  /**
   * Handle load of form overview.
   */
  const loadOverview = async () => {
    const category = await localStore.getItem<string>('form_categories');

    const filters: { [key: string]: string } = {};

    if (category) {
      filters.category = category;
    }

    setState({
      loaded: true,
      defaultFilters: {
        query: '',
        filters,
        order: undefined,
      },
    });
  };

  useEffect(() => {
    loadOverview();
  }, []);

  /**
   * Handling of the category manager dialog.
   */
  const handleCategoryManagerOpen = () => setCategoryManagerOpen(true);
  const handleCategoryManagerClose = () => setCategoryManagerOpen(false);

  /**
   * Handling of the form importer dialog.
   */
  const handleImporterOpen = () => setImporterOpen(true);
  const handleImporterClose = () => setImporterOpen(false);
  const handleImporterImport = () => {
    handleImporterClose();
    setRepository(new FormRepository()); // Refresh data table.
  };

  /**
   * Download an export of all forms as a CSV file.
   */
  const handleFormsCsvExport = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/forms/csv-export`;
  };

  /**
   * Download an export of the form as JSON for later imports.
   */
  const handleFormExport = (form: Form) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/forms/${form.id}/export`;
  };

  /**
   * Download an export of the form as a PDF.
   */
  const handleFormPdfExport = (form: Form) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/forms/${form.id}/pdf`;
  };

  /**
   * Duplicate the given form.
   */
  const handleFormDuplicate = (form: Form) => {
    repository
      .duplicate(form.id)
      .then(() => {
        notifications.enqueueSnackbar('Formulier is succesvol gedupliceerd!', {
          variant: 'success',
        });
        setRepository(new FormRepository());
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  };

  /**
   * Runs whenever the filters are changed.
   * @param filters
   */
  const handleFiltersChange = (filters: ApiFilterCriteria) => {
    if (
      filters.filters?.category !== undefined &&
      typeof filters.filters.category === 'string'
    ) {
      localStore.setItem('form_categories', filters.filters.category);
    } else {
      localStore.removeItem('form_categories');
    }
  };

  /**
   * Set the search context.
   */
  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  if (!loaded) {
    return <Loader />;
  }

  /**
   * Columns for the data table.
   */
  const columns: Column[] = [
    {
      name: 'Titel',
      field: 'title',
      sortable: true,
      render: (form: Form) => (
        <Link to={`/formulieren/${form.id}`}>
          <StatusIndicator indicator={form.inUse ? 'green' : 'red'} />
          <Box ml={1}>{form.title}</Box>
        </Link>
      ),
    },
    {
      name: 'Categorie',
      field: 'category',
      filter: {
        type: 'autocomplete' as const,
        options: async () => {
          const response = await new FormCategoryRepository().findBy(
            undefined,
            1,
            undefined,
            1000,
          );

          return response.data.items.map((category) => ({
            value: category.id,
            label: category.name,
          }));
        },
      },
      render: (form: Form) => (
        <div>{form.categories.map((category) => category.name).join(', ')}</div>
      ),
    },
    {
      name: 'Aantal inzendingen',
      field: 'entryCount',
      sortable: true,
    },
    {
      name: 'Beoordelingstype',
      field: 'evaluationType',
      sortable: true,
      render: (form: Form) => (
        <StatusIndicator
          indicator={
            form.grade || (form.valuation && form.evaluationOptions.length > 0)
              ? 'green'
              : 'orange'
          }
          variant="circle"
        />
      ),
    },
    {
      name: 'Aangemaakt op',
      field: 'created',
      sortable: true,
      render: (form: Form) => (
        <div>
          {form.created ? moment(form.created).format('DD-MM-YYYY') : ''}
        </div>
      ),
    },
  ];

  /**
   * Actions for each item in the data table.
   *
   * @param form
   * @param className
   */
  const itemActions = (form: Form, className: string) => (
    <div className={classes.itemActions}>
      <Tooltip title="ID kopiëren">
        <CopyId
          id={form.id}
          icon="fingerprint"
          iconOnly
          className={`${className} ${classes.copyId}`}
        />
      </Tooltip>
      {roleViewManager.hasPermission('SAVE_FORM') && (
        <Tooltip title="Formulier dupliceren">
          <IconButton
            size="small"
            onClick={() => handleFormDuplicate(form)}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'copy']} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Formulier naar PDF">
        <IconButton
          size="small"
          onClick={() => handleFormPdfExport(form)}
          className={className}
        >
          <FontAwesomeIcon icon={['fal', 'file-pdf']} />
        </IconButton>
      </Tooltip>
      {roleViewManager.hasRole('ROLE_ADMIN') && (
        <Tooltip title="Formulier exporteren">
          <IconButton
            size="small"
            onClick={() => handleFormExport(form)}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'file-export']} />
          </IconButton>
        </Tooltip>
      )}
      {roleViewManager.hasPermission('SAVE_FORM') && (
        <Tooltip title="Formulier bewerken">
          <IconButton
            size="small"
            component={Link}
            to={`/formulieren/${form.id}`}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <FormCategoryManagerDialog
        open={categoryManagerOpen}
        onClose={handleCategoryManagerClose}
      />

      <FormImporterDialog
        open={importerOpen}
        onClose={handleImporterClose}
        onImport={handleImporterImport}
      />

      <TextMedia
        name="Formulieren"
        illustration={<FormsIllustration />}
        description="Beheer hier de formulieren die gebruikt worden<br>voor het inleveren en beoordelen van opdrachten."
        descriptionSpacing={0}
        size="medium"
      />

      <Container>
        <div className={classes.actions}>
          <Grid container spacing={2}>
            {roleViewManager.hasPermission('SAVE_FORM') && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => history.push('/formulieren/aanmaken')}
                  startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                >
                  Nieuw formulier
                </Button>
              </Grid>
            )}
            {roleViewManager.hasPermission('MANAGE_FORM_CATEGORIES') && (
              <Grid item>
                <Button
                  startIcon={<FontAwesomeIcon icon={['fal', 'layer-group']} />}
                  onClick={handleCategoryManagerOpen}
                >
                  Categori&euml;en
                </Button>
              </Grid>
            )}
            {roleViewManager.hasRole('ROLE_ADMIN') && (
              <Grid item>
                <Button
                  startIcon={<FontAwesomeIcon icon={['fal', 'file-import']} />}
                  onClick={handleImporterOpen}
                >
                  Importeren
                </Button>
              </Grid>
            )}
            {roleViewManager.hasRole('ROLE_ADMIN') && (
              <Grid item>
                <Button
                  startIcon={<FontAwesomeIcon icon={['fal', 'file-csv']} />}
                  onClick={handleFormsCsvExport}
                >
                  CSV export
                </Button>
              </Grid>
            )}
          </Grid>
          <SearchInput />
        </div>
        <Box mt={3}>
          <DataTable
            id="form-overview"
            repository={repository}
            columns={columns}
            actions={itemActions}
            deletable={roleViewManager.hasPermission('DELETE_FORM')}
            onDeleteFail={() => {
              notifications.enqueueSnackbar(
                'Fout bij verwijderen van formulier.',
                { variant: 'error' },
              );
            }}
            defaultFilters={defaultFilters}
            onFiltersChange={handleFiltersChange}
          />
        </Box>
      </Container>
    </SearchContext.Provider>
  );
};

export default FormOverview;
