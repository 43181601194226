import {
  CollectionResponse,
  Education,
  EducationPeriod,
  Repository,
} from '../../types';
import ApiClient from '../../api/ApiClient';

class EducationPeriodRepository implements Repository<EducationPeriod> {
  private education: Education;

  constructor(education: Education) {
    this.education = education;
  }

  findBy(): Promise<CollectionResponse<EducationPeriod>> {
    return ApiClient.get(`/api/education/${this.education.id}/periods`);
  }
}

export default EducationPeriodRepository;
