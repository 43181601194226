import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TokenResponse, TwoFactorMethod, User } from '../types';

export interface LoginResponse {
  login: string;
  '2fa_complete'?: boolean;
  error?: '2fa_configuration_required';
  account?: User;
  email?: string;
  auth_method: TwoFactorMethod;
}

class ApiClient {
  client: AxiosInstance;

  url: string | null;

  constructor() {
    this.client = axios.create({
      withCredentials: true,
    });
    this.url = process.env.REACT_APP_API_URL || null;
  }

  getRoles() {
    return this.get('/api/roles');
  }

  getUserFormData() {
    return this.get('/api/user-form-data');
  }

  login(
    email: string,
    password: string,
  ): Promise<AxiosResponse<LoginResponse>> {
    return this.client.post(`${this.url}/api/login`, { email, password });
  }

  getToken(email: string, password: string): Promise<TokenResponse> {
    return this.client.post(`${this.url}/api/token`, { email, password });
  }

  resetTwoFactorMethod(token: string) {
    return axios.post(`${this.url}/2fa_reset`, {
      token,
    });
  }

  resetTwoFactorMethodRequest() {
    return axios.post(`${this.url}/2fa_reset_request`, [], {
      withCredentials: true,
    });
  }

  sendActivationMail(email: string) {
    return axios.post(
      `${this.url}/api/users/send-activation-link`,
      { email },
      { withCredentials: true },
    );
  }

  syncUser(afasId: string) {
    return this.post(`/api/afas/sync/${afasId}`);
  }

  async get(uri: string, params = {}, headers = {}, options = {}) {
    return this.client.get(this.url + uri, { params, headers, ...options });
  }

  async put(uri: string, data = {}) {
    return axios.put(this.url + uri, data, { withCredentials: true });
  }

  async post(uri: string, data = {}, options: AxiosRequestConfig = {}) {
    return axios.post(this.url + uri, data, {
      ...options,
      withCredentials: true,
    });
  }

  async delete(uri: string) {
    return axios.delete(this.url + uri, { withCredentials: true });
  }
}

export default new ApiClient();
