import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { red } from '@material-ui/core/colors';
import { Form, FormEntry as FormEntryInterface } from './types';
import fields from './fields';
import { CompleteUser, FormDataViolation } from '../../types';
import FormContext from './FormContext';
import { FormContextFilesState, FormContextState } from './Form';
import { FieldState } from './fields/FieldProps';
import { FormFieldInterface } from './FormField';
import { filterFormData, getInitialFormDataForEntry } from './helpers';
import FieldStart from './components/FieldStart';
import Loader from '../../components/Loader';
import AppContext from '../../AppContext';

interface FormEntryProps {
  form: Form;
  entries: FormEntryInterface[];
  readOnly?: boolean;
  onFormDataChange?: (
    formData: { [key: string]: FieldState },
    files: FormContextFilesState,
  ) => void;
  onFormEntryChange?: (
    entry: FormEntryInterface,
    mostRecentEntry: boolean,
  ) => void;
  violations?: FormDataViolation[];
}

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: 22,
  },
  entry: {
    marginTop: theme.spacing(2),
  },
  entrySelect: {
    marginBottom: theme.spacing(2),
  },
  fieldLabel: {},
  fieldError: {
    '& > $fieldLabel, & > $violationMessage': {
      color: red[300],
    },
  },
  entryDisplay: {
    opacity: 1,
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    background:
      'linear-gradient(340deg, hsl(209deg 40% 96%), hsl(209deg 40% 99%))',
    borderRadius: 12,
  },
}));

const FormEntry = (props: FormEntryProps) => {
  const classes = useStyles();
  const {
    form,
    entries,
    readOnly = false,
    onFormDataChange,
    onFormEntryChange,
    violations,
  } = props;
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormContextState>({});
  const [files, setFiles] = useState<FormContextFilesState>({});
  const [entry, setEntry] = useState<FormEntryInterface | null>(null);
  const { roleViewManager } = useContext(AppContext);

  const formContextValue = useMemo(
    () => ({ formData, setFormData, files, setFiles }),
    [formData, setFormData, files, setFiles],
  );

  const mostRecentEntry = useMemo(
    () =>
      entries.sort((a, b) =>
        new Date(a.created) > new Date(b.created) ? -1 : 1,
      )[0],
    [entry],
  );

  // Initialize form data from entry.
  useEffect(() => {
    if (!mostRecentEntry) {
      setLoading(false);
      return;
    }

    setFormData(
      getInitialFormDataForEntry(
        mostRecentEntry,
        account,
        roleViewManager.isParticipantView(),
      ),
    );
    setLoading(false);
  }, []);

  const handleEntryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const entry = entries.find((entry) => entry.id === event.target.value)!;
    setEntry(entry);
  };

  useEffect(() => {
    if (!entry) {
      return;
    }

    if (onFormEntryChange) {
      onFormEntryChange(entry, entry.id === mostRecentEntry.id);
    }
  }, [entry]);

  useEffect(() => {
    if (onFormDataChange && entry === mostRecentEntry) {
      onFormDataChange(
        filterFormData(
          form,
          formData,
          account,
          roleViewManager.isParticipantView(),
        ),
        files,
      );
    }
  }, [formData]);

  useEffect(() => {
    setEntry(mostRecentEntry);
  }, [entries]);

  if (loading) {
    return <Loader />;
  }

  return (
    <FormContext.Provider value={formContextValue}>
      {entry && entries.length > 1 && (
        <Select
          value={entry.id}
          onChange={handleEntryChange}
          className={classes.entrySelect}
        >
          {entries
            .sort((a, b) =>
              new Date(a.created) > new Date(b.created) ? -1 : 1,
            )
            .map((entry, index) => (
              <MenuItem key={entry.id} value={entry.id}>
                {moment(entry.created).format('DD-MM-YYYY HH:mm')}
                {index === 0 && ' (Meest recent)'}
              </MenuItem>
            ))}
        </Select>
      )}
      {entry && (
        <div>
          {(entry ? entry.form : form).fields.map(
            (field: FormFieldInterface) => {
              const entryField = entry.fields.find(
                (f) => f.field.id === field.id,
              );

              const canWrite =
                !readOnly &&
                Boolean(
                  onFormDataChange &&
                    entry.id === mostRecentEntry.id &&
                    field.roles &&
                    field.roles.filter(
                      (role) =>
                        role !== 'ROLE_PARTICIPANT' &&
                        account.roles.includes(role),
                    ).length > 0,
                );

              let entryContent = null;

              const Field = fields.find((f) => f.type === field.type)?.field;

              if (canWrite && Field !== undefined) {
                entryContent = (
                  <Field
                    id={field.id}
                    label={field.label}
                    description={field.description}
                    options={field.options || []}
                    metadata={field.metadata}
                    entryField={entryField}
                  />
                );
              }

              if (entryContent === null) {
                const Display = fields.find(
                  (f) => f.type === field.type,
                )?.entryDisplay;

                entryContent = (
                  <>
                    {Display !== undefined && entryField && (
                      <Box className={classes.entryDisplay}>
                        <Display
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...entryField}
                        />
                      </Box>
                    )}
                    <div className={classes.field}>
                      {Display === undefined && entryField && entryField.value}
                      {!entryField && Field !== undefined && (
                        <Field
                          id={field.id}
                          label={field.label}
                          description={field.description}
                          options={field.options || []}
                          metadata={field.metadata}
                          disabled
                        />
                      )}
                    </div>
                  </>
                );
              }

              const fieldClasses = [classes.field];

              const violation =
                violations &&
                violations.find(
                  (violation) => violation.parameters.fieldId === field.id,
                );
              if (violation) {
                fieldClasses.push(classes.fieldError);
              }

              return (
                <div key={field.id} className={fieldClasses.join(' ')}>
                  <FieldStart
                    formField={field}
                    violation={violation}
                    className={classes.fieldLabel}
                  />
                  <div className={classes.entry}>{entryContent}</div>
                </div>
              );
            },
          )}
        </div>
      )}
    </FormContext.Provider>
  );
};

export default FormEntry;
