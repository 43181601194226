import { CollectionResponse, Repository } from '../../types';
import ApiClient from '../../api/ApiClient';

class ParticipantsRepository implements Repository<any> {
  private sessionId: number;

  private eventId: number;

  constructor(sessionId: number, eventId: number) {
    this.sessionId = sessionId;
    this.eventId = eventId;
  }

  findBy(): Promise<CollectionResponse<any>> {
    return ApiClient.get(
      `/api/events/${this.eventId}/sessions/${this.sessionId}/participants`,
    );
  }
}

export default ParticipantsRepository;
