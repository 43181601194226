import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ApiClient from '../../api/ApiClient';
import DialogCloseButton from '../../components/DialogCloseButton';

interface SyncDialogProps {
  open: boolean;
  onClose: () => void;
  onSyncStart?: () => void;
  onSyncFinished?: () => void;
}

const SyncDialog = (props: SyncDialogProps) => {
  const notifications = useSnackbar();
  const { open, onClose, onSyncStart, onSyncFinished } = props;

  /**
   * Triggers user synchronisation.
   */
  const syncUsers = () => {
    if (onSyncStart) {
      onSyncStart();
    }

    ApiClient.post('/api/afas/sync')
      .then(() => {
        notifications.enqueueSnackbar(
          'De synchronisatie is succesvol gestart.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het starten van de synchronisatie.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        if (onSyncFinished) {
          onSyncFinished();
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="sync-dialog-title"
      aria-describedby="sync-dialog-description"
    >
      <DialogTitle id="sync-dialog-title">
        Synchroniseren
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="sync-dialog-description">
          Het uitvoeren van een synchronisatie kan een paar uur duren. Weet je
          zeker dat je de synchronisatie wilt starten?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={syncUsers} variant="contained" color="secondary">
          Starten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncDialog;
