import React, { useContext } from 'react';
import { FieldProps } from './FieldProps';
import LikertComponent from '../Likert';
import FormContext from '../FormContext';

const Likert = (props: FieldProps) => {
  const { id, metadata, entryField, disabled } = props;
  const { rows, columns, sections } = metadata;

  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (selected: string[]) => {
    setFormData!({
      ...formData,
      [id]: {
        valid: true,
        value: JSON.stringify(selected),
      },
    });
  };

  return (
    <div>
      <LikertComponent
        rows={rows}
        columns={columns}
        sections={sections}
        onChange={handleChange}
        initialSelected={entryField ? entryField.options : []}
        multiple={metadata.multiple || false}
        disabled={disabled}
      />
    </div>
  );
};

export default Likert;
