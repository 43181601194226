import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Assignment } from '../../../types';
import AssignmentRepository from './repository/AssignmentRepository';

interface DeleteAssignmentDialogProps {
  assignment: Assignment;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteAssignmentDialog = (props: DeleteAssignmentDialogProps) => {
  const { assignment, onClose, open, onDelete } = props;
  const notifications = useSnackbar();

  const doDelete = () => {
    new AssignmentRepository()
      .delete(assignment.id)
      .then(() => {
        onDelete();
        notifications.enqueueSnackbar('De opdracht is verwijderd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij verwijderen van de opdracht.', {
          variant: 'error',
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-assignment-title"
      aria-describedby="delete-assignment-description"
    >
      <DialogContent>
        <DialogContentText id="delete-assignment-description">
          {`Weet je zeker dat je de opdracht "${assignment.name}" wilt verwijderen?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          Annuleren
        </Button>
        <Button onClick={doDelete} color="secondary">
          Verwijderen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAssignmentDialog;
